import axios from "axios";
import Config from "config";

export const getBalanceData = async () => {
  //get from redux
  const key = window.localStorage.getItem("kraken_key");
  const secret = window.localStorage.getItem("kraken_sec");

  let url = `https://${Config.takyon_domain}/api/v1/balance?k_key=${key}&k_sec=${secret}&exchange=coinbase_light`;

  const response = await axios.get(
    url,
    {},
    {
      headers: {
        Accept: "*",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return response.data;
};

export const getAssetPairs = async (u) => {
  // let url = 'https://api.kraken.com/0/public/AssetPairs';
  let url = `https://${Config.takyon_domain}/api/v1/AssetPairs`;
  // TMD console.log("Now Fetching KrakenAssetPairs from " + url);

  const response = await axios.get(
    url,
    {},
    {
      headers: {
        Accept: "*",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return response.data;
};

export const getTrades = async ({ start, end }) => {
  let startDate = start
    .toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/\//g, "-")
    .split("-")
    .reverse()
    .join("-");

  let endDate = end
    .toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/\//g, "-")
    .split("-")
    .reverse()
    .join("-");
  //

  //CHECK exchange in redux. it can be not coinbase

  const coinbaseToken =
    window.localStorage.getItem("coinbase_token") ||
    window.localStorage.getItem("takyon_token") ||
    "no_token";

  // Let's get Trades here
  let url =
    "https://" +
    Config.takyon_domain +
    "/api/v1/trades?from=" +
    startDate +
    "&to=" +
    endDate +
    "&c_token=" +
    coinbaseToken +
    '&exchange=coinbase_light' ;

  const response = await axios.get(
    url,
    {},
    {
      headers: {
        Host: "10.0.0.121/api/v1",
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return response.data;
};

export const getPortfolio = async ({ start, end, id }) => {
  const coinbaseToken =
    window.localStorage.getItem("coinbase_token") ||
    window.localStorage.getItem("takyon_token") ||
    "no_token";

  // Let's get Portfolio here
  if (id === undefined) {
    id = Number(Date.now()).toString();
    // TMD console.log("id undefined, setting " + id);
  }
  let url =
    "https://" +
    Config.takyon_domain +
    "/api/v1/pnl?id=" +
    id +
    "&c_token=" +
    coinbaseToken +
    "&exchange=coinbase_light";

  const response = await axios.get(
    url,
    {},
    {
      headers: {
        Host: `${Config.takyon_domain}/api/v1`,
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return response;
};

export const getOHLCData = async ({ rcoin, interval }) => {
  const coinbaseToken =
    window.localStorage.getItem("coinbase_token") ||
    window.localStorage.getItem("takyon_token") ||
    "no_token";

  let i = interval;
  if (i === undefined) {
    i = "60";
  }
  let url =
    "https://" +
    Config.takyon_domain +
    "/api/v1/ohlc/?pair=" +
    encodeURIComponent(rcoin) +
    "&interval=" +
    i +
    "&exchange=coinbase&c_token=" +
    coinbaseToken;
  // TMD console.log( '1. Now Fetching OHLC from ' + url);

  const response = await axios.get(
    url,
    {},
    {
      headers: {
        Accept: "*",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return response.data;
};

export const getBalanceHistory = async () => {
  const coinbaseToken =
    window.localStorage.getItem("coinbase_token") || "no_token";

  // http://localhost:3111/balance_history?token=5189b05a7cee5332538c754c613b1ee5a2426d460291fa89d60343c0babbb144&exchange=coinbase_light"

  let url =
    "https://" +
    Config.takyon_domain +
    "/api/v1/balance_history?exchange=coinbase_light&token=" +
    coinbaseToken;
  // TMD console.log( 'Now Fetching Balance-History from ' + url);

  const response = await axios.get(
    url,
    {},
    {
      headers: {
        Accept: "*",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  // TMD console.log(response);
  return response;
};

export const getCoinbaseAccounts = async ({accessToken}) => {
  let url =
    "https://" +
    Config.takyon_domain +
    "/api/v1/accounts?exchange=coinbase_light&c_token=" +
    accessToken;

  const response = await axios.get(
    url,
    {},
    {
      headers: {
        Accept: "*",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return response;
};
