import * as actionTypes from "./actions";

const initialState = {
  isOpen: false,
  response: {}
};

const coinbaseConfirmOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MODAL_COINBASE_CONFIRM_STATUS: {
      const isOpen = action.isOpen;
      return {
        ...state,
        isOpen,
      };
    }
    case actionTypes.SET_MODAL_COINBASE_CONFIRM_RESPONSE: {
      const response = action.response;
      return {
        ...state,
        response,
      };
    }
    default:
      return state;
  }
};

export default coinbaseConfirmOrderReducer;
