import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import PerfectScrollbar from "react-perfect-scrollbar";
import colors from "assets/scss/_themes-vars.module.scss";

import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

// project imports
import MainCard from "ui-component/cards/MainCard";
import SubCard from "ui-component/cards/SubCard";
import SecondaryAction from "ui-component/cards/CardSecondaryAction";

// assets
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useDispatch, useSelector } from "react-redux";

import { getTradesTable } from "store/actions";

// style constant
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

// table data
function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      { date: "2020-01-05", customerId: "11091700", amount: 3 },
      { date: "2020-01-02", customerId: "Anonymous", amount: 1 },
    ],
  };
}

function Row(props) {
  const theme = useTheme();
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const green =
    theme.palette.mode === "dark"
      ? colors.successLightTakyon
      : colors.successDarkTakyon;
  const red =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;
  const cornsilk =
    theme.palette.mode === "dark"
      ? colors.cornsilkLightTakyon
      : colors.cornsilkDarkTakyon;

  return (
    <>
      <TableRow hover className={classes.root}>
        <TableCell sx={{ pl: 3 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.coin}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        {/* <TableCell align="right">{row.calories}</TableCell> */}
        {/* <TableCell align="right">{row.fat}</TableCell> */}
        {/* <TableCell align="right">{row.carbs}</TableCell> */}
        <TableCell align="right" sx={{ pr: 3 }}>
          {row.protein}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
              }}
            >
              <TableContainer>
                <SubCard
                  sx={{
                    bgcolor:
                      theme.palette.mode === "dark"
                        ? "dark.800"
                        : theme.palette.grey[50],
                    mb: 2,
                  }}
                  title="History"
                  content={false}
                >
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>exchId</TableCell>
                        <TableCell>pair</TableCell>
                        <TableCell>side</TableCell>
                        <TableCell>qty</TableCell>
                        <TableCell>rem</TableCell>
                        <TableCell>filled</TableCell>
                        <TableCell>price</TableCell>
                        <TableCell>status</TableCell>
                        <TableCell align="right">sequence</TableCell>
                        <TableCell align="right">data</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.data.map((dataRow) => {
                        let s = "NEW";
                        if (dataRow.orderStatus === 1) s = "NEW";
                        else if (dataRow.orderStatus === 2) s = "PENDING";
                        else if (dataRow.orderStatus === 3) s = "ACK";
                        else if (dataRow.orderStatus === 4)
                          s = "PENDING_CANCEL";
                        else if (dataRow.orderStatus === 5) s = "CANCELED";
                        else if (dataRow.orderStatus === 6) s = "FILLED";
                        else if (dataRow.orderStatus === 7) s = "PARTIAL";
                        else if (dataRow.orderStatus === 8) s = "REJECTED";
                        else s = "UNKNOWN";

                        const rung = dataRow.side === "SELL" ? red : green;

                        return (
                          <TableRow hover key={dataRow.curr}>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ color: rung }}
                            >
                              {" "}
                              {dataRow.exchId}{" "}
                            </TableCell>
                            <TableCell sx={{ color: rung }}>
                              {" "}
                              {dataRow.fc}{" "}
                            </TableCell>
                            <TableCell sx={{ color: rung }}>
                              {" "}
                              {dataRow.side}{" "}
                            </TableCell>
                            <TableCell sx={{ color: rung }}>
                              {" "}
                              {dataRow.qty}{" "}
                            </TableCell>
                            <TableCell sx={{ color: rung }}>
                              {" "}
                              {dataRow.rem}{" "}
                            </TableCell>
                            <TableCell sx={{ color: rung }}>
                              {" "}
                              {dataRow.filled}{" "}
                            </TableCell>
                            <TableCell sx={{ color: rung }}>
                              {" "}
                              {dataRow.px}{" "}
                            </TableCell>
                            <TableCell sx={{ color: rung }}> {s} </TableCell>
                            <TableCell sx={{ color: rung }} align="right">
                              {" "}
                              {dataRow.sequence}{" "}
                            </TableCell>
                            <TableCell sx={{ color: rung }} align="right">
                              {" "}
                              {new Date(dataRow.curr).toLocaleTimeString()}{" "}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </SubCard>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};
 

// ===========================|| TABLE - COLLAPSIBLE ||=========================== //

export default function TableTrades() {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getTradesTable());
  //   console.log("TableTrades: dispatch(getTradesTable)");
  // }, []);

  const tableData = useSelector((state) => state.trades.tradesTable);
  console.log("TableTrades: " + tableData.length);

  return (
    <MainCard
      content={false}
      title="Trades"
      sx={{
        height: "700px",
        padding: 0,
      }}
    >
      <PerfectScrollbar>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ pl: 1 }} />
                <TableCell>coin</TableCell>
                <TableCell>Order ID</TableCell>
              </TableRow>
            </TableHead>
             <TableBody>
              {tableData.map((row) => (
                <Row key={row.name} row={row} />
              ))}
            </TableBody> 
          </Table>
        </TableContainer>
      </PerfectScrollbar>
    </MainCard>
  );
}
