/* eslint-disable react-hooks/rules-of-hooks */
import useOrders from "hooks/useOrders";
import useOrderTicket from "hooks/useOrderTicket";
import useKraken from "hooks/useKraken";


// const { renewKrakenToken } = useKraken();
const { orders } = useOrders();
const { handleOrderError, handleOrderOpen } = useOrderTicket();

export const openOrders = (data) => {
  data.data.map((order) => orders.addItem(order));
  handleOrderOpen(data.data);
};

export const addOrderStatus = (data) => {
  if (data.error) {
    handleOrderError(data.error);
    if(data.error === 'ESession:Invalid session') {
      const { renewKrakenToken } = useKraken();
      renewKrakenToken();
    }
    
  }
};
