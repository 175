import React, { useState, useContext, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import CoinContext from "contexts/CoinContext";
import { useDispatch } from "react-redux";
import colors from "assets/scss/_themes-vars.module.scss";
import SearchIcon from "@material-ui/icons/Search";

import { map_coins, map_balance_to_coin } from "store/constant";
import { Tab, Tabs } from "@material-ui/core";

import { placeOrderTicket, coinbaseBuy, coinbaseSell } from "store/actions";
import {
  setModalSearchStatus,
  setModalCoinbasePaymentmethodStatus,
} from "store/actions";

import {
  Divider,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  IconButton,
  Chip,
  Fab,
} from "@material-ui/core";
import MUIFormControl from "@material-ui/core/FormControl";
import Transitions from "ui-component/extended/Transitions";

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import { Grid, Button, Typography, useMediaQuery } from "@material-ui/core";
import ColorSubCard from "ui-component/cards/ColorSubCard";
import useSocket from "hooks/useSocket";
import useOrderTicket from "hooks/useOrderTicket";
import { IconLoader } from "@tabler/icons";
import AnimateButton from "ui-component/extended/AnimateButton";
import ToolTipHelper from "ui-component/ToolTipHelper";
import { tooltips } from "constants/tooltips";

// style constant
const useStyles = makeStyles((theme) => ({
  revenueCard: {
    position: "relative",
    color: "#fff",
  },
  revenueIcon: {
    position: "absolute",
    right: "13px",
    top: "14px",
    color: "#fff",
    "&> svg": {
      width: "100px",
      height: "100px",
      opacity: "0.5",
    },
    [theme.breakpoints.down("xs")]: {
      top: "13px",
      "&> svg": {
        width: "80px",
        height: "80px",
      },
    },
  },
}));

// =============================|| REVENUE CARD ||============================= //

const LtpButton = React.memo(({ coin, setCurrentLtp }) => {
  const ltp = useSelector((state) => {
    return (
      (state.coins.kraken_asset_pairs_loaded &&
        state.coins.kraken_asset_pairs.get(coin) &&
        state.coins.kraken_asset_pairs.get(coin).ltp) ||
      0.0
    );
  });

  const handleClick = () => setCurrentLtp(ltp);

  return (
    <Button variant="contained" size="small" onClick={handleClick}>
      LTP: {ltp}
    </Button>
  );
});

const OrderTicket = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const green =
    theme.palette.mode === "dark"
      ? colors.successLightTakyon
      : colors.successDarkTakyon;
  const red =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;
  const cornsilk =
    theme.palette.mode === "dark"
      ? colors.cornsilkLightTakyon
      : colors.cornsilkDarkTakyon;
  const whiteTakyon =
    theme.palette.mode === "dark"
      ? colors.whiteLightTakyon
      : colors.whiteDarkTakyon;

  const matchDownXs = useMediaQuery(theme.breakpoints.down("xs"));
  let textc = "lightyellow";
  if (theme.palette.background.paper === "#ffffff") {
    textc = "darkred";
  }

  const [selectedExchange, exchanges] = useSelector((state) => [
    state.exchange.selectedExchange,
    state.exchange.exchanges,
  ]);

  const coinbaseSelectedPayment = useSelector((state) => {
    return state.coinbasePaymentMethods.selected;
  });

  const handleChange = (e) => {}; // setOrder({ ...order, price: e.target.value });

  const handleSelectPaymentMethod = () => {
    dispatch(setModalCoinbasePaymentmethodStatus(true));
  };

  const { coin } = useContext(CoinContext);

  const cash = useSelector((state) => {
    return state.coins.newportfolio
      ? state.coins.newportfolio.total_cash.toFixed(2)
      : 0.0;
  });

  const coin_name = useSelector((state) => {
    const row = state.coins.kraken_asset_pairs.get(coin);
    if (row === undefined) {
      return coin;
    }
    const coin_name = row.marketcap
      ? row.marketcap.Name
      : row.base_currency
      ? row.base_currency
      : row.base
      ? row.base
      : row.wsname;
    return coin_name;
  });

  const { accounts } = useSelector((state) => {
    return state.coinbaseAccounts;
  });

  const account = accounts.find(
    (item) =>
      item.coin.toLowerCase() === coin?.split("/")[0].toLocaleLowerCase()
  ); //transform SKL/USD to  just SKL

  const coinfolio = useSelector((state) => {
    return state.coins.kraken_asset_pairs_loaded &&
      state.coins.kraken_asset_pairs.get(coin) &&
      state.coins.kraken_asset_pairs.get(coin).position
      ? state.coins.kraken_asset_pairs.get(coin).position.balance
      : 0.0;
  });

  const ltp = useSelector((state) => {
    return (
      (state.coins.kraken_asset_pairs_loaded &&
        state.coins.kraken_asset_pairs.get(coin) &&
        state.coins.kraken_asset_pairs.get(coin).ltp) ||
      0.0
    );
  });
  const [tabValue, setTabValue] = React.useState(0);

  const validationSchema = yup.object({
    // quantity: yup.number("only numbers").required("required."),
    // price: yup.number("only numbers").required("required."),
    total: yup.number("only numbers").required("required."),
    // .max(tabValue === 0 ? 1000 : account.balance), //for sell
  });
  const formik = useFormik({
    initialValues: {
      // funds: 0.0,
      quantity: 0,
      // price: 0.0,
      total: 0.0,
    },
    validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
    },
  });

  const [calculatedPrice, setCalculatedPrice] = useState(0.0);

  useEffect(() => {
    setCalculatedPrice((ltp * formik.values.total).toFixed(2));
  }, [formik.values.total, ltp]);

  useEffect(() => {
    if (blocked) {
      setDisplayPreviousOrder(true);
    }

    formik.resetForm();
    setCurrentLtp();
  }, [coin]);

  // const calculateTotal = () =>
  //   formik.setFieldValue("total", formik.values.price * formik.values.quantity);

  // useEffect(() => {
  //   calculateTotal();
  // }, [formik.values.quantity, formik.values.price]);

  // const calculateQuantity = (e) => {
  //   formik.setFieldValue(
  //     "quantity",
  //     Number(e.target.value) / Number(formik.values.price)
  //   );
  // };

  const setCurrentLtp = (ltp = 0) => {
    formik.setFieldValue("price", ltp);
  };

  const increseQuantity = (item) => {
    formik.setFieldValue(
      "quantity",
      Number(formik.values.quantity) + Number(item)
    );
    // calculateTotal();
  };

  const { emit } = useSocket();
  const { checkOrderTimeout } = useOrderTicket();
  // const { orderTicket } = useSelector((state) => state.orderTicket);
  const {
    coin: orderTicketCoin,
    blocked,
    reason,
    // liteOrderTicket,
  } = useSelector((state) => state.orderTicket);

  const liteOrderTicket = useSelector(
    (state) => state.orderTicket.liteOrderTicket
  );

  useEffect(() => {
    setDisplayPreviousOrder(false);
    if (orderTicketCoin !== coin) {
      //clear OrderTicket
    }
  }, [blocked]);

  useEffect(() => {
    formik.setFieldValue("total", liteOrderTicket.total);
    setTabValue(liteOrderTicket.tabValue);
  }, [liteOrderTicket]);

  const [displayPreviousOrder, setDisplayPreviousOrder] = useState(false);

  const handleBuy = () => {
    if (!coinbaseSelectedPayment) {
      dispatch(setModalCoinbasePaymentmethodStatus(true));
      return;
    }

    const buyObj = {
      coin: coin.split("/")[0],
      paymentMethod: coinbaseSelectedPayment?.id,
      amount: formik.values.total,
      accessToken: exchanges.coinbase.credentials.accessToken,
      accountId: account.id,
      commit: false,
    };

    //buy Order
    dispatch(coinbaseBuy(buyObj));

    return;

    const UUID = Date.now() % 32768;

    //to emit helper
    const krakenToken =
      window.localStorage.getItem("kraken_token") || "no_token";
    let orderObject = {
      // k_token: krakenToken,
      exchange: selectedExchange,
      event: "addOrder",
      order: {
        fc: coin,
        // fc: "EOS-USD",
        side: "BUY",
        id: Number(UUID),
        reqid: Number(UUID),
        userref: UUID.toString(),
        px: Number(formik.values.price),
        qty: Number(formik.values.quantity),
        orderType: Number(2),
      },
    };

    if (selectedExchange === "kraken") {
      orderObject["k_token"] = krakenToken;
    }

    if (selectedExchange === "coinbase") {
      orderObject.cb_key = exchanges.coinbase.credentials.key;
      orderObject.cd_sec = exchanges.coinbase.credentials.secret;
      orderObject.cb_pass = exchanges.coinbase.credentials.paraphrase;
    }

    const add_order_msg = JSON.stringify(orderObject);

    console.log("ORDER: ");
    console.log({ add_order_msg });
    return;

    // emit(add_order_msg);
    // observableOrder(UUID);

    /*
    emit(
      addOrder(
        coin,
        "BUY",
        106,
        UUID,
        "100006",
        Number(formik.values.price),
        Number(formik.values.quantity),
        2
      )
    );
    */
  };

  const handleSell = () => {
    if (!coinbaseSelectedPayment) {
      dispatch(setModalCoinbasePaymentmethodStatus(true));
      return;
    }

    const buyObj = {
      coin: coin.split("/")[0],
      paymentMethod: coinbaseSelectedPayment?.id,
      amount: formik.values.total,
      accessToken: exchanges.coinbase.credentials.accessToken,
      accountId: account.id,
      commit: false,
    };

    //buy Order
    dispatch(coinbaseSell(buyObj));

    return;

    const UUID = Date.now() % 32768;
    const krakenToken =
      window.localStorage.getItem("kraken_token") || "no_token";
    let orderObject = {
      event: "addOrder",
      // k_token: krakenToken,
      order: {
        fc: coin.replace("/", "-"),
        side: "SELL",
        id: Number(UUID),
        reqid: Number(UUID),
        userref: UUID.toString(),
        px: Number(formik.values.price),
        qty: Number(formik.values.quantity),
        orderType: Number(2),
      },
    };

    if (selectedExchange === "kraken") {
      orderObject["k_token"] = krakenToken;
    }

    if (selectedExchange === "coinbase") {
      orderObject.cb_key = exchanges.coinbase.credentials.key;
      orderObject.cd_sec = exchanges.coinbase.credentials.secret;
      orderObject.cb_pass = exchanges.coinbase.credentials.paraphrase;
    }

    const add_order_msg = JSON.stringify(orderObject);

    console.log("ORDER: ");
    console.log(add_order_msg);

    return;

    // emit(add_order_msg);
    // observableOrder(UUID);
    return;
    /*
    emit(
      addOrder(
        coin,
        "BUY",
        106,
        UUID,
        "100006",
        Number(formik.values.price),
        Number(formik.values.quantity),
        2
      )
    );
    */
  };

  const observableOrder = (userref) => {
    dispatch(placeOrderTicket(userref));

    setTimeout(() => {
      checkOrderTimeout();
    }, 10000);
  };

  const spot = map_balance_to_coin.get(coin);
  const coin_style = map_coins.get(coin);
  // const coin_name = map_coin_to_name.get(coin) ? map_coin_to_name.get(coin) : coin;

  const handleTabChange = (event, newValue) => {
    // notify();
    setTabValue(newValue);
  };

  const Buy = () => {
    return <font style={{ color: green }}>Buy</font>;
  };

  const Sell = () => {
    return <font style={{ color: red }}>Sell</font>;
  };

  const card_color = tabValue === 0 ? green : red;

  const handleModalSearch = () => {
    dispatch(setModalSearchStatus(true));
  };

  return (
    <ColorSubCard
      border={card_color}
      alignItems="middle"
      md={8}
      aria-label="main mailbox folders"
      style={{ position: "relative" }}
    >
      {/*
      <Grid container spacing={2} justifyContent="center">
        <Grid item container spacing={2} justifyContent="center"></Grid>
      </Grid>
      */}

      <Grid container alignItems="center" flexDirection="column">
        <div style={{ position: "absolute", right: "1px", top: "1px" }}>
          <ToolTipHelper
            hover={tooltips.orderTicket.hover}
            modal={tooltips.orderTicket.modal}
          />
        </div>

        <Grid item>
          <Chip
            label={
              <font
                style={{
                  horizontalAlign: "middle",
                  verticalAlign: "middle",
                  fontSize: "0.9em",
                }}
              >
                <a class={coin_style}>{coin_name}</a>
              </font>
            }
            onClick={handleModalSearch}
            onDelete={handleModalSearch}
            // color="success"
            // variant="outlined"
            deleteIcon={<SearchIcon />}
          />
        </Grid>

        {/* <Grid item>
            <Box sx={{ display: { xs: "none", sm: "block" } }}></Box>
            <font
              style={{
                horizontalAlign: "middle",
                verticalAlign: "middle",
                fontSize: "0.9em",
              }}
            >
              <a class={coin_style}>{coin_name}</a>
            </font>
          </Grid> */}

        {/* <SearchSection /> */}

        <Grid item>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="ant example"
            classes={classes}
          >
            <Tab label={<font style={{ color: green }}>Buy</font>} />
            <Tab label={<font style={{ color: red }}>Sell</font>} />
          </Tabs>
        </Grid>
      </Grid>
      <Grid item container spacing={2} justifyContent="center"></Grid>

      {displayPreviousOrder && (
        <Grid
          container
          textAlign="center"
          alignItems="center"
          style={{ marginTop: 10 }}
        >
          <Grid item>
            <Chip
              label="Order is placing..."
              variant="outlined"
              color="primary"
            />
          </Grid>
        </Grid>
      )}
      <form onSubmit={formik.handleSubmit}>
        {reason !== "" && (
          <Grid item container md={12} spacing={2} style={{ marginTop: 10 }}>
            <Grid item xs={12}>
              <Transitions
                type="zoom"
                in={true}
                position="top-left"
                direction="up"
              >
                <Typography variant="subtitle1" color="error">
                  {reason}
                </Typography>
              </Transitions>
            </Grid>
          </Grid>
        )}
        <Grid item container md={12} spacing={2} style={{ marginTop: 10 }}>
          <Grid item xs={12}>
            <MUIFormControl fullWidth>
              <InputLabel>Total</InputLabel>
              <OutlinedInput
                fullWidth
                label="Total"
                name={"total"}
                error={Boolean(formik.errors.total && formik.touched.total)}
                onChange={(e) => {
                  formik.handleChange(e);
                  // calculateQuantity(e);
                }}
                onBlur={formik.handleBlur}
                helperText={
                  formik.errors.total &&
                  formik.touched.total &&
                  String(formik.errors.total)
                }
                value={formik.values.total}
                disabled={blocked}
                endAdornment={
                  <>
                    <Divider
                      sx={{ height: 28, m: 0.5 }}
                      orientation="vertical"
                    />
                    <InputAdornment position="end">
                      {calculatedPrice !== 0 && <>{calculatedPrice}</>}
                      USD
                    </InputAdornment>
                  </>
                }
              />
            </MUIFormControl>
          </Grid>
          <Grid
            item
            sx={{ textAlign: "center" }}
            xs={12}
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            style={{ marginTop: 10 }}
          >
            {
              <>
                <font
                  style={{
                    textAlign: "center",
                    color: tabValue === 0 ? green : red,
                  }}
                >
                  {account && tabValue === 0 && <>Available Funds</>}
                  {account && tabValue === 1 && <>Available Quantity</>}
                </font>

                {account && tabValue === 1 && (
                  <font style={{ color: "white", fontSize: "1.2rem" }}>
                    {account.balance} {account.coin}
                  </font>
                )}
                {tabValue === 0 && (
                  <font style={{ color: "white", fontSize: "1.2rem" }}>
                    {/* ${tabValue === 0 ? cash : (coinfolio * ltp).toFixed(2)} */}
                    $ {cash}
                  </font>
                )}
              </>
            }
          </Grid>
        </Grid>

        <Grid
          item
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          style={{ marginTop: 10 }}
        >
          {blocked && (
            <Grid item xs={12} textAlign="center" alignItems="center">
              <Fab
                component="div"
                onClick={() => {}}
                size="small"
                variant="string"
                color="secondary"
              >
                <AnimateButton type="rotate">
                  <IconButton color="inherit" size="small">
                    <IconLoader />
                  </IconButton>
                </AnimateButton>
              </Fab>
            </Grid>
          )}
          <div
            style={{ cursor: "pointer" }}
            onClick={handleSelectPaymentMethod}
          >
            {!coinbaseSelectedPayment && <span>Select Payment Method</span>}
            {coinbaseSelectedPayment && (
              <span>{coinbaseSelectedPayment.name}</span>
            )}
          </div>
          {!blocked && (
            <Grid item xs={12} textAlign="center" alignItems="center">
              <Grid item xs={12} alignItems="center">
                {tabValue === 0 && (
                  <Button
                    variant="contained"
                    align="center"
                    size="small"
                    label={account ? "BUY" : "Read only"}
                    color="success"
                    onClick={handleBuy}
                    disabled={!account || !formik.isValid}
                  >
                   {account ? "BUY" : "Read only"}
                  </Button>
                )}

                {tabValue === 1 && (
                  <Button
                    type="submit"
                    variant="contained"
                    label="SELL"
                    size="small"
                    color="error"
                    onClick={handleSell}
                    disabled={!account || !formik.isValid}
                  >
                    {account ? "SELL" : "Read only"}
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
    </ColorSubCard>
  );
};

OrderTicket.propTypes = {};

export default React.memo(OrderTicket);
