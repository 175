import React, { useEffect } from "react";
import { krakenSettingsExchange } from "store/actions";

// material-ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import useKraken from "hooks/useKraken";

// ===============================|| UI DIALOG - FORMS ||=============================== //

export default function KrakenTokenModal() {
  const { getToken } = useKraken();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

 
  
  const [apiKey, setApiKey] = React.useState({
    key: "",
    secret: "",
  });

  const handleChangeKey = (e) => {
    setApiKey({
      ...apiKey,
      key: e.target.value,
    });
  };
  const handleChangeSecret = (e) => {
    setApiKey({
      ...apiKey,
      secret: e.target.value,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (apiKey.key !== "" && apiKey.secret !== "") {
      window.localStorage.setItem("kraken_key", apiKey.key);
      window.localStorage.setItem("kraken_sec", apiKey.secret);
      getToken(apiKey.key, apiKey.secret);
      dispatch(
        krakenSettingsExchange({
          key: apiKey.key,
          secret: apiKey.secret,
        })
      );
    }
  };

  const  Close = () => {
    setOpen(false);
  };

  return (
    <div>
        <Button variant="outlined" onClick={handleClickOpen}>
          Set Kraken API Keys
        </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Kraken API/SECRET</DialogTitle>
        <DialogContent style={{ minWidth: 500 }}>
          <Typography variant="body2" sx={{ pb: 3 }}>
            API KEY
          </Typography>

          <TextField
            autoFocus
            id="name"
            label="KEY"
            type="email"
            fullWidth
            value={apiKey.key}
            onChange={handleChangeKey}
          />
          <Typography variant="body2" sx={{ pb: 3 }}>
            API SECRET
          </Typography>

          <TextField
            autoFocus
            id="name"
            label="SEC"
            type="email"
            fullWidth
            value={apiKey.secret}
            onChange={handleChangeSecret}
          />
        </DialogContent>
        <DialogActions sx={{ pr: 2.5 }}>
          <Button onClick={Close} color="error">
            Cancel
          </Button>
          <Button variant="contained" size="small" onClick={handleClose}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
