import PropTypes from "prop-types";
import React from "react";

// material-ui
import { makeStyles } from "@material-ui/styles";
import { Avatar, Box, ButtonBase } from "@material-ui/core";
import {
  Autocomplete,
  Grid,
  Paper,
  InputAdornment,
  TextField,
} from "@material-ui/core";

// project imports
import LogoSection from "../LogoSection";
import SearchSection from "./SearchSection";
import LocalizationSection from "./LocalizationSection";
import MobileSection from "./MobileSection";
import ProfileSection from "./ProfileSection";
import NotificationSection from "./NotificationSection";
import Search from "./Search";
import KrakenTokenModal from "views/dashboard/DashboardTakyon/KrakenTokenModal";
// assets
import { IconMenu2 } from "@tabler/icons";
import ExchangeSection from "./ExchangeSection";


// style constant
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  headerAvatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: "all .2s ease-in-out",
    background:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.secondary.light,
    color:
      theme.palette.mode === "dark"
        ? theme.palette.secondary.main
        : theme.palette.secondary.dark,
    "&:hover": {
      background:
        theme.palette.mode === "dark"
          ? theme.palette.secondary.main
          : theme.palette.secondary.dark,
      color:
        theme.palette.mode === "dark"
          ? theme.palette.secondary.light
          : theme.palette.secondary.light,
    },
  },
  boxContainer: {
    width: "228px",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  },
}));

// ===========================|| MAIN NAVBAR / HEADER ||=========================== //

const Header = ({ handleLeftDrawerToggle, isConnected }) => {
  const classes = useStyles();

  return (
    <>
      {/* logo & toggler button */}
      <div className={classes.boxContainer}>
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>

        {/* NAVBAR */}
        <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
          <Avatar
            variant="rounded"
            className={classes.headerAvatar}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </div>

      {/* header search */}
      {/* <SearchSection theme="light" /> */}
      <Search />

      <div style={{ height: 20, width: 100 }}></div>

      <div className={classes.grow} />
      <div className={classes.grow} />

      {/* live customization & localization */}
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <ExchangeSection />
      </Box>

      {/* notification & profile */}
      {/* <NotificationSection /> */}

      <Grid container>
        <Grid item xs={12} md={6} lg={4}>
          <Grid item>
            {/* <KrakenTokenModal /> */}
          </Grid>
        </Grid>
      </Grid>
      <ProfileSection isConnected={isConnected} />

      {/* mobile header */}
      {/* <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box> */}
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
