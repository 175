import React from "react";
import AnimateButton from "ui-component/extended/AnimateButton";
import coinbaseLogo from "assets/images/exchanges/coinbase.png";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import useAuth from "hooks/useAuth";

// style constant
const useStyles = makeStyles({
  blueButton: {
    backgroundColor: " #6f9eff",
    color: "white",
  },
});

const CoinBaseLoginButton = () => {
  const classes = useStyles();
  const { coinbaseHandler } = useAuth();

  const flushStore = () => {
    window.localStorage.removeItem("coinbase_token");
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("kraken_sec");
    window.localStorage.removeItem("kraken_key");
    window.localStorage.removeItem("takyon_token");
  };

  const handleLogin = () => {
    flushStore();
    coinbaseHandler();
  };
  return (
    <AnimateButton>
      <Button
        disableElevation
        fullWidth
        className={classes.blueButton}
        onClick={handleLogin}
        size="large"
        variant="contained"
      >
        <img
          src={coinbaseLogo}
          alt="coinbase"
          width="20px"
          className={classes.loginIcon}
        />{" "}
        Sign in with Coinbase
      </Button>
    </AnimateButton>
  );
};
export default CoinBaseLoginButton;
