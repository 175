import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TRADES_START_DATE, TRADES_END_DATE } from "constants/app";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import MUIFormControl from "@material-ui/core/FormControl";
import { OutlinedInput, InputLabel } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';




// style constant
const useStyles = makeStyles((theme) => ({
  wrapped: {
      padding: '35px 0',
      
  }, 
}));

const Trades = ({ onFilter, onSearch }) => {
  const classes = useStyles();

  const [startDate, setStartDate] = React.useState(TRADES_START_DATE);
  const [endDate, setEndDate] = React.useState(TRADES_END_DATE);
  const [value, setValue] = useState("");

  const handleChange = (e) => {
    setValue(e.target.value);
    onSearch(e.target.value);
  };

  const changeStart = (newValue) => {
    setStartDate(newValue);
    onFilter(newValue, endDate);
  };

  const changeEnd = (newValue) => {
    setEndDate(newValue);
    onFilter(startDate, newValue);
  };

  return (
    <TableContainer>
      <Table sx={{ width: "100%" }} size="small" aria-label="">
        <TableHead>
          <TableRow
            sx={{
              alignItems: "middle",
              align: "right",
              backgroundColor: "#191b1e",
            }}
          >
            <TableCell>
              <MUIFormControl fullWidth>
                <InputLabel>Search</InputLabel>
                <OutlinedInput
                  fullWidth
                  label="Search"
                  name={"search"}
                  value={value}
                  onChange={handleChange}
                />
              </MUIFormControl>
            </TableCell>
            <TableCell></TableCell>

            <TableCell>
              <DatePicker
                wrapperClassName={classes.wrapped}
                selected={startDate}
                onChange={(newValue) => changeStart(newValue)}
              />
            </TableCell>

            <TableCell>
              <DatePicker
                selected={endDate}
                onChange={(newValue) => changeEnd(newValue)}
              />
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
};

export default Trades;
