import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Badge, Box, Button, ButtonGroup, FormControlLabel, Grid, IconButton, Switch, Typography } from '@material-ui/core';
import { withStyles, useTheme } from "@material-ui/styles";
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
setLastTrade,
RESET_ALERT_TRENDING_BUY,
RESET_ALERT_TRENDING_SELL,
RESET_ALERT_SWL_POSITIVE,
RESET_ALERT_SWL_NEGATIVE,
RESET_ALERT_VEL_POSITIVE,
RESET_ALERT_VEL_NEGATIVE,
} from "store/actions";
import { useSelector, useDispatch } from "react-redux";


// material-ui
import { makeStyles } from "@material-ui/styles";
import ListItemButton from "@material-ui/core/ListItemButton";
import ListItemText from "@material-ui/core/ListItemText";

// third party
import { FixedSizeList } from "react-window";
import { List, ListItemIcon, Avatar } from "@material-ui/core";
import CoinContext from "contexts/CoinContext";
import FavoriteButton from "../../components/FavoriteButton";
import "styles/coins.css";
import { map_coins, map_coin_to_name } from "store/constant";

// assets
import KeyboardArrowUpOutlinedIcon from "@material-ui/icons/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";

// import ListItemButton from '@material-ui/core/ListItemButton';
// style constant
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 670,
    backgroundColor: theme.palette.background.paper,
  },
  avatarSuccess: {
    width: "16px",
    height: "16px",
    borderRadius: "5px",
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
    marginRight: "5px",
  },
  avatarError: {
    width: "16px",
    height: "16px",
    borderRadius: "5px",
    backgroundColor: theme.palette.orange.light,
    color: theme.palette.orange.dark,
    marginRight: "5px",
  },
  ScrollHeight: {
        height: '400px',
        overflowX: 'hidden',
        minHeight: '400px',
        [theme.breakpoints.down('md')]: {
            height: 'calc(100vh - 190px)',
            minHeight: 0
        }
  },
}));


const CustomizedBadge = withStyles((theme) => ({
    badge: {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px'
    }
}))(Badge);

const AlertItemPercent = ((alertCount, rung, percent) => {

    if ( alertCount !== '1')
    {
        return (
            <Typography variant="h2" sx={{ color: rung }}>
                {percent.toFixed(2)}%
            </Typography>
        );
    }
    else
    {
        return (
            <IconButton aria-label="cart">
                <CustomizedBadge badgeContent={alertCount} color="error">
                    <Typography variant="h2" sx={{ color: rung }}>
                        {percent.toFixed(2)}%
                    </Typography>
                </CustomizedBadge>
            </IconButton>
        );
    }
});

const Item = ({ item, selected, handleListItemClick }) => {
  const customization = useSelector((state) => state.customization);
  const classes = useStyles();
  const rung =  (item.percent < 0.0) ? '#FAA' : 'rgb(132, 237, 102)';
  const coin_style = map_coins.get(item.symbol);
  const coin_name = (map_coin_to_name.get(item.symbol)) ? map_coin_to_name.get(item.symbol) : item.symbol;
  const ts = new Date(item.ts * 1000).toLocaleTimeString('en-US');
  const alertCount  = (item.alerted === true) ? '1' : ' ';

  console.log( 'r_one: ' + item.rate_of_change_one + ', r_two: ' + item.rate_of_change_two);
  const r_one = (item.rate_of_change_one) ? item.rate_of_change_one.toFixed(2) : 0.0;
  const r_two = (item.rate_of_change_two) ? item.rate_of_change_two.toFixed(2) : 0.0;


  return (
    <ListItemButton
      selected={selected}
      onClick={(event) => handleListItemClick(event, item.symbol)}
      sx={{ borderRadius: `${customization.borderRadius}px` }}
    >
      <Grid container justifyContent="space-between" alignItems="center">

        <Grid item>
          <Grid container direction="column" >
            <Grid item>
              <Typography variant="subtitle1" color="inherit">
                    <a class={coin_style}  style={{width: "80px"}}>{coin_name}</a>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" sx={{color:rung}}>
                    {ts}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction="column">
            <Grid item>
                {AlertItemPercent(alertCount, rung, item.percent)}
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="subtitle1" sx={{color:rung}}>
                    {r_one}
                    <FavoriteButton pair={item.symbol} />
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" sx={{color:rung}}>
                    {r_two}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </ListItemButton>
  );
};

// ================================|| UI LIST - SCROLLABLE ||================================ //

export default function SmartVelocityList({ type, items }) {
  const classes = useStyles();
  const customization = useSelector((state) => state.customization);
  const dispatch = useDispatch();

  const [selectedIndex, setSelectedIndex] = React.useState("0");
  const { symbol, setCoin } = useContext(CoinContext);

  const handleListItemClick = (event, symbol) => {
    setSelectedIndex(symbol);
    setCoin(symbol);

    dispatch( { type: RESET_ALERT_VEL_POSITIVE, payload: {coin: symbol}});
    dispatch( { type: RESET_ALERT_VEL_NEGATIVE, payload: {coin: symbol}});

    // Let's reset the alert for this coin.
    /*
    if (type === "VELPositive")
    {
        dispatch( { type: RESET_ALERT_VEL_POSITIVE, payload: {coin: symbol}});
    }
    else if (type === "VELNegative")
    {
        dispatch( { type: RESET_ALERT_VEL_NEGATIVE, payload: {coin: symbol}});
    }
    */
  };

  return (
      <List
        component="nav"
        aria-label="main mailbox folders"
        style={{ maxHeight: "100%", overflow: "hidden" }}
      >
        <PerfectScrollbar className={classes.ScrollHeight}>
        {items.map((item, index) => (
          <Item
            key={index}
            item={item}
            selected={selectedIndex === item.symbol}
            handleListItemClick={handleListItemClick}
          />
        ))}
        </PerfectScrollbar>
      </List>
  );
}
