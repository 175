import React, {useEffect, useReducer, useState} from "react";
import ExploreCard from "./components/ExploreCard";
import { useSelector} from "react-redux";
import { map_coins, map_coin_to_name, map_untradable_kraken_assets} from 'store/constant';
import FavoritesCard from '../Favorites/components/FavoritesCard';
import { Grid, Box, Typography, Tab, Tabs, Chip, Badge } from "@material-ui/core";
import SubCard from "ui-component/cards/SubCard";
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import { useDispatch } from "react-redux";
import {
  TAB_DEL_PINNED_COIN, 
} from "store/actions";
import colors from 'assets/scss/_themes-vars.module.scss';
// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";


const CoinDetails = ({coin,onDelete}) => {
  const key_style = map_coins.get(coin);

  const ltp  = useSelector((state) => {
    return state.coins.kraken_asset_pairs.get(coin) || {ltp: 0.00};
    });

    // console.log(ltp);

    // const move = item.move === "↓↓↓" ? "down" : "up";
    // const rung = item.move === "↓↓↓" ? "#FAA" : "rgb(132, 237, 102)";

    return (<div style={{position:'relative'}}>

<div style={{position:'absolute', top:'-12px', right: '-12px'}} onClick={() => onDelete(coin)}>
<CancelIcon  />
</div>
      <div
      style={{
        height: '50px',
        width: '130px',
    boxAlign: 'center',
    alignItems: 'center',
    padding: '6px',
    backgroundColor: '#222429',
    borderRadius: '8px',
    border: '1px solid rgb(68 68 68)',
    whiteSpace: 'nowrap',

    
      }}
      >
        <div>
        <font sx={{ "font-size": "0.8rem" }} class={key_style} >{coin}</font>

        </div>
        <div>
        <font sx={{ 'color': 'white', "font-size": "0.8rem" }} >{ltp.ltp}</font>
        </div>
      </div>
      {/* </Badge> */}

    </div>)
}
 

const Explore = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const background = (theme.palette.mode === 'dark') ? '#00070f' : '#fff';
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const whiteTakyon = theme.palette.mode === "dark" ? colors.whiteLightTakyon : colors.whiteDarkTakyon;
  const cornsilk = theme.palette.mode === "dark" ? colors.cornsilkLightTakyon : colors.cornsilkDarkTakyon;

  const explore_list = useSelector((state) => {
    return state.coins.kraken_asset_pairs;
  });
  
 const tabbed =  useSelector((state) => {

  return   state.coins.explore_tabs.map(tab => ({
    coin:tab,
    ltp: state.coins.kraken_asset_pairs.get(tab) || 0.000
  })) 
  });

  // console.log(tabbed);
  // console.log(map_tabbed_coins);


  // const [mapTabbedCoins, setMapTabbedCoins] = useState([]);

  const explore_tabs = useSelector((state) => {
    return state.coins.explore_tabs;
  });



  // useEffect(()=>{
  //   setMapTabbedCoins(explore_tabs)
  //   setTimeout(()=>{
  //     setMapTabbedCoins(explore_tabs.map((tab)=>tab))
  //   },3000)

  //   return 
  // },[]);

  
// setTimeout(()=>{setCoins([])},2000)

  //instead of realtime price changes  -  make  price updating every 3-5 sec 

  // useEffect(()=>{

  //   useSelector((state) => {
  //     state.coins.explore_tabs.forEach((key, index) => {
  //         if ( state.coins.kraken_asset_pairs.get(key))
  //         {
  //             map_tabbed_coins.push(state.coins.kraken_asset_pairs.get(key));
  //         }
  //     });
  //   });


  // },[map_tabbed_coins])

  const [tabsValue, setTabsValue] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setTabsValue(newValue);
  };


  const handleDeleteTab  = (coin) => {
    if(explore_tabs.length < 2) {
      setTabsValue(0)
    }
    dispatch({type: TAB_DEL_PINNED_COIN, payload: {coin}});
    forceUpdate();
  }

  return (
    <>
        <Tabs
            value = {tabsValue}
            onChange={handleChangeTab}
            aria-label="ant example"
          >
            <Tab
                    label={
                        <Chip
                            variant="outlined" 
                            color="primary"
                            sx={{bgcolor:background}}
                            label={<font style={{ "font-size": "0.8rem" }} class="EXPLORE" >EXPLORE</font> }
                       />
                      } 
                      />
                    
                 { 
                 explore_tabs.map((tab) =>(
                  <Tab key={tab.toString()} label={<CoinDetails coin={tab}
                  onDelete={handleDeleteTab}
                  />}  />
                 ))
                 }
                 
                 {/* {
                   explore_tabs.map(tab =>(
                    <ExploreCoinTab
                    coin={tab}
                    
                    // background={background}
                    // onDelete={()=>{ handleDeleteTab(tab)}}
                    //  background={}
                    // handleDeleteTab={}
                    // key={}
                    // key_style={}
                    />
                   ))
                 } */}

                
            {/* {
                map_tabbed_coins.map((key, index) => {
                    const key_style = map_coins.get(key.wsname);
                    // const key_name = map_coin_to_name.get(key) ? map_coin_to_name.get(key) : key;
                    return (<Tab
                                label={
                                    <Chip
                                        variant="outlined" 
                                        color="primary"
                                        sx={{bgcolor:background}}
                                        onClick={()=>{}}
                                        onDelete={()=>{ handleDeleteTab(key)}}
                                        deleteIcon={<CancelIcon />} 
                                        label={
                                        <>
                                            <font sx={{ "font-size": "0.8rem" }} class={key_style} >{key.wsname}</font>
                                            <font sx={{ 'color': 'white', "font-size": "0.8rem" }} >{key.ltp}</font>
                                        </>
                                        }
                                    />
                                }
                             />
                              );
                  })
              }
   */}


          </Tabs>
    <Grid container padding={1.0} />
      {tabsValue === 0 && <ExploreCard title="ExploreCard" rows={explore_list} />}
      {tabsValue !== 0 && <FavoritesCard coin={explore_tabs[tabsValue -1]} />}
    </>
  );
};

export default (Explore);
