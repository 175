import React, { useEffect, useState } from "react";
import TradesCard from "./components/TradesCard";
import { useSelector } from "react-redux";
import { TRADES_END_DATE, TRADES_START_DATE } from "constants/app";

const Trades = () => {
  const [filter, setFilter] = useState({
    search: "",
    start: TRADES_START_DATE,
    end: TRADES_END_DATE,
  });

  const mapTrades = useSelector((state) =>
    state.trades.tradesTable.sort(
      (a, b) => new Date(b.time_ms) - new Date(a.time_ms)
    )
  );

  const [trades, setTrades] = useState(mapTrades);

  const onFilter = (start, end) => {
    setFilter({ ...filter, start, end });

    // const output = mapTrades.filter((trade) => {
    //   const time = new Date(trade.time_ms);
    //   return time >= new Date(start) && time <= new Date(end);
    // });
    // setTrades(output);
  };

  const onSearch = (search) => {
    setFilter({ ...filter, search });

    // if (search === "") {
    //   setTrades(mapTrades);
    // }

    // const output = mapTrades.filter((trade) => {
    //   return trade.pair.toLowerCase().includes(search.toLowerCase());
    // });
    // setTrades(output);
  };

  useEffect(() => {
    let output = mapTrades.filter((trade) => {
      const time = new Date(trade.time_ms);
      return time >= new Date(filter.start) && time <= new Date(filter.end);
    });

    if (filter.search !== "") {
      output = output.filter((trade) => {
        return trade.pair.toLowerCase().includes(filter.search.toLowerCase());
      });
    }

    setTrades(output);
  }, [filter, setTrades, mapTrades]);
  return (
    <TradesCard
      title="Trades"
      rows={trades}
      onFilter={onFilter}
      onSearch={onSearch}
    />
  );
};

export default React.memo(Trades);
