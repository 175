import { Grid, Typography, Box } from "@material-ui/core";
import React, { useEffect } from "react";
import DiveBarCandleChart from "../DiveBarCandleChart";
import SummaryCard from "ui-component/cards/SummaryCard";
import { useDispatch, useSelector } from "react-redux";
import Chat from "layout/LiteTakyonLayout/DiveBarClub/Chat";
import DiveBarClub from "layout/LiteTakyonLayout/DiveBarClub/DiveBarClubCard";
import DiveBarExploreCard from "layout/LiteTakyonLayout/DiveBarClub/DiveBarExploreCard";
import CashPortfolio from "layout/LiteTakyonLayout/DiveBarClub/Cash/CashPortfolio";
import ChannelPortfolio from "layout/LiteTakyonLayout/DiveBarClub/Cash/ChannelPortfolio";
import TableTrades from "layout/LiteTakyonLayout/DiveBarClub/Trades/TableTrades";

const message = "{type:'command', pair:'','interval'}";

const DiveBarPortfolioLayout = () => {
  const dispatch = useDispatch();
  const { coin, ohlcInterval, openedRoom } = useSelector(
    (state) => state.diveBarChart
  );

  useEffect(() => {
    dispatch({
      type: "FETCH_DIVEBAR_OHLC",
      payload: {
        rcoin: coin,
        interval: ohlcInterval,
      },
    });
  }, [coin, ohlcInterval, dispatch]);

  const explore_list = useSelector((state) => {
    return state.coins.kraken_asset_pairs;
  });

  return (
    <Grid container spacing={2.0}>
      <Grid item xs={9.5} alignItems="center">
        <Grid container padding={1.0} />
        <Grid container item xs={12} alignItems="center">
            <Box>
                <Typography variant="h2" color="cornsilk">
                    You are in DiveBarClub mode! Look at the DiveBarClub chat!
                </Typography>
            </Box>
        </Grid>
        <Grid container padding={1.0} />
        <DiveBarCandleChart />
        <Grid container padding={1.0} />
        <Grid container spacing={2.0} direction="rows">
            <Grid item xs={12} lg={6} alignItems="center">
                <CashPortfolio />
            </Grid>
            <Grid item xs={12} lg={6} alignItems="center">
                <ChannelPortfolio />
            </Grid>
        </Grid>
      </Grid>

      <Grid item xs={2.5} alignItems="center">
            <Grid container padding={1.0} />
            <SummaryCard /> 
            <Grid container padding={1.0} />
            <DiveBarClub />
            <Grid container padding={1.0} />
            <Chat />
            <Grid container padding={1.0} />
      </Grid>
    </Grid>
  );
};

export default DiveBarPortfolioLayout;
