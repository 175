import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import useAuth from "hooks/useAuth";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const CoinbaseLogin = () => {
  const { coinbaseLogin, isLoggedIn } = useAuth();

  const navigate = useNavigate();

  const search = useLocation().search;
  const code = new URLSearchParams(search).get("code");

  useEffect(() => {
    coinbaseLogin(code);
  }, []);

  useEffect(() => {
      if(isLoggedIn) {
        navigate("/lite", { replace: true });
      }
  }, [isLoggedIn,navigate]);

  return <div>Please wait</div>;
};

export default CoinbaseLogin;
