import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
// import Api from '...'
import {
  KRAKEN_BALANCE,
  COINBASE_BALANCE_HISTORY,
  KRAKEN_ASSET_PAIRS,
  FETCH_TRADES,
  FETCH_PORTFOLIO,
  KRAKEN_OHLC,
  SET_COINBASE_ACCOUNTS,
  SET_COINBASE_PAYMENT_METHODS,
  COINBASE_BUY,
  COINBASE_SELL,
  COINBASE_COMMIT,
  SNACKBAR_OPEN,
  SET_MODAL_COINBASE_CONFIRM_STATUS,
  SET_MODAL_COINBASE_CONFIRM_RESPONSE,
  ADD_TRADE,
  DIVEBAR_OHLC
} from "store/actions";

import {
  getBalanceData,
  getAssetPairs,
  getTrades,
  getPortfolio,
  getOHLCData,
  getBalanceHistory,
  getCoinbaseAccounts,
} from "api";

import {
  buyOrder,
  sellOrder,
  commitOrder,
  fetchPaymetnMethods,
} from "api/coinbaseAPI";

function* fetchBalance() {
  try {
    const data = yield call(getBalanceData);
    // console.log(data);
    yield put({ type: KRAKEN_BALANCE, payload: data });
  } catch (e) {
    //TODO!
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* userFetchFailed(action) {
  yield put({
    type: SNACKBAR_OPEN,
    open: true,
    message: action.message,
    variant: "alert",
    alertSeverity: "error",
    close: true,
    transition: "SlideLeft",
    anchorOrigin: { vertical: "top", horizontal: "right" },
  });
}

function* userSuccess(action) {
  yield put({
    type: SNACKBAR_OPEN,
    open: true,
    message: action.message,
    variant: "alert",
    alertSeverity: "success",
    close: true,
    transition: "SlideLeft",
    anchorOrigin: { vertical: "top", horizontal: "right" },
  });
}

function* fetchBalanceHistory(action) {
  try {
    const data = yield call(getBalanceHistory);
    // console.log(data);
    yield put({
      type: COINBASE_BALANCE_HISTORY,
      payload: { ...action.payload, data },
    });
  } catch (e) {
    //TODO!
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* fetchKrakenAssetPairs() {
  try {
    const data = yield call(getAssetPairs);
    yield put({ type: KRAKEN_ASSET_PAIRS, payload: data });
  } catch (e) {
    //TODO!
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}
function* fetchKrakenAssetPairsPortfolio(action) {
  try {
    const data = yield call(getAssetPairs);
    yield put({ type: KRAKEN_ASSET_PAIRS, payload: data });
    // yield put({ type: "FETCH_PORTFOLIO", payload:action.payload });
    yield* fetchTrades(action);
  } catch (e) {
    //TODO!
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* fetchTrades(action) {
  try {
    const data = yield call(getTrades, action.payload);
   yield put({ type: FETCH_TRADES, payload: data });
  } catch (e) {
    //TODO!
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* fetchPortfolio(action) {
  try {
    const data = yield call(getPortfolio, action.payload);
    // console.log(data.data['response_type']);
    if (data.data && data.data["response_type"] === "notify_pnl") {
      // console.log(data.data);
      yield put({ type: FETCH_PORTFOLIO, payload: data.data });
    } else {
      // console.log(data);
      yield put({ type: FETCH_PORTFOLIO, payload: data });
    }

    //balance
    const dataBalance = yield call(getBalanceData);
    yield put({ type: KRAKEN_BALANCE, payload: dataBalance });

    //balance_history
    const histBalance = yield call(getBalanceHistory);
    yield put({ type: COINBASE_BALANCE_HISTORY, payload: histBalance });

    //  dispatch  fetch balance after get this  portfolio
  } catch (e) {
    //TODO!
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* fetchOHLC(action) {
  try {
    const data = yield call(getOHLCData, action.payload);
    if (data.data) {
      yield put({
        type: KRAKEN_OHLC,
        payload: { ...action.payload, data: data.data },
      });
    } else {
      yield put({ type: KRAKEN_OHLC, payload: { ...action.payload, data } });
    }
  } catch (e) {
    //TODO!
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* fetchDiveBarOHLC(action) {
  try {
    const data = yield call(getOHLCData, action.payload);
    if (data.data) {
      yield put({
        type: DIVEBAR_OHLC,
        payload: { ...action.payload, data: data.data },
      });
    } else {
      yield put({ type: DIVEBAR_OHLC, payload: { ...action.payload, data } });
    }
  } catch (e) {
    //TODO!
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* fetchCoinbaseAccounts(accessToken) {
  try {
    const data = yield call(getCoinbaseAccounts, accessToken);
    // confirm code
    if (data.data) {
      yield put({ type: SET_COINBASE_ACCOUNTS, payload: data.data });
    } else {
      yield put({ type: SET_COINBASE_ACCOUNTS, payload: data });
    }
  } catch (e) {
    //TODO!
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}
function* fetchCoinbasePaymentMethods(accessToken) {
  try {
    const data = yield call(fetchPaymetnMethods, accessToken);
    console.log({ data });
    // confirm code
    if (data.data) {
      yield put({ type: SET_COINBASE_PAYMENT_METHODS, payload: data.data });
    } else {
      yield put({ type: SET_COINBASE_PAYMENT_METHODS, payload: data });
    }
  } catch (e) {
    //TODO!
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* coinbaseBuy(action) {
  try {

 
    const data = yield call(buyOrder, action.payload);

    if (data.payload.committed === false) {
      yield put({
        type: SET_MODAL_COINBASE_CONFIRM_RESPONSE,
        response: { ...data.payload, accountId: data.accountId },
      });
      yield put({ type: SET_MODAL_COINBASE_CONFIRM_STATUS, isOpen: true });
    } else {
      yield put({
        type: "USER_SUCCESS",
        // `Order ${order.exchId} placed successfully. ${order.fc} ${order.qty}`
        message: `Order ${data.payload.id} placed successfully. ${data.payload.amount.amount}  ${data.payload.amount.currency}`,
      });

      yield put({
        type: ADD_TRADE,
        payload: data.payload,
      });
    }
 
  } catch (e) {
    //TODO!
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* coinbaseSell(action) {
  try {
    console.log("Coinbase Buy");
    const data = yield call(sellOrder, action.payload);

    if (data.payload.committed === false) {
      yield put({
        type: SET_MODAL_COINBASE_CONFIRM_RESPONSE,
        response: { ...data.payload, accountId: data.accountId },
      });
      yield put({ type: SET_MODAL_COINBASE_CONFIRM_STATUS, isOpen: true });
    } else {
      yield put({
        type: "USER_SUCCESS",
        message: `Order ${data.payload.id} placed successfully. ${data.payload.amount.amount}  ${data.payload.amount.currency}`,
      });
      
      yield put({
        type: ADD_TRADE,
        payload: data.payload,
      });

    }
    
  } catch (e) {
    //TODO!
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* coinbaseCommitOrder(action) {
  try {
    const data = yield call(commitOrder, action.payload);
    yield put({
      type: "USER_SUCCESS",
      message:       `Order ${data.payload.id} placed successfully. ${data.payload.amount.amount}  ${data.payload.amount.currency}`,
    });

    yield put({
      type: ADD_TRADE,
      payload: data.payload,
    });
    
  } catch (e) {
    //TODO!
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* initData(action) {
  // yield* fetchKrakenAssetPairs();
  // yield* fetchPortfolio(action);
  // yield* fetchKrakenAssetPairsPortfolio(action);
  // yield* fetchBalance();
  yield* fetchTrades(action);
}

function* mySaga() {
  yield takeEvery("USER_SUCCESS", userSuccess);
  yield takeEvery("USER_FETCH_FAILED", userFetchFailed);
  yield takeEvery("FETCH_BALANCE", fetchBalance);
  yield takeEvery("FETCH_BALANCE_HISTORY", fetchBalanceHistory);
  yield takeEvery("FETCH_KRAKEN_ASSET_PAIRS", fetchKrakenAssetPairs);
  yield takeEvery(
    "FETCH_KRAKEN_ASSET_PAIRS_AND_PORTFOLIO",
    fetchKrakenAssetPairsPortfolio
  );
  yield takeEvery("FETCH_TRADES", fetchTrades);
  yield takeEvery("FETCH_PORTFOLIO", fetchPortfolio);
  yield takeEvery("INIT_DATA", initData);
  yield takeEvery("FETCH_OHLC", fetchOHLC);
  yield takeEvery("FETCH_DIVEBAR_OHLC", fetchDiveBarOHLC);
  yield takeEvery("FETCH_COINBASE_ACCOUNTS", fetchCoinbaseAccounts);
  yield takeEvery(
    "FETCH_COINBASE_PAYMENT_METHODS",
    fetchCoinbasePaymentMethods
  );
  yield takeEvery(COINBASE_BUY, coinbaseBuy);
  yield takeEvery(COINBASE_SELL, coinbaseSell);
  yield takeEvery(COINBASE_COMMIT, coinbaseCommitOrder);
}

export default mySaga;
