import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import CoinContext from "contexts/CoinContext";
import { Grid } from "@material-ui/core";
import SubCard from "ui-component/cards/SubCard";
// import VirtualizedList from './components/VirtualizedList';
import FavoritesCard from './components/FavoritesCard';


const Favorites = () => {
    const { coin, setCoin } = useContext(CoinContext);


const explore_list = useSelector((state) => {
    let items = [];

    if (state.coins.kraken_asset_pairs !== undefined) {
        state.favorites.favorites.forEach((item, index) => {
        
            let coin = state.coins.kraken_asset_pairs.get(item.label);
            if ( (coin !== undefined) && (coin.quote === "ZUSD"))
            {
                items.push(coin);
            }
        });
    }
    return items;
});



  return (
    <FavoritesCard title="FavoritesCard..." coin={coin} />
  );
};

export default Favorites;
