import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import MUIFormControl from "@material-ui/core/FormControl";
import BuySellBtn from "assets/images/explore/buysellbtn.png";
import { tooltips } from "constants/tooltips";

import applePay from "assets/images/vc_applePay.png";
import googlePay from "assets/images/vc_googlePay.png";
import amazonPay from "assets/images/vc_amazonPay.png";

import {
  map_coins,
  map_balance_to_coin,
  map_coin_to_name,
  map_category,
} from "store/constant";
import { weekly_portfolio } from "store/weekly";
import I from "immutable";
import useSocket from "hooks/useSocket";
import Transitions from "ui-component/extended/Transitions";
import { useDispatch, useSelector } from "react-redux";
import CoinContext from "contexts/CoinContext";
import colors from "assets/scss/_themes-vars.module.scss";
import ToolTipHelper from "ui-component/ToolTipHelper";

import {
  TAB_ADD_PINNED_COIN,
} from "store/actions";

import {
  InputAdornment,
  OutlinedInput,
  InputLabel,
  IconButton,
  Chip,
  Fab,
} from "@material-ui/core";
import FavoriteButton from "views/dashboard/DashboardTakyon/components/FavoriteButton";

// material-ui
import { withStyles, makeStyles, useTheme } from "@material-ui/styles";

// material-ui
import {
  Badge,
  Button,
  Box,
  Checkbox,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { visuallyHidden } from "@material-ui/utils";

// third party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import SubCard from "ui-component/cards/SubCard";
import ColorSubCard from "ui-component/cards/ColorSubCard";

// assets
import Flag1 from "assets/images/widget/AUSTRALIA.jpg";
import Flag2 from "assets/images/widget/BRAZIL.jpg";
import Flag3 from "assets/images/widget/GERMANY.jpg";
import Flag4 from "assets/images/widget/UK.jpg";
import Flag5 from "assets/images/widget/USA.jpg";


// style constant
const useStyles = makeStyles({
  root: {
    width: "100%",
    overflow: "hidden",
  },
  sortSpan: visuallyHidden,
  cell: {
    cursor: "pointer",
  },
});

// =========================|| DASHBOARD ANALYTICS - LATEST CUSTOMERS TABLE CARD ||========================= //

const VirtualCardDetails = ({virtual_card}) => {

    // TMD console.log( virtual_card);

  const classes = useStyles();
  const { emit } = useSocket();
  const { coin, setCoin } = useContext(CoinContext);
  const dispatch = useDispatch();
  const theme = useTheme();
  const green =
    theme.palette.mode === "dark"
      ? colors.successLightTakyon
      : colors.successDarkTakyon;
  const red =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;
  const rung =
    theme.palette.mode === "dark"
      ? '#fff'
      : colors.cornsilkDarkTakyon;

    // TMD console.log( rung);
  //
  return (
    <SubCard sx={{ width: '100%', align: "center" }}>
        <Grid container padding={2} spacing={2} direction="rows" alignItems="center">

            <Grid item xs={12} lg={4} >
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-pencil" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke={rung} fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />
                  <path d="M13.5 6.5l4 4" />
                </svg>
            </Grid>
            <Grid item xs={12} lg={8} >
                Name your card
            </Grid>
            <Grid item xs={12} lg={4} >
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-key" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke={rung} fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M16.555 3.843l3.602 3.602a2.877 2.877 0 0 1 0 4.069l-2.643 2.643a2.877 2.877 0 0 1 -4.069 0l-.301 -.301l-6.558 6.558a2 2 0 0 1 -1.239 .578l-.175 .008h-1.172a1 1 0 0 1 -.993 -.883l-.007 -.117v-1.172a2 2 0 0 1 .467 -1.284l.119 -.13l.414 -.414h2v-2h2v-2l2.144 -2.144l-.301 -.301a2.877 2.877 0 0 1 0 -4.069l2.643 -2.643a2.877 2.877 0 0 1 4.069 0z" />
                  <path d="M15 9h.01" />
                </svg>
            </Grid>
            <Grid item xs={12} lg={8} >
                View Pin
            </Grid>
            <Grid item xs={12} lg={4} >
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-lock-x" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke={rung} fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M13 21h-6a2 2 0 0 1 -2 -2v-6a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v.5" />
                  <path d="M11 16a1 1 0 1 0 2 0a1 1 0 0 0 -2 0" />
                  <path d="M8 11v-4a4 4 0 1 1 8 0v4" />
                  <path d="M22 22l-5 -5" />
                  <path d="M17 22l5 -5" />
                </svg>
            </Grid>
            <Grid item xs={12} lg={8} >
                Unfreeze Card
            </Grid>
            <Grid item xs={12} lg={4} >
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-map-pin" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke={rung} fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                  <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                </svg>
            </Grid>
            <Grid item xs={12} lg={8} >
                Location based security
            </Grid>
            <Grid item xs={12} lg={4} >
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-hand-move" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke={rung} fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M8 13v-8.5a1.5 1.5 0 0 1 3 0v7.5" />
                  <path d="M11 11.5v-2a1.5 1.5 0 0 1 3 0v2.5" />
                  <path d="M14 10.5a1.5 1.5 0 0 1 3 0v1.5" />
                  <path d="M17 11.5a1.5 1.5 0 0 1 3 0v4.5a6 6 0 0 1 -6 6h-2h.208a6 6 0 0 1 -5.012 -2.7l-.196 -.3c-.312 -.479 -1.407 -2.388 -3.286 -5.728a1.5 1.5 0 0 1 .536 -2.022a1.867 1.867 0 0 1 2.28 .28l1.47 1.47" />
                  <path d="M2.541 5.594a13.487 13.487 0 0 1 2.46 -1.427" />
                  <path d="M14 3.458c1.32 .354 2.558 .902 3.685 1.612" />
                </svg>
            </Grid>
            <Grid item xs={12} lg={8} >
                Swipe payments
            </Grid>
            <Grid item xs={12} lg={4} >
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-building-bank" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke={rung} fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M3 21l18 0" />
                  <path d="M3 10l18 0" />
                  <path d="M5 6l7 -3l7 3" />
                  <path d="M4 10l0 11" />
                  <path d="M20 10l0 11" />
                  <path d="M8 14l0 3" />
                  <path d="M12 14l0 3" />
                  <path d="M16 14l0 3" />
                </svg>
            </Grid>
            <Grid item xs={12} lg={8} >
                ATM Withdrawls
            </Grid>
            <Grid item xs={12} lg={4} >
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-wifi" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke={rung} fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M12 18l.01 0" />
                  <path d="M9.172 15.172a4 4 0 0 1 5.656 0" />
                  <path d="M6.343 12.343a8 8 0 0 1 11.314 0" />
                  <path d="M3.515 9.515c4.686 -4.687 12.284 -4.687 17 0" />
                </svg>
            </Grid>
            <Grid item xs={12} lg={8} >
                Contactless payments
            </Grid>
            <Grid item xs={12} lg={4} >
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke={rung} fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M4 7l16 0" />
                  <path d="M10 11l0 6" />
                  <path d="M14 11l0 6" />
                  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                </svg>
            </Grid>
            <Grid item xs={12} lg={8} >
                Terminate Card
            </Grid>

        </Grid>
    </SubCard>
        
  );
};

export default VirtualCardDetails;
