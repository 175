import React, { useContext, useEffect, useRef, useState } from "react";
import {setLastTrade} from "store/actions";
import { makeStyles, useTheme } from "@material-ui/styles";
import colors from 'assets/scss/_themes-vars.module.scss';

import {
  createChart,
  CrosshairMode,
} from "lightweight-charts";
import { useSelector, useDispatch } from "react-redux";
import I from "immutable";
import axios from "axios";
// 1. No duplicates in the CandleStick Time Series
// 2. All time values must be Ascending order (increasing)
// 3. No NULL values in OHLC for that timestamp

const useStyles = makeStyles((theme) => ({
}));

const MarketShareAreaChartCard = () => {
  const chartContainerRef = useRef();
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const cornsilk = (theme.palette.mode === 'dark') ? 'white' : colors.cornsilkDarkTakyon;
  const whiteTakyon = (theme.palette.mode === 'dark') ? colors.whiteLightTakyon : colors.whiteDarkTakyon;
  const green = (theme.palette.mode === 'dark') ? colors.successLightTakyon : colors.successMain;
  const red = (theme.palette.mode === 'dark') ? colors.errorLightTakyon : colors.errorDarkTakyon;
  // const bgcolor = (theme.palette.mode === 'dark') ? '#12151c' : 'white';
  const bgcolor = theme.palette.background.paper;

  const chart = useRef();
  const series = useRef();
  const avgLine = useRef();
  const priceLine = useRef();

  const resizeObserver = useRef();

  
  const { coin } = useSelector((state) => state.diveBarChart);


  const ltp = useSelector((state) => {
    return ((state.coins.kraken_asset_pairs_loaded && state.coins.kraken_asset_pairs.get(coin) && state.coins.kraken_asset_pairs.get(coin).ltp) || 0.0);
  });

  const daily = useSelector((state) => {
    return ((state.coins.kraken_asset_pairs_loaded && state.coins.kraken_asset_pairs.get(coin) && state.coins.kraken_asset_pairs.get(coin).daily) || {});
  });

  const portfolio = useSelector((state) => {
    if (state.coins.kraken_asset_pairs) {
      let item = state.coins.kraken_asset_pairs.get(coin);
      if ((item !== undefined) && (item.position !== undefined) && (item.position.remaining_qty !== undefined)) {
        return [
          item.position.remaining_qty.toFixed(2),
          (item.position.remaining_qty * ltp).toFixed(2),
          item.position.realized,
          item.position.unrealized,
          item.position.remaining_avg_price,
        ];
      } else {
        return [0, 0, 0, 0, 0];
      }
    } else {
      return [0, 0, 0, 0, 0];
    }
  });

    const avg_px = portfolio[4];
    let avg_ts = 0;
    let avg_df = 1000000.0;

    const lastTrade = useSelector((state) => {

        if ((state.chart.mapLastTrade) && (state.chart.mapLastTrade.get(coin)))
        {
            return state.chart.mapLastTrade.get(coin);
        }
        let items = [];

        if (state.takyon.mapTrades !== undefined) {
          let count = 0;
          state.takyon.mapTrades.forEach((item, i) => {
            if (item.pair === coin) {
              items.push(item);
            }
          });
        }

        items.sort(function(a, b) {
            return (b.time_ms - a.time_ms);
        });
        return items;
    });


    useEffect(() => {
        {
            // TMD console.log( 'coin: dispatching LastTrade: ' + coin);
            if ( lastTrade.length > 0)
            {
                let len = (lastTrade.length < 20) ? lastTrade.length : 20;

                let trades = [];
                for( let i = 0; i < len; ++i)
                {
                    trades = [...trades, lastTrade[i]];
                }
                console.log(trades);
                dispatch(setLastTrade({coin: coin, trade: trades}));
            }
        }

    }, lastTrade[0]);

 

  const lwData =  useSelector((state) => {
    // return state.chart.currentChartData;
    let data = state.diveBarChart.currentChartData;
    if ( data !== undefined && data[data.length-1] !== undefined)
    {
        data[data.length-1]['close'] = ltp;
    }
    return data;
  });


  useEffect(() => {
    // init chart, do not modify here
    chart.current = createChart(chartContainerRef.current, {
      height: 300,
      layout: {
        backgroundColor: bgcolor,
        textColor: cornsilk,
      },
      grid: {
        vertLines: {
            visible: false,
            color: "#111",
        },
        horzLines: {
            visible: false,
            color: "#111",
        },
      },
      crosshair: {
        vertLine: {
          color: "#333",
          width: 0.1,
          style: 0,
          visible: true,
          labelVisible: true,
        },
        horzLine: {
          color: "#333",
          width: 0.1,
          style: 0,
          visible: true,
          labelVisible: true,
        },
        mode: CrosshairMode.Normal,
      },
      priceScale: {
        borderColor: "#888",
        borderVisible: false,
      },
      alignLabels: true,
      timeScale: {
        rightOffset: 10,
        barSpacing: 8,
        fixLeftEdge: false,
        lockVisibleTimeRangeOnResize: true,
        rightBarStaysOnScroll: true,
        borderVisible: false,
        borderColor: "#888",
        visible: true,
        timeVisible: true,
        secondsVisible: true,
      },
    });

    const candleSeries = chart.current.addCandlestickSeries({
      upColor: green,
      downColor: red,
      borderUpColor: green,
      borderDownColor: red,
      wickUpColor: green,
      wickDownColor: red,
    });
    candleSeries.setData([]);

    avgLine.current = candleSeries.createPriceLine({
      price: "0.00",
      color: "orange",
      lineWidth: -1,
      lineStyle: 0,
      axisLabelVisible: true,
      title: "avg",
    });



    chart.current.subscribeClick(function(param) {
        console.log(param.seriesPrices);
        console.log(chart.current.priceScale());
        param.seriesPrices.forEach((value) => {
            console.log(value);
            console.log( 'Percent: ' + (value.close - ltp) * 100.0 / ltp);
        });
    });
    series.current = candleSeries;
 
  }, []);

  useEffect(() => {
    //update chart data here
    if (series.current) {
        series.current.setData(lwData);

        avgLine.current.applyOptions({
            price: avg_px,
        });

        series.current.applyOptions({
            priceFormat: {
                type: 'price',
                precision: 4,
                minMove: 0.0001,
            },
        });
      /*
      priceLine.current.applyOptions({
        price: ltp,
      });
      */

      let markers = [];
      if (lastTrade !== undefined) {
        for (let i = 0; i < lastTrade.length; ++i) {
          if (lastTrade[i] !== undefined) {
            if (lastTrade[i].is_buy === true) {
              // markers.push( {time: lastTrade[i].time_ms/1000, size: 3, shape: "circle", position: "inBar", color: "#84ed66", text: "$" + lastTrade[i].trade_cost.toFixed(2)});
              markers.push({
                time: lastTrade[i].time_ms / 1000,
                size: 2.4,
                shape: "circle",
                position: "inBar",
                color: "white",
                text: "",
              });
              markers.push({
                time: lastTrade[i].time_ms / 1000,
                size: 2.2,
                shape: "circle",
                position: "inBar",
                color: "black",
                text: "",
              });
              markers.push({
                time: lastTrade[i].time_ms / 1000,
                size: 1.4,
                shape: "circle",
                position: "inBar",
                color: "#84dc44",
                text: "",
              });
            } else {
              // markers.push( {time: lastTrade[i].time_ms/1000, size: 2, shape: "circle", position: "inBar", color: "rgb(255, 70, 70)", text: "$" + lastTrade[i].trade_cost.toFixed(2)});
              markers.push({
                time: lastTrade[i].time_ms / 1000,
                size: 2.4,
                shape: "circle",
                position: "inBar",
                color: "white",
                text: "",
              });
              markers.push({
                time: lastTrade[i].time_ms / 1000,
                size: 2.2,
                shape: "circle",
                position: "inBar",
                color: "black",
                text: "",
              });
              markers.push({
                time: lastTrade[i].time_ms / 1000,
                size: 1.4,
                shape: "circle",
                position: "inBar",
                color: "rgb(255, 30, 30)",
                text: "",
              });
            }
          }
        }
      }

      series.current.setMarkers(markers);
    }

  }, [lwData, series, ltp]);

    /*
    <Grid container spacing={0} padding={0}>
        <Grid xs={11} padding={0} alignItems="right">
          <div class={classes.chart} ref={chartContainerRef} />
        </Grid>
        <Grid xs={1} padding={0} alignItems="right">
          <DailyHiLo dh={(daily !== {})? daily.dh_price : 0} dl={(daily !== {})? daily.dl_price : 0} ltp={ltp} avg_px={avg_px} />
        </Grid>
    </Grid>
    */
  return (
          <div class={classes.chart} ref={chartContainerRef} />
  );
};
export default React.memo(MarketShareAreaChartCard);
