import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { coinbaseSettingsExchange } from "store/actions";

// material-ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";

import useKraken from "hooks/useKraken";

// ===============================|| UI DIALOG - FORMS ||=============================== //

export default function CoinbaseTokenModal() {
  const { getToken } = useKraken();
  const dispatch = useDispatch();

  const [selectedExchange, exchanges] = useSelector((state) => [
    state.exchange.selectedExchange,
    state.exchange.exchanges,
  ]);

  const [open, setOpen] = React.useState(false);

  const [apiKey, setApiKey] = React.useState({
    key: window.localStorage.getItem("coinbase_key") || "",
    secret: window.localStorage.getItem("coinbase_sec") || "",
    paraphrase: window.localStorage.getItem("coinbase_paraphrase") || "",
  });

  useEffect(() => {
    if (
      selectedExchange === "coinbase" &&
      exchanges[selectedExchange] &&
      exchanges[selectedExchange].configured === false
    ) {
      setOpen(true);
    }
  }, [selectedExchange, exchanges]);

  const handleChangeKey = (e) => {
    setApiKey({
      ...apiKey,
      key: e.target.value,
    });
  };
  const handleChangeSecret = (e) => {
    setApiKey({
      ...apiKey,
      secret: e.target.value,
    });
  };
  const handleChangeParaphrase = (e) => {
    setApiKey({
      ...apiKey,
      paraphrase: e.target.value,
    });
  }; 

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);

    if (apiKey.key !== "" && apiKey.secret !== "" && apiKey.paraphrase !== "") {
      window.localStorage.setItem("coinbase_key", apiKey.key);
      window.localStorage.setItem("coinbase_sec", apiKey.secret);
      window.localStorage.setItem("coinbase_paraphrase", apiKey.paraphrase);
      dispatch(
        coinbaseSettingsExchange({
          key: apiKey.key,
          secret: apiKey.secret,
          paraphrase: apiKey.paraphrase,
        })
      );
    }
  };

  const Close = () => {
    setOpen(false);
  };

  return (
    <>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Set Coinbase API Keys
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Coinbase API/SECRET</DialogTitle>
        <DialogContent style={{ minWidth: 500 }}>
          <Typography variant="body2" sx={{ pb: 3 }}>
            API KEY
          </Typography>

          <TextField
            autoFocus
            id="name"
            label="KEY"
            type="email"
            fullWidth
            value={apiKey.key}
            onChange={handleChangeKey}
          />
          <Typography variant="body2" sx={{ pb: 3 }}>
            API SECRET
          </Typography>

          <TextField
            autoFocus
            id="name"
            label="SEC"
            type="email"
            fullWidth
            value={apiKey.secret}
            onChange={handleChangeSecret}
          />

          <Typography variant="body2" sx={{ pb: 3 }}>
            API paraphrase
          </Typography>

          <TextField
            autoFocus
            id="name"
            label="SEC"
            type="email"
            fullWidth
            value={apiKey.paraphrase}
            onChange={handleChangeParaphrase}
          />
        </DialogContent>
        <DialogActions sx={{ pr: 2.5 }}>
          <Button onClick={Close} color="error">
            Cancel
          </Button>
          <Button variant="contained" size="small" onClick={handleClose}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
