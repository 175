import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setDiveBarClubChannels,
  setDiveBarMessage,
  setDiveBarClubUser,
  setDiveBarClubUsers,
  setDiveBarCoinAndInterval,
  currencyGetAllResponse,
  setDiveBarClubRoomId,
  setDiveBarClubMainChannel,
  currencyGetChannelResponse
} from "store/actions";
import diveBarSocket from "./diveBarSocket";

let globalLogin = "";
let globalPassword = "";
let isUserAuth = false;

function useDiveBarSocket() {
  const dispatch = useDispatch();
  const [isConnected, setIsconnected] = useState(false);

  const userCreate = (login, password, display_name, email) => {
    globalLogin = login;
    globalPassword = password;

    emit({
      op: "user_create",
      login,
      password,
      display_name,
      email,
    });
  };

  const addToChannel = (channel_id, to) =>
    emit({
      op: "channel_add_request",
      channel_id,
      to,
    });

  const channelCreate = (login, display_name) =>
    emit({
      op: "channel_create",
      login,
      display_name,
    });

  const userAuth = (login, password) =>
    emit({
      op: "user_auth",
      login,
      password,
    });

  const channelList = () => emit({ op: "channel_list" });
  const usersList = (channel_id) => emit({ op: "user_list", channel_id });
  const userMsg = (message, channelId) =>
    emit({ op: "user_msg", to: channelId, msg: message });

  const currencyGetEntity = (entity_id) =>
    emit({ op: "currency_get", ent_id: entity_id });
  const currencyGetAll = () => emit({ op: "currency_get" });
  const currencySend = (to, currency, amount) =>
    emit({ op: "currency_send", to: to, currency: currency, amnt: amount });
  const currencyExchange = (
    dst_fx,
    dst_amount,
    src_fx,
    src_amount,
    channel_id
  ) =>
    emit({
      op: "currency_exchange",
      dst_currency: dst_fx,
      dst_amnt: dst_amount,
      src_currency: src_fx,
      src_amnt: src_amount,
      channel_id: channel_id,
    });

  //TODO! useCallback!!

  const onMessage = (event) => {
    console.log("onmessage");
    console.log({ event });
    try {
      let message = JSON.parse(event.data);
      // console.log(message.op + ", " + message.error_code);
      if (message.op === "user_create" || message.op === "user_auth") {
        dispatch(setDiveBarClubUser(message.id));
        setTimeout(() => {
          isUserAuth = true;
          //after login
          channelList();
        }, 5000);
      } else if (
        message.error_msg &&
        (message.error_msg.includes("User login already exist") ||
          message.error_msg.includes("Duplicate"))
      ) {
        setTimeout(() => {
          userAuth(globalLogin, globalPassword);
        }, 100);
      } else if (message.op === "channel_create") {
        setTimeout(() => {
          channelList();
        }, 500);
      } else if (message.op === "channel_list") {
        dispatch(setDiveBarClubChannels(message.channels));

        message.channels.forEach((channel) => {
          usersList(channel.id);
          if (channel.display_name === "main") {
            //select by default
            dispatch(
              setDiveBarClubRoomId({
                id: channel.id,
                lastMessage: "",
                messages: [],
                name: channel.display_name,
                roomId: channel.id,
                unReadChatCount: 0,
              })
            );

            //Save main channel
            dispatch(setDiveBarClubMainChannel(channel));
            //fetch portfolio for main
            currencyGetEntity(channel.id);

          }
        });
      } else if (message.op === "user_msg") {
        console.log({ message });
        if (message.msg.includes("transport_message")) {
          const transportMessage = JSON.parse(message.msg);
          console.log({ transportMessage });

          dispatch(
            setDiveBarCoinAndInterval(
              transportMessage.coin,
              transportMessage.interval
            )
          );
        } else {
          console.log(
            "Crashing here ----------------------------------------------"
          );
          console.log(message);
          dispatch(setDiveBarMessage(message));
        }
      } else if (message.op === "user_list") {
        console.log("dispatch::users ------------------- ");
        console.log(message.users);
        dispatch(setDiveBarClubUsers(message.users, message.channel_id));
      } else if (message.op === "currency_get") {
        console.log(message.server_portfolio);
        // const cget = JSON.parse(message.server_portfolio);
        // console.log({ cget });
        if (message.server_portfolio) {
          dispatch(currencyGetAllResponse(message.server_portfolio));
        } else {
          dispatch(
            currencyGetChannelResponse({
              id: message.ent_id,
              portfolio: message.portfolio,
            })
          );
        }
      }
    } catch (e) {}
  };

  const onClose = () => {
    setIsconnected(false);
  };

  const onConnect = () => {
    setIsconnected(true);
    console.log("connected!");
  };

  // TODO SAMEER const { emit } = diveBarSocket();

  const emit = (message) => console.log(message);

  useEffect(() => {
    /*
    const { checkIsConnected, connect } = diveBarSocket();
    if (!checkIsConnected()) {
      console.log("connect");
      connect(onMessage, onClose, onConnect);
    }
    */
  }, []);

  //RECONNECT every 20 sec
  useEffect(() => {
    let reconnectTimer = setInterval(() => {
      const { checkIsConnected, connect } = diveBarSocket();
      if (!checkIsConnected()) {
        connect(onMessage, onClose, onConnect);
      }
    }, 20000);

    return () => {
      clearTimeout(reconnectTimer);
    };
  }, [onMessage]);

  return {
    isConnected,
    emit,
    setIsconnected,
    userCreate,
    channelList,
    usersList,
    userMsg,
    userAuth,
    channelCreate,
    addToChannel,
    isUserAuth,
    currencyGetEntity,
    currencyGetAll,
    currencySend,
    currencyExchange,
  };
}

export default useDiveBarSocket;
