import React, {useEffect, useReducer, useState} from "react";
import MobileExploreCard from "./components/MobileExploreCard";
import MobileBreakout from "../MobileBreakout";
import MobileTrendingBuy from "../MobileTrendingBuySell/MobileTrendingBuy";
import MobileTrendingSell from "../MobileTrendingBuySell/MobileTrendingSell";
import MobileBasket from "../MobileBasket";
import MobileLiquidate from "../MobileLiquidate";
import { useSelector} from "react-redux";
import { map_coins, map_coin_to_name, map_untradable_kraken_assets} from 'store/constant';
import FavoritesCard from '../../Favorites/components/FavoritesCard';
import { Grid, Box, Typography, Tab, Tabs, Chip, Badge } from "@material-ui/core";
import SubCard from "ui-component/cards/SubCard";
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import { useDispatch } from "react-redux";
import {
  TAB_DEL_PINNED_COIN, 
} from "store/actions";
import colors from 'assets/scss/_themes-vars.module.scss';
// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";


const CoinDetails = ({coin,onDelete}) => {
  const key_style = map_coins.get(coin);

  const ltp  = useSelector((state) => {
    return state.coins.kraken_asset_pairs.get(coin) || {ltp: 0.00};
    });

    // console.log(ltp);

    // const move = item.move === "↓↓↓" ? "down" : "up";
    // const rung = item.move === "↓↓↓" ? "#FAA" : "rgb(132, 237, 102)";

    return (<div style={{position:'relative'}}>

<div style={{position:'absolute', top:'-12px', right: '-12px'}} onClick={() => onDelete(coin)}>
<CancelIcon  />
</div>
      <div
      style={{
        height: '50px',
        width: '130px',
    boxAlign: 'center',
    alignItems: 'center',
    padding: '6px',
    backgroundColor: '#222429',
    borderRadius: '8px',
    border: '1px solid rgb(68 68 68)',
    whiteSpace: 'nowrap',

    
      }}
      >
        <div>
        <font sx={{ "font-size": "0.8rem" }} class={key_style} >{coin}</font>

        </div>
        <div>
        <font sx={{ 'color': 'white', "font-size": "0.8rem" }} >{ltp.ltp}</font>
        </div>
      </div>
      {/* </Badge> */}

    </div>)
}
 

const MobileExplore = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const background = (theme.palette.mode === 'dark') ? '#00070f' : '#fff';
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const whiteTakyon = theme.palette.mode === "dark" ? colors.whiteLightTakyon : colors.whiteDarkTakyon;
  const cornsilk = theme.palette.mode === "dark" ? colors.cornsilkLightTakyon : colors.cornsilkDarkTakyon;

  const explore_list = useSelector((state) => {
    return state.coins.kraken_asset_pairs;
  });




  const map_tabbed_coins = [];
  

  
  // useSelector((state) => {
  //   state.coins.explore_tabs.forEach((key, index) => {
  //       if ( state.coins.kraken_asset_pairs.get(key))
  //       {
  //           map_tabbed_coins.push(state.coins.kraken_asset_pairs.get(key));
  //       }
  //   });
  // });

  
 const tabbed =  useSelector((state) => {

  return   state.coins.explore_tabs.map(tab => ({
    coin:tab,
    ltp: state.coins.kraken_asset_pairs.get(tab) || 0.000
  })) 
  });

  // console.log(tabbed);
  // console.log(map_tabbed_coins);


  // const [mapTabbedCoins, setMapTabbedCoins] = useState([]);

  const explore_tabs = useSelector((state) => {
    return state.coins.explore_tabs;
  });



  // useEffect(()=>{
  //   setMapTabbedCoins(explore_tabs)
  //   setTimeout(()=>{
  //     setMapTabbedCoins(explore_tabs.map((tab)=>tab))
  //   },3000)

  //   return 
  // },[]);

  
// setTimeout(()=>{setCoins([])},2000)

  //instead of realtime price changes  -  make  price updating every 3-5 sec 

  // useEffect(()=>{

  //   useSelector((state) => {
  //     state.coins.explore_tabs.forEach((key, index) => {
  //         if ( state.coins.kraken_asset_pairs.get(key))
  //         {
  //             map_tabbed_coins.push(state.coins.kraken_asset_pairs.get(key));
  //         }
  //     });
  //   });


  // },[map_tabbed_coins])

  const [tabsValue, setTabsValue] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setTabsValue(newValue);
  };

  // const explore_tabs = useSelector((state) => {
  //   return state.coins.explore_tabs;
  // });





  const handleDeleteTab  = (coin) => {
    dispatch({type: TAB_DEL_PINNED_COIN, payload: {coin}});
    forceUpdate();
  }

  return (
    <>
        <Tabs
            value = {tabsValue}
            onChange={handleChangeTab}
            aria-label="ant example"
            sx={{width:'100%'}}
          >
                <Tab
                    sx={{background: 'white'}}
                    label={
<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-home-2" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <polyline points="5 12 3 12 12 3 21 12 19 12" />
  <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
  <rect x="10" y="12" width="4" height="4" />
</svg>
                      } 
                />
                <Tab
                    sx={{background: 'white'}}
                    label={
<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chart-arrows-vertical" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M18 21v-14" />
  <path d="M9 15l3 -3l3 3" />
  <path d="M15 10l3 -3l3 3" />
  <line x1="3" y1="21" x2="21" y2="21" />
  <line x1="12" y1="21" x2="12" y2="12" />
  <path d="M3 6l3 -3l3 3" />
  <path d="M6 21v-18" />
</svg>
                      } 
                />
                <Tab
                    sx={{background: 'white'}}
                    label={
<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trending-up" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <polyline points="3 17 9 11 13 15 21 7" />
  <polyline points="14 7 21 7 21 14" />
</svg>
                      } 
                />
                <Tab
                    sx={{background: 'white'}}
                    label={
<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trending-down" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <polyline points="3 7 9 13 13 9 21 17" />
  <polyline points="21 10 21 17 14 17" />
</svg>
                      } 
                />
                <Tab
                    sx={{background: 'white'}}
                    label={
<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-shopping-cart-plus" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <circle cx="6" cy="19" r="2" />
  <circle cx="17" cy="19" r="2" />
  <path d="M17 17h-11v-14h-2" />
  <path d="M6 5l6.005 .429m7.138 6.573l-.143 .998h-13" />
  <path d="M15 6h6m-3 -3v6" />
</svg>
                      } 
                />
                <Tab
                    label={
<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-shopping-cart-discount" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <circle cx="6" cy="19" r="2" />
  <circle cx="17" cy="19" r="2" />
  <path d="M17 17h-11v-14h-2" />
  <path d="M20 6l-1 7h-13" />
  <path d="M10 10l6 -6" />
  <circle cx="10.5" cy="4.5" r=".5" />
  <circle cx="15.5" cy="9.5" r=".5" />
</svg>
                      } 
                />
          </Tabs>
    <Grid container padding={1.0} />
      {tabsValue === 0 && <MobileExploreCard title="MobileExploreCard" rows={explore_list} />}
      {tabsValue === 1 && <MobileBreakout title="MobileBreakout"/>}
      {tabsValue === 2 && <MobileTrendingBuy title="MobileTrendingBuy"/>}
      {tabsValue === 3 && <MobileTrendingSell title="MobileTrendingSell"/>}
      {tabsValue === 4 && <MobileBasket title="MobileBasket"/>}
      {tabsValue === 5 && <MobileLiquidate title="MobileLiquidate"/>}
    </>
  );
};

export default (MobileExplore);



/*

Home

<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-home-2" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <polyline points="5 12 3 12 12 3 21 12 19 12" />
  <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
  <rect x="10" y="12" width="4" height="4" />
</svg>


Chart

<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chart-bar" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <rect x="3" y="12" width="6" height="8" rx="1" />
  <rect x="9" y="8" width="6" height="12" rx="1" />
  <rect x="15" y="4" width="6" height="16" rx="1" />
  <line x1="4" y1="20" x2="18" y2="20" />
</svg>

Trending Buy

<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trending-up" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <polyline points="3 17 9 11 13 15 21 7" />
  <polyline points="14 7 21 7 21 14" />
</svg>


Trending Sell

<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trending-down" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <polyline points="3 7 9 13 13 9 21 17" />
  <polyline points="21 10 21 17 14 17" />
</svg>


Breakout

<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chart-arrows-vertical" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M18 21v-14" />
  <path d="M9 15l3 -3l3 3" />
  <path d="M15 10l3 -3l3 3" />
  <line x1="3" y1="21" x2="21" y2="21" />
  <line x1="12" y1="21" x2="12" y2="12" />
  <path d="M3 6l3 -3l3 3" />
  <path d="M6 21v-18" />
</svg>


Basket

<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-shopping-cart-plus" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <circle cx="6" cy="19" r="2" />
  <circle cx="17" cy="19" r="2" />
  <path d="M17 17h-11v-14h-2" />
  <path d="M6 5l6.005 .429m7.138 6.573l-.143 .998h-13" />
  <path d="M15 6h6m-3 -3v6" />
</svg>

Liquidate

<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-shopping-cart-discount" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <circle cx="6" cy="19" r="2" />
  <circle cx="17" cy="19" r="2" />
  <path d="M17 17h-11v-14h-2" />
  <path d="M20 6l-1 7h-13" />
  <path d="M10 10l6 -6" />
  <circle cx="10.5" cy="4.5" r=".5" />
  <circle cx="15.5" cy="9.5" r=".5" />
</svg>
*/
