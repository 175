import React, { useContext } from "react";
import { Box, Slider } from "@material-ui/core";
import { useSelector } from "react-redux";
import CoinContext from "contexts/CoinContext";

const DailyHiLo = ({dh, dl, ltp, avg_px}) => {
  //todo coinReducer
 

  const { coin } = useContext(CoinContext);

const value = [ltp, avg_px.toFixed(2)]; 


  function valueText(value) {
    return `${value}`;
  }

  // console.log( 'DAILY_HILO: ' + coin + ', avg: ' + avg_px + ', ltp: ' + ltp + ', ' + dl + ' ---> ' + dh);

  const marks = [
    {
      value: dl,
      label: `lo ${dl}`,
    },
    {
      value: dh,
      label: `hi ${dh}`,
    },
  ];

  return (
    <Box sx={{ width: 150 }}>
      <Slider
        orientation="horizontal"
        id={value}
        size="small"
        value={value}
        getAriaValueText={valueText}
        valueLabelDisplay="off"
        min={dl}
        max={dh}
        marks={marks}
        color="primary"
        sx={{
            "& .MuiSlider-valueLabel": {
            color: "secondary.dark",
          },
        }}
      />
    </Box>
  );
};

export default DailyHiLo;
