import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import MUIFormControl from "@material-ui/core/FormControl";
import BuySellBtn from "assets/images/explore/buysellbtn.png";
import { tooltips } from "constants/tooltips";

import applePay from "assets/images/vc_applePay.png";
import googlePay from "assets/images/vc_googlePay.png";
import amazonPay from "assets/images/vc_amazonPay.png";

import {
  map_coins,
  map_balance_to_coin,
  map_coin_to_name,
  map_category,
} from "store/constant";
import { weekly_portfolio } from "store/weekly";
import I from "immutable";
import useSocket from "hooks/useSocket";
import Transitions from "ui-component/extended/Transitions";
import { useDispatch, useSelector } from "react-redux";
import CoinContext from "contexts/CoinContext";
import colors from "assets/scss/_themes-vars.module.scss";
import ToolTipHelper from "ui-component/ToolTipHelper";

import {
  TAB_ADD_PINNED_COIN,
} from "store/actions";

import {
  InputAdornment,
  OutlinedInput,
  InputLabel,
  IconButton,
  Chip,
  Fab,
} from "@material-ui/core";
import FavoriteButton from "views/dashboard/DashboardTakyon/components/FavoriteButton";

// material-ui
import { withStyles, makeStyles, useTheme } from "@material-ui/styles";

// material-ui
import {
  Badge,
  Button,
  Box,
  Checkbox,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { visuallyHidden } from "@material-ui/utils";

// third party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import SubCard from "ui-component/cards/SubCard";
import ColorSubCard from "ui-component/cards/ColorSubCard";

// assets
import Flag1 from "assets/images/widget/AUSTRALIA.jpg";
import Flag2 from "assets/images/widget/BRAZIL.jpg";
import Flag3 from "assets/images/widget/GERMANY.jpg";
import Flag4 from "assets/images/widget/UK.jpg";
import Flag5 from "assets/images/widget/USA.jpg";


// style constant
const useStyles = makeStyles({
  root: {
    width: "100%",
    overflow: "hidden",
  },
  sortSpan: visuallyHidden,
  cell: {
    cursor: "pointer",
  },
});

// =========================|| DASHBOARD ANALYTICS - LATEST CUSTOMERS TABLE CARD ||========================= //

const VirtualCardSettings = ({virtual_card}) => {

    // TMD console.log( virtual_card);

  const classes = useStyles();
  const { emit } = useSocket();
  const { coin, setCoin } = useContext(CoinContext);
  const dispatch = useDispatch();
  const theme = useTheme();
  const green =
    theme.palette.mode === "dark"
      ? colors.successLightTakyon
      : colors.successDarkTakyon;
  const red =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;
  const cornsilk =
    theme.palette.mode === "dark"
      ? '#fff'
      : colors.cornsilkDarkTakyon;

  //
  return (
      <SubCard sx={{ width: '100%', align: "center" }}>
        <Grid container padding={0} spacing={0} alignItems="center">
            Add Virtual Card to Payment Apps
        </Grid>
        <Grid container padding={2} spacing={2} direction="rows" alignItems="center">
            <Grid item xs={12} lg={12} padding={2} spacing={1} >
                <Table>
                    <TableBody>
                        <TableRow 
                            hover
                            key={'apple'}
                        >
                            <TableCell>
                <img src={applePay} alt="image" style={{borderRadius: '8px', width: '40%', height: '40%'}} />
                            </TableCell>
                            <TableCell>
                &nbsp;&nbsp;&nbsp;&nbsp;Apple Pay
                            </TableCell>
                        </TableRow>
                        <TableRow
                            hover
                            key={'google'}
                        >
                            <TableCell>
                <img src={googlePay} alt="image" style={{borderRadius: '8px', width: '40%', height: '40%'}} />
                            </TableCell>
                            <TableCell>
                &nbsp;&nbsp;&nbsp;&nbsp;Google Pay
                            </TableCell>
                        </TableRow>
                        <TableRow
                            hover
                            key={'amazon'}
                        >
                            <TableCell>
                <img src={amazonPay} alt="image" style={{borderRadius: '8px', width: '30%', height: '30%'}} />
                            </TableCell>
                            <TableCell>
                &nbsp;&nbsp;&nbsp;&nbsp;Amazon Pay
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
      </SubCard>
  );
};

export default VirtualCardSettings;
