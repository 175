import { Grid } from "@material-ui/core";
import React from "react";
import MobileVirtualCard from "../mobile/MobileVirtualCard";
import MobileSummaryCard from "ui-component/cards/mobile/MobileSummaryCard";

const VirtualCardLayout = () => {
  return (
    <Grid container spacing={1.0}>
      <Grid item xs={12} alignItems="center"/>

      <Grid item spacing={0} xs={12}>
           <MobileSummaryCard sx={{ alignItems: "middle" }} />
      </Grid>
      <Grid item xs={12} alignItems="center">
        <MobileVirtualCard />
      </Grid>
    </Grid>
  );
};

export default VirtualCardLayout;
