import React, { useState } from "react";

import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { useSelector, useDispatch } from "react-redux";
import { addToFavorites, removeFromFavorites } from "store/actions";

const FavoriteButton = ({ pair }) => {
  const dispatch = useDispatch();

  const items = useSelector((state) => state.favorites.favorites);

  const item = items.find((item) => item.label === pair);

  const handleAdd = () => dispatch(addToFavorites(pair));
  const handleRemove = () => dispatch(removeFromFavorites(pair));

  return (
    <span>
      {item && (
        <StarIcon
          style={{ color: "#ffd966" }}
          fontSize="medium"
          onClick={handleRemove}
        />
      )}

      {!item && (
        <StarBorderIcon
          style={{ color: "#ffd966" }}
          fontSize="medium"
          onClick={handleAdd}
        />
      )}
    </span>
  );
};

export default FavoriteButton;
