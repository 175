import candlestick_interval from 'assets/images/help/candlestick_interval.png';
import daily_hi_lo from 'assets/images/help/daily_hi_lo.png';
import ltp_hilo_percent from 'assets/images/help/ltp_hilo_percent.png';
import balance from 'assets/images/help/balance.png';
import basket_main from 'assets/images/help/basket_main.png';
import basket_ticket from 'assets/images/help/basket_ticket.png';
import breakout_main from 'assets/images/help/breakout_main.png';
import explore_category from 'assets/images/help/explore_category.png';
import explore from 'assets/images/help/explore.png';
import trending_buy from 'assets/images/help/trending_buy.png';
import trending_sell from 'assets/images/help/trending_sell.png';
import liquidate_allocate from 'assets/images/help/liquidate_allocate.png';
import liquidate_main from 'assets/images/help/liquidate_main.png';
import liquidate_ticket from 'assets/images/help/liquidate_ticket.png';
import lite_order_ticket from 'assets/images/help/lite_order_ticket.png';
import pinned from 'assets/images/help/pinned.png';

import swl_start from 'assets/images/help/swl_start.png';
import swl_seconds from 'assets/images/help/swl_seconds.png';
import swl_breakout from 'assets/images/help/swl_breakout.png';
import swl_24h from 'assets/images/help/swl_24h.png';
import swl_price from 'assets/images/help/swl_price.png';

import {
    Typography,
} from "@material-ui/core";

export const tooltips = {
    none: {
        hover:`' '`,
        modal:' ',
    },
    explore_category: {
        hover:`Filter coins based on categories/new/invested`,
        modal: 
                <table>
                    <tr>
                    <td>
                    <img style={{width: '518px'}} src={explore_category} />
                    </td>
                    </tr>
                    <tr>
                    <td>
            <li>Filter All coins, New coins (not invested), or just the ones that are invested</li>
            <li>From previous filter, u can now filter based on the categories (Meme types etc.)</li>
                    </td>
                    </tr>
                    <tr>
                    <td>
                    <img style={{width: '518px'}} src={explore} />
                    </td>
                    </tr>
                    <tr>
                    <td>
            <Typography variant="h4">Click on Table Headers to SORT (Increasing/Decreasing)</Typography>
            <p/>
            <p/>
            <li>Asset: Coins to trade on the selected exchange</li>
            <li>Price: last trade price of the coin (LTP)</li>
            <li>24h %: Market move in this coin from midnight (daily hi/lo)</li>
            <li>Balance: Your current Holdings (Investments) in this coin</li>
                    </td>
                    </tr>
                </table>
    },
    trending_buy: {
        hover:`Your Personalized Portfolio, based on Last BUY trades`,
        modal: 
                <table>
                    <tr>
                    <td>
                    <img style={{width: '518px'}} src={trending_buy} />
                    </td>
                    </tr>
                    <tr>
                    <td>
            <li>Value of Last (BUY) Trade executed. </li>
            <li>% of Market Movement since your last (BUY) Trade executed. (+ve is GOOD).</li>
            <li>Profit if you SOLD your last (BUY) Trade exit last position.</li>
            <li>Pressure: Market-Pressure (algorithm estimate) "↓↓↓" or "↑↑↑"</li>
                    </td>
                    </tr>
                </table>
    },
    trending_sell: {
        hover:`Your Personalized Portfolio, based on Last SELL trades`,
        modal: 
                <table>
                    <tr>
                    <td>
                    <img style={{width: '518px'}} src={trending_sell} />
                    </td>
                    </tr>
                    <tr>
                    <td>
            <li>Value of Last (SELL) Trade executed. </li>
            <li>% of Market Movement since your last (SELL) Trade executed. (-ve is GOOD)</li>
            <li>Potential Profit if you BOUGHT again, as the Probability of market going back up is high.</li>
            <li>Pressure: Market-Pressure (algorithm estimate) "↓↓↓" or "↑↑↑"</li>
                    </td>
                    </tr>
                </table>
    },
    pinned: {
        hover:`Coins you need to Watch`,
        modal: 
                <table>
                    <tr>
                    <td>
                    <img src={pinned} />
                    </td>
                    </tr>
                    <tr>
                    <td>
            You can 'PIN/Save' (lock) the Coins u need to visit later, in the Chart/Explore-Details.
                    </td>
                    </tr>
                </table>
    },
    swlstart: {
        hover:`Start Time when the 'Breakout' was detected`,
        modal: 
                <table>
                    <tr>
                    <td>
                        START: Local-Time when the 'breakout' event was detected by the Engine, and sent to the Client Window.
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <img src={swl_start} />
                    </td>
                    </tr>
                    <tr>
                    <td>
                        Click on the Header 'Start' to sort in increasing or decreasing chronological order.
                    </td>
                    </tr>
                </table>
    },
    swlsince: {
        hover:`Total time elapsed since the 'breakout' (Start) was reported`,
        modal: 
                <table>
                    <tr>
                    <td>
                        SECONDS: Total time elapsed since the 'breakout' (Start) was reported. The lower the value, the most recent the breakout is.
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <img src={swl_seconds} />
                    </td>
                    </tr>
                    <tr>
                    <td>
                        Click on the Header 'Since' to sort in increasing or decreasing order of seconds since 'Start'.
                    </td>
                    </tr>
                </table>
    },
    swlpercent: {
        hover:`Live % Breakout since the Start of the event`,
        modal: 
                <table>
                    <tr>
                    <td>
                        Shows Live % breakout of this asset in the market. The breakouts may last from 1 - 10 minutes, and may continuously go +ve and -ve during the course of the breakouts.
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <img src={swl_breakout} />
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <li>Start:         Time when the 'Breakout' was detected by a % move, usually greater than 0.5% (when rising) or less than -0.5% (when falling)</li>
                        <li>Seconds:       Total time elapsed in Seconds since the breakout was detected</li>
                        <li>Breakout %:    Percent breakout happening in real-time as we report</li>
                        <li>24h %:         24 Hour % move in the asset price since midnight</li>
                        <li>Price:         Last Trade Price in the market for this coin </li>
                    </td>
                    </tr>
                </table>
    },
    swltable: {
        hover:`Live Breakout List`,
        modal: 
                <table>
                    <tr>
                    <td>
                        A 'Breakout' is an 'event' in the market when the volatility (Fear-Index) increases, and the trading volume/activity increases with it. The 'Breakout' can occur in either direction: Markets going up, or Markets going down. Most of the day, the markets remain flat (light trading volume). The 'Breakout' event detects heavy participation by traders (Buyers/Sellers alike), thus pushing the market abnormaly high/low.
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <img style={{width: '518px'}} src={breakout_main} />
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <li>Start:         Time when the 'Breakout' was detected by a % move, usually greater than 0.5% (when rising) or less than -0.5% (when falling)</li>
                        <li>Seconds:       Total time elapsed in Seconds since the breakout was detected</li>
                        <li>Breakout %:    Percent breakout happening in real-time as we report</li>
                        <li>24h %:         24 Hour % move in the asset price since midnight</li>
                        <li>Price:         Last Trade Price in the market for this coin </li>
                    </td>
                    </tr>
                </table>
    },
    backet: {
        hover:`Basket Full Of Coins To Trade in One-Click`,
        modal: 
                <table>
                    <tr>
                    <td>
                        Basket functionality, as the name suggests, allows a 'ONE-CLICK-BUY' option of a group of assets. 
                        This is useful, when the markets are 'screaming upwards', and u want to quickly buy multiple coins at once!
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <img style={{width: '518px'}} src={basket_main} />
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <li>Balance:        Total balance (qty * last_traded_price) in your portfolio</li>
                        <li>Profit:         Current Profit (or Loss) in assets that are in your holdings</li>
                        <li>Profit %:       Unrealized Profit (or Loss), of your holdings in this asset</li>
                        <li>% of each asset:User can allocate a % of the balance to BUY, and increase Cash Reserves</li>
                        <li>Add:            Checkbox selection allows this coin (amount) to be added to the Basket Order Ticket on the right</li>
                    </td>
                    </tr>
                </table>

    },
    backetPercent: {
        hover:`Quickly allocate a percent of your total CASH for each asset to BUY`,
        modal: 
                <table>
                    <tr>
                    <td>
                        Often times, u need to buy a set quantity, from your cash holdings to purchase a group of coins at once.
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <img style={{width: '518px'}} src={liquidate_allocate} />
                    </td>
                    </tr>
                </table>
    },
    liquidate: {
        hover:`Liquidate (SELL) a bunch of coins in One-Click`,
        modal: 
                <table>
                    <tr>
                    <td>
                        Liquidate functionality comes in Handy when u have to 'quickly SELL a bunch of coins' to get back Cash Reserves.
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <img style={{width: '518px'}} src={liquidate_main} />
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <li>Balance:        Total balance (qty * last_traded_price) in your portfolio</li>
                        <li>Profit:         Current Profit, if u were to SELL all your holdings</li>
                        <li>Profit %:       Unrealized Profit (or Loss), of your holdings in this asset</li>
                        <li>% of each asset:User can allocate a % of the balance to SELL, and increase Cash Reserves</li>
                        <li>Add:            Checkbox selection allows this coin (amount) to be added to the Liquidate Order Ticket on the right</li>
                    </td>
                    </tr>
                </table>
    },
    liquidatePercent: {
        hover:`Quickly allocate a percent of your each asset Balance to SELL in One-Click`,
        modal: 
                <table>
                    <tr>
                    <td>
                        Choose a set % of each asset holdings to SELL via Liquidate-One-Click Order Ticket on the right
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <img style={{width: '518px'}} src={liquidate_allocate} />
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <li>Allocate a % to be liquidated</li>
                        <li>The value u see is % of the total balance in each asset holdings u want to Liquidate/SELL</li>
                        <li>U can edit the amount (and change) upto the Balance in your asset holdings</li>
                        <li>Now, select the checkbox to include this asset in the Liquidate Order Ticket</li>
                        <li>Press the 'Liquidate' button to send the SELL group order, thus increasing your Cash Reserves</li>
                    </td>
                    </tr>
                </table>
    },
    orderTicket: {
        hover:`BUY/SELL Coming Soon`,
        modal:
                <table>
                    <tr>
                    <td>
                    <img src={lite_order_ticket} />
                    </td>
                    </tr>
                    <tr>
                    <td>
                        Coming Soon (Order Entry) for BUY/SELL
                    </td>
                    </tr>
                </table>
    },
    bal: {
        hover:`Current balance of your asset holdings, not including Fiat Currency`,
        modal: 
                <table>
                    <tr>
                    <td>
                    <img style={{width: '518px'}} src={balance} />
                    </td>
                    </tr>
                    <tr>
                    <td>
            Value shows the sum total of all invested assets.<p/>
            quantity (qty) * asset-last-traded-price (LTP)<p/>
            <Typography variant="body1">Note</Typography>
                A working ORDER(SELLs) against this assets (BUYs) its qty will be deducted.<p/>
                Hence, the total asset value will not reflect the actual holdings (for now).
                    </td>
                    </tr>
                </table>
    },
    interval: {
        hover:`Candlestick Chart and Intervals`,
        modal: 
                <table>
                    <tr>
                    <td>
                    <img src={candlestick_interval} />
                    </td>
                    </tr>
                    <tr>
                    <td>
            Each Candlestick represents the Time interval (1m: 1 minute, 1h: 1 hour, 1w: 1 week etc.
                    </td>
                    </tr>
                    <tr>
                    <td>
            <img src={daily_hi_lo} />
                    </td>
                    </tr>
                    <tr>
                    <td>
            Slidebar shows the 24 hour Hi/Low price movement (since Midnight, GMT time)
                    </td>
                    </tr>
                    <tr>
                    <td>
            <img src={ltp_hilo_percent} />
                    </td>
                    </tr>
                    <tr>
                    <td>
            LTP: Last Traded Price, and the % change from Daily Hi/Lo since Midnight
                    </td>
                    </tr>
                </table>
    },
    dailyHiLo: {
        hover:`Hover help description`,
        modal: `Aliquam eget finibus ante, non facilisis lectus. Sed vitae dignissim est, vel aliquam tellus.
        Praesent non nunc mollis, fermentum neque at, semper arcu.
        Nullam eget est sed sem iaculis gravida eget vitae justo.`
    },

}
