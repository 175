import PropTypes from "prop-types";
import React, { useState, useContext, useEffect } from "react";
import * as yup from "yup";
import { useSelector } from "react-redux";
import CoinContext from "contexts/CoinContext";
import { useDispatch } from "react-redux";
import { setOrderTicketLite } from "store/actions";
import {
  map_coins,
  map_balance_to_coin,
  map_coin_to_name,
} from "store/constant";
// import useKrakenOHLC from "hooks/useKrakenOHLC";
import "styles/coins.css";
import colors from "assets/scss/_themes-vars.module.scss";

import {
  setLastTrade,
  RESET_ALERT_TRENDING_BUY,
  RESET_ALERT_TRENDING_SELL,
  RESET_ALERT_SWL_POSITIVE,
  RESET_ALERT_SWL_NEGATIVE,
  RESET_ALERT_VEL_POSITIVE,
  RESET_ALERT_VEL_NEGATIVE,
  MOBILE_CHART_OPENED,
} from "store/actions";

// material-ui
import {
  Badge,
  Button,
  Checkbox,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  IconButton,
  Chip,
  Fab,
  Box,
} from "@material-ui/core";

import {
  placeOrderTicket,
  orderTicketTimeout,
  OrderTicketTypes,
} from "store/actions";

import MUIFormControl from "@material-ui/core/FormControl";
import Transitions from "ui-component/extended/Transitions";

// material-ui
import { withStyles, makeStyles, useTheme } from "@material-ui/styles";
import SubCard from "ui-component/cards/SubCard";
import MainCard from "ui-component/cards/MainCard";
import useSocket from "hooks/useSocket";
import useOrderTicket from "hooks/useOrderTicket";
import { addOrder } from "emitters";
import { IconSettings } from "@tabler/icons";

import { IconLoader } from "@tabler/icons";
import AnimateButton from "ui-component/extended/AnimateButton";
import FavoriteButton from "../../../components/FavoriteButton";

/*

    item.coin
    item.alerted
 */
// style constant
const useStyles = makeStyles((theme) => ({
  revenueCard: {
    position: "relative",
    color: "#fff",
  },
  revenueIcon: {
    position: "absolute",
    right: "13px",
    top: "14px",
    color: "#fff",
    "&> svg": {
      width: "100px",
      height: "100px",
      opacity: "0.5",
    },
    [theme.breakpoints.down("xs")]: {
      top: "13px",
      "&> svg": {
        width: "80px",
        height: "80px",
      },
    },
  },
  table: {
    border: 0,
    "table-layout": "fixed",
    width: "50%",
  },
  td: {
    border: "1px solid black",
    width: "50%",
    overflow: "hidden",
  },
}));

const CustomizedBadge = withStyles((theme) => ({
  badge: {
    right: -85,
    top: -25,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

// table filter
function descendingComparator(a, b, orderBy) {
  if (orderBy !== "Pair") {
    if (Number(b[orderBy]) < Number(a[orderBy])) {
      return -1;
    }
    if (Number(b[orderBy]) > Number(a[orderBy])) {
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (array === undefined) {
    return [];
  }
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// =============================|| REVENUE CARD ||============================= //

// table header
const headCells = [
  /*
  {
    id: "ts",
    numeric: false,
    disablePadding: false,
    label: "Time",
  },
  */
  {
    id: "Pair",
    numeric: false,
    disablePadding: true,
    label: "Pair",
  },
  /*
  {
    id: "invested",
    numeric: true,
    disablePadding: false,
    label: "Invested",
  },
  */
  {
    id: "move",
    numeric: false,
    disablePadding: true,
    label: "Pressure",
  },
  {
    id: "last_trade",
    numeric: true,
    disablePadding: false,
    label: "Last Trade",
  },
  {
    id: "level_percent",
    numeric: true,
    disablePadding: false,
    label: "Profit(%)",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    liquidateAmount,
    changeLiquidateAmount,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{backgroundColor: '#191b1e' }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const SummarizedTable = ({ type, items }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("level_percent");
  const { emit } = useSocket();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [selectedIndex, setSelectedIndex] = React.useState("0");
  const { coin, setCoin } = useContext(CoinContext);

  // const { fetchOHLC } = useKrakenOHLC();
  // const { fetchBalance } = useBalance();

  // const ohlcInterval = useSelector((state) => {
  //   return state.chart.ohlcInterval;
  // });

  const coin_style = map_coins.get(coin);
  const coin_name = map_coin_to_name.get(coin)
    ? map_coin_to_name.get(coin)
    : coin;
  const theme = useTheme();

  const green =
    theme.palette.mode === "dark"
      ? colors.successLightTakyon
      : colors.successDarkTakyon;
  const red =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;

  const handleListItemClick = (event, coin, total) => {
    setSelectedIndex(coin);
    setCoin(coin);
    // fetchOHLC(coin, ohlcInterval); // default interval
    // fetchBalance();
    console.log({ type });
    // Let's reset the alert for this coin.

    const tab  = (type === "TrendingBuy" ?  1: 0 );
    dispatch(setOrderTicketLite(tab, total));

    if (type === "TrendingBuy") {
      dispatch({ type: RESET_ALERT_TRENDING_BUY, payload: { coin: coin } });
    } else if (type === "TrendingSell") {
      dispatch({ type: RESET_ALERT_TRENDING_SELL, payload: { coin: coin } });
    }
    dispatch({type: MOBILE_CHART_OPENED, payload: {is_mobile_chart_opened: true}});
  };

  // alerted: true
  // coin: "EOS/USD"
  // icon: "images/logo_EOSUSD.png"
  // move: "↑↑↑"
  // percent: -4.5
  // position: 4.2595
  // price: 4.0845
  // qty: 35.2154
  // side: "buy"
  // ts: 1637276909000

  return (
  <>
    <TableContainer className={classes.container}>
      <Table sx={{ width: "100%" }} size="small" aria-label="LiqPortfolio">
        <EnhancedTableHead
          classes={classes}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {items !== undefined &&
            stableSort(items, getComparator(order, orderBy)).map(
              (row, index) => {
                const coin_style = map_coins.get(row.coin);
                const coin_name = map_coin_to_name.get(row.coin) ? map_coin_to_name.get(row.coin) : row.coin;
                const mrung = row.move === "↓↓↓" ? red : green;
                const rung = row.level_percent < 0.0 ? red : green;
                const labelId = `${row.coin}`;
                const alertCount = row.alerted ? 1 : 0;
                // const ts = new Date(row.ts).toLocaleTimeString();

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    key={row.coin}
                    onClick={(event) => handleListItemClick(event, row.coin, row.last_trade)}
                  >
                    {/*
                                    <TableCell>
                                        <Typography variant="subtitle1" sx={{ fontSize: "0.9rem", textAlign: "left", color: "white", }} >
                                            {ts}
                                        </Typography>
                                    </TableCell>
                                    */}
                    <TableCell>
                      <table
                        style={{
                          fixed: { "table-layout": "fixed" },
                          td: { overflow: "hidden" },
                        }}
                      >
                        <tr>
                          <td style={{ align: "left" }}>
                            <a class={coin_style}> {coin_name} </a>
                        {alertCount == 1 && (
                          <IconButton aria-label="cart">
                            <CustomizedBadge
                              badgeContent={alertCount}
                              color="error"
                            />
                          </IconButton>
                        )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontSize: "0.6rem",
                                textAlign: "left",
                                color: "gray",
                              }}
                            >
                              {/*(map_balance_to_coin.get(row.coin)) ? map_balance_to_coin.get(row.coin) : row.coin*/}
                            </Typography>
                          </td>
                        </tr>
                      </table>
                    </TableCell>

                    <TableCell sx={{ color: rung }}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "1.1rem",
                          textAlign: "left",
                          color: mrung,
                        }}
                      >
                        {row.move}
                      </Typography>
                    </TableCell>

                    <TableCell sx={{ color: rung }}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "1.1rem",
                          textAlign: "left",
                          color: rung,
                        }}
                      >
                        ${row.last_trade.toFixed(0)}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ color: rung }}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: "1.1rem",
                          textAlign: "left",
                          color: rung,
                        }}
                      >
                        {row.level_percent.toFixed(0)}%
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
        </TableBody>
      </Table>
    </TableContainer>
  </>
  );
};

SummarizedTable.propTypes = {};

export default React.memo(SummarizedTable);
