import * as actionTypes from "./actions";
 
const initialState = {
  isOpen: false,
};

const modalSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MODAL_SEARCH_STATUS: {
      return {
        ...state,
        isOpen: action.isOpen,
      };
    }
    default:
      return state;
  }
};

export default modalSearchReducer;
