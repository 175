// ===========================|| WIDGET - TOTAL GRAPH VALUE 1 CHART ||=========================== //

const chartData = {
    type: 'area',
    height: 100,
    options: {
        chart: {
            id: 'coin_portfolio',
            sparkline: {
                enabled: true
            }
        },
        dataLabels: {
            enabled: false
        },
        colors: ['#fff'],
        fill: {
            type: 'solid',
            opacity: 0.4
        },
        stroke: {
            curve: 'smooth',
            width: 3
        },
        tooltip: {
            theme: 'dark',
            fixed: {
                enabled: true
            },
            x: {
                show: false
            },
            y: {
                title: 'Total Sales'
            },
            marker: {
                show: false
            }
        }
    },
    series: [
        {
            name: 'series1',
            data: [20, 10, 18, 12, 25, 10, 20]
        }
    ]
};

export default chartData;
