import * as actionTypes from "./actions";
import * as I from "immutable";
import { Map } from "immutable";
import CoinContext from "contexts/CoinContext";
import { map_coins, map_balance, assetPairsCoins } from "./constant";
import Portfolio from "./models/Portfolio";
import { OrderTicketTypes } from "./actions";

const initialState = {

  coinbase_asset_pairs_loaded: false,
  coinbase_asset_pairs: I.Map(),                  // holds all the info,

  start_date: new Date("July 25, 2021 23:59:59"),
  end_date: new Date("December 31, 2021 23:59:59"),

};

// ===========================|| COINBASE REDUCER ||=========================== //


const coinbaseReducer = (state = initialState, action) => {

  switch (action.type) {

    case actionTypes.COINBASE_ASSET_PAIRS: {
        let iMap = state.coinbase_asset_pairs;
        let coinbase_asset_pairs_loaded = false;

 /*
 {
    "id": "LCX-USDT",
    "base_currency": "LCX",
    "quote_currency": "USDT",
    "base_min_size": "1",
    "base_max_size": "1800000",
    "quote_increment": "0.0001",
    "base_increment": "0.1",
    "display_name": "LCX/USDT",
    "min_market_funds": "1",
    "max_market_funds": "100000",
    "margin_enabled": false,
    "fx_stablecoin": false,
    "max_slippage_percentage": "0.10000000",
    "post_only": false,
    "limit_only": true,
    "cancel_only": false,
    "trading_disabled": false,
    "status": "online",
    "status_message": "",
    "auction_mode": false
  },
  */
        if ( iMap === undefined)
        {
            iMap = new I.Map();
        }
        action.payload.data.forEach((item, index) => {
            if (item.quote_currency === "USD")
            {
                console.log( '-------------> COINBASE: ASSET_PAIR: ' + item.id + ', pair_dec: ' + item.quote_increment + ', ordermin: ' + item.base_min_size);
                iMap = iMap.set( item.id, item);
                coinbase_asset_pairs_loaded = true;
            }
        });

        if ( coinbase_asset_pairs_loaded === true)
        {
            console.log( '-------------> COINBASE: ASSET_PAIRS_LOADED ' + iMap.size);
            return {
                ...state,
                coinbase_asset_pairs_loaded: coinbase_asset_pairs_loaded,
                coinbase_asset_pairs: iMap,
            };
        }
        else
        {
            return state;
        }
    }

    /*
    case actionTypes.TRADE_NOTIFY: {
      let iMap = state.coinbase_asset_pairs;
      let payload = action.payload;
      let coin = iMap.get(payload.coin);

      if (coin !== undefined) {
            coin.ltp = payload.price;
            coin.ts = payload.ts;
            iMap = iMap.set(payload.coin, coin);
            // TMD console.log( '-------------> COINBASE: TRADE_NOTIFY: ' + payload.coin + ' ' + JSON.stringify(coin));
            return { 
                ...state, 
                coinbase_asset_pairs: iMap
            };
      }
      else
      {
            // TODO Implement console.log( 'COINBASE: ERROR TRADE_NOTIFY-----------,  Not In Map' + payload.coin);
            // TODO Implement console.log( state.kraken_asset_pairs);
      }
      return state;
    }
    */

    default:
      return state;
  }
};

export default coinbaseReducer;
