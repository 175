import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { map_coins, map_balance_to_coin, map_coin_to_name } from "store/constant";
import { weekly_portfolio } from "store/weekly";
import I from "immutable";
import useSocket from "hooks/useSocket";
import Transitions from 'ui-component/extended/Transitions';
import { useDispatch,useSelector } from "react-redux";
import CoinContext from "contexts/CoinContext";
import TotalLineChartCard from 'ui-component/cards/TotalLineChartCard';
import { portfolio_weekly_total } from 'store/coinReducer';
import chartData from 'views/widget/Chart/chart-data';
import ApexCharts from 'apexcharts';
import CandleChart from "../../CandleChart";

// import useKrakenOHLC from "hooks/useKrakenOHLC";
import colors from 'assets/scss/_themes-vars.module.scss';

import {
  InputAdornment,
  OutlinedInput,
  InputLabel,
  IconButton,
  Chip,
  Fab,
} from "@material-ui/core";
import MUIFormControl from "@material-ui/core/FormControl";
import FavoriteButton from "../../components/FavoriteButton";

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";

// material-ui
import {
  Button,
  Checkbox,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { visuallyHidden } from "@material-ui/utils";

// third party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import SubCard from "ui-component/cards/SubCard";

// assets
import Flag1 from "assets/images/widget/AUSTRALIA.jpg";
import Flag2 from "assets/images/widget/BRAZIL.jpg";
import Flag3 from "assets/images/widget/GERMANY.jpg";
import Flag4 from "assets/images/widget/UK.jpg";
import Flag5 from "assets/images/widget/USA.jpg";


// style constant
const useStyles = makeStyles({
  root: {
    width: "100%",
    overflow: "hidden",
  },
  sortSpan: visuallyHidden,
});

// =========================|| DASHBOARD ANALYTICS - LATEST CUSTOMERS TABLE CARD ||========================= //

const FavoritesCard = ({coin}) => {

  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const { setCoin } = useContext(CoinContext);

  const cornsilk = (theme.palette.mode === 'dark') ? colors.cornsilkLightTakyon : colors.cornsilkDarkTakyon;
  const whiteTakyon = (theme.palette.mode === 'dark') ? colors.whiteLightTakyon : colors.whiteDarkTakyon;
  const green = (theme.palette.mode === 'dark') ? colors.successLightTakyon : colors.successDarkTakyon;
  const red = (theme.palette.mode === 'dark') ? colors.errorLightTakyon : colors.errorDarkTakyon;
  const coin_style = map_coins.get(coin);
  const coin_name = (map_coin_to_name.get(coin)) ? map_coin_to_name.get(coin) : coin;

    setCoin(coin);

    let trending_buy = {percent:0, avg:0, balance:0, last_trade:0, invested:0};
    let trending_sell = {percent:0, avg:0, balance:0, last_trade:0, invested:0};
    let position = {balance: 0, curr_price: 0, remaining_avg_price: 0, total_fee: 0, realized: 0, profit: 0};
    let daily = {dh_percent:10, dh_price:10, dl_price:1, curr_price:5};

    let tb_rung = red;
    let ts_rung = red;
    let r_rung = red;

    const item  = useSelector((state) => {

        if (state.coins.kraken_asset_pairs !== undefined) {
            const item = state.coins.kraken_asset_pairs.get(coin);
            if ( item !== undefined)
            {
               if ( item.trending_buy)
                    trending_buy = item.trending_buy;
               if ( item.trending_sell)
                    trending_sell = item.trending_sell;
               if ( item.position)
               {
                    position = item.position;
                    if ( item.trending_buy)
                    {
                        trending_buy.avg = item.position.remaining_avg_price.toFixed(3);
                        trending_buy.balance = item.position.balance;
                        trending_buy.last_trade = Number((item.trending_buy.position * item.trending_buy.qty).toFixed(0));
                        trending_buy.invested = Number((item.trending_buy.balance * item.trending_buy.price).toFixed(0));
                        if ( trending_buy.percent > 0)
                        {
                            tb_rung = green;
                        }
                    }
                    if ( item.trending_sell)
                    {
                        trending_sell.avg = item.position.remaining_avg_price.toFixed(3);
                        trending_sell.balance = item.position.balance;
                        trending_sell.last_trade = Number((item.trending_sell.position * item.trending_sell.qty).toFixed(0));
                        trending_sell.invested = Number((item.trending_sell.balance * item.trending_sell.price).toFixed(0));
                        if ( trending_sell.percent > 0)
                        {
                            ts_rung = green;
                        }
                    }
               }
               if ( item.daily)
                    daily = item.daily;
                return item;
            }
        }
        return {};
    });

    //////////////////////////
    // Trades for this coin
    //////////////////////////

    const listTrades = useSelector((state) => {
        let items = [];
        if (state.takyon.mapTrades !== undefined) {
          let count = 0;
          state.takyon.mapTrades.forEach((item, i) => {
            if (item.pair === coin) {
              items.push(item);
            }
          });
        }

        items.sort(function(a, b) {
            // console.log( a.time_ms + ' and ' + b.time_ms);
            return (a.time_ms - b.time_ms);
        });
        return items;
    });


    //////////
    // LTP
    //////////

  let ltp = 0.0;
  if ( daily)
  {
    ltp = daily.curr_price;
  }

  const coin_ltp = useSelector((state) => {
     return (
                (state.coins.kraken_asset_pairs_loaded                  &&
                 state.coins.kraken_asset_pairs.get(coin)               &&
                 state.coins.kraken_asset_pairs.get(coin).ltp
                 )
                 || undefined);
  });

  if ( coin_ltp !== undefined)
  {
    ltp = coin_ltp;
  }


    let rung = 'cornsilk';
    let invested = 0.0;
    let liquidate = 0.0;
    if ( item !== undefined && position !== undefined)
    {
        rung = (ltp < item.avg) ? red : green;
        invested = Number((position) ? (position.balance * position.market_price) : 0.0).toFixed(2);
        liquidate = ((ltp - position.remaining_avg_price) * position.balance).toFixed(2);
        r_rung = (position.realized < 0) ? red : green;

        // Let's find out how many trades are below the avg price, and how many are above.
        let b_trades_below = 0;

        let current_avg = 0.0;
        let current_qty = 0.0;
        let recentb = [];
        let recents = [];
        listTrades.forEach((trade, index) => {
            
            if ( trade.is_buy)
            {
                const ts = new Date(trade.time_ms).toLocaleString("en-US");
                {
                    if ( (b_trades_below + trade.trade_qty) < position.balance)
                    {
                        // Let's include this in the total.
                        b_trades_below += trade.trade_qty;
                        current_avg += (trade.trade_price * trade.trade_qty);
                        current_qty += trade.trade_qty;
                        recentb.push(trade);
                    }
                }
            }
            /*
            if ( trade.is_buy)
            {
                const ts = new Date(trade.time_ms).toLocaleString("en-US");
                if ( (trade.trade_price < ltp)) //  || (trade.trade_price < position.remaining_avg_price))
                {
                    if ( (b_trades_below + trade.trade_qty) < position.balance)
                    {
                        // console.log( trade.pair + ', ' + ts + ', ' + trade.trade_qty + ', ' + position.balance + ', ' + b_trades_below + ', ' + trade.trade_price + ', ' + ltp);
                        // Let's include this in the total.
                        b_trades_below += trade.trade_qty;
                        current_avg += (trade.trade_price * trade.trade_qty);
                        current_qty += trade.trade_qty;
                    }
                }
            }
            */
        });
        // console.log('----------------------');
        if ( current_qty > 0)
        {
            const below_avg_price = (current_avg / current_qty);
            const pnl = b_trades_below * (ltp - below_avg_price);
            // console.log( coin + ': b: ' + position.balance + ', qty: ' + b_trades_below + ', l: ' + ltp + ', a: ' + position.remaining_avg_price.toFixed(2) + ', new:' + below_avg_price.toFixed(2) + ', pnl: ' + pnl.toFixed(2) + ', i:' + invested + ', liq: ' + liquidate);

            // We have recent trades. Find out how many are below the ltp
            let r_tot = 0;
            let r_qty = 0;
            recentb.forEach((t, index) => {
                if ( t.trade_price < ltp)
                {
                    r_tot += (t.trade_price * t.trade_qty);
                    r_qty += t.trade_qty;
                    console.log(ltp + ', ' + {t});
                    console.log(t);
                }
            });
            const f_avg = (r_tot / r_qty);
            const f_pnl = (ltp - f_avg) * r_qty;
            const f_percent = (ltp - f_avg) * 100.0 / f_avg;

            // console.log( coin + ', F-------->: fa: ' + f_avg.toFixed(2) + ' ' + ltp + ' ' + position.remaining_avg_price.toFixed(2) + ', fpnl: ' + f_pnl.toFixed(2) + ', %:' + f_percent.toFixed(2) + ', f_qty: ' + r_qty + ', b: ' + position.balance + ', i: ' + invested + ', l: ' + liquidate);
        }

        // Let's print the output
        /*

        let b_trades_below = 0;
        let b_trades_total = 0;
        let s_trades_below = 0;
        let s_trades_total = 0;
        let b_cost_total = 0;
        let b_cost_below = 0;
        let s_cost_total = 0;
        let s_cost_below = 0;

        let last_b_trades = [];
        let last_s_trades = [];

        let cumul_qty = 0;

        listTrades.forEach((trade, index) => {
            
            if ( trade.is_buy)
            {
                cumul_qty += trade.trade_qty;

                last_b_trades.push(trade);
                b_trades_total += trade.trade_qty;
                b_cost_total += trade.trade_qty * trade.trade_price;
                if ( trade.trade_price < item.avg)
                {
                    b_trades_below += trade.trade_qty;
                    b_cost_below += trade.trade_qty * trade.trade_price;
                }
            }
            else
            {
                if ( cumul_qty > trade.trade_qty)
                {
                    cumul_qty -= trade.trade_qty;
                }
                else
                {
                    cumul_qty = 0;
                }

                last_s_trades.push(trade);
                b_trades_total += trade.trade_qty;
                s_trades_total += trade.trade_qty;
                s_cost_total += trade.trade_qty * trade.trade_price;
                if ( trade.trade_price < item.avg)
                {
                    s_trades_below += trade.trade_qty;
                    s_cost_below += trade.trade_qty * trade.trade_price;
                }
            }
        });

        const rem = (b_trades_total - s_trades_total);
        const below = (b_trades_below < rem) ? b_trades_below : rem;


        // Let's calculate the PNL of the last trades.
        if ( ltp > 0.0)
        {
                const pnl = trade.trade_qty * (ltp - trade.trade_price).toFixed(2);
                const percent = ((ltp - trade.trade_price) * 100 / trade.trade_price).toFixed(2);
                console.log( 'PnL: ' + trade.pair + ', ' + trade.trade_id + ', ' + trade.trade_qty + ', ' + trade.trade_price + ', ' + ltp + ', ' + pnl + ', ' + percent);
            console.log('-------------------------------------------');
        }

        // console.log( coin + ' below: ' + b_trades_below.toFixed(0) + ', rem: ' + rem.toFixed(0) + ', f: ' + below.toFixed(0) + ', ' + item.ltp + ' - ' + item.avg + ' = ' + (item.ltp - item.avg) + ', liq: ' + (below * (item.ltp - item.avg)));
        console.log( coin + 
            ', qty: ' + s_trades_total.toFixed(0) + ', ' + b_trades_total.toFixed(0) +
            ', px: ' + s_cost_total.toFixed(0) + ', ' + b_cost_total.toFixed(0) +
            ', pnl: ' + (s_cost_total - b_cost_total).toFixed(0) +
            ', %: ' + ((s_cost_total - b_cost_total) * 100 / s_cost_total).toFixed(0) +
            ', rem ' + (b_trades_total - s_trades_total).toFixed(0) + ', below: ' + b_trades_total.toFixed(0)
        );

            bt = 100; bb = 20; st = 60; (remaining = bt - st = 40);
            bt < rem: bt otherwise
            bt = 100; bb = 20; st = 90; (remaining = bt - st = 10);

        console.log( coin + 
            ', qty: ' + s_trades_below.toFixed(0) + ', ' + b_trades_below.toFixed(0) +
            ', px: ' + s_cost_below.toFixed(0) + ', ' + b_cost_below.toFixed(0) +
            ', pnl: ' + (s_cost_below - b_cost_below).toFixed(0) +
            ', %: ' + ((s_cost_below - b_cost_below) * 100 / s_cost_below).toFixed(0) +
            ', rem ' + (b_trades_below - s_trades_below).toFixed(0) + ', below: ' + b_trades_below.toFixed(0)
        );
        */
    }

    const tb_str = JSON.stringify(trending_buy);
    const ts_str = JSON.stringify(trending_sell);
    const pos_str = JSON.stringify(position);
    const daily_str = JSON.stringify(daily);



    /*
    const cdata_coin = useSelector((state) => {
        return state.coins.cdata_coin.get(coin);
    });
    chartData.TotalLineCardChart1.series[0].data = cdata_coin;
    */

    // Let's find out how many trades are below the ltp.

  return (
  <>
        <CandleChart />
        <Grid container padding={1.0} />
        <>
        <Grid container padding={2}>
            <Grid xs={3} padding={1} justifyContent="center">
                <SubCard alignItems="center">
                    <Typography style={{ color: whiteTakyon, align: 'middle', "text-align": "center", "font-size": "1.2rem", }} >
                        Summary
                    </Typography>
                    <Grid container spacing={1} padding={2} alignItems="middle">
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: cornsilk, "text-align": "left", "font-size": "1.0rem", }} >
                                {'Invested'}
                            </font>    
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: rung, "text-align": "left", "font-size": "1.3rem", }} >
                                {invested}
                            </font>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: cornsilk, "text-align": "left", "font-size": "1.0rem", }} >
                                {'Liquidate'}
                            </font>    
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: rung, "text-align": "left", "font-size": "1.3rem", }} >
                                {liquidate}
                            </font>    
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: cornsilk, "text-align": "left", "font-size": "1.0rem", }} >
                                {'Quantity'}
                            </font>    
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: whiteTakyon, "text-align": "left", "font-size": "0.9rem", }} >
                                {position.balance}
                            </font>    
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
                {trending_buy.percent !== 0 &&
            <Grid xs={3} padding={1} alignItems="center">
                <SubCard padding={1} spacing={1} alignItems="center">
                    <Typography sx={{align: 'middle', textAlign: 'center'}}>
                        <font style={{ color: whiteTakyon, align: 'center', "text-align": "center", "font-size": "1.2rem", }} >
                            {'Trending '}
                        </font>
                        <font style={{ color: green, align: 'center', "text-align": "center", "font-size": "1.2rem", }} >
                            {' BUY'}
                        </font>
                    </Typography>
                    <Grid container spacing={1} padding={2} alignItems="middle">
                        <Grid item xs={12} lg={12} />
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: cornsilk, "text-align": "left", "font-size": "1.0rem", }} >
                                {'Change'}
                            </font>    
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: tb_rung, "text-align": "left", "font-size": "1.3rem", }} >
                                {trending_buy.percent}{'%'}
                            </font>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: cornsilk, "text-align": "left", "font-size": "1.0rem", }} >
                                {'Last Trade'}
                            </font>    
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: tb_rung, "text-align": "left", "font-size": "1.3rem", }} >
                                {'$'}{trending_buy.last_trade}
                            </font>    
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: cornsilk, "text-align": "left", "font-size": "1.0rem", }} >
                                {'Profit/Loss'}
                            </font>    
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: tb_rung, "text-align": "left", "font-size": "1.3rem", }} >
                                {'$'}{((trending_buy.price - trending_buy.position) * trending_buy.qty).toFixed(2)}
                            </font>    
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
                }
                {trending_sell.percent !== 0 &&
            <Grid xs={3} padding={1} alignItems="center">
                <SubCard alignItems="center">
                    <Typography sx={{align: 'middle', textAlign: 'center'}}>
                        <font style={{ color: whiteTakyon, align: 'center', "text-align": "center", "font-size": "1.2rem", }} >
                            {'Trending '}
                        </font>
                        <font style={{ color: red, align: 'center', "text-align": "center", "font-size": "1.2rem", }} >
                            {' SELL'}
                        </font>
                    </Typography>
                    <Grid container spacing={1} padding={2} alignItems="middle">
                        <Grid item xs={12} lg={12} />
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: cornsilk, "text-align": "left", "font-size": "1.0rem", }} >
                                {'Change'}
                            </font>    
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: ts_rung, "text-align": "left", "font-size": "1.3rem", }} >
                                {trending_sell.percent}{'%'}
                            </font>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: cornsilk, "text-align": "left", "font-size": "1.0rem", }} >
                                {'Last Trade'}
                            </font>    
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: ts_rung, "text-align": "left", "font-size": "1.3rem", }} >
                                {'$'}{trending_sell.last_trade}
                            </font>    
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: cornsilk, "text-align": "left", "font-size": "1.0rem", }} >
                                {'Profit/Loss'}
                            </font>    
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: ts_rung, "text-align": "left", "font-size": "1.3rem", }} >
                                {'$'}{((trending_sell.price - trending_sell.position) * trending_sell.qty).toFixed(2)}
                            </font>    
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
                }
            <Grid xs={3} padding={1} alignItems="center">
                <SubCard alignItems="center">
                    <Typography style={{ color: whiteTakyon, align: 'middle', "text-align": "center", "font-size": "1.2rem", }} >
                        Take Interim Profit
                    </Typography>
                    <Grid container spacing={1} padding={2} alignItems="middle">
                        <Grid item xs={12} lg={12} />
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: cornsilk, "text-align": "left", "font-size": "1.0rem", }} >
                                {'Invested'}
                            </font>    
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: rung, "text-align": "left", "font-size": "1.3rem", }} >
                                {invested}
                            </font>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: cornsilk, "text-align": "left", "font-size": "1.0rem", }} >
                                {'Liquidate'}
                            </font>    
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: rung, "text-align": "left", "font-size": "1.3rem", }} >
                                {liquidate}
                            </font>    
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: cornsilk, "text-align": "left", "font-size": "1.0rem", }} >
                                {'LTP'}
                            </font>    
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: cornsilk, "text-align": "left", "font-size": "1.3rem", }} >
                                {daily.curr_price}
                            </font>    
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: cornsilk, "text-align": "left", "font-size": "1.0rem", }} >
                                {'Average Px'}
                            </font>    
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: 'orange', "text-align": "left", "font-size": "1.3rem", }} >
                                {position.remaining_avg_price.toFixed(3)}
                            </font>    
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
            <Grid xs={3} padding={1} alignItems="center">
                <SubCard alignItems="center">
                    <Typography style={{ color: whiteTakyon, align: 'middle', "text-align": "center", "font-size": "1.2rem", }} >
                        PnL Summary
                    </Typography>
                    <Grid container spacing={1} padding={2} alignItems="middle">
                        <Grid item xs={12} lg={12} />
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: cornsilk, "text-align": "left", "font-size": "1.0rem", }} >
                                {'Realized'}
                            </font>    
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: r_rung, "text-align": "left", "font-size": "1.3rem", }} >
                                {'$'}{position.realized?.toFixed(2)}
                            </font>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: cornsilk, "text-align": "left", "font-size": "1.0rem", }} >
                                {'Unrealized'}
                            </font>    
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: rung, "text-align": "left", "font-size": "1.3rem", }} >
                                {'$'}{position.profit}
                            </font>    
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: cornsilk, "text-align": "left", "font-size": "1.0rem", }} >
                                {'Fee'}
                            </font>    
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <font style={{ color: cornsilk, "text-align": "left", "font-size": "1.3rem", }} >
                                {'$'}{position.total_fee?.toFixed(2)}
                            </font>    
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
            <Grid xs={3} padding={1} alignItems="center">
                <TotalLineChartCard
                    chartData={chartData.TotalLineCardChart1}
                    bgColor={theme.palette.error.main}
                    value={coin}
                    title="Weekly Portfolio"
                    percentage="15%"
                />
            </Grid>
        </Grid>
        </>
  </>
  );
};

FavoritesCard.propTypes = {
  title: PropTypes.string,
};

export default FavoritesCard;
