import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import PerfectScrollbar from "react-perfect-scrollbar";
import colors from "assets/scss/_themes-vars.module.scss";

import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
    Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";

// project imports
import MainCard from "ui-component/cards/MainCard";
import SubCard from "ui-component/cards/SubCard";
import SecondaryAction from "ui-component/cards/CardSecondaryAction";

// assets
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useDispatch, useSelector } from "react-redux";

import { getTradesTable } from "store/actions";

// tab content
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid item xs={12} md={12}>
          <Typography variant="h4" sx={{color: 'white'}}>{children}</Typography>
        </Grid>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


// style constant
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

// table data
function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      { date: "2020-01-05", customerId: "11091700", amount: 3 },
      { date: "2020-01-02", customerId: "Anonymous", amount: 1 },
    ],
  };
}

function Row(props) {
  const theme = useTheme();
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const green =
    theme.palette.mode === "dark"
      ? colors.successLightTakyon
      : colors.successDarkTakyon;
  const red =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;
  const cornsilk =
    theme.palette.mode === "dark"
      ? '#fff'
      : colors.cornsilkDarkTakyon;

  return (
    <>
      <TableRow hover className={classes.root}>
        <TableCell sx={{ pl: 3 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.coin}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right" sx={{ pr: 3 }}>
          {row.protein}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 1,
              }}
            >
              <TableContainer>
                <SubCard
                  sx={{
                    bgcolor:
                      theme.palette.mode === "dark"
                        ? "dark.800"
                        : theme.palette.grey[50],
                    mb: 2,
                  }}
                  title="History"
                  content={false}
                >
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>exchId</TableCell>
                        <TableCell>pair</TableCell>
                        <TableCell>side</TableCell>
                        <TableCell>qty</TableCell>
                        <TableCell>rem</TableCell>
                        <TableCell>filled</TableCell>
                        <TableCell>price</TableCell>
                        <TableCell>status</TableCell>
                        <TableCell align="right">sequence</TableCell>
                        <TableCell align="right">data</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.data.map((dataRow) => {
                        let s = "NEW";
                        if (dataRow.orderStatus === 1) s = "NEW";
                        else if (dataRow.orderStatus === 2) s = "PENDING";
                        else if (dataRow.orderStatus === 3) s = "ACK";
                        else if (dataRow.orderStatus === 4)
                          s = "PENDING_CANCEL";
                        else if (dataRow.orderStatus === 5) s = "CANCELED";
                        else if (dataRow.orderStatus === 6) s = "FILLED";
                        else if (dataRow.orderStatus === 7) s = "PARTIAL";
                        else if (dataRow.orderStatus === 8) s = "REJECTED";
                        else s = "UNKNOWN";

                        const rung = dataRow.side === "SELL" ? red : green;

                        return (
                          <TableRow hover key={dataRow.curr}>
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ color: rung }}
                            >
                              {" "}
                              {dataRow.exchId}{" "}
                            </TableCell>
                            <TableCell sx={{ color: rung }}>
                              {" "}
                              {dataRow.fc}{" "}
                            </TableCell>
                            <TableCell sx={{ color: rung }}>
                              {" "}
                              {dataRow.side}{" "}
                            </TableCell>
                            <TableCell sx={{ color: rung }}>
                              {" "}
                              {dataRow.qty}{" "}
                            </TableCell>
                            <TableCell sx={{ color: rung }}>
                              {" "}
                              {dataRow.rem}{" "}
                            </TableCell>
                            <TableCell sx={{ color: rung }}>
                              {" "}
                              {dataRow.filled}{" "}
                            </TableCell>
                            <TableCell sx={{ color: rung }}>
                              {" "}
                              {dataRow.px}{" "}
                            </TableCell>
                            <TableCell sx={{ color: rung }}> {s} </TableCell>
                            <TableCell sx={{ color: rung }} align="right">
                              {" "}
                              {dataRow.sequence}{" "}
                            </TableCell>
                            <TableCell sx={{ color: rung }} align="right">
                              {" "}
                              {new Date(dataRow.curr).toLocaleTimeString()}{" "}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </SubCard>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};
 

// ===========================|| TABLE - COLLAPSIBLE ||=========================== //

export default function VirtualCardTransactions({virtual_card}) {

    // TMD console.log( virtual_card);

  // const tableData = useSelector((state) => state.trades.tradesTable);
  // console.log("VirtualCardTransactions: " + tableData.length);

    const [value, setValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const list_event_cards = useSelector((state) => { return state.cards.list_event_cards });
    console.log( {list_event_cards});

  return (
    <>

        <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            aria-label="simple tabs example"
            variant="scrollable"
        >

            <Tab value={0} onChange={handleTabChange} label={'Events'} />
            <Tab value={1} onChange={handleTabChange} label={'Trans'} />
            <Tab value={2} onChange={handleTabChange} label={'Merchants'} />
            <Tab value={3} onChange={handleTabChange} label={'Auth'} />

        </Tabs>

        <TabPanel value={value} index={0}>
              <PerfectScrollbar>
                <TableContainer>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                          <TableCell>Amount</TableCell>
                          <TableCell>Created</TableCell>
                          <TableCell>Result</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Token</TableCell>
                      </TableRow>
                    </TableHead>
                     <TableBody>
                      {list_event_cards.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell sx={{ pl: 3 }}> {'$'}{row.amount} </TableCell>
                            <TableCell sx={{ pl: 3 }}> {row.created} </TableCell>
                            <TableCell sx={{ pl: 3 }}> {row.result} </TableCell>
                            <TableCell sx={{ pl: 3 }}> {row.type} </TableCell>
                            <TableCell sx={{ pl: 3 }}> {row.token} </TableCell>
                        </TableRow>
                      ))}
                    </TableBody> 
                  </Table>
                </TableContainer>
              </PerfectScrollbar>
        </TabPanel>

        <TabPanel value={value} index={1}>
              <PerfectScrollbar>
                <TableContainer>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                          <TableCell>card_token</TableCell>
                          <TableCell>amount</TableCell>
                          <TableCell>authorization_amount</TableCell>
                          <TableCell>merchant_amount</TableCell>
                          <TableCell>merchant_authorization_amount</TableCell>
                          <TableCell>merchant_currency</TableCell>
                          <TableCell>acquirer_fee</TableCell>
                          <TableCell>created</TableCell>
                          <TableCell>settled_amount</TableCell>
                          <TableCell>status</TableCell>
                          <TableCell>token</TableCell>
                          <TableCell>authorization_code</TableCell>
                      </TableRow>
                    </TableHead>
                     <TableBody>
                      {/*tableData.map((row) => (
                        <Row key={row.name} row={row} />
                      ))*/}
                    </TableBody> 
                  </Table>
                </TableContainer>
              </PerfectScrollbar>
        </TabPanel>

        <TabPanel value={value} index={2}>
              <PerfectScrollbar>
                <TableContainer>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                          <TableCell>AcceptorId</TableCell>
                          <TableCell>City</TableCell>
                          <TableCell>State</TableCell>
                          <TableCell>Country</TableCell>
                          <TableCell>Descriptor</TableCell>
                      </TableRow>
                    </TableHead>
                     <TableBody>
                      {/*tableData.map((row) => (
                        <Row key={row.name} row={row} />
                      ))*/}
                    </TableBody> 
                  </Table>
                </TableContainer>
              </PerfectScrollbar>
        </TabPanel>

        <TabPanel value={value} index={3}>
              <PerfectScrollbar>
                <TableContainer>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                          <TableCell>VerifyAttempts</TableCell>
                          <TableCell>VerifyResult</TableCell>
                          <TableCell>LiabilityShift</TableCell>
                          <TableCell>3DS_Version</TableCell>
                          <TableCell>AcquirerExc</TableCell>
                      </TableRow>
                    </TableHead>
                     <TableBody>
                      {/*tableData.map((row) => (
                        <Row key={row.name} row={row} />
                      ))*/}
                    </TableBody> 
                  </Table>
                </TableContainer>
              </PerfectScrollbar>
        </TabPanel>

    </>
  );
}
