import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import MUIFormControl from "@material-ui/core/FormControl";
import BuySellBtn from "assets/images/explore/buysellbtn.png";
import { tooltips } from "constants/tooltips";
import MobileChart from "../../MobileChart";
import MobileLiteOrderTicket from "ui-component/cards/MobileLiteOrderTicket";

import {
  map_coins,
  map_balance_to_coin,
  map_coin_to_name,
  map_category,
} from "store/constant";
import { weekly_portfolio } from "store/weekly";
import I from "immutable";
import useSocket from "hooks/useSocket";
import Transitions from "ui-component/extended/Transitions";
import { useDispatch, useSelector } from "react-redux";
import CoinContext from "contexts/CoinContext";
import { addExploreRecent } from "store/actions";
import colors from "assets/scss/_themes-vars.module.scss";
import ToolTipHelper from "ui-component/ToolTipHelper";

import {
  setLastTrade,
  TAB_ADD_PINNED_COIN,
  RESET_ALERT_TRENDING_BUY,
  RESET_ALERT_TRENDING_SELL,
  RESET_ALERT_SWL_POSITIVE,
  RESET_ALERT_SWL_NEGATIVE,
  RESET_ALERT_VEL_POSITIVE,
  RESET_ALERT_VEL_NEGATIVE,
  MOBILE_CHART_OPENED,
  MOBILE_LITE_ORDER_TICKET,
} from "store/actions";

import {
  InputAdornment,
  OutlinedInput,
  InputLabel,
  IconButton,
  Chip,
  Fab,
} from "@material-ui/core";
import FavoriteButton from "../../../components/FavoriteButton";

// material-ui
import { withStyles, makeStyles, useTheme } from "@material-ui/styles";

// material-ui
import {
  Badge,
  Button,
  Box,
  Checkbox,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { visuallyHidden } from "@material-ui/utils";

// third party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import SubCard from "ui-component/cards/SubCard";

// assets
import Flag1 from "assets/images/widget/AUSTRALIA.jpg";
import Flag2 from "assets/images/widget/BRAZIL.jpg";
import Flag3 from "assets/images/widget/GERMANY.jpg";
import Flag4 from "assets/images/widget/UK.jpg";
import Flag5 from "assets/images/widget/USA.jpg";

// table filter
function descendingComparator(a, b, orderBy) {
  if (orderBy !== "Pair") {
    if (Number(b[orderBy]) < Number(a[orderBy])) {
      return -1;
    }
    if (Number(b[orderBy]) > Number(a[orderBy])) {
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const CustomizedBadge = withStyles((theme) => ({
  badge: {
    right: 0,
    top: -15,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

// ===========================|| TABLE - HEADER ||=========================== //

// table header
const headCells = [
  {
    id: "Pair",
    numeric: false,
    disablePadding: true,
    label: "Asset",
  },
  {
    id: "ltp",
    numeric: true,
    disablePadding: false,
    label: "price",
  },
  {
    id: "invested",
    numeric: true,
    disablePadding: false,
    label: "Balance",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    purchaseAmount,
    changePurchaseAmount,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const theme = useTheme();
  const cornsilk =
    theme.palette.mode === "dark"
      ? colors.cornsilkLightTakyon
      : colors.cornsilkDarkTakyon;
  const whiteTakyon =
    theme.palette.mode === "dark"
      ? colors.whiteLightTakyon
      : colors.whiteDarkTakyon;

  return (
    <TableHead>
      <TableRow
        sx={{
          alignItems: "middle",
          align: "right",
          backgroundColor: "#191b1e",
        }}
      >
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              backgroundColor: "#191b1e",
            }}
            key={headCell.id}
            align={headCell.numeric ? "left" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              // bgColor={"gray"}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

// style constant
const useStyles = makeStyles({
  root: {
    width: "100%",
    overflow: "hidden",
  },
  sortSpan: visuallyHidden,
  cell: {
    cursor: "pointer",
  },
});

const Row = ({ row, trending_buy, trending_sell, pos, daily }) => {
  const [open, setOpen] = useState(false);
  const { coin, setCoin } = useContext(CoinContext);
  const dispatch = useDispatch();
  const theme = useTheme();
  const green =
    theme.palette.mode === "dark"
      ? colors.successLightTakyon
      : colors.successDarkTakyon;
  const red =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;
  const cornsilk =
    theme.palette.mode === "dark"
      ? colors.cornsilkLightTakyon
      : colors.cornsilkDarkTakyon;
  const whiteTakyon =
    theme.palette.mode === "dark"
      ? colors.whiteLightTakyon
      : colors.whiteDarkTakyon;

  const coin_style = map_coins.get(row.coin);
  // const coin_name = map_coin_to_name.get(row.wsname) ? map_coin_to_name.get(row.wsname) : row.wsname;

  // const coin_name = row.marketcap ?  row.marketcap.Name : row.coin;
  const coin_name = row.marketcap
    ? row.marketcap.Name
    : row.base_currency
    ? row.base_currency
    : row.base
    ? row.base
    : row.coin;

  const rung = row.ltp < row.avg ? red : green;
  const labelId = `${row.coin}`;
  const prung = row.hilo_percent < 0.0 ? red : green;

  // let invested = (row.position) ? (row.position.balance * row.position.market_price) : 0;
  // let quantity = (row.position) ? (row.position.balance) : 0;
  let qty_coin = map_balance_to_coin.get(row.coin)
    ? map_balance_to_coin.get(row.coin)
    : row.coin;
  let dollar = "$";

  if (row.invested === 0 || isNaN(row.invested)) {
    row.invested = " ";
    dollar = " ";
    row.quantity = " ";
    qty_coin = " ";
  }

  let total_bal = 0.0;
  let total_pnl = 0.0;
  let total_value = 0.0;
  let total_inv = 0.0;
  if (pos) {
    total_bal = pos.balance;
    total_pnl = (
      (pos.market_price - pos.remaining_avg_price) *
      pos.balance
    ).toFixed(0);
    total_inv = (pos.remaining_avg_price * pos.balance).toFixed(0);
    total_value = (pos.market_price * pos.balance).toFixed(0);
  }

  const handleListItemClick = (event, row) => {
    dispatch({type: MOBILE_CHART_OPENED, payload: {is_mobile_chart_opened: true}});
    return dispatchTab(row);
  };

  const dispatchTab = (row) => {
    setCoin(row.wsname);
    setOpen(!open);

    dispatch({ type: TAB_ADD_PINNED_COIN, payload: { coin: row.coin } });
    dispatch(addExploreRecent(row.wsname));
    if (row.trending_buy && row.trending_buy.alerted) {
      dispatch({ type: RESET_ALERT_TRENDING_BUY, payload: { coin: coin } });
    } else if (row.trending_sell && row.trending_sell.alerted) {
      dispatch({ type: RESET_ALERT_TRENDING_SELL, payload: { coin: coin } });
    }
  };

  const classes = useStyles();

  const alertTrendingBuyCount = trending_buy && trending_buy.alerted ? 1 : 0;
  const alertTrendingSellCount = trending_sell && trending_sell.alerted ? 1 : 0;

  return (
    <>
      {/******************************************************************************
                                           EXPLORE TABLE ROWS
                      ******************************************************************************/}
      <TableRow
        // sx={{ backgroundColor: "#191b1e" }}
        hover
        role="checkbox"
        key={row.coin}
        selected={row.coin === coin}
        // onClick={(event) => handleListItemClick(event, row)}
      >
        <TableCell
          onClick={(event) => handleListItemClick(event, row)}
          // className={classes.cell}
        >
          <table>
            <tr>
              <td
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <FavoriteButton pair={row.coin} />
              </td>
              <td style={{ align: "left" }}>
                <a class={coin_style}> {coin_name} </a>
                <br/>
                  <font
                    variant="subtitle1"
                    style={{
                      fontSize: "0.8rem",
                      textAlign: "left",
                      color: "white",
                    }}
                  >
                    {" "}
                    {pos &&
                      pos.balance < 1 &&
                      pos.balance.toFixed(5)}
                    {pos &&
                      pos.balance >= 1 &&
                      pos.balance.toFixed(2)}
                  </font>
                {/*alertTrendingSellCount == 1 && (
                          <IconButton aria-label="cart">
                            <CustomizedBadge
                              badgeContent={alertTrendingSellCount}
                              color="error"
                            />
                          </IconButton>
                        )*/}
              </td>
            </tr>
          </table>
        </TableCell>

        <TableCell onClick={(event) => handleListItemClick(event, row)}>
          <table
            style={{
              fixed: { "table-layout": "fixed" },
              td: { overflow: "hidden" },
            }}
          >
            <tr>
              <td style={{ textAlign: "left" }}>
                <font
                  variant="subtitle1"
                  style={{
                    fontSize: "1.1rem",
                    textAlign: "left",
                    color: cornsilk,
                  }}
                >
                  ${row.ltp}
                </font>
                <br/>
              <font
                variant="subtitle1"
                style={{ fontSize: "0.8rem", textAlign: "left", color: prung }}
              >
                {row.hilo_percent < 0 && " -"}{" "}
                {row.hilo_percent < 0 && row.hilo_percent.toFixed(2)}
                {row.hilo_percent > 0 && " +"}
                {row.hilo_percent > 0 && row.hilo_percent.toFixed(2)}
                {"%"}
              </font>
              </td>
            </tr>
          </table>
        </TableCell>


        <TableCell
          sx={{ "font-weight": "bold", color: rung }}
          onClick={(event) => handleListItemClick(event, row)}
          className={classes.cell}
        >
          <table
            style={{
              fixed: { "table-layout": "fixed" },
              td: { overflow: "hidden" },
            }}
          >
            <tr>
              <td style={{ textAlign: "left" }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: "1.2rem",
                    textAlign: "left",
                    color: cornsilk,
                  }}
                >
                  {dollar}
                  {row.invested}
                </Typography>
              </td>
            </tr>
          </table>
        </TableCell>

      </TableRow>
    </>
  );
};
// =========================|| DASHBOARD ANALYTICS - LATEST CUSTOMERS TABLE CARD ||========================= //

const MobileExploreCard = ({ title, rows }) => {
  const [checkbox, setCheckbox] = React.useState([
    true,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [category, setCategory] = React.useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("invested");
  const [query, setQuery] = useState("");

  const classes = useStyles();
  const { emit } = useSocket();
  const { coin, setCoin } = useContext(CoinContext);
  const dispatch = useDispatch();
  const theme = useTheme();
  const green =
    theme.palette.mode === "dark"
      ? colors.successLightTakyon
      : colors.successDarkTakyon;
  const red =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;
  const cornsilk =
    theme.palette.mode === "dark"
      ? colors.cornsilkLightTakyon
      : colors.cornsilkDarkTakyon;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  let items = [];
  let categoryItems = [];
  const start = Date.now();
  let filteredItems = [];
  if (rows !== undefined) {
    rows
      .filter((post) => {
        if (query === "") {
          return post;
        } else if (post.coin_name.toLowerCase().includes(query.toLowerCase())) {
          return post;
        }
      })
      .map((post, index) => {
        filteredItems.push(post);
      });
  }

  let changed = items.length;

  if (filteredItems !== undefined) {
    if (checkbox[0]) {
      filteredItems
        .filter((post) => {
          return post;
        })
        .map((post, index) => {
          items.push(post);
        });
    } else if (checkbox[1]) {
      filteredItems
        .filter((post) => {
          if (post && post.invested < 0.1) {
            return post;
          }
        })
        .map((post, index) => {
          items.push(post);
        });
    } else if (checkbox[4]) {
      /**
            else if ( checkbox[2])
            {
                filteredItems.filter((post) => {
                    if (post && post.trending_sell !== undefined)
                    {
                        return post;
                    }
                }).map((post, index) => {
                    items.push(post);
                });
            }
            else if ( checkbox[3])
            {
                filteredItems.filter((post) => {
                    if (post && post.trending_buy !== undefined)
                    {
                        return post;
                    }
                }).map((post, index) => {
                    items.push(post);
                });
            }
            *
            */
      filteredItems
        .filter((post) => {
          if (post) {
            if (post.trending_buy && post.trending_buy.alerted) {
              return post;
            }
            if (post.trending_sell && post.trending_sell.alerted) {
              return post;
            }
          }
        })
        .map((post, index) => {
          items.push(post);
        });
    } else if (checkbox[5]) {
      filteredItems
        .filter((post) => {
          if (post && post.invested > 0.1) {
            return post;
          }
        })
        .map((post, index) => {
          items.push(post);
        });
    } else {
      items = filteredItems;
    }
  }

  if (items !== undefined) {
    if (category[0]) {
      items
        .filter((post) => {
          if (
            map_category.get(post.wsname) &&
            map_category.get(post.wsname) === "defi"
          ) {
            return post;
          }
        })
        .map((post, index) => {
          categoryItems.push(post);
        });
    } else if (category[1]) {
      items
        .filter((post) => {
          if (
            map_category.get(post.wsname) &&
            map_category.get(post.wsname) === "gamefi"
          ) {
            return post;
          }
        })
        .map((post, index) => {
          categoryItems.push(post);
        });
    } else if (category[2]) {
      items
        .filter((post) => {
          if (
            map_category.get(post.wsname) &&
            map_category.get(post.wsname) === "lending"
          ) {
            return post;
          }
        })
        .map((post, index) => {
          categoryItems.push(post);
        });
    } else if (category[3]) {
      items
        .filter((post) => {
          if (
            map_category.get(post.wsname) &&
            map_category.get(post.wsname) === "memes"
          ) {
            return post;
          }
        })
        .map((post, index) => {
          categoryItems.push(post);
        });
    } else if (category[4]) {
      items
        .filter((post) => {
          if (!map_category.get(post.wsname)) {
            return post;
          }
        })
        .map((post, index) => {
          categoryItems.push(post);
        });
    } else {
      items
        .filter((post) => {
          return post;
        })
        .map((post, index) => {
          categoryItems.push(post);
        });
    }
  }

  const handleCheckboxSelect = (event, index) => {
    console.log("handleCheckboxSelect: " + index);
    console.log(event.target.checked);
    if (event.target.checked) {
      let cb = [false, false, false, false, false];
      cb[index] = true;
      setCheckbox(cb);
    } else {
      let cb = [false, false, false, false, false];
      setCheckbox(cb);
    }
  };

  const handleCategorySelect = (event, index) => {
    console.log("handleCheckboxSelect: " + index);
    console.log(event.target.checked);
    if (event.target.checked) {
      let cb = [false, false, false, false, false];
      cb[index] = true;
      setCategory(cb);
    } else {
      let cb = [false, false, false, false, false];
      setCategory(cb);
    }
  };

  const handleBuySellClick = ((event) => {
    dispatch({type: MOBILE_LITE_ORDER_TICKET, payload: {is_mobile_lite_order_ticket: true}});
  });

  const [firstInit, setFirstInit] = useState(false);

  const onCloseModal = () => {
    setFirstInit(false);
    window.localStorage.setItem("explore_card_help", "true");
  };

  useEffect(() => {
    const item = window.localStorage.getItem("explore_card_help");
    if (!item) {
      setFirstInit(true);
    }
  }, []);

    const mobile_chart_opened = useSelector((state) => {
        return state.chart.mobile_chart_opened;
    });


    const mobile_lite_order_ticket = useSelector((state) => {
        return state.chart.mobile_lite_order_ticket;
    });

  //
  return (
    <>
      {mobile_chart_opened && <MobileChart />}
      <Grid container padding={1.0} />
      {mobile_lite_order_ticket && <MobileLiteOrderTicket />}
      <Grid container item padding={1} />
      <SubCard content={false} sx={{ align: "center" }}>
        <Grid container padding={0} spacing={0} alignItems="center">
          <Grid item xs={8} >
                  <ToolTipHelper
                    hover={tooltips.explore_category.hover}
                    modal={tooltips.explore_category.modal}
                    isOpen={Boolean(firstInit)}
                    onClose={onCloseModal}
                  >
            <MUIFormControl>
              <InputLabel>Search</InputLabel>
              <OutlinedInput
                fullWidth
                label="Search"
                name={"search"}
                onChange={(event) => setQuery(event.target.value)}
              />
            </MUIFormControl>
            </ToolTipHelper>
          </Grid>
          <Grid item xs={4} >
                  <IconButton color="inherit" size="small" onClick={(event) => handleBuySellClick(event)} linkButton={true} >
                      <img src={BuySellBtn} alt="stop" />
                  </IconButton>
          </Grid>
          <Grid item padding={0} spacing={0} xs={12} lg={9.5}>
                  <Checkbox
                    checked={checkbox[0]}
                    color="primary"
                    onChange={(event) => handleCheckboxSelect(event, 0)}
                    inputProps={{ "aria-labelledby": "All" }}
                  />
                  <font style={{fontSize: '0.6rem'}}>{"All Coins"}</font>
                  <Checkbox
                    checked={checkbox[1]}
                    color="primary"
                    onChange={(event) => handleCheckboxSelect(event, 1)}
                    inputProps={{ "aria-labelledby": "New Coins" }}
                  />
                  <font style={{fontSize: '0.6rem'}}>{"New Coins"}</font>
                  <Checkbox
                    checked={checkbox[5]}
                    color="primary"
                    onChange={(event) => handleCheckboxSelect(event, 5)}
                    inputProps={{ "aria-labelledby": "Invested" }}
                  />
                  <font style={{ color: cornsilk, fontSize: '0.6rem'}}>{"Invested"}</font>
                  <br/>
                  <Checkbox
                    checked={category[0]}
                    color="primary"
                    onChange={(event) => handleCategorySelect(event, 0)}
                    inputProps={{ "aria-labelledby": "DeFi" }}
                  />
                  <font style={{fontSize: '0.6rem'}}>{"DeFi"}</font>
                  <Checkbox
                    checked={category[1]}
                    color="primary"
                    onChange={(event) => handleCategorySelect(event, 1)}
                    inputProps={{ "aria-labelledby": "GameFi" }}
                  />
                  <font style={{fontSize: '0.6rem'}}>{"GameFi"}</font>
                  <Checkbox
                    checked={category[2]}
                    color="primary"
                    onChange={(event) => handleCategorySelect(event, 2)}
                    inputProps={{ "aria-labelledby": "Lending" }}
                  />
                  <font style={{fontSize: '0.6rem'}}>{"Lending"}</font>
                  <Checkbox
                    checked={category[3]}
                    color="primary"
                    onChange={(event) => handleCategorySelect(event, 3)}
                    inputProps={{ "aria-labelledby": "Memes" }}
                  />
                  <font style={{fontSize: '0.6rem'}}>{"Memes"}</font>
                  <Checkbox
                    checked={category[4]}
                    color="primary"
                    onChange={(event) => handleCategorySelect(event, 4)}
                    inputProps={{ "aria-labelledby": "Currency" }}
                  />
                  <font style={{fontSize: '0.6rem'}}>{"Currency"}</font>
          </Grid>
        </Grid>
        <TableContainer>
          <Table stickyHeader size="small" aria-label="explore">
            <EnhancedTableHead
              // classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              // backgroundColor={"#ccc"}
            />
            <TableBody>
              {stableSort(categoryItems, getComparator(order, orderBy)).map(
                (row, index) => {
                  const coin_style = map_coins.get(row.coin);
                  const coin_name = map_coin_to_name.get(row.wsname)
                    ? map_coin_to_name.get(row.wsname)
                    : row.wsname;
                  const rung = row.ltp < row.avg ? red : green;
                  const labelId = `${row.coin}`;
                  // let invested = (row.position) ? (row.position.balance * row.position.market_price) : 0;
                  // let quantity = (row.position) ? (row.position.balance) : 0;
                  let qty_coin = map_balance_to_coin.get(row.coin)
                    ? map_balance_to_coin.get(row.coin)
                    : row.coin;
                  let dollar = "$";
                  if (row.invested === 0 || isNaN(row.invested)) {
                    row.invested = " ";
                    dollar = " ";
                    row.quantity = " ";
                    qty_coin = " ";
                  }

                  const trending_buy = row.trending_buy ? row.trending_buy : {};
                  const trending_sell = row.trending_sell
                    ? row.trending_sell
                    : {};
                  const pos = row.position ? row.position : {};
                  const daily = row.daily ? row.daily : {};

                  return (
                    <Row
                      row={row}
                      trending_buy={trending_buy}
                      trending_sell={trending_sell}
                      pos={pos}
                      daily={daily}
                    />
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </SubCard>
    </>
  );
};

MobileExploreCard.propTypes = {
  title: PropTypes.string,
};

export default MobileExploreCard;
