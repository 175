const message = { event: "addOrder" };

export const addOrder = (coin, side, id, reqid, userref, px, qty, orderType) =>
  JSON.stringify({
    ...message,
    event: "",
    order: { fc: coin, side, reqid, id, userref, px, qty, orderType },
  });

// ({...message, event:"addOrder", order: { fc: coin,  side,	id, userref, px, qty ,orderType} });

export const cancelOrder = (txid) => {
  const krakenToken = window.localStorage.getItem("kraken_token") || "no_token";
  return JSON.stringify({ k_token: krakenToken ,event: "cancelOrder", txid });
}
export const initialMessage = (token) => JSON.stringify({ k_token: token });
