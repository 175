import { Grid } from "@material-ui/core";
import React from "react";
import CenterLayout from "../CenterLayout";
import Liquidate from "../Liquidate";

const LiquidateLayout = () => {
  return (
        <Liquidate />
  );
};

export default LiquidateLayout;
