import React, { lazy, useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";

// project imports
import MainRoutes from "./MainRoutes";
// Takyon specific routes
import TakyonRoutes from "./TakyonRoutes";
import LiteTakyonMobileRoutes from "./LiteTakyonMobileRoutes";
import LoginRoutes from "./LoginRoutes";
import LiteTakyonRoutes from "./LiteTakyonRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import Loadable from "ui-component/Loadable";

const PagesLanding = Loadable(lazy(() => import("views/pages/landing")));

// ===========================|| ROUTING RENDER ||=========================== //

export default function ThemeRoutes() {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  // return useRoutes([{ path: '/', element: <PagesLanding /> }, AuthenticationRoutes, LoginRoutes, MainRoutes, TakyonRoutes, LiteTakyonRoutes]);

  const routes = isMobile
    ? [
        AuthenticationRoutes,
        LoginRoutes,
        MainRoutes,
        TakyonRoutes,
        LiteTakyonMobileRoutes,
      ]
    : [
        AuthenticationRoutes,
        LoginRoutes,
        MainRoutes,
        TakyonRoutes,
        LiteTakyonRoutes,
      ];
  return useRoutes(routes);
}
