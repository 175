import React, { useMemo, useRef, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import SubCard from "ui-component/cards/SubCard";
import MobileChart from "../../MobileChart";
import BuySellBtn from "assets/images/explore/buysellbtn.png";
import MobileLiteOrderTicket from "ui-component/cards/MobileLiteOrderTicket";

import {
  Box,
  ButtonGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { withStyles, useTheme } from "@material-ui/styles";
import {
  setLastTrade,
  RESET_ALERT_TRENDING_BUY,
  RESET_ALERT_TRENDING_SELL,
  RESET_ALERT_SWL_POSITIVE,
  RESET_ALERT_SWL_NEGATIVE,
  RESET_ALERT_VEL_POSITIVE,
  RESET_ALERT_VEL_NEGATIVE,
  CLEAR_SWL_POSITIVE_ALERTS,
  CLEAR_SWL_NEGATIVE_ALERTS,
  MOBILE_CHART_OPENED,
  MOBILE_LITE_ORDER_TICKET,
} from "store/actions";

// material-ui
import {
  Badge,
  Button,
  Checkbox,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,

  InputAdornment,
  OutlinedInput,
  InputLabel,
  IconButton,
  Chip,
  Fab,
} from "@material-ui/core";

import { useSelector, useDispatch } from "react-redux";
import clear from 'assets/images/clear.svg';

// material-ui
import { makeStyles } from "@material-ui/styles";

// third party
import { FixedSizeList } from "react-window";
import { List, ListItemIcon, Avatar } from "@material-ui/core";
import CoinContext from "contexts/CoinContext";
import "styles/coins.css";
import { map_coins, map_balance_to_coin, map_coin_to_name } from "store/constant";
import colors from 'assets/scss/_themes-vars.module.scss';
import ToolTipHelper from 'ui-component/ToolTipHelper';
import {tooltips} from 'constants/tooltips';

// assets
import KeyboardArrowUpOutlinedIcon from "@material-ui/icons/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import FavoriteButton from "../../../components/FavoriteButton";
import {currencyFormat} from 'utils/currencyFormat';

// import ListItemButton from '@material-ui/core/ListItemButton';
// style constant
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 670,
    backgroundColor: theme.palette.background.paper,
  },
  avatarSuccess: {
    width: "16px",
    height: "16px",
    borderRadius: "5px",
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
    marginRight: "5px",
  },
  avatarError: {
    width: "16px",
    height: "16px",
    borderRadius: "5px",
    backgroundColor: theme.palette.orange.light,
    color: theme.palette.orange.dark,
    marginRight: "5px",
  },
  ScrollHeight: {
        height: '400px',
        overflowX: 'hidden',
        minHeight: '400px',
        [theme.breakpoints.down('md')]: {
            height: 'calc(100vh - 190px)',
            minHeight: 0
        }
  },
  chart: {
    position: 'absolute inherit',
    width: '100%'
  }
}));

const CustomizedBadge = withStyles((theme) => ({
  badge: {
    right: -5,
    top: -5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

const AlertItemPercent = (alertCount, rung, percent) => {
  if (alertCount !== "1") {
    return (
      <Typography variant="h2" sx={{ color: rung }}>
        {percent.toFixed(2)}%
      </Typography>
    );
  } else {
    return (
      <IconButton aria-label="cart">
        <CustomizedBadge badgeContent={alertCount} color="error">
          <Typography variant="h2" sx={{ color: rung }}>
            {percent.toFixed(2)}%
          </Typography>
        </CustomizedBadge>
      </IconButton>
    );
  }
};

// table filter
function descendingComparator(a, b, orderBy) {
  if (orderBy !== "Pair") {
    if (Number(b[orderBy]) < Number(a[orderBy])) {
      return -1;
    }
    if (Number(b[orderBy]) > Number(a[orderBy])) {
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if ( array === undefined)
  {
    return [];
  }
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// table header
const headCells = [
  {
    id: "Pair",
    numeric: true,
    disablePadding: false,
    label: "Pair",
    hover: "none.hover",
    modal: "none.modal",
  },
  /*
  {
    id: "epoch_start",
    numeric: true,
    disablePadding: true,
    label: "Start",
    hover: "tooltips.none.hover",
    modal: "tooltips.none.modal",
  },
  {
    id: "pnl",
    numeric: true,
    disablePadding: true,
    label: "PnL",
  },
  {
    id: "invested",
    numeric: true,
    disablePadding: true,
    label: "Balance",
  },
  */
  {
    id: "since",
    numeric: true,
    disablePadding: true,
    label: "Sec",
    hover: "tooltips.none.hover",
    modal: "tooltips.none.modal",
  },
  {
    id: "percent",
    numeric: true,
    disablePadding: false,
    label: "Brkout%",
    hover: "tooltips.none.hover",
    modal: "tooltips.none.modal",
  },
  /*
  {
    id: "hilo_percent",
    numeric: true,
    disablePadding: false,
    label: "24h %",
    hover: "tooltips.none.hover",
    modal: "tooltips.none.modal",
  },
  {
    id: "end_ref",
    numeric: false,
    disablePadding: false,
    label: "Price",
    hover: "tooltips.none.hover",
    modal: "tooltips.none.modal",
  },
  */
];

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    liquidateAmount,
    changeLiquidateAmount,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
              // <ToolTipHelper  hover={tooltips[headCell.id].hover} modal={tooltips[headCell.id].modal} >

  return (
    <TableHead>
      <TableRow sx={{ alignItems: 'middle', align: 'right', backgroundColor: '#191b1e' }}>
        {headCells.map((headCell) => {
        
            return (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              bgColor={'gray'}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
            {headCell.id === "Pair" &&
                headCell.label
            }
            {/*headCell.id === "epoch_start" &&
              <ToolTipHelper  hover={tooltips.swlstart.hover} modal={tooltips.swlstart.modal} >
                {headCell.label}
              </ToolTipHelper>
            */}
            {headCell.id === "since" && headCell.label
            }
            {headCell.id === "percent" && headCell.label
            }
            {/*headCell.id === "hilo_percent" &&
                headCell.label
            */}
            {/*headCell.id === "end_ref" &&
                headCell.label
            */}
            </TableSortLabel>
          </TableCell>
        )})}
      </TableRow>
    </TableHead>
  );
}

// ================================|| UI LIST - SCROLLABLE ||================================ //

const MobileBreakoutTable = ({ type, items, positives, negatives, sentiment, inputRef }) => {
  const classes = useStyles();
  //test 
  document.title = `Takyon - ${sentiment.toFixed()}% `

  const customization = useSelector((state) => state.customization);
  const dispatch = useDispatch();
  const theme = useTheme();
  const green = (theme.palette.mode === 'dark') ? colors.successLightTakyon : colors.successDarkTakyon;
  const red = (theme.palette.mode === 'dark') ? colors.errorLightTakyon : colors.errorDarkTakyon;
  const cornsilk = (theme.palette.mode === 'dark') ? colors.cornsilkLightTakyon : colors.cornsilkDarkTakyon;
  const chartContainerRef = useRef(new Array());
  const bgcolor = (theme.palette.mode === 'dark') ? '#00070f' : 'white';
  const srung = sentiment < 0.0 ? red : green;


  const [selectedIndex, setSelectedIndex] = React.useState("0");
  const { symbol, setCoin } = useContext(CoinContext);


  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("percent");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [modalCoin, setModalCoin] = useState(undefined);

  const handleListItemClick = (event, symbol, row) => {
    setModalCoin(row);
    console.log( modalCoin);
    dispatch({type: MOBILE_CHART_OPENED, payload: {is_mobile_chart_opened: true}});
    return dispatchRow(symbol);
  };

  const dispatchRow =  (symbol) => {
    setSelectedIndex(symbol);
    setCoin(symbol);
    dispatch({ type: RESET_ALERT_SWL_POSITIVE, payload: { coin: symbol } });
    dispatch({ type: RESET_ALERT_SWL_NEGATIVE, payload: { coin: symbol } });
  }

  const handleClearAllAlerts = (event) => {
    console.log( 'RESET_ALERT_SWL_POSITIVE + RESET_ALERT_SWL_NEGATIVE');
    dispatch({ type: CLEAR_SWL_POSITIVE_ALERTS});
    dispatch({ type: CLEAR_SWL_NEGATIVE_ALERTS});
  };

  const handleBuySellClick = ((event) => {
    dispatch({type: MOBILE_LITE_ORDER_TICKET, payload: {is_mobile_lite_order_ticket: true}});
  });

   // Let's get the count here.
   const alertCountPositive = useSelector((state) => {
    if (
      state.coins.alerts === undefined ||
      state.coins.alerts.alertsSP === undefined
    )
      return 0;
    else return state.coins.alerts.alertsSP.size;
  });


  const alertCountNegative = useSelector((state) => {
    if (
      state.coins.alerts === undefined ||
      state.coins.alerts.alertsSN === undefined
    )
      return 0;
    else return state.coins.alerts.alertsSN.size;
  });

    const alertCount = alertCountPositive + alertCountNegative;

    const mobile_chart_opened = useSelector((state) => {
        return state.chart.mobile_chart_opened;
    });

    const mobile_lite_order_ticket = useSelector((state) => {
        return state.chart.mobile_lite_order_ticket;
    });

  return (
    <> 
            {mobile_chart_opened && <MobileChart />}
            <Grid container item padding={1} />
            {mobile_lite_order_ticket && <MobileLiteOrderTicket />}
            <Grid container item padding={1} />
            <SubCard 
            title={
            <Grid container padding={0} alignItems="center"  justifyContent="center" direction="row" spacing={0.5}>
                <Grid item xs={7} >
                    <ToolTipHelper  hover={tooltips.swltable.hover} modal={tooltips.swltable.modal} >
                        <font style={{ color: cornsilk, "text-align": "middle", "font-size": "0.9rem", }} >{"Breakout"}</font>
                        <font style={{ color: srung, "text-align": "middle", "font-size": "1.1rem", }} >{' '}{sentiment.toFixed(2)}{'%'}</font>
                <a onClick={(event) => handleClearAllAlerts(event)} style={{cursor: 'pointer'}}>
                    <IconButton aria-label="cart">
                        <CustomizedBadge badgeContent={alertCount} color="error"> 
                            <img src={clear}  width="30" height="30" alt="clear"/>
                        </CustomizedBadge>
                    </IconButton>
                </a> 
                    </ToolTipHelper>
                </Grid>
                <Grid item xs={4} >
                      <IconButton color="inherit" size="small" onClick={(event) => handleBuySellClick(event)} linkButton={true} >
                          <img src={BuySellBtn} alt="stop" />
                      </IconButton>
                </Grid>
            </Grid>
            }
        >
                <TableContainer >
                    <Table sx={{width: '100%'}} size="small" aria-label="LiqPortfolio">
                        <EnhancedTableHead
                          classes={classes}
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          backgroundColor={'#ccc'}
                    />
                        <TableBody>
                              {items !== undefined && stableSort(items, getComparator(order, orderBy)).map((row, index) => {

                              const rung = row.swl_single.percent < 0.0 ? red : green;
                              const drung = row.hilo_percent < 0.0 ? red : green;
                              const pnl =( row.position ?  (row.position.balance * (row.ltp - row.position.remaining_avg_price))  : 0).toFixed(2) ;
                              const irung = (row.ltp < row.position?.remaining_avg_price) ? red : green;

                              const coin_style = map_coins.get(row.swl_single.symbol);
                              const coin_name = row.marketcap ?  row.marketcap.Name : (row.base_currency ? row.base_currency : (row.base ? row.base : row.coin));

                              const epoch = new Date(row.swl_single.epoch_start * 1000).toLocaleTimeString();
                              const ts = new Date(row.swl_single.ts * 1000).toLocaleTimeString("en-US");
                              const alertCount = row.swl_single.alerted === true ? "1" : " ";

                              // ApexCharts.exec(row.swl_ohlc.options.chart.id, 'updateSeries', [{ data: row.swl_ohlc.series[0].data}], true); 

                              return (
                                <TableRow 
                                    hover 
                                    role="checkbox" 
                                    key={row.swl_single.symbol}
                                    selected={row.swl_single.symbol === selectedIndex}
                                    onClick={(event) => handleListItemClick(event, row.swl_single.symbol, row)}
                                >

                                    <TableCell sx={{ pl: 3 }} padding="checkbox">
                                        <table style={{ fixed: { "table-layout": "fixed" }, td: { overflow: "hidden" }, }} >
                                            <tr>
                                            <td>
                                                <Typography variant="subtitle1" sx={{ fontSize: "0.8rem", textAlign: "left"}} >
                                                    <FavoriteButton pair={row.swl_single.symbol} /> 
                                                </Typography>
                                            </td>
                                            <td style={{ align: "left" }}>
                                                <a class={coin_style}> {coin_name} </a>
                                            </td>
                                            </tr>
                                            <tr>
                                            <td>
                                                  {
                                                    alertCount == 1 && 
                                                       <IconButton aria-label="cart">
                                                         <CustomizedBadge badgeContent={alertCount} color="error" />
                                                       </IconButton>
                                                  }
                                            </td>
                                            <td>
                                        <Typography sx={{ fontSize: "0.9rem", textAlign: "left", color: rung}} >
                                        { currencyFormat(row.swl_single.end_ref)}
                                        </Typography>
                                            </td>
                                            </tr>
                                        </table>
                                    </TableCell>
                                    {/*
                                    <TableCell>
                                        <font style={{ fontSize: "1.1rem", textAlign: "left", color: cornsilk}} >
                                            {epoch}
                                        </font>
                                    </TableCell>
                                    <TableCell>
                                        <font style={{ fontSize: "1.1rem", textAlign: "left", color: irung}} >
                                            {ts}
                                        </font>
                                    </TableCell>
                                    <TableCell>
                                        <font style={{ fontSize: "1.1rem", textAlign: "left", color: cornsilk}} >
                                            {'$'}{row.position?.invested}
                                        </font>
                                    </TableCell>
                                    <TableCell>
                                        <font style={{ fontSize: "1.1rem", textAlign: "left", color: rung}} >
                                            {'$'}{pnl}
                                        </font>
                                    </TableCell>
                                    */}
                                    <TableCell>
                                        <font style={{ fontSize: "1.1rem", textAlign: "left", color: cornsilk}} >
                                            {row.swl_single.since}
                                        </font>
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{ fontSize: "1.1rem", textAlign: "left", color: rung}} >
                                            {row.swl_single.percent.toFixed(2)}%
                                        </Typography>
                                    </TableCell>
                                    {/*
                                    <TableCell>
                                        <font style={{ fontSize: "1.1rem", textAlign: "left", color: drung}} >
                                            {row.hilo_percent.toFixed(2)}%
                                        </font>
                                    </TableCell>
                                    <TableCell>
                                        <font style={{ fontSize: "1.1rem", textAlign: "left", color: cornsilk}} >
                                            {row.swl_single.start_ref}
                                        </font>
                                    </TableCell>
                                    <TableCell>
                                        <font style={{ fontSize: "1.1rem", textAlign: "left", color: rung}} >
                                        { currencyFormat(row.swl_single.end_ref)}
                                        </font>
                                    </TableCell>
                                    */}
                                </TableRow>
                              );
                            })
                          }
                        </TableBody>
                    </Table>
                </TableContainer>
        </SubCard>

    </>
  );
};
export default React.memo(MobileBreakoutTable);
