import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import SubCard from "ui-component/cards/SubCard";
import ColorSubCard from "ui-component/cards/ColorSubCard";
import MobileSummarizedTable from "./components/MobileSummarizedTable";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  sortLastBuy,
  clearTrendingBuyAlerts,
  clearTrendingSellAlerts,
  MOBILE_LITE_ORDER_TICKET,
} from "store/actions";
import MUIFormControl from "@material-ui/core/FormControl";
import { tooltips } from "constants/tooltips";
import ToolTipHelper from "ui-component/ToolTipHelper";
import CoinBaseLoginButton from "ui-component/CoinBaseLoginButton";
import MobileChart from "../MobileChart";
import BuySellBtn from "assets/images/explore/buysellbtn.png";
import MobileLiteOrderTicket from "ui-component/cards/MobileLiteOrderTicket";

import {
  map_coins,
  map_balance_to_coin,
  map_coin_to_name,
} from "store/constant";
import {
  InputAdornment,
  OutlinedInput,
  InputLabel,
  IconButton,
  Chip,
  Fab,
  Typography,
} from "@material-ui/core";
import colors from "assets/scss/_themes-vars.module.scss";
// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";

const sort_by = (field, reverse, primer) => {
    const key = primer
      ? function (x) {
          return primer(x[field]);
        }
      : function (x) {
          return x[field];
        };

    reverse = !reverse ? 1 : -1;
    return function (a, b) {
      return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
    };
  };

const MobileTrendingBuy = () => {
  const [queryBuy, setQueryBuy] = useState("");
  const [querySell, setQuerySell] = useState("");
  const [numberBuy, setNumberBuy] = useState(0);

  const [selectedExchange, exchanges] = useSelector((state) => [
    state.exchange.selectedExchange,
    state.exchange.exchanges,
  ]);

  const dispatch = useDispatch();
  const theme = useTheme();
  const cornsilk =
    theme.palette.mode === "dark"
      ? colors.cornsilkLightTakyon
      : colors.cornsilkDarkTakyon;
  const whiteTakyon =
    theme.palette.mode === "dark"
      ? colors.whiteLightTakyon
      : colors.whiteDarkTakyon;
  const green =
    theme.palette.mode === "dark"
      ? colors.successLightTakyon
      : colors.successDarkTakyon;
  const red =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;

  /*
alerted: true
curr_price: 0.0638
icon: "images/logo_ACHUSD.png"
level_percent: 5.5
level_price: 0.063823702
move: "↓↓↓"
open_price: 0.0604964
pair: "ACH/USD"
prev_price: 0.063831
qty: 5600
side: "buy"
ts: 1640460835
*/

  const mapTrendingBuy = useSelector((state) => {
    let items = [];

    if (
      state.coins === undefined ||
      state.coins.kraken_asset_pairs === undefined
    ) {
      return items;
    }

    state.coins.kraken_asset_pairs.forEach((item) => {
      if (item.trending_buy !== undefined) {
        item.trending_buy.coin_name = map_coin_to_name.get(item.coin)
          ? map_coin_to_name.get(item.coin)
          : item.coin;
        /*
            if (item.position !== undefined) 
            {
                item.trending_buy.avg = item.position.remaining_avg_price.toFixed(3);
                item.trending_buy.balance = item.position.balance;
                item.trending_buy.last_trade = Number( (item.trending_buy.position * item.trending_buy.qty).toFixed(0));
                item.trending_buy.invested = Number( (item.trending_buy.balance * item.trending_buy.price).toFixed(0));
            }
            */
        items.push(item.trending_buy);
      }
    });
    return items;
  });

  if (numberBuy !== mapTrendingBuy.length) {
    setNumberBuy(mapTrendingBuy.length);
  }

  const handleClearAllBuyAlerts = () => dispatch(clearTrendingBuyAlerts());

  let filteredBuy = [];
  mapTrendingBuy
    .filter((post) => {
      if (queryBuy === "") {
        return post;
      } else if (post === undefined) {
        return post;
      } else if (
        post.coin_name.toLowerCase().includes(queryBuy.toLowerCase())
      ) {
        return post;
      }
    })
    .map((post, index) => {
      filteredBuy.push(post);
    });

  const mapTrendingSell = useSelector((state) => {
    let items = [];

    if (
      state.coins === undefined ||
      state.coins.kraken_asset_pairs === undefined
    ) {
      return items;
    }

    state.coins.kraken_asset_pairs.forEach((item) => {
      if (item.trending_sell !== undefined) {
        item.trending_sell.coin_name = map_coin_to_name.get(item.coin)
          ? map_coin_to_name.get(item.coin)
          : item.coin;
        items.push(item.trending_sell);
      }
    });

    return items;
  });

  const handleClearAllSellAlerts = () => dispatch(clearTrendingSellAlerts());

  const handleBuySellClick = ((event) => {
    dispatch({type: MOBILE_LITE_ORDER_TICKET, payload: {is_mobile_lite_order_ticket: true}});
  });

  let filteredSell = [];
  mapTrendingSell
    .filter((post) => {
      if (querySell === "") {
        return post;
      } else if (post === undefined) {
        return post;
      } else if (
        post.coin_name.toLowerCase().includes(querySell.toLowerCase())
      ) {
        return post;
      }
    })
    .map((post, index) => {
      filteredSell.push(post);
    });

    const mobile_chart_opened = useSelector((state) => {
        return state.chart.mobile_chart_opened;
    });

    const mobile_lite_order_ticket = useSelector((state) => {
        return state.chart.mobile_lite_order_ticket;
    });

  return (
  <>
    
    {mobile_chart_opened && <MobileChart />}
    <Grid container item padding={1} />
    {mobile_lite_order_ticket && <MobileLiteOrderTicket />}
    <Grid container item padding={1} />
    <Grid
      item
      container
      padding={0}
      direction="rows"
      alignItems="top"
      spacing={0.5}
    >
      <Grid item xs={12} >
        <ColorSubCard
          border={green}
          title={
            <Grid item container padding={0} alignItems="center" spacing={1.0}>
              <Grid item xs={5} >
                <ToolTipHelper hover={tooltips.trending_buy.hover} modal={tooltips.trending_buy.modal} >
                <font
                  style={{
                    color: cornsilk,
                    "text-align": "middle",
                    "font-size": "0.9rem",
                  }}
                >
                  {" Trending"}{" "}
                </font>
                <font
                  style={{
                    color: green,
                    "text-align": "middle",
                    "font-size": "0.9rem",
                  }}
                >
                  {"BUY"}
                </font>
                <a onClick={handleClearAllBuyAlerts}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-bell-off"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#2c3e50"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <line x1="3" y1="3" x2="21" y2="21" />
                    <path d="M17 17h-13a4 4 0 0 0 2 -3v-3a7 7 0 0 1 1.279 -3.716m2.072 -1.934c.209 -.127 .425 -.244 .649 -.35a2 2 0 1 1 4 0a7 7 0 0 1 4 6v3" />
                    <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
                  </svg>
                </a>
                </ToolTipHelper>
                </Grid>
              <Grid item xs={5} >
                <MUIFormControl>
                  <InputLabel>Search</InputLabel>
                  <OutlinedInput
                    fullWidth
                    label="Search"
                    name={"search"}
                    onChange={(event) => setQueryBuy(event.target.value)}
                  />
                </MUIFormControl>
              </Grid>
              <Grid item xs={2} >
                  <IconButton color="inherit" size="small" onClick={(event) => handleBuySellClick(event)} linkButton={true} >
                      <img src={BuySellBtn} alt="stop" />
                  </IconButton>
              </Grid>
            </Grid>
          }
        >
          <MobileSummarizedTable type="TrendingBuy" items={filteredBuy} />
        </ColorSubCard>
      </Grid>
      {selectedExchange === "noExchange" && (
        <>
          <Grid item xs={12} />
          <Grid item xs={12} />

          <Grid item xs={12}>
            <ColorSubCard border={"orange"}>
              <Grid container xs={12} alignItems={"center"} flexDirection={"column"}>
                <Grid item>
                  <Typography variant="h2" sx={{ color: whiteTakyon }}>
                    Login to Coinbase Exchange to see Trends in your
                  </Typography>
                </Grid>
                <Grid item>
                  <CoinBaseLoginButton />
                </Grid>
              </Grid>
            </ColorSubCard>
          </Grid>
        </>
      )}
    </Grid>
  </>
  );
};

export default React.memo(MobileTrendingBuy);
