import React, { lazy, useEffect,useState } from "react";
import useAuth from "hooks/useAuth";

// project imports
import LiteTakyonLayout from "layout/LiteTakyonLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import SmartWatchListLayout from "views/dashboard/DashboardTakyon/layouts/SmartWatchListLayout";
import VelocityLayout from "views/dashboard/DashboardTakyon/layouts/VelocityLayout";
import TrendingBuyLayout from "views/dashboard/DashboardTakyon/layouts/TrendingBuyLayout";
import TrendingSellLayout from "views/dashboard/DashboardTakyon/layouts/TrendingSellLayout";
import LiquidateLayout from "views/dashboard/DashboardTakyon/layouts/LiquidateLayout";
import BasketLayout from "views/dashboard/DashboardTakyon/layouts/BasketLayout";
import ExploreLayout from "views/dashboard/DashboardTakyon/layouts/ExploreLayout";
import Trades from "views/dashboard/DashboardTakyon/Trades";
// import TableTrades from "views/dashboard/DashboardTakyon/TableTrades";
import TableTrades from "views/dashboard/DashboardTakyon/TableTrades";
import FavoritesLayout from "views/dashboard/DashboardTakyon/layouts/FavoritesLayout";
import SummarizedLayout from "views/dashboard/DashboardTakyon/layouts/SummarizedLayout";
import DiveBarPortfolioLayout from "views/dashboard/DashboardTakyon/layouts/DiveBarPortfolioLayout";
import VirtualCardsLayout from "views/dashboard/DashboardTakyon/layouts/VirtualCardsLayout";
import UploadLayout from "views/dashboard/DashboardTakyon/layouts/UploadLayout";
import CollaborativeLayout from "views/dashboard/DashboardTakyon/layouts/CollaborativeLayout";
// import DetailedLayout from 'views/dashboard/DashboardTakyon/layouts/DetailedLayout';

// takyon routing
const LiteDash = Loadable(
  lazy(() => import("views/dashboard/DashboardTakyon/LiteDash"))
);
const ProfileSettings = Loadable(lazy(() => import("views/Profile")));
const FAQ = Loadable(lazy(() => import("views/Profile/FAQ")));
const EmptyContactForm = Loadable(
  lazy(() =>
    import("views/pages/authentication/authentication1/EmptyContactForm")
  )
);

const Logout = () => {
  const { logout } = useAuth();

  useEffect(() => {
    async function logMeOut() {
      try {
        await logout();
      } catch (err) {
        console.error(err);
      }
    }

    logMeOut();
  }, []);

  return <div></div>;
};
// ===========================|| MAIN ROUTING ||=========================== //

const LiteTakyonRoutes = {
  path: "/lite",
  element: (
    <AuthGuard>
      <LiteTakyonLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/",
      element: <ExploreLayout />,
    },
    // {
    //     path: '/',
    //     element: <LiteDash />,
    // },
    {
      path: "/settings",
      element: <ProfileSettings />,
    },

    {
      path: "/explore",
      element: <ExploreLayout />,
    },
    {
      path: "/swl",
      element: <SmartWatchListLayout />,
    },
    {
      path: "/velocity",
      element: <VelocityLayout />,
    },
    {
      path: "/trendingbuy",
      element: <TrendingBuyLayout />,
    },
    {
      path: "/trendingsell",
      element: <TrendingSellLayout />,
    },
    {
      path: "/oneclickbuy",
      element: <BasketLayout />,
    },
    {
      path: "/liquidate",
      element: <LiquidateLayout />,
    },
    {
      path: "/trades",
      element: <Trades />,
    },
    {
      path: "/orders",
      element: <TableTrades />,
    },
    // {
    //     path: '/favorites',
    //     element: <FavoritesLayout />
    // },
    {
      path: "/favorites",
      element: <FavoritesLayout />,
    },
    {
      path: "/summarized",
      element: <SummarizedLayout />,
    },
    {
      path: "/divebarportfolio",
      element: <DiveBarPortfolioLayout />,
    },
    {
      path: "/virtualcards",
      element: <VirtualCardsLayout />,
    },
    {
      path: "/support",
      element: <EmptyContactForm />,
    },
    {
      path: "/faq",
      element: <FAQ />,
    },
    {
      path: "/upload",
      element: <UploadLayout />,
    },
    {
      path: "/collaborative",
      element: <CollaborativeLayout />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
  ],
};

export default LiteTakyonRoutes;
