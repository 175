import React, { useContext, useState } from "react";
import {
  setModalCoinbasePaymentmethodStatus,
  selectCoinbasePaymentMethod,
} from "store/actions";
// material-ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Search from "./components/Search";
import SearchInput from "./components/SearchInput";
import CoinContext from "contexts/CoinContext";
import { searchCoins } from "store/constant";

// ===============================|| UI DIALOG - SCROLLABLE ||=============================== //

export default function ModalCoinBasePaymentMethod() {
  //
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => {
    return state.coinbasePaymentMethods.isOpen;
  });
  const listItems = useSelector((state) => {
    return state.coinbasePaymentMethods.paymentMethods;
  });
  console.log({ listItems });
  const scroll = "paper";
  // const [listItems, setListItems] = useState(searchCoins);

  const handleClose = () => {
    dispatch(setModalCoinbasePaymentmethodStatus(false));
    // setTimeout(()=>setListItems(searchCoins), 0)
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (isOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [isOpen]);

  const handleSelect = (item) => {
    // setCoin(coin);
    console.log({ item });
    dispatch(selectCoinbasePaymentMethod(item));
    handleClose();
  };
 

  // const handleSearch = (text) => {
  //     const  filtered = searchCoins.filter(coin => coin.label.toLowerCase().includes(text.toLowerCase()));
  //     setListItems(filtered)
  // };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Payment method
          {/* <SearchInput handleSearch={handleSearch}  /> */}
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <Grid container spacing={3}>
            <Grid item>
              <Search handleSelect={handleSelect} listItems={listItems} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ pr: 2.5, pt: 2.5 }}>
          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
