import * as actionTypes from "./actions";
import * as I from "immutable";
import { Map } from "immutable";
import CoinContext from "contexts/CoinContext";
import { map_coins, map_balance, assetPairsCoins } from "./constant";
import Portfolio from "./models/Portfolio";
import { OrderTicketTypes } from "./actions";

const initialState = {
  filters: {
    mapTrendingBuy: {
      item: "percent",
      value: Boolean(true),
    },
    mapTrendingSell: {
      item: "percent",
      value: Boolean(true),
    },
    mapSWLPositive: {
      item: "ts",
      value: Boolean(true),
    },
    mapSWLNegative: {
      item: "ts",
      value: Boolean(true),
    },
    mapVelPositive: {
      item: "vel",
      value: Boolean(true),
    },
    mapVelNegative: {
      item: "vel",
      value: Boolean(true),
    },
  },

  mapTrades: new I.Map(),
  mapLastTrade: new I.Map(),
  start_date: new Date("July 25, 2021 23:59:59"),
  end_date: new Date("December 31, 2021 23:59:59"),

  tradesTable: [],
};

// ===========================|| TAKYON REDUCER ||=========================== //

export class Trade {
  constructor(
    pair,
    trade_id,
    order_id,
    time_ms,
    trade_price,
    trade_qty,
    remain_qty,
    trade_cost,
    fee,
    is_buy
  ) {
    this.pair = pair;
    this.trade_id = trade_id;
    this.order_id = order_id;
    this.time_ms = time_ms;
    this.trade_price = trade_price;
    this.trade_qty = trade_qty;
    this.remain_qty = remain_qty;
    this.trade_cost = trade_cost;
    this.fee = fee;
    this.is_buy = is_buy;
  }
}

const takyonReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.SORT_VEL_POS: {
      const filters = state.filters;
      filters.mapVelPositive = {
        ...state.filters.mapVelPositive,
        value: !Boolean(state.filters.mapVelPositive.value),
        item: action.payload,
      };
      return { ...state, filters };
    }

    case actionTypes.SORT_VEL_NEG: {
      const filters = state.filters;
      filters.mapVelNegative = {
        ...state.filters.mapVelNegative,
        value: !Boolean(state.filters.mapVelNegative.value),
        item: action.payload,
      };
      return { ...state, filters };
    }

    case actionTypes.SORT_SWL_POS_TS: {
      const filters = state.filters;
      filters.mapSwlPositive = {
        ...state.filters.mapSwlPositive,
        value: !Boolean(state.filters.mapSwlPositive.value),
        item: action.payload,
      };
      return { ...state, filters };
    }

    case actionTypes.SORT_SWL_NEG_TS: {
      const filters = state.filters;
      filters.mapSwlNegative = {
        ...state.filters.mapSwlNegative,
        value: !Boolean(state.filters.mapSwlNegative.value),
        item: action.payload,
      };
      return { ...state, filters };
    }

    case actionTypes.SET_LAST_TRADE: {
      let iMap = state.mapLastTrade;
      iMap = iMap.set(action.payload.coin, action.payload.trade);

      return {
        ...state,
        mapLastTrade: iMap,
      };
    }

    case actionTypes.TRADES_TABLE: {
        let mapOrders = state.mapOrders;
        if ( mapOrders === undefined)
        {
            mapOrders = I.Map();
        }

        map_coins.forEach((value, key) => {
            const mapListOrders = action.payload.filter(
                        (orderList) =>
                            orderList.coin === key &&
                            !orderList.data.some((order) => order.orderStatus === 6)
                ).map(item => item.data[item.data.length -1]);
            if ( (key !== undefined) && (mapListOrders !== undefined))
            {
                mapOrders = mapOrders.set( key, mapListOrders);
            }
        });
        console.log( 'reducer: TRADES_TABLE mapOrders');
        console.log( mapOrders);

      return {
        ...state,
        tradesTable: action.payload,
        mapOrders: mapOrders
      };
    }
    case actionTypes.FETCH_TRADES: {
      let iMap = state.mapTrades;
      
      if(action.payload.trades) {
        action.payload.trades.forEach((t, index) => {
          let trade = new Trade(
            t.pair,
            t.trade_id,
            t.order_id,
            t.time_ms,
            t.trade_price,
            t.trade_qty,
            t.remain_qty,
            t.trade_cost,
            t.fee,
            t.is_buy
          );
          iMap = iMap.set(trade.trade_id, trade);
        });
      }
      else {
        action.payload.data.trades.forEach((t, index) => {
          let trade = new Trade(
            t.pair,
            t.trade_id,
            t.order_id,
            t.time_ms,
            t.trade_price,
            t.trade_qty,
            t.remain_qty,
            t.trade_cost,
            t.fee,
            t.is_buy
          );
          iMap = iMap.set(trade.trade_id, trade);
        });
      }
   

      return {
        ...state,
        mapTrades: iMap,
      };
    }

    default:
      return state;
  }
};

export default takyonReducer;
