import React, { useState } from "react";
import MobileLiquidateNewCard from "./components/MobileLiquidateNewCard";
import { useSelector } from "react-redux";

const Liquidate = () => {

  const [query, setQuery] = useState("");

  const positionList = useSelector((state) => {
    let items = [];
    if (state.coins.kraken_asset_pairs !== undefined) {
      state.coins.kraken_asset_pairs.forEach((item) => {
        if ( item.position !== undefined && item.position.invested > 1)
        {
            // TMD console.log( 'PORT: pos: ' + JSON.stringify(item.position));
            items.push(item);
        }
      });
    }
    return items;
  });

  let filtered = [];
  positionList
    .filter((post) => {
      if (query === "") {
        return post;
      } else if (post === undefined) {
        return post;
      } else if (post.coin_name.toLowerCase().includes(query.toLowerCase())) {
        return post;
      }
    })
    .map((post, index) => {
      filtered.push(post);
  });

  return <MobileLiquidateNewCard title="Liquidate" rows={filtered} />;
};

export default React.memo(Liquidate);
