import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import {
  createChart,
  createPriceLine,
  CrosshairMode,
} from "lightweight-charts";
import { weekly_portfolio } from "store/weekly";
import I from "immutable";
import useSocket from "hooks/useSocket";
import Transitions from "ui-component/extended/Transitions";
import {
  map_coins,
  map_balance_to_coin,
  map_coin_to_name,
  map_category,
} from "store/constant";

import {
  InputAdornment,
  OutlinedInput,
  InputLabel,
  IconButton,
  Chip,
  Fab,
} from "@material-ui/core";
import MUIFormControl from "@material-ui/core/FormControl";
import FavoriteButton from "../../../components/FavoriteButton";
// import Purchase from "./Purchase";
import SummaryCard from "ui-component/cards/SummaryCard";
import colors from "assets/scss/_themes-vars.module.scss";
import ToolTipHelper from "ui-component/ToolTipHelper";
import { tooltips } from "constants/tooltips";

import { Button, ButtonGroup } from "@material-ui/core";

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";

// material-ui
import {
  Checkbox,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { visuallyHidden } from "@material-ui/utils";

// third party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import SubCard from "ui-component/cards/SubCard";
import ColorSubCard from "ui-component/cards/ColorSubCard";

// assets
import Flag1 from "assets/images/widget/AUSTRALIA.jpg";
import Flag2 from "assets/images/widget/BRAZIL.jpg";
import Flag3 from "assets/images/widget/GERMANY.jpg";
import Flag4 from "assets/images/widget/UK.jpg";
import Flag5 from "assets/images/widget/USA.jpg";
import { useSelector } from "react-redux";

// table filter
function descendingComparator(a, b, orderBy) {
  if (orderBy !== "Pair") {
    if (Number(b[orderBy]) < Number(a[orderBy])) {
      return -1;
    }
    if (Number(b[orderBy]) > Number(a[orderBy])) {
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// ===========================|| TABLE - HEADER ||=========================== //

// table header
const headCells = [
  {
    id: "Pair",
    numeric: false,
    disablePadding: true,
    label: "Pair",
  },
  {
    id: "invested",
    numeric: true,
    disablePadding: false,
    label: "Invested",
  },
  /*
  {
    id: "percent",
    numeric: true,
    disablePadding: false,
    label: "Profit %",
  },
  // {
  //   id: "purchase",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Purchase",
  // },
  */
];

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    purchaseAmount,
    changePurchaseAmount,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "#191b1e" }}>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ backgroundColor: "#191b1e" }}
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}

        <TableCell
          align="left"
          padding="normal"
          sx={{ backgroundColor: "#191b1e" }}
          // sortDirection={orderBy === headCell.id ? order : false}
        >
          <MUIFormControl sx={{ width: "200px" }}>
            <InputLabel>{"$ amount"}</InputLabel>
            <OutlinedInput
              fullWidth
              label="Amount"
              name={"amount"}
              value={purchaseAmount}
              onChange={changePurchaseAmount}
              disabled={false}
              endAdornment={
                <>
                  <Divider sx={{ height: 24, m: 0.5 }} orientation="vertical" />
                  <InputAdornment position="end">%</InputAdornment>
                </>
              }
            />
          </MUIFormControl>
        </TableCell>
        <TableCell
          align="left"
          padding="normal"
          sx={{ width: "50px", backgroundColor: "#191b1e" }}
          // sortDirection={orderBy === headCell.id ? order : false}
        >
          {"Add"}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

// =========================|| DASHBOARD ANALYTICS - LATEST CUSTOMERS TABLE CARD ||========================= //

// style constant
const useStyles = makeStyles({
  /*
  root: {
    width: "100%",
    overflow: "hidden",
  },
  sortSpan: visuallyHidden,
  */
});

const MobileBasketCard = ({ title, rows }) => {
  const [coinCheckbox, setCoinCheckbox] = React.useState([
    true,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [category, setCategory] = React.useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [firstInit, setFirstInit] = useState(false);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("percent");
  const classes = useStyles();
  const { emit } = useSocket();
  const theme = useTheme();
  const green =
    theme.palette.mode === "dark"
      ? colors.successLightTakyon
      : colors.successDarkTakyon;
  const red =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [queryBasket, setQueryBasket] = useState("");
  const [purchaseAmount, setPurchaseAmount] = useState(0);

  const [purchase, setPurchase] = useState(
    rows.map((row) => {
      // const coin_name = map_coin_to_name.get(row.coin) ? map_coin_to_name.get(row.coin) : row.coin;
      const coin_name = row.marketcap
        ? row.marketcap.Name
        : row.base_currency
        ? row.base_currency
        : row.base
        ? row.base
        : row.coin;
      return {
        ...row,
        coin: row.coin,
        coin_name: coin_name,
        amount: 0,
        cost: row.position ? row.position.invested : 0,
        selected: false,
      };
    })
  );

  const handleSellMarket = (coin, qty) => {
    const UUID = Date.now() % 32768;
    const krakenToken =
      window.localStorage.getItem("kraken_token") || "no_token";

    const add_order_msg = JSON.stringify({
      event: "addOrder",
      k_token: krakenToken,
      order: {
        fc: coin,
        side: "SELL",
        id: Number(UUID),
        reqid: Number(UUID),
        userref: UUID.toString(),
        qty,
        orderType: Number(1),
      },
    });

    // TMD  console.log("ORDER: ");
    // TMD console.log(add_order_msg);

    emit(add_order_msg);

    return;
  };

  const handlePurchase = () => {
    purchase.forEach((item) => {
      if (item.selected === true) {
        console.log("Purchase " + item.coin);

        console.log(item.coin, item.amount / item.ltp);
        // handleBuyMarket( item.coin, (item.amount / item.ltp),  ) /// add here params
      }
    });

    let items = [...purchase];
    items.forEach((item) => {
      item.selected = false;
    });

    setPurchase(items);

    // setPurchase(
    //   rows.map((row) => ({
    //     coin: row.coin,
    //     amount: 0,
    //     cost: row.invested,
    //     selected: false,
    //   }))
    // );
  };

  const changePurchaseAmount = (e) => {
    if (Number(e.target.value) > 100 || Number(e.target.value) < 0) {
      return;
    }
    setPurchaseAmount(e.target.value);

    let items = [...purchase];
    items.forEach((item) => {
      let amount = Number(e.target.value);
      item.amount = amount;
    });

    setPurchase(items);
  };

  const setPercent = (percent) => {
    setPurchaseAmount(percent);

    let items = [...purchase];
    items.forEach((item) => {
      let amount = Number(percent);
      item.amount = amount;
    });

    setPurchase(items);
  };

  const changePurchaseAmountRow = (e, row) => {
    let amount = e.target.value; // row.position.invested - e.target.value > 0.0 ? e.target.value : row.position.price;
    const coin = row.coin;

    changePurchasePrice(coin, amount);
  };

  const changePurchasePrice = (pair, amount) => {
    let items = [...purchase];
    const index = items.findIndex((item) => item.coin === pair);
    const item = { ...items[index], amount: amount };
    items[index] = item;
    setPurchase(items);
  };

  const handleCheckboxSelect = (pair) => {
    let items = [...purchase];
    const index = items.findIndex((item) => item.coin === pair);
    if ( items[index] !== undefined)
    {
        const item = { ...items[index], selected: !items[index].selected };
        items[index] = item;
        setPurchase(items);
    }
  };

  const checkSelected = (pair) =>
    purchase.find((item) => item.coin === pair).selected;

  const coinPurchaseAmount = (pair) => {
    let amount = purchase.find((item) => item.coin === pair).amount;
    amount = Math.floor(amount * 100) / 100.0;
    return amount;
  };

  const percents = [25, 50, 75, 100];

  let cornsilk = "cornsilk";
  let items = [];
  let categoryItems = [];
  let filteredItems = [];
  if (purchase !== undefined) {
    purchase
      .filter((post) => {
        if (queryBasket === "") {
          return post;
        } else if (
          post.coin_name.toLowerCase().includes(queryBasket.toLowerCase())
        ) {
          return post;
        }
      })
      .map((post, index) => {
        filteredItems.push(post);
      });
  }

  let changed = items.length;

  if (filteredItems !== undefined) {
    if (coinCheckbox[0]) {
      filteredItems
        .filter((post) => {
          return post;
        })
        .map((post, index) => {
          items.push(post);
        });
    } else if (coinCheckbox[1]) {
      filteredItems
        .filter((post) => {
          if (post && post.invested < 0.1) {
            return post;
          }
        })
        .map((post, index) => {
          items.push(post);
        });
    } else if (coinCheckbox[4]) {
      filteredItems
        .filter((post) => {
          if (post) {
            if (post.trending_buy && post.trending_buy.alerted) {
              return post;
            }
            if (post.trending_sell && post.trending_sell.alerted) {
              return post;
            }
          }
        })
        .map((post, index) => {
          items.push(post);
        });
    } else if (coinCheckbox[5]) {
      filteredItems
        .filter((post) => {
          if (post && post.invested > 0.1) {
            return post;
          }
        })
        .map((post, index) => {
          items.push(post);
        });
    } else {
      items = filteredItems;
    }
  }

  if (items !== undefined) {
    if (category[0]) {
      items
        .filter((post) => {
          if (
            map_category.get(post.wsname) &&
            map_category.get(post.wsname) === "defi"
          ) {
            return post;
          }
        })
        .map((post, index) => {
          categoryItems.push(post);
        });
    } else if (category[1]) {
      items
        .filter((post) => {
          if (
            map_category.get(post.wsname) &&
            map_category.get(post.wsname) === "gamefi"
          ) {
            return post;
          }
        })
        .map((post, index) => {
          categoryItems.push(post);
        });
    } else if (category[2]) {
      items
        .filter((post) => {
          if (
            map_category.get(post.wsname) &&
            map_category.get(post.wsname) === "lending"
          ) {
            return post;
          }
        })
        .map((post, index) => {
          categoryItems.push(post);
        });
    } else if (category[3]) {
      items
        .filter((post) => {
          if (
            map_category.get(post.wsname) &&
            map_category.get(post.wsname) === "memes"
          ) {
            return post;
          }
        })
        .map((post, index) => {
          categoryItems.push(post);
        });
    } else if (category[4]) {
      items
        .filter((post) => {
          if (!map_category.get(post.wsname)) {
            return post;
          }
        })
        .map((post, index) => {
          categoryItems.push(post);
        });
    } else {
      items
        .filter((post) => {
          return post;
        })
        .map((post, index) => {
          categoryItems.push(post);
        });
    }
  }

  const handleCoinCheckboxSelect = (event, index) => {
    console.log("handleCoinCheckboxSelect: " + index);
    console.log(event.target.checked);
    if (event.target.checked) {
      let cb = [false, false, false, false, false];
      cb[index] = true;
      setCoinCheckbox(cb);
    } else {
      let cb = [false, false, false, false, false];
      setCoinCheckbox(cb);
    }
  };

  const handleCategorySelect = (event, index) => {
    console.log("handleCategorySelect: " + index);
    console.log(event.target.checked);
    if (event.target.checked) {
      let cb = [false, false, false, false, false];
      cb[index] = true;
      setCategory(cb);
    } else {
      let cb = [false, false, false, false, false];
      setCategory(cb);
    }
  };

  const onCloseModal = () => {
    setFirstInit(false);
    window.localStorage.setItem("explore_card_help", "true");
  };

    const basket_items= purchase.filter((item) => item.selected === true);

  // {"realized":812.3701633220427,"unrealized":-9.884240169643094,"remaining_qty":30.622686090000002,"remaining_cost":1112.757217432384,"remaining_avg_price":36.33767508709044,"total_fee":57.248827999999996,"market_price":36.0149,"total":"802.49","ltp":"36.015","percent":"-0.89","balance":27.43680818}
  return (
    <Grid container spacing={0.0} padding={0} alignItems="top">
      <Grid xs={12} padding={2} alignItems="center">
        <ColorSubCard
          border={green}
          title={
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    label="One-Click-BUY"
                    color="success"
                    onClick={handlePurchase}
                    disabled={!(basket_items.length > 0)}
                  >
                    Basket (BUY)
                  </Button>
          }
          content={false}
          sx={{
            padding: 0,
          }}
        >
          <TableContainer className={classes.container}>
            <Table stickyHeader size="small" aria-label="Basket">
              <TableBody>
                {purchase
                  .filter((item) => item.selected === true)
                  .map((item) => {
                    const coin_style = map_coins.get(item.coin);
                    const coin_name = map_coin_to_name.get(item.coin)
                      ? map_coin_to_name.get(item.coin)
                      : item.coin;
                    const amount = Math.floor(item.amount * 100) / 100.0;
                    return (
                      <Transitions type={"slide"} in={true} direction={"left"}>
                        <TableRow hover key={item.coin}>
                          <TableCell sx={{ color: red }}>
                            <table
                              style={{
                                fixed: { "table-layout": "fixed" },
                                td: { overflow: "hidden" },
                              }}
                            >
                              <tr>
                                <td>
                                  <a class={coin_style}>{coin_name}</a>
                                </td>
                              </tr>
                            </table>
                          </TableCell>

                          <TableCell
                            sx={{ "font-weight": "bold", color: "rung" }}
                          >
                            {"$"}
                            {amount}
                          </TableCell>
                        </TableRow>
                      </Transitions>
                    );
                  })}

                <TableRow hover>
                  <TableCell sx={{ "font-weight": "bold", color: "rung" }}>
                    {"$"}
                    {purchase
                      .filter((item) => item.selected === true)
                      .reduce(
                        (acc, item) =>
                          (Number(acc) + Number(item.amount)).toFixed(2),
                        0
                      )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </ColorSubCard>
      </Grid>
      <Grid container padding={1.0} />
      <Grid item xs={12} padding={0} alignItems="center">
        <ColorSubCard
          border={green}
          content={false}
          sx={{ align: "center" }}
          md={5}
          aria-label="main mailbox folders"
        >
            <Grid container alignItems="center">
              <Grid item xs={12}>
                      <ToolTipHelper
                        hover={tooltips.backetPercent.hover}
                        modal={tooltips.backetPercent.modal}
                      >
                      <MUIFormControl>
                        <InputLabel>Search</InputLabel>
                        <OutlinedInput
                          fullWidth
                          label="Search"
                          name={"search"}
                          onChange={(event) =>
                            setQueryBasket(event.target.value)
                          }
                        />
                      </MUIFormControl>
                        <ButtonGroup
                          variant="outlined"
                          aria-label="outlined button group"
                        >
                          {percents.map((percent) => (
                            <Button
                              disableElevation
                              variant={"outlined"}
                              onClick={() => setPercent(percent)}
                              sx={{width:'40px'}}
                            >
                              {"$"}
                              {percent}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </ToolTipHelper>
              </Grid>

              <Grid item padding={0} spacing={0} xs={12}>
                      <Checkbox
                        checked={coinCheckbox[0]}
                        color="primary"
                        onChange={(event) => handleCoinCheckboxSelect(event, 0)}
                        inputProps={{ "aria-labelledby": "All" }}
                      />
                      <font style={{fontSize: '0.6rem'}}>{"All Coins"}</font>
                      <Checkbox
                        checked={coinCheckbox[1]}
                        color="primary"
                        onChange={(event) => handleCoinCheckboxSelect(event, 1)}
                        inputProps={{ "aria-labelledby": "New Coins" }}
                      />
                      <font style={{fontSize: '0.6rem'}}>{"New Coins"}</font>
                      <Checkbox
                        checked={coinCheckbox[5]}
                        color="primary"
                        onChange={(event) => handleCoinCheckboxSelect(event, 5)}
                        inputProps={{ "aria-labelledby": "Invested" }}
                      />
                      <font style={{ color: cornsilk, fontSize: '0.6rem' }}>{"Invested"}</font>
                      <br/>
                      <Checkbox
                        checked={category[0]}
                        color="primary"
                        onChange={(event) => handleCategorySelect(event, 0)}
                        inputProps={{ "aria-labelledby": "DeFi" }}
                      />
                      <font style={{fontSize: '0.6rem'}}>{"DeFi"}</font>
                      <Checkbox
                        checked={category[1]}
                        color="primary"
                        onChange={(event) => handleCategorySelect(event, 1)}
                        inputProps={{ "aria-labelledby": "GameFi" }}
                      />
                      <font style={{fontSize: '0.6rem'}}>{"GameFi"}</font>
                      <Checkbox
                        checked={category[2]}
                        color="primary"
                        onChange={(event) => handleCategorySelect(event, 2)}
                        inputProps={{ "aria-labelledby": "Lending" }}
                      />
                      <font style={{fontSize: '0.6rem'}}>{"Lending"}</font>
                      <Checkbox
                        checked={category[3]}
                        color="primary"
                        onChange={(event) => handleCategorySelect(event, 3)}
                        inputProps={{ "aria-labelledby": "Memes" }}
                      />
                      <font style={{fontSize: '0.6rem'}}>{"Memes"}</font>
                      <Checkbox
                        checked={category[4]}
                        color="primary"
                        onChange={(event) => handleCategorySelect(event, 4)}
                        inputProps={{ "aria-labelledby": "Currency" }}
                      />
                      <font style={{fontSize: '0.6rem'}}>{"Currency"}</font>
              </Grid>
            </Grid>
          <TableContainer className={classes.container}>
            <Table stickyHeader size="small" aria-label="Basket">
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                purchaseAmount={purchaseAmount}
                changePurchaseAmount={changePurchaseAmount}
              />
              <TableBody>
                {stableSort(categoryItems, getComparator(order, orderBy)).map(
                  (row, index) => {
                    const coin_style = map_coins.get(row.coin);
                    // const coin_name = map_coin_to_name.get(row.coin) ? map_coin_to_name.get(row.coin) : row.coin;
                    const coin_name = row.marketcap
                      ? row.marketcap.Name
                      : row.base_currency
                      ? row.base_currency
                      : row.base
                      ? row.base
                      : row.coin;
                    const rung = row.position
                      ? row.ltp < row.position.remaining_avg_price
                        ? red
                        : green
                      : green;
                    const labelId = `${row.coin}`;

                    return (
                      <TableRow hover role="checkbox" key={row.coin}>
                        <TableCell>
                          <table
                            style={{
                              fixed: { "table-layout": "fixed" },
                              td: { overflow: "hidden" },
                            }}
                          >
                            <tr>
                              <td style={{ align: "left" }}>
                                <a class={coin_style}> {coin_name} </a>
                                <Typography>
                                  <font
                                    variant="subtitle1"
                                    style={{
                                      fontSize: "0.8rem",
                                      textAlign: "left",
                                      color: "gray",
                                    }}
                                  >
                                    {/*map_balance_to_coin.get(row.coin) ? map_balance_to_coin.get(row.coin) : row.coin*/}
                                    {row.base_currency}
                                  </font>
                                  <font
                                    variant="subtitle1"
                                    style={{
                                      fontSize: "0.8rem",
                                      textAlign: "left",
                                      color: "white",
                                    }}
                                  >
                                    {" "}
                                    {row.position &&
                                      row.position.balance < 1 &&
                                      row.position.balance.toFixed(5)}
                                    {row.position &&
                                      row.position.balance >= 1 &&
                                      row.position.balance.toFixed(2)}
                                  </font>
                                </Typography>
                              </td>
                            </tr>
                          </table>
                        </TableCell>

                        <TableCell sx={{ "font-weight": "bold", color: rung }}>
                                <Typography variant="subtitle1" sx={{ fontSize: "1.0rem", textAlign: "left", color: rung, }} >
                                       {"$"}
                                       {row.position && row.position.profit}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: "0.8rem", textAlign: "left", color: cornsilk, }} >
                                  {row.position && row.position.percent}
                                  {"%"}
                                </Typography>
                        </TableCell>
                        <TableCell>
                          <MUIFormControl sx={{ width: "200px" }}>
                            <InputLabel>{"$"}</InputLabel>
                            <OutlinedInput
                              fullWidth
                              coin={row.coin}
                              // value={
                              //   mapPurchaseRows.get(row.coin)
                              //     ? mapPurchaseRows.get(row.coin).amount
                              //     : 0
                              // }
                              value={coinPurchaseAmount(row.coin)}
                              onChange={(event) =>
                                changePurchaseAmountRow(event, row)
                              }
                              disabled={false}
                              endAdornment={
                                <>
                                  <Divider
                                    sx={{ height: 16, m: 0.5 }}
                                    orientation="vertical"
                                  />
                                  <InputAdornment position="end">
                                    USD
                                  </InputAdornment>
                                </>
                              }
                            />
                          </MUIFormControl>
                        </TableCell>
                        <TableCell sx={{ pl: 3 }} padding="checkbox">
                          <table>
                            <tr>
                              <td>
                                <Checkbox
                                  color="primary"
                                  // selected={checkSelected(row.coin)}
                                  // checked
                                  checked={row.selected === true}
                                  onChange={() =>
                                    handleCheckboxSelect(row.coin)
                                  }
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </td>
                              <td>
                                <FavoriteButton pair={row.coin} />
                              </td>
                            </tr>
                          </table>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </ColorSubCard>
      </Grid>
    </Grid>
  );
};

MobileBasketCard.propTypes = {
  title: PropTypes.string,
};

export default MobileBasketCard;
