import React, { useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import * as I from "immutable";
import { Map } from "immutable";
import LayersTwoToneIcon from "@material-ui/icons/LayersTwoTone";
import { Button, Grid, IconButton, Tooltip } from "@material-ui/core";
import CoinContext from "contexts/CoinContext";
import colors from 'assets/scss/_themes-vars.module.scss';

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { IconTrash } from "@tabler/icons";

// project imports
import MainCard from "ui-component/cards/MainCard";
import { cancelOrder } from "emitters";
import useSocket from "hooks/useSocket";
import { useDispatch } from "react-redux";
import { getTradesTable } from "store/actions";

const OrderCoin = () => {
  const { coin } = useContext(CoinContext);

  const { emit } = useSocket();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTradesTable());
  }, []);

  let tableData = useSelector((state) => state.takyon.tradesTable);

  //TODO: move to actions
  const mapOrders = tableData
    .filter(
      (orderList) =>
        orderList.coin === coin &&
        !orderList.data.some((order) => order.orderStatus === 6)
    )
    .map((item) => item.data[item.data.length - 1]);

  const handleDeleteOrder = (id) => {
    emit(cancelOrder(id));
  };

      const theme = useTheme();
      const green = (theme.palette.mode === 'dark') ? colors.successLightTakyon : colors.successDarkTakyon;
      const red = (theme.palette.mode === 'dark') ? colors.errorLightTakyon : colors.errorDarkTakyon;
      const cornsilk = (theme.palette.mode === 'dark') ? colors.cornsilkLightTakyon : colors.cornsilkDarkTakyon;
      const whiteTakyon = (theme.palette.mode === 'dark') ? colors.whiteLightTakyon : colors.whiteDarkTakyon;

  return (
    <MainCard
      content={false}
      sx={{
        height: "400px",
        padding: 0,
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>exchId</TableCell>
              <TableCell>side</TableCell>
              <TableCell>qty</TableCell>
              <TableCell>price</TableCell>
              <TableCell>cost</TableCell>
              <TableCell>status</TableCell>
              <TableCell>action</TableCell>
              <TableCell align="right">sequence</TableCell>
              <TableCell align="right">date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mapOrders.map((dataRow, index) => {
              let s = "NEW";
              if (dataRow.orderStatus === 1) s = "NEW";
              else if (dataRow.orderStatus === 2) s = "PENDING";
              else if (dataRow.orderStatus === 3) s = "ACK";
              else if (dataRow.orderStatus === 4) s = "PENDING_CANCEL";
              else if (dataRow.orderStatus === 5) s = "CANCELED";
              else if (dataRow.orderStatus === 6) s = "FILLED";
              else if (dataRow.orderStatus === 7) s = "PARTIAL";
              else if (dataRow.orderStatus === 8) s = "REJECTED";
              else s = "UNKNOWN";

              const rung = dataRow.side === "SELL" ? red : green;
              const cost = (dataRow.qty * dataRow.px).toFixed(2);

              return (
                <TableRow key={index}>
                  <TableCell sx={{ color: rung }}> {dataRow.exchId} </TableCell>
                  <TableCell sx={{ color: rung }}> {dataRow.side} </TableCell>
                  <TableCell sx={{ color: rung }}>
                    {" "}
                    {dataRow.qty.toFixed(3)}{" "}
                  </TableCell>
                  <TableCell sx={{ color: rung }}>
                    {" "}
                    ${dataRow.px.toFixed(2)}{" "}
                  </TableCell>
                  <TableCell sx={{ color: rung }}> ${cost} </TableCell>
                  <TableCell sx={{ color: rung }}> {s} </TableCell>
                  <TableCell sx={{ color: rung }}>
                    <Tooltip title="Delete">
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => handleDeleteOrder(dataRow.exchId)}
                      >
                        <IconTrash stroke={1.5} size="1.8rem" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={{ color: rung }} align="right">
                    {" "}
                    {dataRow.sequence}{" "}
                  </TableCell>
                  <TableCell sx={{ color: rung }} align="right">
                    {" "}
                    {new Date(dataRow.curr).toLocaleTimeString()}{" "}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default OrderCoin;
