import React, { lazy, useEffect, useState } from "react";
import useAuth from "hooks/useAuth";

// project imports
import MobileLiteTakyonLayout from "layout/LiteTakyonLayout/MobileLiteTakyonLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import MobileBreakoutLayout from "views/dashboard/DashboardTakyon/mobileLayouts/SmartWatchListLayout";
import MobileTrendingBuyLayout from "views/dashboard/DashboardTakyon/mobileLayouts/TrendingBuyLayout";
import MobileTrendingSellLayout from "views/dashboard/DashboardTakyon/mobileLayouts/TrendingSellLayout";
import MobileLiquidateLayout from "views/dashboard/DashboardTakyon/mobileLayouts/LiquidateLayout";
import MobileBasketLayout from "views/dashboard/DashboardTakyon/mobileLayouts/BasketLayout";
import MobileExploreLayout from "views/dashboard/DashboardTakyon/mobileLayouts/ExploreLayout";
import MobileVirtualCardLayout from "views/dashboard/DashboardTakyon/mobileLayouts/VirtualCardLayout";

// takyon routing
const LiteDash = Loadable(
  lazy(() => import("views/dashboard/DashboardTakyon/LiteDash"))
);
const ProfileSettings = Loadable(lazy(() => import("views/Profile")));
const FAQ = Loadable(lazy(() => import("views/Profile/FAQ")));
const EmptyContactForm = Loadable(
  lazy(() =>
    import("views/pages/authentication/authentication1/EmptyContactForm")
  )
);

const Logout = () => {
  const { logout } = useAuth();

  useEffect(() => {
    async function logMeOut() {
      try {
        await logout();
      } catch (err) {
        console.error(err);
      }
    }

    logMeOut();
  }, []);

  return <div></div>;
};
// ===========================|| MAIN ROUTING ||=========================== //

const LiteTakyonMobileRoutes = {
  path: "/lite",
  element: (
    <AuthGuard>
      <MobileLiteTakyonLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/",
      element: <MobileExploreLayout />,
    },
    // {
    //     path: '/',
    //     element: <LiteDash />,
    // },
    {
      path: "/settings",
      element: <ProfileSettings />,
    },

    {
      path: "/explore",
      element: <MobileExploreLayout />,
    },
    {
      path: "/swl",
      element: <MobileBreakoutLayout />,
    },
    {
      path: "/trendingbuy",
      element: <MobileTrendingBuyLayout />,
    },
    {
      path: "/trendingsell",
      element: <MobileTrendingSellLayout />,
    },
    {
      path: "/oneclickbuy",
      element: <MobileBasketLayout />,
    },
    {
      path: "/liquidate",
      element: <MobileLiquidateLayout />,
    },
    {
      path: "/virtualcards",
      element: <MobileVirtualCardLayout />,
    },
    {
      path: "/support",
      element: <EmptyContactForm />,
    },
    {
      path: "/faq",
      element: <FAQ />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
  ],
};

export default LiteTakyonMobileRoutes;
