import * as actionTypes from "./actions";

const initialState = {
  paymentMethods: [],
  selected: false,
  isOpen: false,
};

const coinbasePaymentMethodsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_COINBASE_PAYMENT_METHODS: {
      const newPaymentMethods = state.paymentMethods.concat(action.payload);
      return {
        ...state,
        paymentMethods: newPaymentMethods,
      };
    }
    case actionTypes.SET_MODAL_COINBASE_PAYMENTMETHOD_STATUS: {
      const isOpen = action.isOpen;
      return {
        ...state,
        isOpen,
      };
    }
    case actionTypes.SELECT_COINBASE_PAYMENT_METHOD: {
      return {
        ...state,
        selected: action.item,
      };
    }
    default:
      return state;
  }
};

export default coinbasePaymentMethodsReducer;
