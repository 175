// import axios from "utils/axios";
import Config from 'config';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addBalance } from "store/actions";
import {map_coins} from "../store/constant";

export const getData = async () => {

  const key = window.localStorage.getItem("kraken_key");
  const secret = window.localStorage.getItem("kraken_sec");

    let url = `https://${Config.takyon_domain}/api/v1/balance?k_key=${key}&k_sec=${secret}&exchange=coinbase_light`;




  const response = await axios.get(
    url,
    {},
    {
      headers: {
        "Accept":	"*",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return response.data;
};


//DEPRECATED! to be removed
export default function useBalance() {
  const dispatch = useDispatch();

  const fetchBalance = () => {

    // let rcoin = map_coins.get(coin);

    getData().then((data) => {
      console.log({ data });
      dispatch(addBalance(data));
    });
  };

  return {
    fetchBalance,
  };
}
