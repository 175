import { Grid } from "@material-ui/core";
import React from "react";
import Favorites from "../Favorites";
import FavoritesRecent from "../Favorites/components/FavoritesRecent";
import CandleChart from "../CandleChart";
import LiteOrderTicket from "ui-component/cards/LiteOrderTicket";
import SummaryCard from "ui-component/cards/SummaryCard";

const FavoritesLayout = () => {
  return (
    <Grid container spacing={2.0} >

        <Grid item xs={9.5} alignItems="center">
          <CandleChart />
          <Grid container padding={1.0} />
          <Favorites />
        </Grid>
 
        <Grid item xs={2.5} alignItems="center">
            <SummaryCard sx={{alignItems: 'middle'}} />
            <Grid container padding={1.0} />
            <LiteOrderTicket />
            <Grid container padding={1.0} />
            <FavoritesRecent />
        </Grid>

    </Grid>
  );
};

export default FavoritesLayout;
