import PropTypes from "prop-types";
import React, { useEffect } from "react";

// material-ui
import { useTheme } from "@material-ui/core/styles";
import { Box, Card, Grid, Typography, Button } from "@material-ui/core";
import ApexCharts from "apexcharts";
import AnimateButton from "ui-component/extended/AnimateButton";
import coinbaseLogo from "assets/images/exchanges/coinbase.png";
import { makeStyles } from "@material-ui/styles";
import CoinBaseLoginButton from "ui-component/CoinBaseLoginButton";
import Chart from "react-apexcharts";

// ============================|| TOTAL LINE CHART CARD ||============================ //

// style constant
const useStyles = makeStyles({
  blueButton: {
    backgroundColor: " #6f9eff",
    color: "white",
  },
});

const ExplorePortfolioReadOnlyCard = ({
  bgColor,
  color,
  chartData,
  title,
  percentage,
  value,
  cdata,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Card>
      <Box
        sx={{
          color: color,
          bgcolor: bgColor,
        }}
      >
        <Box sx={{ p: 2.5 }}>
          <Grid container direction="column">
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
            >
              {value && (
                <Grid item>
                  <Typography variant="h4" color="inherit">
                    {value}
                  </Typography>
                </Grid>
              )}
              {percentage && (
                <Grid item>
                  <Typography variant="body2" color="inherit">
                    {percentage}
                  </Typography>
                </Grid>
              )}
            </Grid>
            {title && (
              <Grid item>
                <Typography variant="h4" color="inherit">
                  {" "}
                  {title}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <Grid item xs={12}>
              <CoinBaseLoginButton />
            </Grid>
          </div>
        </div>

        {chartData && <Chart {...chartData} />}
      </Box>
    </Card>
  );
};

ExplorePortfolioReadOnlyCard.propTypes = {
  bgColor: PropTypes.string,
  chartData: PropTypes.object,
  title: PropTypes.string,
  percentage: PropTypes.string,
  value: PropTypes.number,
};

export default ExplorePortfolioReadOnlyCard;
