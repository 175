import {useRef, useContext} from 'react';
import * as actionTypes from "./actions";
import * as I from "immutable";
import { Map } from "immutable";
import { map_weeks, map_coins, map_balance, assetPairsCoins } from "./constant";
import Portfolio from "./models/Portfolio";
import { OrderTicketTypes } from "./actions";
import {map_coin_to_name, map_untradable_kraken_assets} from 'store/constant';

const krakenAssetPairs = require("assets/json/krakenAssetsPairs.json");
const coinbaseAssetPairs = require("assets/json/coinbaseAssetsPairs.json");

// const isEUR = false;
const isEUR = window.localStorage.getItem("currency") === "eur" ;


let default_tabs = ['BTC/USD', 'ETH/USD'];
if ( isEUR)
{
    default_tabs = ['BTC/EUR', 'ETH/EUR'];
}

// Portfolio related
export const portfolio_weekly = require("assets/json/portfolio/pnl_static.json");
export const portfolio_weekly_total = require("assets/json/portfolio/pnl_total_static.json");
export const marketcap = require('assets/json/crypto_marketcap.json');

let krakenPairs = krakenAssetPairs.result;
let coinbasePairs = coinbaseAssetPairs;
let assetPairItems = I.Map();

export const balance_history = {
    "id": "",
    "timestamp": 1640394912861,
    "response_type": "balance_history",
    "data": {
        "202145": 157.09213591999998,
        "202146": 449.59645786,
        "202147": 446.75717156,
        "202148": 460.58606973999997,
        "202149": 373.5279945799999,
        "202150": 3621.8495760446403,
        "202151": 3719.8835758593
    }
};
// TMD console.log(krakenPairs);

const groups  =[""];


//  * For KrakenAssetPairs

/*
Object.keys(krakenPairs).forEach((key)=> {
    // OPTIMIZE. Let's only add coins that are USD and tradable.
    if ( (krakenPairs[key].quote === "ZUSD") && !map_untradable_kraken_assets.get(krakenPairs[key].wsname))
    {
        // TMD console.log( krakenPairs[key].quote + ' ' + krakenPairs[key].wsname + ' ' + map_untradable_kraken_assets.get(krakenPairs[key].wsname));
        krakenPairs[key].coin = krakenPairs[key].wsname;
        krakenPairs[key].exchange = "kraken";
        krakenPairs[key].coin_name = (map_coin_to_name.get(krakenPairs[key].wsname)) ? map_coin_to_name.get(krakenPairs[key].wsname) : krakenPairs[key].wsname;

        krakenPairs[key].quantity = 0;


        //todo:
        // krakenPairs[key].group = groups...; // grou

        assetPairItems = assetPairItems.set( krakenPairs[key].coin,krakenPairs[key]);
    }
});
*/
 
Object.keys(coinbasePairs).forEach((key)=> {
    // OPTIMIZE. Let's only add coins that are USD and tradable.
    if ( !isEUR && (coinbasePairs[key].quote_currency === "USD"))
    {
        // TMD console.log( krakenPairs[key].quote + ' ' + krakenPairs[key].wsname + ' ' + map_untradable_kraken_assets.get(krakenPairs[key].wsname));
        coinbasePairs[key].coin = coinbasePairs[key].id.replace('-', '/');
        coinbasePairs[key].wsname = coinbasePairs[key].id.replace('-', '/');
        coinbasePairs[key].wsname = coinbasePairs[key].id.replace('-', '/');
        coinbasePairs[key].coin_style = coinbasePairs[key].id.replace('-', '');
        coinbasePairs[key].quote = "ZUSD";
        coinbasePairs[key].exchange = "coinbase";
        coinbasePairs[key].coin_name = (map_coin_to_name.get(coinbasePairs[key].wsname)) ? map_coin_to_name.get(coinbasePairs[key].wsname) : coinbasePairs[key].wsname;
        coinbasePairs[key].quantity = 0;
        coinbasePairs[key].hilo_percent = 0;
        coinbasePairs[key].trending_hilo_percent = 0;
        coinbasePairs[key].daily_hi = 0;
        coinbasePairs[key].daily_lo = 0;
        assetPairItems = assetPairItems.set( coinbasePairs[key].coin,coinbasePairs[key]);
        // console.log( "map_coins[\"" + coinbasePairs[key].wsname + "\", \"" + coinbasePairs[key].coin_style + "\"]");
        // console.log( "rev_coins[\"" + coinbasePairs[key].coin_style + "\", \"" + coinbasePairs[key].wsname + "\"]");
    }
    else if ( isEUR && (coinbasePairs[key].quote_currency === "EUR"))
    {
        // TMD console.log( krakenPairs[key].quote + ' ' + krakenPairs[key].wsname + ' ' + map_untradable_kraken_assets.get(krakenPairs[key].wsname));
        coinbasePairs[key].coin = coinbasePairs[key].id.replace('-', '/');
        coinbasePairs[key].wsname = coinbasePairs[key].id.replace('-', '/');
        coinbasePairs[key].wsname = coinbasePairs[key].id.replace('-', '/');
        coinbasePairs[key].coin_style = coinbasePairs[key].id.replace('-', '');
        coinbasePairs[key].quote = "ZEUR";
        coinbasePairs[key].exchange = "coinbase";
        coinbasePairs[key].coin_name = (map_coin_to_name.get(coinbasePairs[key].wsname)) ? map_coin_to_name.get(coinbasePairs[key].wsname) : coinbasePairs[key].wsname;
        coinbasePairs[key].quantity = 0;
        coinbasePairs[key].hilo_percent = 0;
        coinbasePairs[key].trending_hilo_percent = 0;
        coinbasePairs[key].daily_hi = 0;
        coinbasePairs[key].daily_lo = 0;
        assetPairItems = assetPairItems.set( coinbasePairs[key].coin,coinbasePairs[key]);
        console.log( "map_coins[\"" + coinbasePairs[key].wsname + "\", \"" + coinbasePairs[key].coin_style + "\"]");
        console.log( "rev_coins[\"" + coinbasePairs[key].coin_style + "\", \"" + coinbasePairs[key].wsname + "\"]");
    }
});

Object.keys(marketcap).forEach((key)=> {
    let coin = assetPairItems.get(marketcap[key].Symbol);
    if ( coin !== undefined)
    {
        coin.marketcap = marketcap[key];
        assetPairItems = assetPairItems.set(marketcap[key].Symbol, coin);
    }
});

// console.log(assetPairItems);
// console.log(assetPairItems.size);
// console.log({portfolio_weekly});
// console.log({portfolio_weekly_total});

        // Let's get the portfolio in place.
        /*
        let cdata = [];
        portfolio_weekly_total.forEach((item, index) => {
            const total = (item.data.total_realized + item.data.total_unrealized - item.data.total_fee);
            // console.log(item.id + ', ' + item.data.total_realized + ', ' + item.data.total_unrealized + ', ' + item.data.total_fee);
            cdata.push(total.toFixed(0));
        });

        let cdata_coin = I.Map();
        portfolio_weekly_total.forEach((item, index) => {
            // console.log( item.data);
            Object.keys(krakenPairs).forEach((key) => {
                const value = item.data[krakenPairs[key].wsname];
                if ( value !== undefined)
                {
                    let total = value.realized + value.unrealized - value.total_fee;
                    let array = cdata_coin.get(krakenPairs[key].wsname);
                    if ( array === undefined)
                    {
                        cdata_coin = cdata_coin.set(krakenPairs[key].wsname, [total.toFixed(0)]);
                    }
                    else
                    {
                        array.push(total.toFixed(0));
                        cdata_coin = cdata_coin.set(krakenPairs[key].wsname,array);
                    }
                }
            });
        });
        */

const swlChartData = {
    type: 'area',
    height: 100,
    options: {
        chart: {
            id: 'swl',
            sparkline: {
                enabled: true
            }
        },
        dataLabels: {
            enabled: false
        },
        colors: ['#fff'],
        fill: {
            type: 'solid',
            opacity: 0.4
        },
        stroke: {
            curve: 'smooth',
            width: 3
        },
        tooltip: {
            theme: 'dark',
            fixed: {
                enabled: true
            },
            x: {
                show: false
            },
            y: {
                title: 'Total Sales'
            },
            marker: {
                show: false
            }
        }
    },
    series: [
        {
            name: 'series1',
            data: [20, 10, 18, 12, 25, 10, 20]
        }
    ]
};
export class Alerts {
  constructor() {
    this.alertsTB = new I.Map();
    this.alertsTS = new I.Map();
    this.alertsSP = new I.Map();
    this.alertsSN = new I.Map();
    this.alertsVP = new I.Map();
    this.alertsVN = new I.Map();
  }

  toString() {
    return (
      "ALERTS: TB: " +
      this.alertsTB.size() +
      ", TS: " +
      this.alertsTS.size() +
      ", SP: " +
      this.alertsSP.size() +
      ", SN: " +
      this.alertsSN.size() +
      ", VP: " +
      this.alertsVP.size() +
      ", VN: " +
      this.alertsVN.size()
    );
  }
}

class NewPortfolio {
  constructor(total_fee, total_total, total_unreal, total_real, ts) {
    this.total_fee = total_fee;
    this.total_unreal = total_unreal;
    this.total_real = total_real;
    this.total_total = total_total;
    this.ts = ts;
    this.total_cash = 0.0;
    this.total_coin_balance = 0.0;
    this.total_revenue = 0.0;
  }
}

const initialState = {
  kraken_asset_pairs_loaded: true,
  kraken_asset_pairs:  I.Map(assetPairItems), // holds all the info, iMapTest, 

  map_explore_recent: I.Map(),                  // holds all explore recent info
  alerts: new Alerts(),

  newportfolio: undefined,
  start_date: new Date("July 25, 2021 23:59:59"),
  end_date: new Date("December 31, 2021 23:59:59"),

  explore_tabs: default_tabs,
  selectedExchange: "coinbase",
  balance_history: [],
  weeks: [],
  // cdata: [],
  // cdata_coin: [],
};


// TMD console.log(initialState)

// ===========================|| TAKYON REDUCER ||=========================== //

export class NewPosition {
  constructor(icon, coin, ltp, total, real, unreal, fee, qty, avg, cost, ts) {
    this.icon = icon;
    this.coin = coin;
    this.ltp = ltp;
    this.real = real;
    this.unreal = unreal;
    this.fee = fee;
    this.qty = qty;
    this.avg = avg;
    this.cost = cost;
    this.ts = ts;
    this.total = total;
    this.balance = 0.0;
    this.percent = 0.0;
  }
}

const coinReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.SELECT_EXCHANGE: {
      // TMD console.log("switch exchange");
      // TMD console.log(action.payload);
      // TMD console.log(state.kraken_asset_pairs);

      let newMap  = I.Map();
      let iMap = state.kraken_asset_pairs;
      
      iMap.forEach((item)=>{
        newMap.set(item.coin,item);
      })


      // return {
      //   ...state,
      //   kraken_asset_pairs:newMap,
      // };
    }


    //deprecated
    case actionTypes.KRAKEN_ASSET_PAIRS: {
      // TMD console.log("COIN_R: KRAKEN_ASSET_PAIRS");
      let iMap = state.kraken_asset_pairs;
      let kraken_asset_pairs_loaded = false;
      // filter  by exchange
        // TMD console.log("asset_pairts_exchange:" + state.selectedExchange)

      if ( !isEUR)
      {
          iMap = iMap.set("ACH/USD", {
            altname: "ACH/USD",
            wsname: "ACH/USD",
            aclass_base: "currency",
            base: "ACH",
            aclass_quote: "currency",
            quote: "ZUSD",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZUSD",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });

          iMap = iMap.set("ARPA/USD", {
            altname: "ARPA/USD",
            wsname: "ARPA/USD",
            aclass_base: "currency",
            base: "ARPA",
            aclass_quote: "currency",
            quote: "ZUSD",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZUSD",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });
          
          iMap = iMap.set("AVAX/USD", {
            altname: "AVAX/USD",
            wsname: "AVAX/USD",
            aclass_base: "currency",
            base: "AVAX",
            aclass_quote: "currency",
            quote: "ZUSD",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZUSD",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });

          iMap = iMap.set("CRO/USD", {
            altname: "CRO/USD",
            wsname: "CRO/USD",
            aclass_base: "currency",
            base: "CRO",
            aclass_quote: "currency",
            quote: "ZUSD",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZUSD",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });
          
          iMap = iMap.set("IOTX/USD", {
            altname: "IOTX/USD",
            wsname: "IOTX/USD",
            aclass_base: "currency",
            base: "IOTX",
            aclass_quote: "currency",
            quote: "ZUSD",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZUSD",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });

          iMap = iMap.set("KRL/USD", {
            altname: "KRL/USD",
            wsname: "KRL/USD",
            aclass_base: "currency",
            base: "KRL",
            aclass_quote: "currency",
            quote: "ZUSD",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZUSD",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });
          
          iMap = iMap.set("POLY/USD", {
            altname: "POLY/USD",
            wsname: "POLY/USD",
            aclass_base: "currency",
            base: "POLY",
            aclass_quote: "currency",
            quote: "ZUSD",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZUSD",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });

          iMap = iMap.set("SHIB/USD", {
            altname: "SHIB/USD",
            wsname: "SHIB/USD",
            aclass_base: "currency",
            base: "SHIB",
            aclass_quote: "currency",
            quote: "ZUSD",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZUSD",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });



          iMap = iMap.set("LTC/USD", {
            altname: "LTCUSD",
            wsname: "LTC/USD",
            aclass_base: "currency",
            base: "XLTC",
            aclass_quote: "currency",
            quote: "ZUSD",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZUSD",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });
          iMap = iMap.set("MLN/USD", {
            altname: "MLNUSD",
            wsname: "MLN/USD",
            aclass_base: "currency",
            base: "XMLN",
            aclass_quote: "currency",
            quote: "ZUSD",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZUSD",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });
          iMap = iMap.set("REP/USD", {
            altname: "REPUSD",
            wsname: "REP/USD",
            aclass_base: "currency",
            base: "XREP",
            aclass_quote: "currency",
            quote: "ZUSD",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZUSD",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });
          iMap = iMap.set("XBT/USD", {
            altname: "XBTUSD",
            wsname: "XBT/USD",
            aclass_base: "currency",
            base: "XXBT",
            aclass_quote: "currency",
            quote: "ZUSD",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZUSD",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });
          iMap = iMap.set("BTC/USD", {
            altname: "BTCUSD",
            wsname: "BTC/USD",
            aclass_base: "currency",
            base: "XBTC",
            aclass_quote: "currency",
            quote: "ZUSD",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZUSD",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });
          iMap = iMap.set("XLM/USD", {
            altname: "XLMUSD",
            wsname: "XLM/USD",
            aclass_base: "currency",
            base: "XXLM",
            aclass_quote: "currency",
            quote: "ZUSD",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZUSD",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });
      }
      else
      {
          iMap = iMap.set("ACH/EUR", {
            altname: "ACH/EUR",
            wsname: "ACH/EUR",
            aclass_base: "currency",
            base: "ACH",
            aclass_quote: "currency",
            quote: "ZEUR",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZEUR",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });

          iMap = iMap.set("ARPA/EUR", {
            altname: "ARPA/EUR",
            wsname: "ARPA/EUR",
            aclass_base: "currency",
            base: "ARPA",
            aclass_quote: "currency",
            quote: "ZEUR",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZEUR",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });
          
          iMap = iMap.set("AVAX/EUR", {
            altname: "AVAX/EUR",
            wsname: "AVAX/EUR",
            aclass_base: "currency",
            base: "AVAX",
            aclass_quote: "currency",
            quote: "ZEUR",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZEUR",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });

          iMap = iMap.set("CRO/EUR", {
            altname: "CRO/EUR",
            wsname: "CRO/EUR",
            aclass_base: "currency",
            base: "CRO",
            aclass_quote: "currency",
            quote: "ZEUR",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZEUR",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });
          
          iMap = iMap.set("IOTX/EUR", {
            altname: "IOTX/EUR",
            wsname: "IOTX/EUR",
            aclass_base: "currency",
            base: "IOTX",
            aclass_quote: "currency",
            quote: "ZEUR",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZEUR",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });

          iMap = iMap.set("KRL/EUR", {
            altname: "KRL/EUR",
            wsname: "KRL/EUR",
            aclass_base: "currency",
            base: "KRL",
            aclass_quote: "currency",
            quote: "ZEUR",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZEUR",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });
          
          iMap = iMap.set("POLY/EUR", {
            altname: "POLY/EUR",
            wsname: "POLY/EUR",
            aclass_base: "currency",
            base: "POLY",
            aclass_quote: "currency",
            quote: "ZEUR",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZEUR",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });

          iMap = iMap.set("SHIB/EUR", {
            altname: "SHIB/EUR",
            wsname: "SHIB/EUR",
            aclass_base: "currency",
            base: "SHIB",
            aclass_quote: "currency",
            quote: "ZEUR",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZEUR",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });



          iMap = iMap.set("LTC/EUR", {
            altname: "LTCEUR",
            wsname: "LTC/EUR",
            aclass_base: "currency",
            base: "XLTC",
            aclass_quote: "currency",
            quote: "ZEUR",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZEUR",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });
          iMap = iMap.set("MLN/EUR", {
            altname: "MLNEUR",
            wsname: "MLN/EUR",
            aclass_base: "currency",
            base: "XMLN",
            aclass_quote: "currency",
            quote: "ZEUR",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZEUR",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });
          iMap = iMap.set("REP/EUR", {
            altname: "REPEUR",
            wsname: "REP/EUR",
            aclass_base: "currency",
            base: "XREP",
            aclass_quote: "currency",
            quote: "ZEUR",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZEUR",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });
          iMap = iMap.set("XBT/EUR", {
            altname: "XBTEUR",
            wsname: "XBT/EUR",
            aclass_base: "currency",
            base: "XXBT",
            aclass_quote: "currency",
            quote: "ZEUR",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZEUR",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });
          iMap = iMap.set("BTC/EUR", {
            altname: "BTCEUR",
            wsname: "BTC/EUR",
            aclass_base: "currency",
            base: "XBTC",
            aclass_quote: "currency",
            quote: "ZEUR",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZEUR",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });
          iMap = iMap.set("XLM/EUR", {
            altname: "XLMEUR",
            wsname: "XLM/EUR",
            aclass_base: "currency",
            base: "XXLM",
            aclass_quote: "currency",
            quote: "ZEUR",
            lot: "unit",
            pair_decimals: 6,
            lot_decimals: 8,
            lot_multiplier: 1,
            leverage_buy: [2],
            leverage_sell: [2],
            fees: [],
            fees_maker: [],
            fee_volume_currency: "ZEUR",
            margin_call: 80,
            margin_stop: 40,
            ordermin: "10",
          });
      }
      assetPairsCoins.forEach((c, index) => {
        
        if (state.selectedExchange  !== c.exchange ) {
          return;
        }

        if (action.payload.data.data.result[c] !== undefined) {
          let item = action.payload.data.data.result[c];
    
          if (!isEUR && item && item.quote && item.quote === "ZUSD") {
            // console.log( "COIN_R: ASSET_PAIR: " + item.wsname +   ",  exchange: " +   item.exchange);

            if (iMap === undefined) {
              iMap = new I.Map();
            }
            item.coin_name = (map_coin_to_name.get(item.wsname)) ? map_coin_to_name.get(item.wsname) : item.wsname;
            item.fees = undefined;
            item.fees_maker = undefined;
            item.swl_ohlc = swlChartData;
            item.swl_ohlc.series[0].data = ['0'];
            item.swl_ohlc.options.chart.id = item.wsname;
            item.swl_lineseries = [];
            item.swlRef = useRef();

            iMap = iMap.set(item.wsname, item);
            kraken_asset_pairs_loaded = true;
          }
          else if (isEUR && item && item.quote && item.quote === "ZEUR") {
            // console.log( "COIN_R: ASSET_PAIR: " + item.wsname +   ",  exchange: " +   item.exchange);

            if (iMap === undefined) {
              iMap = new I.Map();
            }
            item.coin_name = (map_coin_to_name.get(item.wsname)) ? map_coin_to_name.get(item.wsname) : item.wsname;
            item.fees = undefined;
            item.fees_maker = undefined;
            item.swl_ohlc = swlChartData;
            item.swl_ohlc.series[0].data = ['0'];
            item.swl_ohlc.options.chart.id = item.wsname;
            item.swl_lineseries = [];
            item.swlRef = useRef();

            iMap = iMap.set(item.wsname, item);
            kraken_asset_pairs_loaded = true;
          }
        }
      });

      if (kraken_asset_pairs_loaded === true) {
        // TMD console.log("COIN_R: ASSET_PAIRS_LOADED");

        return {
          ...state,
          kraken_asset_pairs_loaded: kraken_asset_pairs_loaded,
          kraken_asset_pairs: iMap,
          // cdata: cdata, 
          // cdata_coin: cdata_coin,
        };
      } else {
        return state;
      }
    }

    case actionTypes.TRADE_NOTIFY: {
      let iMap = state.kraken_asset_pairs;
      let payload = action.payload;

      Object.keys(payload.data.data).forEach((key)=> {
          let asset = iMap.get(payload.data.data[key].pair);
          if (asset !== undefined) {
              asset.ltp = payload.data.data[key].price;
              asset.ts = payload.data.data[key].ts;
              // asset.hilo_percent = (asset.ltp - asset.daily_lo) * 100.0 / (asset.daily_hi - asset.daily_lo);
              asset.hilo_percent = (asset.ltp - asset.daily_lo) * 100.0 / (asset.daily_lo);

              if ( asset.positoin !== undefined)
              {
                asset.position.ltp = asset.ltp;
              }
              iMap = iMap.set(payload.data.data[key].pair, asset);
          }
      });

        return {
            ...state,
            kraken_asset_pairs: iMap,
        };
    }

    case actionTypes.DHL_NOTIFY: {
      let iMap = state.kraken_asset_pairs;
      let payload = action.payload;

      Object.keys(payload.data).forEach((key)=> {
          let coin = iMap.get(payload.data[key].pair);
          if (coin !== undefined) {
              coin.daily_hi = payload.data[key].high_price;
              coin.daily_lo = payload.data[key].low_price;
              coin.hilo_percent = (coin.ltp - coin.daily_lo) * 100.0 / (coin.daily_hi - coin.daily_lo);
              iMap = iMap.set(payload.data[key].pair, coin);

              // console.log( coin.wsname + ', hi: ' + coin.daily_hi + ', lo: ' + coin.daily_lo);
          }
      });

        return {
            ...state,
            kraken_asset_pairs: iMap,
        };
    }

    case actionTypes.DAILY_HILO_WL: {
      let iMap = state.kraken_asset_pairs;
      let payload = action.payload;

      Object.keys(payload.data.data).forEach((key, index)=> {
              let coin = iMap.get(payload.data.data[key].pair);
              if (coin !== undefined) {
                  coin.ltp = payload.data.data[key].curr_price;
                  coin.ts = payload.data.data[key].ts;
                  coin.daily = payload.data.data[key];
                  coin.daily_hi = payload.data.data[key].dh_price;
                  coin.daily_lo = payload.data.data[key].dl_price;
                  coin.trending_hilo_percent = payload.data.data[key].level_percent;
                  coin.hilo_percent = (coin.ltp - coin.daily_lo) * 100.0 / (coin.daily_hi - coin.daily_lo);

                  if ( coin.position !== undefined)
                  {
                    coin.position.ltp = coin.ltp;
                  }
                  iMap = iMap.set(payload.data.data[key].pair, coin);
                  // console.log( coin.wsname + ', ' + coin.ltp);
              }
      });

        return {
            ...state,
            kraken_asset_pairs: iMap,
        };
    }

    case actionTypes.COINBASE_BALANCE_HISTORY: {
        let payload = action.payload;
        let cdata = [];
        let weeks = [];
        // console.log(payload);

        Object.keys(payload.data).forEach((key, index) => {
            // key = '202101'...'202145'
            const week = map_weeks.get(key);
            weeks.push(week);
            cdata.push(balance_history.data[key]);
        });


        return {
            ...state,
            balance_history: cdata,
            weeks: weeks,
        };
    }

    case actionTypes.KRAKEN_BALANCE: {
      let newportfolio = state.newportfolio;
      if (newportfolio === undefined) {
        newportfolio = new NewPortfolio(0, 0, 0, 0, new Date());
      }
      // newportfolio.total_balance = 0.0;
      
      let payload = action.payload;
      // TMD console.log(payload);
      let posMap = state.kraken_asset_pairs;

      let pairs =  Object.keys(payload).map(key => ({ key, ...payload[key]}));

      pairs.forEach(balItem=> {
        const coin = (!isEUR) ? (balItem.key + '/USD') : (balItem.key + '/EUR');
        const item = posMap.get(coin);
        // TMD console.log( 'BAL: ' + coin + ', ' + JSON.stringify(balItem));
        if ( !isEUR && coin === "USD/USD")
        {
            // TMD console.log( 'BAL: total_cash USD: ' + balItem.cost);
            newportfolio.total_cash = balItem.cost;
        }
        if ( isEUR && coin === "EUR/EUR")
        {
            // TMD console.log( 'BAL: total_cash EUR: ' + balItem.cost);
            newportfolio.total_cash = balItem.cost;
        }

        if ( item !== undefined)
        {
            let bal = parseFloat(balItem.qty);

            if ( item.position === undefined)
            {
                item.position = {balance:0, market_price:0, invested:0, profit:0, percent:0, remaining_avg_price:0, remaining_qty:0, remaining_cost:0};
            }
            item.position.balance = bal;
            item.position.market_price = balItem.price;
            item.position.invested = Number((item.position.balance * balItem.price).toFixed(0));
            item.invested = item.position.invested;
            item.position.profit = Number((item.position.balance * (item.position.market_price - item.position.remaining_avg_price)).toFixed(0));
            item.position.percent = Number( (item.position.profit * 100.0 / item.position.invested).toFixed(2));
            newportfolio.total_balance += bal * balItem.price;
            posMap = posMap.set(coin, item);
        }
        else
        {
            // TMD console.log( 'BAL: ... ERROR: ... position === undefined for ' + balItem);
        }

      })

      return {
        ...state,
        kraken_asset_pairs: posMap,
        newportfolio: newportfolio,
      };
    }

    case actionTypes.FETCH_PORTFOLIO: {

        // TMD console.log(action.payload.data);
      let total =
        action.payload.data.total_unrealized +
        action.payload.data.total_realized -
        action.payload.data.total_fee;

      let posMap = state.kraken_asset_pairs;
    
      if (posMap === undefined) {
        // TMD console.log( "COIN_R: ERROR FETCH_PORTFOLIO: NOT SETTING ---------- ERROR");
        return { state };
      }
      let newportfolio = state.newportfolio;
      if (state.protfolio === undefined) {
        newportfolio = new NewPortfolio(
          action.payload.data.total_fee.toFixed(2),
          total.toFixed(2),
          action.payload.data.total_unrealized.toFixed(2),
          action.payload.data.total_realized.toFixed(2),
          action.payload.timestamp / 1000000
        );
      } else {
        /*
        newportfolio.total_fee = action.payload.data.total_fee.toFixed(2);
        newportfolio.total_total = total.toFixed(2);
        newportfolio.total_unreal = action.payload.data.total_unrealized.toFixed(2);
        newportfolio.total_real = action.payload.data.total_realized.toFixed(2);
        newportfolio.ts = action.payload.timestamp / 1000000;
        */
      }
      newportfolio.total_balance = 0.0;

        Object.keys(action.payload.data).forEach((c) => {
            // TMD console.log('COIN_R: POS: COIN: ' + c);
            // console.log(action.payload.data[c]);
            if (action.payload.data[c] !== undefined && 
                c !== 'total_realized' &&
                c !== 'total_unrealized' &&
                c !== 'total_fee' &&
                c !== 'exchange'
               ) {

              let total = action.payload.data[c].realized + action.payload.data[c].unrealized;
              // TMD console.log( "COIN_R: POS: " + c + ": total: " + total + ", real: " + action.payload.data[c].realized + ", unreal: " + action.payload.data[c].unrealized + ', total_balance: ' + newportfolio.total_balance);
              let np = action.payload.data[c];
              np.total = total;
              np.ltp = action.payload.data[c].market_price;

              /*
              np.balance = np.remaining_qty;
              np.invested = np.remainig_cost;
              np.profit = (np.balance * (np.market_price - np.remaining_avg_price));
              np.percent = (np.profit * 100.0 / np.invested);
              */

              // NOT HERE newportfolio.total_balance += (action.payload.data[c].remaining_cost - action.payload.data[c].unrealized);

              let item = posMap.get(c);
              if (item !== undefined) {
                item.position = np;
                item.ltp = np.ltp;

                item.position.balance = item.position.remaining_qty;
                item.balance = item.position.balance;
                // item.invested = Number(item.position.remaining_cost.toFixed(0));

                // item.position.invested = Number(item.position.remaining_cost.toFixed(0));
                // item.position.profit = Number((item.position.balance * (item.position.market_price - item.position.remaining_avg_price)).toFixed(0));
                // item.position.percent = Number( (item.position.profit * 100.0 / item.position.invested).toFixed(2));

                // TMD console.log( 'COIN_R: POS: ' + item.coin + ', ' + item.position.invested + ', ' + item.position.profit + ', ' + item.position.percent + '%');

                item.avg = item.position.remaining_avg_price;
                item.position.coin = c;
                item.coin = c;

                if ( item.trending_buy !== undefined)
                {
                    item.trending_buy.avg = item.position.remaining_avg_price;
                    item.trending_buy.balance = item.position.balance;
                }
                if (item.trending_sell !== undefined) {
                  item.trending_sell.avg =
                    item.position.remaining_avg_price;
                  item.trending_sell.balance = item.position.balance;
                }
                if (item.swl_positive !== undefined) {
                  item.swl_positive.avg =
                    item.position.remaining_avg_price;
                  item.swl_positive.balance = item.position.balance;
                }
                if (item.swl_negative !== undefined) {
                  item.swl_negative.avg =
                    item.position.remaining_avg_price;
                  item.swl_negative.balance = item.position.balance;
                }
                if (item.vel_positive !== undefined) {
                  item.vel_positive.avg =
                    item.position.remaining_avg_price;
                  item.vel_positive.balance = item.position.balance;
                }
                if (item.vel_negative !== undefined) {
                  item.vel_negative.avg =
                    item.position.remaining_avg_price;
                  item.vel_negative.balance = item.position.balance;
                }
                posMap = posMap.set(c, item);
              } else {
                console.log("COIN_R: ERROR: NewPosition not in Map, " + c);
                // TMD console.log("COIN_R: -----------------------, " + c);
              }
            }
        });
      // TMD console.log( "COIN_R: PORT: " + newportfolio.total_fee + ", " + newportfolio.total_unreal + ", " + newportfolio.total_real + ", " + newportfolio.ts);
      return {
        ...state,
        newportfolio: newportfolio,
        kraken_assset_pairs: posMap,
      };
    }

    case actionTypes.SWL_NOTIFY: {
      let payload = action.payload;
      let posMap = state.kraken_asset_pairs;


      Object.keys(payload.data.data).forEach((key)=> {
          let item = posMap.get(payload.data.data[key].symbol);
          let alerts = state.alerts;
          if (item !== undefined) {
            if (payload.data.data[key].percent > 0.0) {

              item.swl_positive = payload.data.data[key];
              if ( item.swl_positive.status !== 2)
              {
                item.swl_positive.alerted = true;
                alerts.alertsSP = alerts.alertsSP.set(payload.data.data[key].symbol, 1);
              }
              else
              {
                item.swl_positive.alerted = false;
                alerts.alertsSP = alerts.alertsSP.delete(payload.data.data[key].symbol);
              }

              posMap = posMap.set(payload.data.data[key].symbol, item);
            } else if (payload.data.data[key].percent < 0.0) {

              item.swl_negative = payload.data.data[key];
              if ( item.swl_negative.status !== 2)
              {
                item.swl_negative.alerted = true;
                alerts.alertsSN = alerts.alertsSN.set(payload.data.data[key].symbol, 1);
              }
              else
              {
                item.swl_negative.alerted = false;
                alerts.alertsSN = alerts.alertsSN.delete(payload.data.data[key].symbol);
              }

              posMap = posMap.set(payload.data.data[key].symbol, item);
            } 
            // Let's also create a single entry here.
            item.swl_single = payload.data.data[key];
            if ( item.swl_ohlc === undefined)
            {
                item.swl_ohlc = swlChartData;
                item.swl_ohlc.series[0].data = [payload.data.data[key].ohlc.close.toFixed(3)];
                item.swl_ohlc.options.chart.id = payload.data.data[key].symbol;
                item.swl_lineseries = [];
            }
            else
            {
                item.swl_ohlc.series[0].data.push(payload.data.data[key].ohlc.close.toFixed(3));
                // item.swl_lineseries.push({time: payload.data.data[key].ohlc.epoch, value: payload.data.data[key].ohlc.close.toFixed(3)});
                item.swl_lineseries.push({time: item.swl_lineseries.length, value: payload.data.data[key].ohlc.close.toFixed(3)});
            }
            // console.log( item.swl_lineseries);
            posMap = posMap.set(payload.data.data[key].symbol, item);
          }
      });

      return {
        ...state,
        kraken_asset_pairs: posMap,
      };

    }

    case actionTypes.VELOCITY_NOTIFY: {
        let payload = action.payload;
        let posMap = state.kraken_asset_pairs;

      let item = posMap.get(payload.data.symbol);
      if (item !== undefined) {
        if (payload.data.percent >= 0.0) {
          item.vel_positive = payload.data;
          item.vel_positive.alerted = true;

          let alerts = state.alerts;
          alerts.alertsVP = alerts.alertsVP.set(payload.data.symbol, 1);
        } else {
          item.vel_negative = payload.data;
          item.vel_negative.alerted = true;

          let alerts = state.alerts;
          alerts.alertsVN = alerts.alertsVN.set(payload.data.symbol, 1);
        }
        posMap.set(payload.data.symbol, item);
        return {
          ...state,
          kraken_asset_pairs: posMap,
        };
      }
      // TODO Set Alerts
      // Nothing to do
      return state;
    }

    case actionTypes.TRENDING_BOOTSTRAP: {
      let payload = action.payload;
      // TMD console.log("COIN_R: BOOT_PAYLOAD");

      if (state.kraken_asset_pairs === undefined) {
        // TMD console.log("COIN_R: BOOT_ERROR: kraken_asset_pairs not set");
        return state;
      }
      let iMap = state.kraken_asset_pairs;

      payload.data.forEach((t, index) => {
        if (t.is_buy) {
          // TRENDING_BUY
          if (t.lb_up !== undefined) {
            let move = "↑↑↑";
            if (t.lb_up === false) {
              move = "↓↓↓";
            }
            let coin = iMap.get(t.pair);
            if (coin !== undefined) {
              coin.trending_buy = {
                icon: t.pair,
                coin: t.pair,
                level_price: t.market_price,
                level_percent: t.lb_pc,
                side: "BUY",
                open_price: t.trade_price,
                qty: t.trade_qty,
                move: move,
                ts: t.timestamp,
                alerted: false,
                ltp: 0.0,
                last_trade: (t.trade_price * t.trade_qty),
              };
            }
            iMap = iMap.set(t.pair, coin);
            // TMD console.log( "COIN_R: BOOT_BUY : " + t.pair + ", lb_up: " + t.lb_up + ", lb_pc: " + t.lb_pc);
          }
        } else {
          // TRENDING_SELL
          if (t.lb_up !== undefined) {
            let move = "↑↑↑";
            if (t.lb_up === false) {
              move = "↓↓↓";
            }
            let coin = iMap.get(t.pair);
            if (coin !== undefined) {
              coin.trending_sell = {
                icon: t.pair,
                coin: t.pair,
                level_price: t.market_price,
                level_percent: t.lb_pc,
                side: "SELL",
                open_price: t.trade_price,
                qty: t.trade_qty,
                move: move,
                ts: t.timestamp,
                alerted: false,
                ltp: 0.0,
                last_trade: (t.trade_price * t.trade_qty),
              };
            }
            iMap = iMap.set(t.pair, coin);
            // TMD console.log( "COIN_R: BOOT_SELL: " + t.pair + ", lb_up: " + t.lb_up + ", lb_pc: " + t.lb_pc);
          }
        }
      });

      return { ...state, kraken_asset_pairs: iMap };
    }

    case actionTypes.TRENDING_NOTIFY_ALL: {
      let payload = action.payload;
      let posMap = state.kraken_asset_pairs;
      let alerts = state.alerts;
      // TMD console.log( payload);

      Object.keys(payload.data.data).forEach((key)=> {
          let item = posMap.get(payload.data.data[key].pair);
          if (item !== undefined) {
            if ( payload.data.data[key].side === "buy")
            {
                item.trending_buy = payload.data.data[key];
                item.trending_buy.alerted = true;
                item.trending_buy.coin = payload.data.data[key].pair;
                item.trending_buy.last_trade = item.trending_buy.qty * item.trending_buy.open_price;
                posMap = posMap.set(payload.data.data[key].pair, item);
                alerts.alertsTB = alerts.alertsTB.set(payload.data.data[key].pair, 1);
            }
            else
            {
                item.trending_sell = payload.data.data[key];
                item.trending_sell.alerted = true;
                item.trending_sell.coin = payload.data.data[key].pair;
                item.trending_sell.last_trade = item.trending_sell.qty * item.trending_sell.open_price;
                posMap = posMap.set(payload.data.data[key].pair, item);
                alerts.alertsTS = alerts.alertsTS.set(payload.data.data[key].pair, 1);
            }
          }
      });

      return {
          ...state,
          kraken_asset_pairs: posMap,
          alerts: alerts,
      };
      return state;
    }

    case actionTypes.TRENDING_NOTIFY_BUY: {
      let payload = action.payload;
      let posMap = state.kraken_asset_pairs;

      let item = posMap.get(payload.coin);
      if (item !== undefined) {
        item.trending_buy = payload;
        item.trending_buy.alerted = true;
        posMap.set(payload.coin, item);
        // TMD console.log( 'COIN_R: TRENDING_BUY: ' + payload.coin + ' ' + JSON.stringify(item));

        let alerts = state.alerts;
        alerts.alertsTB = alerts.alertsTB.set(payload.coin, 1);

        return {
          ...state,
          kraken_asset_pairs: posMap,
          alerts: alerts,
        };
      }

      return state;
    }

    case actionTypes.TRENDING_NOTIFY_SELL: {
      let payload = action.payload;
      let posMap = state.kraken_asset_pairs;

      let item = posMap.get(payload.coin);
      if (item !== undefined) {
        item.trending_sell = payload;
        item.trending_sell.alerted = true;
        posMap.set(payload.coin, item);
        // TMD console.log( 'COIN_R: TRENDINGSELL: ' + payload.coin + ' ' + JSON.stringify(item));

        let alerts = state.alerts;
        alerts.alertsTS = alerts.alertsTS.set(payload.coin, 1);

        return {
          ...state,
          kraken_asset_pairs: posMap,
          alerts: alerts,
        };
      }
      return state;
    }

    case actionTypes.RESET_ALERT_TRENDING_BUY: {
      let payload = action.payload;
      let alerts = state.alerts;
      let tMap = state.kraken_asset_pairs;
      if (alerts.alertsTB !== undefined) {
        alerts.alertsTB = alerts.alertsTB.delete(payload.coin);

        let iTB = tMap.get(payload.coin);
        if (iTB !== undefined && iTB.trending_buy !== undefined) {
          iTB.trending_buy.alerted = false;
          tMap = tMap.set(payload.coin, iTB);
        }
      }
      return {
        ...state,
        kraken_asset_pairs: tMap,
        alerts: alerts,
      };
    }

    case actionTypes.RESET_ALERT_TRENDING_SELL: {
      let payload = action.payload;
      let alerts = state.alerts;
      let tMap = state.kraken_asset_pairs;
      if (alerts.alertsTS !== undefined) {
        alerts.alertsTS = alerts.alertsTS.delete(payload.coin);

        let iTS = tMap.get(payload.coin);
        if (iTS !== undefined && iTS.trending_sell !== undefined) {
          iTS.trending_sell.alerted = false;
          tMap = tMap.set(payload.coin, iTS);
        }
      }
      return {
        ...state,
        kraken_asset_pairs: tMap,
        alerts: alerts,
      };
    }

    case actionTypes.RESET_ALERT_SWL_POSITIVE: {
      let payload = action.payload;
      let alerts = state.alerts;
      let mapSP = state.kraken_asset_pairs;
      if (alerts.alertsSP !== undefined) {
        alerts.alertsSP = alerts.alertsSP.delete(payload.coin);

        let iSP = mapSP.get(payload.coin);
        if (iSP !== undefined && iSP.swl_positive !== undefined) {
          iSP.swl_positive.alerted = false;
          mapSP = mapSP.set(payload.coin, iSP);
        }
      }
      return {
        ...state,
        kraken_asset_pairs: mapSP,
        alerts: alerts,
      };
    }

    case actionTypes.RESET_ALERT_SWL_NEGATIVE: {
      let payload = action.payload;
      let alerts = state.alerts;
      let mapSN = state.kraken_asset_pairs;
      if (alerts.alertsSN !== undefined) {
        // TMD console.log("SN delete " + payload.coin);
        alerts.alertsSN = alerts.alertsSN.delete(payload.coin);

        let iSN = mapSN.get(payload.coin);
        if (iSN !== undefined && iSN.swl_negative !== undefined) {
          iSN.swl_negative.alerted = false;
          mapSN = mapSN.set(payload.coin, iSN);
        }
      }
      return {
        ...state,
        kraken_asset_pairs: mapSN,
        alerts: alerts,
      };
    }

    case actionTypes.RESET_ALERT_VEL_POSITIVE: {
      let payload = action.payload;
      let alerts = state.alerts;
      let mapVP = state.kraken_asset_pairs;
      if (alerts.alertsVP !== undefined) {
        // TMD console.log("VP delete " + payload.coin);
        alerts.alertsVP = alerts.alertsVP.delete(payload.coin);

        let iVP = mapVP.get(payload.coin);
        if (iVP !== undefined && iVP.vel_positive !== undefined) {
          iVP.vel_positive.alerted = false;
          mapVP = mapVP.set(payload.coin, iVP);
        }
      }
      return {
        ...state,
        kraken_asset_pairs: mapVP,
        alerts: alerts,
      };
    }

    case actionTypes.RESET_ALERT_VEL_NEGATIVE: {
      let payload = action.payload;
      let alerts = state.alerts;
      let mapVN = state.kraken_asset_pairs;
      if (alerts.alertsVN !== undefined) {
        // TMD console.log("VN delete " + payload.coin);
        alerts.alertsVN = alerts.alertsVN.delete(payload.coin);

        let iVN = mapVN.get(payload.coin);
        if (iVN !== undefined && iVN.vel_negative !== undefined) {
          iVN.vel_negative.alerted = false;
          mapVN = mapVN.set(payload.coin, iVN);
        }
      }
      return {
        ...state,
        kraken_asset_pairs: mapVN,
        alerts: alerts,
      };
    }

    case actionTypes.CLEAR_SWL_POSITIVE_ALERTS: {
      let alerts = state.alerts;
      let mapSP = state.kraken_asset_pairs;
      if (alerts.alertsSP !== undefined) {
        alerts.alertsSP = alerts.alertsSP.clear();

        mapSP.forEach((item, index) => {
            if (item !== undefined && item.swl_positive !== undefined) {
                item.swl_positive.alerted = false;
                mapSP = mapSP.set(item.wsname, item);
            }
        });
      }
      return {
        ...state,
        kraken_asset_pairs: mapSP,
        alerts: alerts,
      };
    }

    case actionTypes.CLEAR_SWL_NEGATIVE_ALERTS: {
      let alerts = state.alerts;
      let mapSN = state.kraken_asset_pairs;
      if (alerts.alertsSN !== undefined) {
        alerts.alertsSN = alerts.alertsSN.clear();

        mapSN.forEach((item, index) => {
            if (item !== undefined && item.swl_negative !== undefined) {
                item.swl_negative.alerted = false;
                mapSN = mapSN.set(item.wsname, item);
            }
        });
      }
      return {
        ...state,
        kraken_asset_pairs: mapSN,
        alerts: alerts,
      };
    }

    case actionTypes.CLEAR_TRENDING_BUY_ALERTS: {
      let alerts = state.alerts;
      let tMap = state.kraken_asset_pairs;
      if (alerts.alertsTB !== undefined) {
        alerts.alertsTB = alerts.alertsTB.clear();

        tMap.forEach((item, index) => {
          if (item.trending_buy !== undefined) {
            item.trending_buy.alerted = false;
          }
        });
      }
      return {
        ...state,
        kraken_asset_pairs: tMap,
        alerts: alerts,
      };
    }

    case actionTypes.CLEAR_TRENDING_SELL_ALERTS: {
      let alerts = state.alerts;
      let tMap = state.kraken_asset_pairs;
      let nMap = new I.Map();
      if (alerts.alertsTS !== undefined) {
        alerts.alertsTS = alerts.alertsTS.clear();

        tMap.forEach((item, index) => {
          if (item.trending_sell !== undefined) {
            item.trending_sell.alerted = false;
          }
        });
      }
      return {
        ...state,
        kraken_asset_pairs: tMap,
        alerts: alerts,
      };
    }

    case actionTypes.EXPLORE_RECENT_ADD: {
        const payload = action.payload;

        let iMap = state.map_explore_recent;
        iMap = iMap.set(payload.coin, true);

        return {
            ...state,
            map_explore_recent: iMap
        }
    }

    case actionTypes.TAB_ADD_PINNED_COIN: {
        let tabs = state.explore_tabs;
        // tabs.slice(tabs.indexOf(action.payload.coin), 1);
        tabs.push( action.payload.coin);
        const items = tabs.slice(-6);

        return {
          ...state, 
          explore_tabs: items.filter((value, index, self) => self.indexOf(value) === index)
      }
    }

    case actionTypes.TAB_DEL_PINNED_COIN: {
      let tabs = state.explore_tabs;
      const index = tabs.indexOf(action.payload.coin);
      tabs.splice(index, 1); 
      // tabs.push( action.payload.coin);
      // tabs.slice(-6);

      return {
        ...state, 
        explore_tabs: tabs  
    }
    }

    default:
      return state;
  }
};

export default coinReducer;
