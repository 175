import * as actionTypes from "./actions";

 const krakenKey = window.localStorage.getItem("kraken_key") ;
 const krakenSecret = window.localStorage.getItem("kraken_sec") ;

 const coinbaseKey = window.localStorage.getItem("coinbase_key");
 const coinbaseSecret = window.localStorage.getItem("coinbase_sec");
 const coinbaseParaphrase = window.localStorage.getItem("coinbase_paraphrase");

const initialState = {
  selectedExchange: "coinbase",
  method:'api',
  exchanges: {
    configured: true,
    noExchange: {
      name: "noExchange",
      credentials: {}
    },
    kraken: {
      configured: (krakenKey && krakenSecret && true) || false,
      name: "Kraken",
      credentials: {
          key: krakenKey,
          secret: krakenSecret,
      },
    },
    coinbase: {
      // configured: (coinbaseKey && coinbaseSecret && coinbaseParaphrase && true) || false,
      configured: false, // (coinbaseKey && coinbaseSecret && coinbaseParaphrase && true) || false,
      name: "Coinbase",
      credentials: {
        accessToken: '',
        refreshToken: '',
        // key: coinbaseKey,
        // secret: coinbaseSecret,
        // paraphrase: coinbaseParaphrase,
      },
    },
  },
};

const exchangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECT_EXCHANGE: {
      return {
        ...state,
        selectedExchange: action.payload,
      };
    }

    case actionTypes.KRAKEN_SETTINGS: {
      const settings = action.payload;
      return {
        ...state,
        exchanges: {
          ...state.exchanges,
          kraken: {
            ...state.exchanges.kraken,
            credentials: settings,
            configured: true,
          },
        },
      };
    }

    case actionTypes.COINBASE_SETTINGS: {
      const settings = action.payload;
      return {
        ...state,
        exchanges: {
          ...state.exchanges,
          coinbase: {
            ...state.exchanges.coinbase,
            credentials: settings,
            configured: true,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default exchangeReducer;
