import React, { useEffect, useState } from "react";
import { gridSpacing } from "store/constant";
import { makeStyles } from "@material-ui/styles";
import { useDispatch } from "react-redux";
import { setFavorites } from "store/actions";

import { searchCoins } from "store/constant";

import TextField from "@material-ui/core/TextField";
// import Autocomplete from "@material-ui/lab/Autocomplete";

// material-ui
import {
  Autocomplete,
  Grid,
  Paper,
  InputAdornment,
  Box,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "434px",
    marginLeft: "31px",
    paddingRight: "16px",
    paddingLeft: "16px",
    "& input": {
      background: "transparent !important",
      paddingLeft: "5px !important",
    },
    [theme.breakpoints.down("lg")]: {
      width: "250px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: "4px",
      background:
        theme.palette.mode === "dark" ? theme.palette.dark[800] : "#fff",
    },
    option: {
      fontSize: 15,
      "& > span": {
        marginRight: 10,
        fontSize: 18,
      },
    },
  },
}));

const Search = () => {
  const classes = useStyles();
  // const handleChange = (e) => {
  //   console.log({ e });
  //   console.log(e.target.innerText);
  // };
  const dispatch = useDispatch();

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (event, value) => setSelectedOptions(value);

  const handleSubmit = () => console.log(selectedOptions);

  useEffect(() => {
    dispatch(setFavorites(selectedOptions));
  }, [selectedOptions]);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12} md={6} lg={4}>
        <Grid item>
          <Autocomplete
            multiple
            className={classes.root}
            disablePortal
            options={searchCoins}
            onChange={handleChange}
            // getOptionLabel={(option) => <div><span className={option.id}>{option.label}</span></div>}
            // renderOption={(option) => (
            //   <React.Fragment>
            //     <span>{option.id}</span>
            //    </React.Fragment>
            // )}
            renderInput={(params) => (
              <TextField {...params} label="Search..." />
            )}
          />

          {/* <Autocomplete
            id="country-select-demo"
            style={{ width: 300 }}
            options={countries}
            classes={{
              option: classes.option,
            }}
            autoHighlight
            getOptionLabel={(option) => option.label}
            renderOption={(option) => (
              <React.Fragment>
                <span>{countryToFlag(option)}</span>
                {option.label} ({option.code}) +{option.phone}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a country"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          /> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Search;
