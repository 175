import React from "react";
import { Grid } from "@material-ui/core";
import SubCard from "../../../../ui-component/cards/SubCard";
import { sort_by } from "./helpers";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import SmartVelocityList from "./components/SmartVelocityList";
import { sortVelNeg } from "store/actions";
const VelocityNegative = () => {
  const dispatch = useDispatch();



  const listVelNegative = useSelector((state) => {
    let items = [];

    if ( state.coins === undefined || state.coins.kraken_asset_pairs === undefined)
    {
         return items;
    }

    state.coins.kraken_asset_pairs.forEach((item) => {
        if (item.vel_negative !== undefined)
        {
            if ( item.position !== undefined)
            {
                item.vel_negative.avg = item.position.remaining_avg_price.toFixed(3);
            }
            items.push(item.vel_negative);
        }
        if (item.vel_positive !== undefined)
        {
            if ( item.position !== undefined)
            {
                item.vel_positive.avg = item.position.remaining_avg_price.toFixed(3);
            }
            items.push(item.vel_positive);
        }
    });
    return items.sort(
      sort_by(
        state.takyon.filters.mapVelNegative.item,
        state.takyon.filters.mapVelNegative.value,
        (a) => {
          if (typeof a === "string") {
            return a.toUpperCase();
          }
          return a;
        }
      )
    );

  });

  const handleSortVelNegCoin = () => dispatch(sortVelNeg("ts"));
  const handleSortVelNegTwo = () => dispatch(sortVelNeg("rate_of_change_two"));

  return (
    <Grid>
      <SubCard title="Velocity">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div onClick={handleSortVelNegCoin}>Sort TS</div>
          <div onClick={handleSortVelNegTwo}>Sort Vel</div>
        </div>
        <SmartVelocityList type="VELNegative" items={listVelNegative} />
      </SubCard>
    </Grid>
  );
};

export default React.memo(VelocityNegative);
