import axios from "axios";

export const buyOrder = async (data) => {
  const { coin, accountId, paymentMethod, amount, accessToken } = data;
  const { commit } = data || true;

  const response = await axios.post(
    `https://api.coinbase.com/v2/accounts/${accountId}/buys`,
    {
      amount,
      currency: coin,
      payment_method: paymentMethod,
      // commit: true,
      commit,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "CB-VERSION": "2021-12-13",
      },
    }
  );
  return { accountId, confirm: response.data.id, payload: response.data };
};

export const sellOrder = async (data) => {
  const { coin, accountId, paymentMethod, amount, accessToken } = data;
  const { commit } = data || true;

  const response = await axios.post(
    `https://api.coinbase.com/v2/accounts/${accountId}/sells`,
    {
      amount,
      currency: coin,
      payment_method: paymentMethod,
      commit,
      // commit: false, // !!
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "CB-VERSION": "2021-12-13",
      },
    }
  );
  return { accountId, confirm: response.data.id, payload: response.data };
};

export const commitOrder = async (data) => {
  const { accountId, buyId, accessToken, resource } = data;

  const side = resource === "buy" ? "buys" : "sells";

  const response = await axios.post(
    `https://api.coinbase.com/v2/accounts/${accountId}/${side}/${buyId}/commit`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "CB-VERSION": "2021-12-13",
      },
    }
  );

  return { payload: response.data };
};

export const fetchPaymetnMethods = async ({ accessToken }) => {
  // const accessToken = "1234";
  const paymentsMethodconfig = {
    method: "get",
    url: "https://api.coinbase.com/v2/payment-methods",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "CB-VERSION": "2021-12-13",
    },
  };

  const response = await axios(paymentsMethodconfig);

  return response.data;
};
