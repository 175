import React from "react";
import BasketCard from "./components/BasketCard";
import { useSelector } from "react-redux";

const Basket = () => {
  const positionList = useSelector((state) => {
    let coinlist = [];
    let items = state.coins.kraken_asset_pairs;
    items.forEach((item) => {
        if ( item.position === undefined)
        {
            // item.position = { invested: 100, remaining_avg_price: 0, price: item.ltp};
        }
        coinlist = coinlist.concat(item);
    });
    return coinlist;
  });

  return <BasketCard title="BasketCard" rows={positionList} />;
};

export default React.memo(Basket);
