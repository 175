import React from "react";
import MobileBasketCard from "./components/MobileBasketCard";
import { useSelector } from "react-redux";

const MobileBasket = () => {
  const positionList = useSelector((state) => {
    let coinlist = [];
    let items = state.coins.kraken_asset_pairs;
    items.forEach((item) => {
        if ( item.position === undefined)
        {
            // item.position = { invested: 100, remaining_avg_price: 0, price: item.ltp};
        }
        coinlist = coinlist.concat(item);
    });
    return coinlist;
  });

  return <MobileBasketCard title="BasketCard" rows={positionList} />;
};

export default React.memo(MobileBasket);
