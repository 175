import React from "react";
import { Box, Typography } from "@material-ui/core";
import Quotes from "./components/Quotes";

 
// ================================|| UI TABS - ICONS ||================================ //

const QuotesPanel = () => {
  return (
    // <TabPanel>
      <div role="tabpanel1">
        <Box
          sx={{
            height: '40px'
          }}
        >
          <Typography>
            <Quotes />
          </Typography>
        </Box>
      </div>
    // </TabPanel>
  );
};

export default React.memo(QuotesPanel);
