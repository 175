import { Grid } from "@material-ui/core";
import React from "react";
import TrendingBuy from "../TrendingBuySell/TrendingBuy";
import TrendingBuySummary from "../TrendingBuySell/TrendingBuySummary";
import CenterLayout from "../CenterLayout";
import CandleChart from "../CandleChart";
import LiteOrderTicket from "ui-component/cards/LiteOrderTicket";
import SummaryCard from "ui-component/cards/SummaryCard";

const TrendingBuyLayout = () => {
  return (
    <Grid container spacing={2.0}>

        <Grid item xs={9.5} alignItems="center">
          <CandleChart />
          <Grid container padding={1.0} />
          <TrendingBuy />
        </Grid>

        <Grid item xs={2.5} alignItems="center">
          <SummaryCard />
          <Grid container padding={1.0} />
          <LiteOrderTicket />
          <Grid container padding={1.0} />
          <TrendingBuySummary />
        </Grid>

    </Grid>
  );
};

export default TrendingBuyLayout;
