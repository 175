import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles';
import {
    Avatar,
    Box,
    ButtonBase,
    ClickAwayListener,
    Grid,
    List,
    ListItemText,
    ListItemIcon,
    Paper,
    Popper,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import ListItemButton from '@material-ui/core/ListItemButton';

// project imports
import Transitions from 'ui-component/extended/Transitions';
import * as actionTypes from 'store/actions';

// assets
import TranslateTwoToneIcon from '@material-ui/icons/TranslateTwoTone';
import krakenLogo from 'assets/images/exchanges/kraken.png';
import coinbaseLogo from 'assets/images/exchanges/coinbase.png';


const useStyles = makeStyles((theme) => ({
    navContainer: {
        width: '100%',
        minWidth: '200px',
        maxWidth: '280px',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '10px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '250px'
        }
    },
    headerAvatar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        color: theme.palette.primary.dark,
        transition: 'all .2s ease-in-out',
        '&[aria-controls="menu-list-grow"],&:hover': {
            borderColor: theme.palette.primary.main,
            background: theme.palette.primary.main,
            color: theme.palette.primary.light
        }
    },
    box: {
        marginLeft: '16px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '8px'
        }
    }
}));

// ===========================|| LOCALIZATION ||=========================== //
const exchangeLogos ={
    kraken : krakenLogo,
    coinbase: coinbaseLogo
}

const ExchangeSection = () => {
    const classes = useStyles();
    const customization = useSelector((state) => state.customization);
    const [selectedExchange, exchanges] = useSelector((state) => [state.exchange.selectedExchange,state.exchange.exchanges ]);
    const dispatch = useDispatch();

    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('sm'));

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [language, setLanguage] = React.useState(customization.locale);

    const handleListItemClick = (key) => {
        dispatch({ type: actionTypes.SELECT_EXCHANGE, payload: key });
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);


    return (
        <>
            <Box component="span" className={classes.box}>
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    <Avatar
                        variant="rounded"
                        className={classes.headerAvatar}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="inherit"
                    >
                         <img src={exchangeLogos[selectedExchange]} alt="" width="30" height="30"/>
                    </Avatar>
                </ButtonBase>
            </Box>

            <Popper
                placement={matchesXs ? 'bottom-start' : 'bottom'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 0 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position="top" in={open} {...TransitionProps}>
                        <Paper elevation={16}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <List component="nav" className={classes.navContainer}>
                                {
                                    Object.keys(exchanges).map(key => (

                                          <ListItemButton selected={language === 'en'} onClick={(event) => handleListItemClick(key)}>
                                           <ListItemIcon>
               <img src={exchangeLogos[key]} alt="" width="34" height="34"/>
              </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Grid container>
                                                  {/* <Avatar
                        variant="rounded"
                        className={classes.headerAvatar}
                         aria-haspopup="true"
                         color="inherit"
                    >
                         <img src={exchangeLogos[key]} alt="" width="34" height="34"/>
                    </Avatar> */}

                                                    <Typography color="textPrimary">{exchanges[key].name}</Typography>
                                                    {/* <Typography variant="caption" color="textSecondary" sx={{ ml: '8px' }}>
                                                        (UK)
                                                    </Typography> */}
                                                </Grid>
                                            }
                                        />
                                    </ListItemButton>
                                    ))
                                }
                                     
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default ExchangeSection;
