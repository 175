import React, { useContext, useState } from "react";
import SummaryCard from "ui-component/cards/SummaryCard";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import CoinContext from "contexts/CoinContext";
import IconCard from "./IconCard";
import { gridSpacing } from "store/constant";


// ================================|| UI TABS - ICONS ||================================ //

const Quotes = () => {

  const { coin } = useContext(CoinContext);

  const firstPair = useSelector((state) => {
    return ((state.coins.kraken_asset_pairs_loaded && state.coins.kraken_asset_pairs.get(coin) && state.coins.kraken_asset_pairs.get(coin).ltp) || "0.0");
  });

  const btcUsd = useSelector((state) => {
    return ((state.coins.kraken_asset_pairs_loaded && state.coins.kraken_asset_pairs.get('XBT/USD') && state.coins.kraken_asset_pairs.get("XBT/USD").ltp) || "0.0");
  });

  const ethUsd = useSelector((state) => {
    return ((state.coins.kraken_asset_pairs_loaded && state.coins.kraken_asset_pairs.get('ETH/USD') && state.coins.kraken_asset_pairs.get("ETH/USD").ltp) || "0.0");
  });

  /*
  const dogeUsd = useSelector((state) => {
    return ((state.coins.kraken_asset_pairs_loaded && state.coins.kraken_asset_pairs.get('XDG/USD') && state.coins.kraken_asset_pairs.get("XDG/USD").ltp) || "0.0");
  });
  */

  return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="right"
      >
        <div>
          <Grid container sx={{padding: '15px'}} spacing={"30"}>
            <Grid item>
              <IconCard sx={{padding: '5px'}} pair="BTC/USD" price={btcUsd} />
            </Grid>
            <Grid item>
              <IconCard sx={{padding: '5px'}} pair="ETH/USD" price={ethUsd} />
            </Grid>
            {/*
            <Grid item>
              <IconCard sx={{padding: '5px'}} pair="DOGE/USD" price={dogeUsd} />
            </Grid>
            */}
          </Grid>
        </div>

        {/*
        <div>
          <Grid container sx={{padding: '0px'}} spacing={gridSpacing}>
            <Grid item>
              <SummaryCard sx={{alignItems: 'top'}} />
            </Grid>
          </Grid>
        </div>
        */}
      </Grid>
  );
};

export default React.memo(Quotes);
