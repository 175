import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import useAuth from "hooks/useAuth";

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import { AppBar, CssBaseline, Toolbar, useMediaQuery } from "@material-ui/core";
import { openOrders } from "../../handlers/index";

// third-party
import clsx from "clsx";

// project imports
import Breadcrumbs from "ui-component/extended/Breadcrumbs";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Customization from "../Customization";
import { drawerWidth } from "store/constant";
import { SET_MENU, addVelocityNotify } from "store/actions";
import useKraken from "hooks/useKraken";
import KrakenTokenModal from "./KrakenTokenModal";
import CoinbaseTokenModal from "./CoinbaseTokenModal";

// assets
import { IconChevronRight } from "@tabler/icons";
// eslint-disable-next-line import/no-unresolved
import useSocket from "hooks/useSocket";
import useOrders from "hooks/useOrders";
import { addOrder } from "emitters";

// import useTrades from "hooks/useTrades";
// import usePortfolio from "hooks/usePortfolio";
// import useBalance from "hooks/useBalance";
// import useKrakenAssetPairs from "hooks/useKrakenAssetPairs";
// import useCoinbaseAssetPairs from "hooks/useCoinbaseAssetPairs";
import CoinContext from "contexts/CoinContext";
import ModalSearch from "ui-component/ModalSearch";

// style constant
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // backgroundColor: 'red'
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
  },
  appBarWidth: {
    transition: theme.transitions.create("width"),
    backgroundColor: theme.palette.background.default,
  },
  content: {
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("md")]: {
      marginLeft: "10px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "0px",
      marginRight: "10px",
    },
    backgroundColor: theme.palette.background.default,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
    },
  },
}));

// ===========================|| MAIN LAYOUT ||=========================== //

const MobileLiteTakyonLayout = () => {
  const [coin, setCoin] = React.useState("BTC/USD");

  const coinValue = useMemo(() => ({ coin, setCoin }), [coin]);

  const dispatch = useDispatch();

  const { user } = useAuth();

  // const isConnected = false;

  const { isConnected, reConnect } = useSocket();

  // useEffect(() => {
  //   let reconnectTimer = setInterval(() => {

  //     console.log(isConnected);
  //     if (!isConnected) {
  //       reConnect();
  //     }
  //   }, 20000);

  //   return () => {
  //     clearTimeout(reconnectTimer);
  //   };
  // }, [isConnected, reConnect]);


  //Temporarry disable kraken. move to Sagas!
  // const { renewKrakenToken } = useKraken();

  // useEffect(() => {
  //   if (isConnected) {
  //     renewKrakenToken();
  //   }
  // }, [isConnected]);

  useEffect(() => {
    dispatch({
      type: "INIT_DATA",
      payload: {
        start: new Date("May 31, 2021 23:59:59"),
        end: new Date("December 31, 2021 23:59:59"),
      },
    });
  }, []);

  const ohlcInterval = useSelector((state) => state.chart.ohlcInterval);

  useEffect(() => {
    dispatch({
      type: "FETCH_OHLC",
      payload: {
        rcoin: coin,
        interval: ohlcInterval,
      },
    });
  }, [coin, ohlcInterval]);

  //Fetch balance every 30 sec.
  useEffect(() => {
    dispatch({
      type: "FETCH_PORTFOLIO",
      payload: {
        start: new Date("May 31, 2021 23:59:59"),
        end: new Date("December 31, 2021 23:59:59"),
      },
    });

    let balanceTimer = setInterval(() => {
      dispatch({
        type: "FETCH_PORTFOLIO",
        payload: {
          start: new Date("Feb 28, 2021 23:59:59"),
          end: new Date("December 31, 2021 23:59:59"),
        },
      });
    }, 60000);

    return () => {
      clearTimeout(balanceTimer);
    };
  }, [dispatch]);

  // fetch portfolio, then balance!

  const classes = useStyles();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => {
    let item = state.customization.opened;
    return state.customization.opened;
  });
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  // React.useEffect(() => {
  //   dispatch({ type: SET_MENU, opened: !matchDownMd });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [matchDownMd]);

  return (
    <div className={classes.root}>
      <CoinContext.Provider value={coinValue}>
        <CssBaseline />
        {/* drawer */}
        <Sidebar
          drawerOpen={leftDrawerOpened}
          drawerToggle={handleLeftDrawerToggle}
        />

        {/* main content */}
        <main
          className={clsx([
            classes.content,
            {
              [classes.contentShift]: leftDrawerOpened,
            },
          ])}
        >
          <Outlet />
        </main>
        {/* <Customization /> */}
        <KrakenTokenModal />
        <ModalSearch />
        {/* // replaced with Oauth */}
        {/* <CoinbaseTokenModal /> */}
      </CoinContext.Provider>
    </div>
  );
};

export default MobileLiteTakyonLayout;
