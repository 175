import useOrders from "hooks/useOrders";

// eslint-disable-next-line react-hooks/rules-of-hooks
const { orders } = useOrders();

// action - account reducer
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const ACCOUNT_INITIALIZE = "ACCOUNT_INITIALIZE";
export const FIREBASE_STATE_CHANGED = "FIREBASE_STATE_CHANGED";

// action - cards reducer
export const SET_VIRTUAL_CARD = "@cards/SET_VIRTUAL_CARD";
export const DEL_VIRTUAL_CARD = "@cards/DEL_VIRTUAL_CARD";
export const UPDATE_VIRTUAL_CARD = "@cards/UPDATE_VIRTUAL_CARD";
export const SET_VIRTUAL_CARD_DIALOG_STATUS = "@cards/SET_VIRTUAL_CARD_DIALOG_STATUS";
export const SET_VIRTUAL_CARD_LIMITS = "@cards/SET_VIRTUAL_CARD_LIMITS";

// action - customization reducer
export const SET_MENU = "@customization/SET_MENU";
export const MENU_TOGGLE = "@customization/MENU_TOGGLE";
export const MENU_OPEN = "@customization/MENU_OPEN";
export const MENU_TYPE = "@customization/MENU_TYPE";
export const PRESET_COLORS = "@customization/PRESET_COLORS";
export const THEME_LOCALE = "@customization/THEME_LOCALE";
export const THEME_RTL = "@customization/THEME_RTL";
export const SET_FONT_FAMILY = "@customization/SET_FONT_FAMILY";
export const SET_BORDER_RADIUS = "@customization/SET_BORDER_RADIUS";
export const SET_OUTLINED_FILLED = "@customization/SET_OUTLINED_FILLED";

// action - snackbar
export const SNACKBAR_OPEN = "@snackbar/SNACKBAR_OPEN";

// action - cart

export const MOBILE_CHART_OPENED = "@cart/MOBILE_CHART_OPENED";
export const MOBILE_LITE_ORDER_TICKET = "@cart/MOBILE_LITE_ORDER_TICKET";
export const ADD_PRODUCTS = "@cart/ADD_PRODUCTS";
export const REMOVE_PRODUCT = "@cart/REMOVE_PRODUCT";
export const UPDATE_QUANTITY = "@cart/UPDATE_QUANTITY";
export const NEXT_STEP = "@cart/NEXT_STEP";
export const BACK_STEP = "@cart/BACK_STEP";
export const SET_STEP = "@cart/SET_STEP";
export const SET_BILLING_ADDRESS = "@cart/SET_BILLING_ADDRESS";
export const SET_DISCOUNT = "@cart/SET_DISCOUNT";
export const SET_SHIPPING_CHARGE = "@cart/SET_SHIPPING_CHARGE";
export const SET_PAYMENT_METHOD = "@cart/SET_PAYMENT_METHOD";
export const SET_PAYMENT_CARD = "@cart/SET_PAYMENT_CARD";
export const RESET_CART = "@cart/RESET_CART";

// action - takyon

// trade_notify
// bbo_notify
// daily_hilo_wl
// notify_pnl
// swl_notify
// trending_notify

export const KRAKEN_BALANCE = "@takyon/KRAKEN_BALANCE";
export const COINBASE_BALANCE_HISTORY = "@takyon/COINBASE_BALANCE_HISTORY";
export const KRAKEN_ASSET_PAIRS = "@takyon/KRAKEN_ASSET_PAIRS";
export const EXPLORE_RECENT_ADD = "@takyon/EXPLORE_RECENT_ADD";
export const COINBASE_ASSET_PAIRS = "@takyon/COINBASE_ASSET_PAIRS";
export const TRADE_NOTIFY = "@takyon/TRADE_NOTIFY";
export const BBO_NOTIFY = "@takyon/BBO_NOTIFY";
export const DAILY_HILO_WL = "@takyon/DAILY_HILO_WL";
export const DHL_NOTIFY = "@takyon/DHL_NOTIFY";
export const NOTIFY_PNL = "@takyon/NOTIFY_PNL";
export const SWL_NOTIFY = "@takyon/SWL_NOTIFY";
export const VELOCITY_NOTIFY = "@takyon/VELOCITY_NOTIFY";
export const DIVEBAR_OHLC = "@takyon/DIVEBAR_OHLC";
export const KRAKEN_OHLC = "@takyon/KRAKEN_OHLC";
export const KRAKEN_OHLC_INTERVAL = "@takyon/KRAKEN_OHLC_INTERVAL";
export const SET_DIVEBAR_OHLC_INTERVAL = "@takyon/SET_DIVEBAR_OHLC_INTERVAL";
export const SET_DIVEBAR_COIN = "@takyon/SET_DIVEBAR_COIN";
export const SET_DIVEBAR_COIN_AND_INTERVAL =
  "@takyon/SET_DIVEBAR_COIN_AND_INTERVAL";

// export const TRENDING_NOTIFY = "@takyon/TRENDING_NOTIFY";
export const TRENDING_BOOTSTRAP = "@takyon/TRENDING_BOOTSTRAP";
export const TRENDING_NOTIFY_BUY = "@takyon/TRENDING_NOTIFY_BUY";
export const TRENDING_NOTIFY_SELL = "@takyon/TRENDING_NOTIFY_SELL";
export const TRENDING_NOTIFY_ALL = "@takyon/TRENDING_NOTIFY_ALL";
export const FETCH_PORTFOLIO = "@takyon/FETCH_PORTFOLIO";
export const FETCH_TRADES = "@takyon/FETCH_TRADES";
export const ADD_TRADE = "@takyon/ADD_TRADE";
export const SET_LAST_TRADE = "@takyon/SET_LAST_TRADE";

export const TAB_ADD_PINNED_COIN = "@takyon/TAB_ADD_PINNED_COIN";
export const TAB_DEL_PINNED_COIN = "@takyon/TAB_DEL_PINNED_COIN";

// reset Alerts
export const RESET_ALERT_TRENDING_BUY = "@takyon/RESET_ALERT_TRENDING_BUY";
export const RESET_ALERT_TRENDING_SELL = "@takyon/RESET_ALERT_TRENDING_SELL";
export const RESET_ALERT_SWL_POSITIVE = "@takyon/RESET_ALERT_SWL_POSITIVE";
export const RESET_ALERT_SWL_NEGATIVE = "@takyon/RESET_ALERT_SWL_NEGATIVE";
export const RESET_ALERT_VEL_POSITIVE = "@takyon/RESET_ALERT_VEL_POSITIVE";
export const RESET_ALERT_VEL_NEGATIVE = "@takyon/RESET_ALERT_VEL_NEGATIVE";

export const CLEAR_TRENDING_BUY_ALERTS = "@takyon/CLEAR_TRENDING_BUY_ALERTS";
export const CLEAR_TRENDING_SELL_ALERTS = "@takyon/CLEAR_TRENDING_SELL_ALERTS";

export const CLEAR_SWL_POSITIVE_ALERTS = "@takyon/CLEAR_SWL_POSITIVE_ALERTS";
export const CLEAR_SWL_NEGATIVE_ALERTS = "@takyon/CLEAR_SWL_NEGATIVE_ALERTS";

export const SORT_LAST_BUY_COIN = "@takyon/SORT_LAST_BUY_COIN";
export const SORT_LAST_SELL_COIN = "@takyon/SORT_LAST_SELL_COIN";

export const SORT_LAST_BUY_PERCENT = "@takyon/SORT_LAST_BUY_PERCENT";
export const SORT_LAST_SELL_PERCENT = "@takyon/SORT_LAST_SELL_PERCENT";

export const SORT_VEL_POS_COIN = "@takyon/SORT_VEL_POS_COIN";
export const SORT_VEL_NEG_COIN = "@takyon/SORT_VEL_NEG_COIN";

export const SORT_VEL_POS_TWO = "@takyon/SORT_VEL_POS_TWO";
export const SORT_VEL_NEG_TWO = "@takyon/SORT_VEL_NEG_TWO";

export const SORT_SWL_POS_TS = "@takyon/SWL_POS_TS";
export const SORT_SWL_NEG_TS = "@takyon/SWL_NEG_TS";

export const SORT_LAST_BUY = "@takyon/SORT_LAST_BUY";
export const SORT_LAST_SELL = "@takyon/SORT_LAST_SELL";
export const TRADES_TABLE = "@takyon/TRADES_TABLE";

export const SORT_VEL_POS = "@takyon/SORT_VEL_POS";
export const SORT_VEL_NEG = "@takyon/SORT_VEL_NEG";

export const ORDER_TICKET = "@takyon/ORDER_TICKET";
export const ORDER_TICKET_LITE = "@takyon/ORDER_TICKET_LITE";
export const SET_FAVORITES = "@takyon/SET_FAVORITES";
export const ADD_FAVORITES = "@takyon/ADD_FAVORITES";
export const REMOVE_FAVORITES = "@takyon/REMOVE_FAVORITES";

export const SELECT_EXCHANGE = "@takyon/SELECT_EXCHANGE";
export const KRAKEN_SETTINGS = "@takyon/KRAKEN_SETTINGS";
export const COINBASE_SETTINGS = "@takyon/COINBASE_SETTINGS";

export const SET_MODAL_SEARCH_STATUS = "@takyon/SET_MODAL_SEARCH_STATUS";
export const SET_COINBASE_QUOTES = "@takyon/SET_COINBASE_QUOTES";
export const SET_COINBASE_ACCOUNTS = "@takyon/SET_COINBASE_ACCOUNTS";
export const SET_COINBASE_PAYMENT_METHODS =
  "@takyon/SET_COINBASE_PAYMENT_METHODS";
export const SELECT_COINBASE_PAYMENT_METHOD =
  "@takyon/SELECT_COINBASE_PAYMENT_METHOD";

export const SET_MODAL_COINBASE_PAYMENTMETHOD_STATUS =
  "@takyon/SET_MODAL_COINBASE_PAYMENTMETHOD_STATUS";
export const SET_MODAL_COINBASE_CONFIRM_STATUS =
  "@takyon/SET_MODAL_COINBASE_CONFIRM_STATUS";
export const SET_MODAL_COINBASE_CONFIRM_RESPONSE =
  "@takyon/SET_MODAL_COINBASE_CONFIRM_RESPONSE";

export const COINBASE_BUY = "@takyon/COINBASE_BUY";
export const COINBASE_SELL = "@takyon/COINBASE_SELL";
export const COINBASE_COMMIT = "@takyon/COINBASE_COMMIT";

export const SET_DIVEBARCLUB_MODAL_STATUS =
  "@takyon/SET_DIVEBARCLUB_MODAL_STATUS";
export const SET_DIVEBARCLUB_ROOM = "@takyon/SET_DIVEBARCLUB_ROOM";
export const SET_DIVEBARCLUB_CHANNELS = "@takyon/SET_DIVEBARCLUB_CHANNELS";
export const SET_DIVEBARCLUB_MESSAGE = "@takyon/SET_DIVEBARCLUB_MESSAGE";
export const SET_DIVEBARCLUB_USER = "@takyon/SET_DIVEBARCLUB_USER";
export const SET_DIVEBARCLUB_USERS = "@takyon/SET_DIVEBARCLUB_USERS";
export const DIVEBARCLUB_CURRENCY_SENT = "@takyon/DIVEBARCLUB_CURRENCY_SENT";
export const DIVEBARCLUB_CURRENCY_GET_ALL =
  "@takyon/DIVEBARCLUB_CURRENCY_GET_ALL";

export const SET_DIVEBARCLUB_MAIN_CHANNEL = "@takyon/SET_DIVEBARCLUB_MAIN_CHANNEL";
export const DIVEBARCLUB_CURRENCY_CHANNEL = "@takyon/DIVEBARCLUB_CURRENCY_CHANNEL";
export const SET_FREEZE_VIRTUAL_CARD = "@takyon/SET_FREEZE_VIRTUAL_CARD";

export const OrderTicketTypes = {
  CLEAR: "CLEAR",
  PLACING: "PLACING",
  ERROR: "ERROR",
  TIMEOUT: "TIMEOUT",
  PLACED: "",
};

export function addTradeNotify(trending) {
  return { type: TRADE_NOTIFY, payload: trending };
}

export function addBboNotify(trending) {
  const payload = {
    icon: trending.data.icon,
    coin: trending.data.pair,
    bidq: trending.data.bidq,
    bidp: trending.data.bidp,
    askq: trending.data.askq,
    askp: trending.data.askp,
    ts: trending.data.ts * 1000,
  };

  return { type: BBO_NOTIFY, payload };
}

export function addDailyHiloWl(trending) {
  return { type: DAILY_HILO_WL, payload: trending };
}

export function addNotifyPnl(trending) {
  const payload = trending;

  return { type: NOTIFY_PNL, payload };
}

export function addSwlNotify(trending) {
  const payload = trending;

  return { type: SWL_NOTIFY, payload };
}

export function addVelocityNotify(trending) {
  const payload = trending;

  return { type: VELOCITY_NOTIFY, payload };
}

export function addTrendingNotify({ data }) {
  const type = data.side === "buy" ? TRENDING_NOTIFY_BUY : TRENDING_NOTIFY_SELL;

  // console.log(data);
  const payload = {
    icon: data.icon,
    coin: data.pair,
    price: data.curr_price,
    percent: data.level_percent,
    side: data.side,
    position: data.open_price,
    qty: data.qty,
    move: data.move,
    ts: data.ts * 1000,
  };

  return { type, payload };
}

export function addTrendingBootstrap(payload) {
  return { type: TRENDING_BOOTSTRAP, payload };
}

export function addPortfolio(payload) {
  return { type: FETCH_PORTFOLIO, payload };
}

export function addTrades(payload) {
  return { type: FETCH_TRADES, payload };
}

export function setLastTrade(payload) {
  return { type: SET_LAST_TRADE, payload };
}

export function addKrakenOHLC(coin, payload) {
  return { type: KRAKEN_OHLC, payload: { coin: coin, data: payload } };
}

export function addKrakenAssetPairs(payload) {
  return { type: KRAKEN_ASSET_PAIRS, payload: { data: payload } };
}

export function addCoinbaseAssetPairs(payload) {
  return { type: COINBASE_ASSET_PAIRS, payload: { data: payload } };
}

export function addExploreRecent(payload) {
  return { type: EXPLORE_RECENT_ADD, payload: { coin: payload } };
}

export function addBalance(payload) {
  return { type: KRAKEN_BALANCE, payload };
}

export function sortLastBuy(payload) {
  return { type: SORT_LAST_BUY, payload };
}

export function sortLastSell(payload) {
  return { type: SORT_LAST_SELL, payload };
}

export function sortSwlPosTs(payload) {
  return { type: SORT_SWL_NEG_TS, payload };
}

export function sortSwlNegTs(payload) {
  return { type: SORT_SWL_POS_TS, payload };
}

export function sortVelPos(payload) {
  return { type: SORT_VEL_POS, payload };
}

export function sortVelNeg(payload) {
  return { type: SORT_VEL_NEG, payload };
}

export function getTradesTable() {
  //
  let data = orders.getOrders(); //todo!
  console.log({ data });
  return { type: TRADES_TABLE, payload: data };
}

export function placeOrderTicket(userRef) {
  const data = {
    status: OrderTicketTypes.PLACING,
    blocked: true,
    reason: "",
    userRef,
  };
  console.log({ data });
  return { type: ORDER_TICKET, payload: data };
}

export function orderTicketPlaced() {
  const data = {
    status: OrderTicketTypes.PLACED,
    blocked: false,
    reason: "",
    userRef: "",
  };
  return { type: ORDER_TICKET, payload: data };
}

export function orderTicketError(reason) {
  const data = {
    status: OrderTicketTypes.ERROR,
    blocked: false,
    reason,
    userRef: "",
  };
  return { type: ORDER_TICKET, payload: data };
}

export function orderTicketTimeout(userRef) {
  const data = {
    status: OrderTicketTypes.TIMEOUT,
    blocked: false,
    reason: "Order ticket Timeout",
    userRef,
  };

  return { type: ORDER_TICKET, payload: data };
}

export function setFavorites(payload) {
  return { type: SET_FAVORITES, payload };
}

export function addToFavorites(payload) {
  return { type: ADD_FAVORITES, payload };
}

export function removeFromFavorites(payload) {
  return { type: REMOVE_FAVORITES, payload };
}

export function selectExchange(payload) {
  return { type: SELECT_EXCHANGE, payload };
}

export function krakenSettingsExchange(payload) {
  return { type: KRAKEN_SETTINGS, payload };
}

export function coinbaseSettingsExchange(payload) {
  return { type: COINBASE_SETTINGS, payload };
}

export function clearTrendingBuyAlerts() {
  return { type: CLEAR_TRENDING_BUY_ALERTS };
}

export function clearTrendingSellAlerts() {
  return { type: CLEAR_TRENDING_SELL_ALERTS };
}

export function setKrakenInterval(ohlcInterval) {
  return { type: KRAKEN_OHLC_INTERVAL, payload: { ohlcInterval } };
}

export function setDiveBarInterval(ohlcInterval) {
  return { type: SET_DIVEBAR_OHLC_INTERVAL, ohlcInterval };
}

export function setDiveBarCoin(coin) {
  return { type: SET_DIVEBAR_COIN, coin };
}

export function setDiveBarCoinAndInterval(coin, ohlcInterval) {
  return {
    type: SET_DIVEBAR_COIN_AND_INTERVAL,
    payload: { coin, ohlcInterval },
  };
}

export function setOrderTicketLite(tabValue, total) {
  return {
    type: ORDER_TICKET_LITE,
    payload: {
      tabValue,
      total,
    },
  };
}

export function setModalSearchStatus(isOpen) {
  return { type: SET_MODAL_SEARCH_STATUS, isOpen };
}

export function setModalCoinbasePaymentmethodStatus(isOpen) {
  return { type: SET_MODAL_COINBASE_PAYMENTMETHOD_STATUS, isOpen };
}

export function setModalCoinbaseConfirmStatus(isOpen) {
  return { type: SET_MODAL_COINBASE_CONFIRM_STATUS, isOpen };
}

export function setModalDiveBarClubStatus(isOpen) {
  return { type: SET_DIVEBARCLUB_MODAL_STATUS, isOpen };
}

export function setDiveBarClubRoomId(roomId) {
  return { type: SET_DIVEBARCLUB_ROOM, roomId };
}

export function setDiveBarClubMainChannel(channel) {
  return { type: SET_DIVEBARCLUB_MAIN_CHANNEL, channel };
}

export function setDiveBarClubUser(userId) {
  return { type: SET_DIVEBARCLUB_USER, userId };
}

export function setDiveBarClubUsers(users, channelId) {
  return { type: SET_DIVEBARCLUB_USERS, payload: { users, channelId } };
}

export function setDiveBarClubChannels(channels) {
  return { type: SET_DIVEBARCLUB_CHANNELS, channels };
}

export function setDiveBarMessage(message) {
  return { type: SET_DIVEBARCLUB_MESSAGE, message };
}

export function currencySent() {
  return { type: DIVEBARCLUB_CURRENCY_SENT };
}

export function currencyGetAllResponse(payload) {
  return { type: DIVEBARCLUB_CURRENCY_GET_ALL, payload };
}
export function currencyGetChannelResponse(payload) {
  return { type: DIVEBARCLUB_CURRENCY_CHANNEL, payload };
}

export function setModalCoinbaseResponse(response) {
  return { type: SET_MODAL_COINBASE_CONFIRM_RESPONSE, response };
}

export function setCoinbaseQutes(payload) {
  return { type: SET_COINBASE_QUOTES, payload };
}

export function setCoinbaseAccounts(payload) {
  return { type: SET_COINBASE_ACCOUNTS, payload };
}

export function fetchCoinbaseAccounts(accessToken) {
  return { type: "FETCH_COINBASE_ACCOUNTS", accessToken };
}

export function fetchCoinbasePaymentMethods(accessToken) {
  return { type: "FETCH_COINBASE_PAYMENT_METHODS", accessToken };
}

export function selectCoinbasePaymentMethod(item) {
  return { type: SELECT_COINBASE_PAYMENT_METHOD, item };
}

export function coinbaseBuy(data) {
  return { type: COINBASE_BUY, payload: data };
}
export function coinbaseCommit(data) {
  return { type: COINBASE_COMMIT, payload: data };
}

export function coinbaseSell(data) {
  return { type: COINBASE_SELL, payload: data };
}

export function setVirtualCard(data) {
  return { type: SET_VIRTUAL_CARD, payload: data };
}

export function delVirtualCard(data) {
  return { type: DEL_VIRTUAL_CARD, payload: data };
}

export function updateVirtualCard(data) {
  return { type: UPDATE_VIRTUAL_CARD, payload: data };
}

export function setModalVirtualCardDialogStatus(isOpen, last) {
  return { type: SET_VIRTUAL_CARD_DIALOG_STATUS, isOpen,  last_virtual_card: last};
}
export function setFreezeVirtualCard(isFreezed, pan) {
  return { type: SET_FREEZE_VIRTUAL_CARD, payload: {isFreezed,  pan}};
}

export function setVirtualCardLimits(payload) {
  return { type: SET_VIRTUAL_CARD_LIMITS, payload: payload};
}

