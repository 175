import React, { useContext } from "react";
import { useSelector } from "react-redux";
import CoinContext from "contexts/CoinContext";
import { Grid, Typography } from "@material-ui/core";
import { map_coins } from "store/constant";

const Panel = () => {
  const { coin } = useContext(CoinContext);
  const coin_style = map_coins.get(coin);

  /*
  const ltp = useSelector((state) => {
     return ((state.coins.kraken_asset_pairs_loaded && state.coins.kraken_asset_pairs.get(coin) && state.coins.kraken_asset_pairs.get(coin).daily) || 0.0);
  });
  */

  const daily = useSelector((state) => {
     return (
                (state.coins.kraken_asset_pairs_loaded                  && 
                 state.coins.kraken_asset_pairs.get(coin)               && 
                 state.coins.kraken_asset_pairs.get(coin).daily
                 ) 
                 || undefined);
  });

  let ltp = 0.0;
  if ( daily)
  {
    ltp = daily.curr_price;
  }
  const daily_hi = useSelector((state) => {
     return (
                (state.coins.kraken_asset_pairs_loaded                  && 
                 state.coins.kraken_asset_pairs.get(coin)               && 
                 state.coins.kraken_asset_pairs.get(coin).daily         &&
                 state.coins.kraken_asset_pairs.get(coin).daily.high_price
                 ) 
                 || 0.0);
  });
  const daily_lo = useSelector((state) => {
     return (
                (state.coins.kraken_asset_pairs_loaded                  && 
                 state.coins.kraken_asset_pairs.get(coin)               && 
                 state.coins.kraken_asset_pairs.get(coin).daily         &&
                 state.coins.kraken_asset_pairs.get(coin).daily.low_price
                 ) 
                 || 0.0);
  });
  const daily_percent = useSelector((state) => {
     return (
                (state.coins.kraken_asset_pairs_loaded                  && 
                 state.coins.kraken_asset_pairs.get(coin)               && 
                 state.coins.kraken_asset_pairs.get(coin).daily         &&
                 state.coins.kraken_asset_pairs.get(coin).daily.dh_percent*-1.0
                 ) 
                 || 0.0);
  });

  let rung = (daily_percent < 0.0) ? "#ff4976" : "#4bffb5";

    if (daily)
    {
        console.log(daily);
    }
    else
    {
        console.log( 'DAILY: ' + coin + ': ' + daily_lo + ' ---> ' + daily_hi + ', ' + daily_percent + ', ' + ltp);
    }
  /*
  const portfolio = useSelector((state) => {
    if (state.coins.kraken_asset_pairs) {
      let item = state.coins.kraken_asset_pairs.get(coin);
      if ((item !== undefined) && (item.position !== undefined)) {
        return [
          (item.position.balance !== undefined)? item.position.balance.toFixed(2): 0.0,
          (item.position.balance !== undefined)? (item.position.balance * ltp).toFixed(2): 0.0,
          (item.position.realized !== undefined) ? item.position.realized: 0.0,
          (item.position.unrealized !== undefined) ? item.position.unrealized: 0.0,
          (item.position.remaining_avg_price !== undefined) ? item.position.remaining_avg_price.toFixed(3): 0.0,
        ];
      } else {
        return [0, 0, 0, 0, 0];
      }
    } else {
      return [0, 0, 0, 0, 0];
    }
  });
  */

  return (
    <Grid item>
      <Typography variant="h3">
        {/*<font style={{ fontWeight: 'normal', color: "cornsilk", "font-size": "1.1rem", }} >{'$'}</font>*/}
        <font style={{ fontWeight: 'normal', spacing: "10px", "font-size": "2.2rem" }}> {ltp} </font>
        <font style={{ color: rung, textAlign: 'top', fontWeight: 'normal', spacing: "10px", "font-size": "1.0rem" }}> {daily_percent} % </font>
      </Typography>
    </Grid>
  );
};

export default React.memo(Panel);
