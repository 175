import { Grid } from "@material-ui/core";
import React from "react";
import Explore from "../Explore";
import ExploreSummary from "../Explore/components/ExploreSummary";
import ExploreRecent from "../Explore/components/ExploreRecent";
import ExplorePortfolio from "../Explore/components/ExplorePortfolio";
import LiteOrderTicket from "ui-component/cards/LiteOrderTicket";
import SummaryCard from "ui-component/cards/SummaryCard";

const ExploreLayout = () => {
  return (
    <Grid container spacing={2.0} >

        <Grid item xs={9.5} alignItems="center">
          <Grid container padding={1.0} />
          <Explore />
        </Grid>

        <Grid item xs={2.5}>
            <Grid container padding={1.0} />
            <SummaryCard sx={{alignItems: 'middle'}} />
            <Grid container padding={1.0} />
            <LiteOrderTicket />
            {/*
            <Grid container padding={2.5} />
            <ExploreSummary />
            */}
            <Grid container padding={2.5} />
            <ExploreRecent />
        </Grid>

    </Grid>
  );
};

export default ExploreLayout;
