import React, { useEffect, useState } from "react";
import { gridSpacing } from "store/constant";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";

import { searchCoins } from "store/constant";

import { List, ListItemIcon, ListItemText } from "@material-ui/core";
import ListItemButton from "@material-ui/core/ListItemButton";

import { map_coins } from "store/constant";

// material-ui
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 360,
    minHeight: 200,
    minWidth: 500,
    backgroundColor: theme.palette.background.paper,
  },
}));

const Search = ({ handleSelect, listItems }) => {
  const classes = useStyles();
  const customization = useSelector((state) => state.customization);

  const handleListItemClick = (listItem) => {
    handleSelect(listItem);
  };

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item>
        <div className={classes.root}>
          <List component="nav" aria-label="main mailbox folders">
            {listItems.map((listItem) => {
              // const coin_style = map_coins.get(listItem.label);

              return (
                <ListItemButton
                  key={listItem.id}
                  classes={{
                    root: classes.buttonRoot,
                  }}
                  onClick={() => handleListItemClick(listItem)}
                  sx={{ borderRadius: `${customization.borderRadius}px` }}
                >
                  {/* <ListItemText primary={listItem.label} /> */}
                  <ListItemText
                    primary={
                      <font
                        style={{
                          horizontalAlign: "middle",
                          verticalAlign: "middle",
                          fontSize: "0.9em",
                        }}
                      >
                        {listItem.name}
                      </font>
                    }
                  />
                </ListItemButton>
              );
            })}
          </List>
        </div>
      </Grid>
    </Grid>
  );
};

export default Search;
