import * as actionTypes from "./actions";

const initialState = {
  coin: "BTC/USD",
  ohlcInterval: "360",
  currentChartData:[]
}; 

const chartReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DIVEBAR_OHLC_INTERVAL: {
      return {
        ...state,
        ohlcInterval: action.ohlcInterval,
      };
    }

    case actionTypes.SET_DIVEBAR_COIN: {
      const { coin } = action;
      return {
        ...state,
        coin,
      };
    }

    case actionTypes.SET_DIVEBAR_COIN_AND_INTERVAL: {
      const { coin, ohlcInterval } = action.payload;
      return {
        ...state,
        coin,
        ohlcInterval,
      };
    }

    case actionTypes.DIVEBAR_OHLC: {
      const payload = action.payload;

      const ohlcData = payload.data[payload.rcoin].reverse().map((result) => {
        let ts = result[0] - 5 * 3600;

        return {
          time: ts,
          open: result[1],
          high: result[2],
          low: result[3],
          close: result[4],
        };
      });

      return {
        ...state,
        currentChartData: ohlcData,
      };
    }


    // case actionTypes.MOBILE_CHART_OPENED: {
    //   return {
    //     ...state,
    //     mobile_chart_opened: action.payload.is_mobile_chart_opened,
    //   };
    // }

    // case actionTypes.MOBILE_LITE_ORDER_TICKET: {
    //   return {
    //     ...state,
    //     mobile_lite_order_ticket: action.payload.is_mobile_lite_order_ticket,
    //   };
    // }

    default:
      return state;
  }
};

export default chartReducer;
