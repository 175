import Config from "config";
import { useSelector } from "react-redux";

const API_URL = "wss://api.takyon.us/api/v1/";

let socket;

function connect(onMessage, onCLose, onConnect) {
  let url = API_URL;
  // if(coinbaseToken) {
  //   url = `${url}&c_token=${coinbaseToken}`
  // }

  socket = new WebSocket(url);
  socket.onerror = (e) => {
    socket.close();
    // onError(e);
  };
  socket.onclose = (e) => {
    setTimeout(function () {
      connect(onMessage, onCLose, onConnect);
    }, 50000);
    onCLose(e);
  };

  socket.onopen = onConnect;
  socket.onmessage = onMessage;
}

function emit(message) {
  checkIsConnected() && socket.send(JSON.stringify(message).toString());
}

const checkIsConnected = () => {
  if (socket) {
    if (socket.readyState === WebSocket.OPEN) {
      return true;
    }
    if (socket.readyState === WebSocket.CONNECTING) {
      return true;
    }
  }
  return false;
};

export default () => ({
  checkIsConnected,
  connect,
  emit,
});
