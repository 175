// import axios from "utils/axios";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addCoinbaseAssetPairs } from "store/actions";

const getData = async (u) => {

    let url = 'https://api.exchange.coinbase.com/products';

  const response = await axios.get(
    url,
    {},
    {
      headers: {
        "Accept":	"application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return response.data;
};

export default function useCoinbaseAssetPairs() {
  const dispatch = useDispatch();

  const fetchCoinbaseAssetPairs = () => {

    getData({}).then((data) => {
      dispatch(addCoinbaseAssetPairs(data));
    });
  };

  return {
    fetchCoinbaseAssetPairs,
  };
}
