import React, { useContext } from "react";
// material-ui
import { Typography } from "@material-ui/core";
import { Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import MainCard from "ui-component/cards/MainCard";
import { map_coins, map_coin_to_name } from "store/constant";
// import useKrakenOHLC from "hooks/useKrakenOHLC";
// import useBalance from "hooks/useBalance";
import { useSelector } from "react-redux";
import CoinContext from "contexts/CoinContext";
import FavoriteButton from "../../components/FavoriteButton";
import colors from 'assets/scss/_themes-vars.module.scss';

// style constant
/*
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  pairIcon: {
    width: "100px",
    height: "40px",
    color: theme.palette.primary.dark.dark,
    borderRadius: "10px",
    lineHeight: "40px",
    paddingLeft: "15px",
    // backgroundColor: theme.palette.grey[500],
    // backgroundColor: theme.palette.orange.light,
    // backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#ffe9e9';
    backgroundColor: "#e8f6f5",
  },
  pairGrid: {
    borderRadius: "10px",
    backgroundColor: "#fff",
    border: `1px solid ${theme.palette.grey[100]}`,
    paddingRight: "1px",
    boxShadow: theme.customShadows.primary,
    ":hover": {
      boxShadow: "none",
    },
  },
}));
*/

// style constant
const useStyles = makeStyles((theme) => ({
  revenueCard: {
    position: "relative",
    color: "#fff",
  },
  // revenueIcon: {
  //   position: "absolute",
  //   right: "13px",
  //   top: "14px",
  //   color: "#fff",
  //   "&> svg": {
  //     width: "50px",
  //     height: "50px",
  //     opacity: "0.5",
  //   },
  //   [theme.breakpoints.down("xs")]: {
  //     top: "13px",
  //     "&> svg": {
  //       width: "80px",
  //       height: "40px",
  //     },
  //   },
  // },

  pairIcon: {
    width: "100px",
    height: "40px",
    color: theme.palette.primary.dark.dark,
    borderRadius: "10px",
    lineHeight: "40px",
    paddingLeft: "15px",
    // backgroundColor: theme.palette.grey[500],
    // backgroundColor: theme.palette.orange.light,
    // backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#ffe9e9';
    // backgroundColor: "#e8f6f5",
  },
  pairGrid: {
    borderRadius: "10px",
    // backgroundColor: "#fff",
    border: `1px solid ${theme.palette.grey[50]}`,
    paddingRight: "15px",
    boxShadow: theme.customShadows.primary,
    ":hover": {
      boxShadow: "none",
    },
  },
}));

// ================================|| UI TABS - ICONS ||================================ //

export default function IconCard({ pair, price }) {
  const classes = useStyles();
  const coin_style = map_coins.get(pair);
  const { coin, setCoin } = useContext(CoinContext);

  // const { fetchOHLC } = useKrakenOHLC();
  // const { fetchBalance } = useBalance();

  const theme = useTheme();
  const cornsilk = (theme.palette.mode === 'dark') ? colors.cornsilkLightTakyon : colors.cornsilkDarkTakyon;
  const matchDownXs = useMediaQuery(theme.breakpoints.down("xs"));

  const ohlcInterval = useSelector((state) => {
    return state.chart.ohlcInterval;
  });

  const handleListItemClick = (event, coin) => {
    console.log("IconCard: clicked on " + coin);
    if (coin === "DOGE/USD") {
      setCoin("XDG/USD");
    } else if (coin === "BTC/USD") {
      setCoin("XBT/USD");
    } else {
      setCoin(coin);
    }
    // fetchOHLC(coin, ohlcInterval); // default interval
    // fetchBalance();
  };

  const coin_name = (map_coin_to_name.get(pair)) ? map_coin_to_name.get(pair) : pair;

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      onClick={(event) => handleListItemClick(event, pair)}
    >
      <Grid item>
        <Typography sx={{ color: cornsilk }} variant="h6">
          <a class={coin_style}>
            {coin_name}
          </a>
        </Typography>
      </Grid>
      <Grid item sm>
        <Typography
          sx={{
            minWidth: "10px",
            paddingLeft: "5px",
            paddingRight: "1px",
            overflow: "hidden",
            color: cornsilk,
          }}
          variant="h4"
        >
          {price}
        </Typography>
      </Grid>
    </Grid>
  );
}
