import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles';
import {
    Avatar,
    Box,
    ButtonBase,
    ClickAwayListener,
    Grid,
    List,
    ListItemText,
    ListItemIcon,
    Paper,
    Popper,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import ListItemButton from '@material-ui/core/ListItemButton';

// project imports
import Transitions from 'ui-component/extended/Transitions';
import * as actionTypes from 'store/actions';

// assets
import TranslateTwoToneIcon from '@material-ui/icons/TranslateTwoTone';
import krakenLogo from 'assets/images/exchanges/kraken.png';
import coinbaseLogo from 'assets/images/exchanges/coinbase.png';


const useStyles = makeStyles((theme) => ({
    navContainer: {
        width: '100%',
        minWidth: '200px',
        maxWidth: '280px',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '10px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '250px'
        }
    },
    headerAvatar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        color: theme.palette.primary.dark,
        transition: 'all .2s ease-in-out',
        '&[aria-controls="menu-list-grow"],&:hover': {
            borderColor: theme.palette.primary.main,
            background: theme.palette.primary.main,
            color: theme.palette.primary.light
        }
    },
    box: {
        marginLeft: '16px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '8px'
        }
    }
}));

// ===========================|| LOCALIZATION ||=========================== //
const exchangeLogos ={
    kraken : krakenLogo,
    coinbase: coinbaseLogo
}

const SelectedExchange = () => {
    const classes = useStyles();
    const customization = useSelector((state) => state.customization);
    const [selectedExchange, exchanges] = useSelector((state) => [state.exchange.selectedExchange,state.exchange.exchanges ]);
    const dispatch = useDispatch();

    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('sm'));
 
 
 
 

    return (
        <>
            <Box component="span" className={classes.box}>
                <ButtonBase sx={{ borderRadius: '12px' }}>
                    <Avatar
                        variant="rounded"
                        className={classes.headerAvatar}
                        aria-haspopup="true"
                        color="inherit"
                    >
                         <img src={exchangeLogos[selectedExchange]} alt="" width="30" height="30"/>
                    </Avatar>
                </ButtonBase>
            </Box>
        </>
    );
};

export default SelectedExchange;
