import * as PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MUIFormControl from "@material-ui/core/FormControl";

// material-ui
import {
  OutlinedInput,
  InputLabel,
  Chip,
  Divider,
  Grid,
  List,
  ListItemText,
  Typography,
  ListSubheader,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import ListItemButton from "@material-ui/core/ListItemButton";
import useDiveBarSocket from "hooks/useDiveBarClubSocket";

// ===========================|| CHAT USER LIST ||=========================== //

const ModalAddGroup = ({ handleGroupCreated }) => {
  const [name, setName] = useState("");

  const { channelCreate } = useDiveBarSocket();
  const handleCreateNewGroup = () => {
    channelCreate(name, name);
    setName("");
    handleGroupCreated();
  };

  return (
    <Grid item xs={12}>
      <MUIFormControl fullWidth>
        <InputLabel>New group</InputLabel>
        <OutlinedInput
          fullWidth
          label="New group"
          name={"New group"}
          onChange={(e) => {
            setName(e.target.value);
          }}
          value={name}
          endAdornment={
            <>
              <Button
                variant="contained"
                align="center"
                size="small"
                label={"CREATE"}
                color="success"
                onClick={handleCreateNewGroup}
              >
                Create
              </Button>
            </>
          }
        />
      </MUIFormControl>
    </Grid>
  );
};

const AddUsersModal = ({ onClose, modalGroup }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);

  const { addToChannel } = useDiveBarSocket();

  const handleSendInvites = () => {
    selectedUsers.map((userId) => addToChannel(modalGroup, userId));
    onClose();
  };

  const channelUsers = useSelector((state) =>
    state.diveBarClub.channelUsers.get(modalGroup)
  );

  const users = useSelector((state) =>
    state.diveBarClub.channelUsers
      .get(state.diveBarClub.mainChannel.id)
      .filter(
        (user) =>
          !channelUsers.some((channelUser) => channelUser.id === user.id)
      )
  );

  const handleChange = (id, value) => {
    if (value) {
      const newUsers = [...selectedUsers, id];
      setSelectedUsers(newUsers);
    } else {
      const newUsers = selectedUsers.filter((user) => user !== id);
      setSelectedUsers(newUsers);
    }
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add user to group</DialogTitle>
      <DialogContent style={{ minWidth: 500 }}>
        {users.map((user) => (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedUsers.includes(user.id)}
                  onChange={(e) => {
                    handleChange(user.id, e.target.checked);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={user.display_name}
            />
          </FormGroup>
        ))}
        <hr />
        {channelUsers.map((user) => (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={true}
                  // onChange={(e) => {
                  //   handleChange(user.id, e.target.checked);
                  // }}
                  inputProps={{ "aria-label": "controlled" }}
                  disabled={true}
                />
              }
              label={user.display_name}
            />
          </FormGroup>
        ))}
      </DialogContent>
      <DialogActions sx={{ pr: 2.5 }}>
        <Button variant="contained" size="small" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" size="small" onClick={handleSendInvites}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const GroupsList = ({ setUser, addNewGroup }) => {
  const { groups } = useSelector((state) => state.diveBarClub);

  const [addGroupModal, setAddGroupModal] = useState(false);
  const handleAddNewGroupModal = () => setAddGroupModal(!addGroupModal);
  const handleGroupCreated = () => setAddGroupModal(false);

  const [modalGroup, setModalGroup] = useState(undefined);

  const addToGroup = (groupId) => {
    //open modal add to group
    setModalGroup(groupId);
  };

  const closeModal = () => setModalGroup(undefined);

  return (
    <>
      {modalGroup && (
        <AddUsersModal onClose={closeModal} modalGroup={modalGroup} />
      )}
      <List
        component="nav"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>Collaborate Here</div>
            <Button
              variant="text"
              align="center"
              size="small"
              color={addGroupModal ? "warning" : "success"}
              onClick={handleAddNewGroupModal}
            >
              {addGroupModal ? "-" : "+"}
            </Button>
          </ListSubheader>
        }
      >
        {addGroupModal && (
          <ModalAddGroup handleGroupCreated={handleGroupCreated} />
        )}
        {groups.map((group) => (
          <React.Fragment key={group.id}>
            <ListItemButton
              onClick={() => {
                setUser(group);
              }}
            >
              <ListItemText
                primary={
                  <Grid
                    container
                    alignItems="center"
                    spacing={1}
                    component="span"
                  >
                    <Grid item xs zeroMinWidth component="span">
                      <Typography
                        variant="h5"
                        color="inherit"
                        component="span"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          display: "block",
                        }}
                      >
                        {group.name}
                      </Typography>
                    </Grid>
                    <Grid item component="span">
                      <Button
                        variant="text"
                        align="center"
                        size="small"
                        color={"success"}
                        onClick={() => addToGroup(group.id)}
                      >
                        +
                      </Button>
                    </Grid>
                  </Grid>
                }
                secondary={
                  <Grid
                    container
                    alignItems="center"
                    spacing={1}
                    component="span"
                  >
                    <Grid item xs zeroMinWidth component="span">
                      <Typography
                        variant="caption"
                        component="span"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          display: "block",
                        }}
                      >
                        {group.lasMessage}
                      </Typography>
                    </Grid>
                    <Grid item component="span">
                      {group.unReadChatCount !== 0 && (
                        <Chip
                          label={group.unReadChatCount}
                          component="span"
                          color="secondary"
                          sx={{
                            width: "20px",
                            height: "20px",
                            "& .MuiChip-label": {
                              px: "4px",
                            },
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                }
              />
            </ListItemButton>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </>
  );
};

GroupsList.propTypes = {
  setUser: PropTypes.func,
};

export default GroupsList;
