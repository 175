import React, {useRef} from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import SubCard from "../../../../ui-component/cards/SubCard";
import SmartWatchListTable from "./components/SmartWatchListTable";
import { sortSwlNegTs } from "store/actions";
import {
  createChart,
  createPriceLine,
  CrosshairMode,
} from "lightweight-charts";
import { withStyles, useTheme } from "@material-ui/styles";
import colors from 'assets/scss/_themes-vars.module.scss';


// vel_positive: {"bar_vol":4500,"end_ref":2.73951,"icon":"images/logo_MANAUSD.png","logo":"logo_MANAUSD","percent":1.1650836792271744,"rate_of_change_one":2322.4479078520258,"rate_of_change_two":4073.783039223284,"since":205,"start_ref":2.70796,"symbol":"MANA/USD","ts":1636504838,"velocity_one":0.0005016619211514334,"velocity_two":0.028599551522736754},

// "swl_positive":{"bar_count":3,"bar_vol":4500,"delta":0.03155000000000019,"end_ref":2.73951,"icon":"images/logo_MANAUSD.png","logo":"logo_MANAUSD","ohlc":{"close":0,"epoch":0,"high":0,"low":0,"open":0},"percent":1.1650836792271744,"side":"buy","since":205,"start_ref":2.70796,"symbol":"MANA/USD","ts":1636504838},"

const SwlNegative = () => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const bgcolor = (theme.palette.mode === 'dark') ? '#00070f' : 'white';
    const cornsilk = (theme.palette.mode === 'dark') ? colors.cornsilkLightTakyon : colors.cornsilkDarkTakyon;

  let positives = 0;
  let negatives = 0;
  let breakoutSentiment = 0;

  const listSwlNegative = useSelector((state) => {
    let items = [];

    if ( state.coins === undefined || state.coins.kraken_asset_pairs === undefined)
    {
         return items;
    }

    state.coins.kraken_asset_pairs.forEach((item) => {
        
        if (item !== undefined  && item.swl_single !== undefined && (item.swl_single.status !== 2))
        {
            if ( item.position !== undefined)
            {
                item.swl_single.avg = item.position.remaining_avg_price.toFixed(3);
            }
            item.epoch_start = item.swl_single.epoch_start;
            item.ts = item.swl_single.ts;
            item.since = item.swl_single.since;
            item.percent = item.swl_single.percent;
            if ( item.percent > 0.0)
            {
                ++positives;
            }
            else
            {
                ++negatives;
            }
            items.push(item);
        }

    });

    return items;
  });

  if ( listSwlNegative.length > 0)
  {
    breakoutSentiment = (positives * 100) / (listSwlNegative.length);
  }

  /*
  const historySwlNegative = useSelector((state) => {
    let items = [];

    if ( state.coins === undefined || state.coins.kraken_asset_pairs === undefined)
    {
         return items;
    }

    const now = Date.now() /1000;
    state.coins.kraken_asset_pairs.forEach((item) => {
        
        if (item !== undefined && item.swl_single !== undefined && (item.swl_single.status === 2) && ((now - item.swl_single.ts) < 300) )
        {
            if ( item.position !== undefined)
            {
                item.swl_single.avg = item.position.remaining_avg_price.toFixed(3);
                item.epoch_start = item.swl_single.epoch_start;
            }
            items.push(item);
        }

    });
    return items;
  });

    const cdata_coin = useSelector((state) => {
        return state.coins.cdata_coin.get("ETH/USD");
    });
  */

  const handleSortBuyCoin = () => dispatch(sortSwlNegTs("ts"));
  const handleSortBuyPercent = () => dispatch(sortSwlNegTs("percent"));


  return (
    <>
        <SmartWatchListTable type="SWLNegative" items={listSwlNegative} positives={positives} negatives={negatives} sentiment={breakoutSentiment} inputRef={null}/>
    </>
  );
};

export default React.memo(SwlNegative);
