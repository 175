import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import CheckTwoToneIcon from "@material-ui/icons/CheckTwoTone";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { useDispatch } from "react-redux";
import { MENU_TYPE } from "store/actions"; // THEME_RTL

import {
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  SpeedDial,
  SpeedDialAction,
  Switch,
} from "@material-ui/core";

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Avatar,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Paper,
  Popper,
  Typography,
} from "@material-ui/core";
import ListItemButton from "@material-ui/core/ListItemButton";
import { AvatarGroup, Badge } from "@material-ui/core";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import UpgradePlanCard from "./UpgradePlanCard";
import useAuth from "hooks/useAuth";

// assets
import { IconLogout, IconSearch, IconSettings, IconUser } from "@tabler/icons";
import User1 from "assets/images/users/avatar-1.png";

// style const
const useStyles = makeStyles((theme) => ({
  navContainer: {
    width: "100%",
    maxWidth: "350px",
    minWidth: "300px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  },
  headerAvatar: {
    cursor: "pointer",
    ...theme.typography.mediumAvatar,
    margin: "8px 0 8px 8px !important",
  },
  profileChip: {
    height: "48px",
    alignItems: "center",
    borderRadius: "27px",
    transition: "all .2s ease-in-out",
    borderColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.primary.light,
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.primary.light,
    '&[aria-controls="menu-list-grow"], &:hover': {
      borderColor: theme.palette.primary.main,
      background: `${theme.palette.primary.main} !important`,
      color: theme.palette.primary.light,
      "& svg": {
        stroke: theme.palette.primary.light,
      },
    },
  },
  profileLabel: {
    lineHeight: 0,
    padding: "12px",
  },
  listItem: {
    marginTop: "5px",
  },
  cardContent: {
    padding: "16px !important",
  },
  card: {
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark[800]
        : theme.palette.primary.light,
    marginBottom: "16px",
    marginTop: "16px",
  },
  searchControl: {
    width: "100%",
    paddingRight: "8px",
    paddingLeft: "16px",
    marginBottom: "16px",
    marginTop: "16px",
  },
  startAdornment: {
    fontSize: "1rem",
    color: theme.palette.grey[500],
  },
  flex: {
    display: "flex",
  },
  name: {
    marginLeft: "2px",
    fontWeight: 400,
  },
  ScrollHeight: {
    height: "100%",
    maxHeight: "calc(100vh - 250px)",
    overflowX: "hidden",
  },
  badgeWarning: {
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.dark.dark
        : theme.palette.warning.dark,
    color: "#fff",
  },
}));

// ===========================|| PROFILE MENU ||=========================== //

const ProfileSection = ({ isConnected }) => {
  const classes = useStyles();
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const dispatch = useDispatch();

  const [sdm, setSdm] = React.useState(true);
  const [value, setValue] = React.useState("");
  const [notification, setNotification] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const { logout } = useAuth();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    handleClose(event);
  };
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const [themeColor, setThemecolor] = React.useState(customization.navType);

  const handleThemeChange = (event) => {
    if (themeColor === "dark") {
      setThemecolor("light");
    } else {
      setThemecolor("dark");
    }
  };

 
  useEffect(() => {
    dispatch({ type: MENU_TYPE, navType: themeColor });
  }, [dispatch, themeColor]);
  //dark light
  return (
    <>
      {/* <Grid item>
                            <Badge
                                overlap="circular"
                                badgeContent={
                                    <Avatar color="success" size="badge">
                                        <CheckTwoToneIcon />
                                    </Avatar>
                                }
                            >
                                <Avatar alt="User 1" src={Profile} size="xl" outline color="success" />
                            </Badge>
                        </Grid> */}

      {isConnected && (
        <FiberManualRecordIcon
          sx={{
            cursor: "pointer",
            color: theme.palette.success.dark,
            verticalAlign: "middle",
            fontSize: "14px",
          }}
        />
      )}

      {!isConnected && (
        <FiberManualRecordIcon
          sx={{
            cursor: "pointer",
            color: theme.palette.error.dark,
            verticalAlign: "middle",
            fontSize: "14px",
          }}
        />
      )}

      <FormControlLabel
        control={
          <Switch
            checked={(themeColor === 'dark')}
            onChange={handleThemeChange}
            color="primary"
          />
        }
        label=""
      />

      {/* <Chip
        classes={{ label: classes.profileLabel }}
        className={classes.profileChip}
        icon={
         
          <Avatar
            src={User1}
            className={classes.headerAvatar}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            color="success"
          />
        }
        label={
          <IconSettings
            stroke={1.5}
            size="1.5rem"
            color={theme.palette.primary.main}
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      /> */}
      {/* <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions position="top-right" in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <CardContent className={classes.cardContent}>
                    <Grid container direction="column" spacing={0}>
                      <Grid item className={classes.flex}>
                        <Typography variant="h4">Good Morning</Typography> 
                      </Grid>
                      <Grid item>
                        
                        <Typography variant="subtitle2">
                          Project Admin
                        </Typography>
                      </Grid>
                    </Grid>
                     
                    <Divider />
                    <PerfectScrollbar className={classes.ScrollHeight}>
                     
                      <Divider />
                      
                      <Divider />
                      <List component="nav" className={classes.navContainer}>
                        <ListItemButton
                                                    className={classes.listItem}
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    selected={selectedIndex === 0}
                                                    onClick={(event) => handleListItemClick(event, 0)}
                                                    component={React.forwardRef((props, ref) => (
                                                        <RouterLink ref={ref} {...props} to="/lite/settings" />
                                                    ))}
                                                >
                                                    <ListItemIcon>
                                                        <IconSettings stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Account Settings</Typography>} />
                                                </ListItemButton>
                      
                        <ListItemButton
                          className={classes.listItem}
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">Logout</Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </PerfectScrollbar>
                  </CardContent>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper> */}
    </>
  );
};

export default ProfileSection;
