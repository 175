import PropTypes from "prop-types";
import React, { useState, useContext, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import CoinContext from "contexts/CoinContext";
import { useDispatch } from "react-redux";
import { map_coins, map_balance_to_coin } from "store/constant";
import { Tab, Tabs } from "@material-ui/core";
import SelectedExchange from "ui-component/SelectedExchange";
import colors from 'assets/scss/_themes-vars.module.scss';


import {
  placeOrderTicket,
  orderTicketTimeout,
  OrderTicketTypes,
} from "store/actions";

import {
  Divider,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  IconButton,
  Chip,
  Fab,
  Box,
} from "@material-ui/core";
import MUIFormControl from "@material-ui/core/FormControl";
import Transitions from "ui-component/extended/Transitions";

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import SubCard from "ui-component/cards/SubCard";
import useSocket from "hooks/useSocket";
import useOrderTicket from "hooks/useOrderTicket";
import { addOrder } from "emitters";
import { IconSettings } from "@tabler/icons";

import { IconLoader } from "@tabler/icons";
import AnimateButton from "ui-component/extended/AnimateButton";

// style constant
const useStyles = makeStyles((theme) => ({
  revenueCard: {
    position: "relative",
    color: "#fff",
    background: "#00070f",
  },
  revenueIcon: {
    position: "absolute",
    right: "13px",
    top: "14px",
    color: "#fff",
    background: "#00070f",
    "&> svg": {
      width: "100px",
      height: "100px",
      opacity: "0.5",
    },
    [theme.breakpoints.down("xs")]: {
      top: "13px",
      "&> svg": {
        width: "80px",
        height: "80px",
      },
    },
  },
}));

// =============================|| REVENUE CARD ||============================= //

const TrendingSellSummary = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const green = (theme.palette.mode === 'dark') ? colors.successLightTakyon : colors.successDarkTakyon;
  const red = (theme.palette.mode === 'dark') ? colors.errorLightTakyon : colors.errorDarkTakyon;

  const matchDownXs = useMediaQuery(theme.breakpoints.down("xs"));
  let textc = "lightyellow";
  if (theme.palette.background.paper === "#ffffff") {
    textc = "darkred";
  }

  
  const [selectedExchange, exchanges] = useSelector((state) => [
    state.exchange.selectedExchange,
    state.exchange.exchanges,
  ]);


  const { coin } = useContext(CoinContext);

  const cash = useSelector((state) => {
        return ((state.coins.newportfolio) ? state.coins.newportfolio.total_cash.toFixed(2): 0.0);
  });

    const trending = useSelector((state) => {
        return ((state.coins.kraken_asset_pairs_loaded && state.coins.kraken_asset_pairs.get(coin) && state.coins.kraken_asset_pairs.get(coin).trending_sell)?state.coins.kraken_asset_pairs.get(coin).trending_sell : {});
    });

    const pos = useSelector((state) => {
        return ((state.coins.kraken_asset_pairs_loaded && state.coins.kraken_asset_pairs.get(coin) && state.coins.kraken_asset_pairs.get(coin).position)?state.coins.kraken_asset_pairs.get(coin).position : {});
    });

  const spot = map_balance_to_coin.get(coin);
  const coin_style = map_coins.get(coin);
  const rung = (trending.position <  trending.price)? red : green;
  const lrung = (trending.avg >  trending.price)? red : green;

  return (
    <SubCard>
                <Grid container padding={1} alignItems="center" spacing={1}>

                    <Grid item xs={12} alignItems="center" textAlign="center">
                        <font style={{ textAlign: 'right', fontSize: '1.3rem', fontWeight: 'normal', bgcolor: '#00070f', color: 'cornsilk' }}>
                            {'Buy Back'}
                        </font>
                    </Grid>

                    <Grid item xs={12} lg={12} alignItems="center"  textAlign="center">
                        <a class={coin_style}>{coin}</a>
                    </Grid>

                    <Grid item xs={12} lg={12} />
                    <Grid item xs={12} lg={12} />

                    <Grid item xs={12} lg={6}>
                                    <font style={{ textAlign: 'right', fontSize: '1.3rem', fontWeight: 'normal', bgcolor: '#00070f', color: '#fff' }}>
                                        {'Percent'}
                                    </font>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                                    <font style={{ textAlign: 'right', fontSize: '1.3rem', fontWeight: 'normal', bgcolor: '#00070f', color: rung }}>
                                        {trending.percent}{'%'}
                                    </font>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                                    <font style={{ textAlign: 'right', fontSize: '1.3rem', fontWeight: 'normal', bgcolor: '#00070f', color: '#fff' }}>
                                        {'Last Buy'}
                                    </font>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                                    <font style={{ textAlign: 'right', fontSize: '1.3rem', fontWeight: 'normal', bgcolor: '#00070f', color: rung }}>
                                        {'$'}{(trending.qty * trending.position).toFixed(2)}
                                    </font>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                                    <font style={{ textAlign: 'right', fontSize: '1.3rem', fontWeight: 'normal', bgcolor: '#00070f', color: '#fff' }}>
                                        {'P&L'}
                                    </font>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                                    <font style={{ textAlign: 'right', fontSize: '1.3rem', fontWeight: 'normal', bgcolor: '#00070f', color: rung }}>
                                        {'$'}{(trending.qty * (trending.price - trending.position)).toFixed(2)}
                                    </font>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                                    <font style={{ textAlign: 'right', fontSize: '1.3rem', fontWeight: 'normal', bgcolor: '#00070f', color: '#fff' }}>
                                        {'Invested'}
                                    </font>
                    </Grid>
                    <Grid item xs={12} lg={2}>
                                    <font style={{ textAlign: 'right', fontSize: '1.3rem', fontWeight: 'normal', bgcolor: '#00070f', color: rung }}>
                                        {'$'}{trending.invested}
                                    </font>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                                    <font style={{ textAlign: 'right', fontSize: '1.3rem', fontWeight: 'normal', bgcolor: '#00070f', color: '#fff' }}>
                                        {'Liquidate'}
                                    </font>
                    </Grid>
                    <Grid item xs={12} lg={2}>
                                    <font style={{ textAlign: 'right', fontSize: '1.3rem', fontWeight: 'normal', bgcolor: '#00070f', color: lrung }}>
                                        {'$'}{(trending.balance * (trending.price - trending.avg)).toFixed(2)}
                                    </font>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                                    <font style={{ textAlign: 'right', fontSize: '1.3rem', fontWeight: 'normal', bgcolor: '#00070f', color: '#fff' }}>
                                        {'LTP'}
                                    </font>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                                    <font style={{ textAlign: 'right', fontSize: '1.3rem', fontWeight: 'normal', bgcolor: '#00070f', color: rung }}>
                                        {trending.price}
                                    </font>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                                    <font style={{ textAlign: 'right', fontSize: '1.3rem', fontWeight: 'normal', bgcolor: '#00070f', color: '#fff' }}>
                                        {'Avg.Px.'}
                                    </font>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                                    <font style={{ textAlign: 'right', fontSize: '1.3rem', fontWeight: 'normal', bgcolor: '#00070f', color: 'orange' }}>
                                        {trending.avg}
                                    </font>
                    </Grid>
                </Grid>
    </SubCard>
  );
};

TrendingSellSummary.propTypes = {};

export default React.memo(TrendingSellSummary);
