const theme = localStorage.getItem('theme') || 'dark';
// const host  = (window.location.host === 'localhost:3000' ?  'usd.takyon.us': window.location.host);

const currencyHost = window.localStorage.getItem("currency") || 'usd';


// const host  = (window.location.host === 'localhost:3000' ?  'usd.takyon.us': window.location.host);
const host  = `api.takyon.us` ;


const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '/stage',
    defaultPath: '/lite',
    fontFamily: `'Trykker', sans-serif`,
    borderRadius: 12,
    outlinedFilled: true,
    theme: theme, // light, dark
    presetColor: 'theme2', // default, theme1, theme2, theme3, theme4, theme5, theme6
    // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    i18n: 'en',
    rtlLayout: false,
    jwt: {
        secret: 'SECRET-KEY',
        timeout: '1 days'
    },
    firebase: {
        apiKey: "AIzaSyDrdaGkCZt54rFCsthxsrvFkiwPwTOCmpE",
        authDomain: "takyon-8327e.firebaseapp.com",
        projectId: "takyon-8327e",
        storageBucket: "takyon-8327e.appspot.com",
        messagingSenderId: "973389179910",
        appId: "1:973389179910:web:1d4f814dcdcad516f3e787",
        measurementId: "G-C1ZG6FZLFP"
    },
    coinbase: {
        grant_type: "authorization_code",
        client_id:
          "04e1f98ceea2a890c4b1dccace5439e239f5b5163190ba8b9ff9cf663465c017",
        client_secret:
          "8e10265457fc6856d75a3fec0a1b88d5915c82bb1210307726a328f4ad82947e",
        redirect_uri:  (window.location.host === 'localhost:3000' ?  'http://localhost:3000/coinbase'   : `https://${window.location.host}/coinbase`)
    },

    auth0: {
        client_id: 'HvYn25WaEHb7v5PBT7cTYe98XATStX3r',
        domain: 'demo-localhost.us.auth0.com'
    }, 
    takyon_domain: host,
    // takyon_domain: 'ws-beta.takyon.us',
    // takyon_domain: 'ec2-18-207-108-0.compute-1.amazonaws.com',

};

export default config;
