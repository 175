import React, { useContext } from "react";
import Panel from "./Panel";

import { useSelector, useDispatch } from "react-redux";
import { map_coins } from "store/constant";
import { IconButton } from "@material-ui/core";
import { setDiveBarInterval, setModalSearchStatus } from "store/actions";
import DailyHiLo from "../../components/DailyHiLo";
import colors from "assets/scss/_themes-vars.module.scss";
import ToolTipHelper from "ui-component/ToolTipHelper";
import { tooltips } from "constants/tooltips";

// material-ui
import { useTheme } from "@material-ui/styles";
import { Grid } from "@material-ui/core";

// third party
import TakyonLightweightChart from "./Chart";

// project imports
import SubCard from "ui-component/cards/SubCard";
import TradesCoin from "../../Trades/TradesCoin";
import OrdersCoin from "./OrdersCoin.js";
import useDiveBarSocket from "hooks/useDiveBarClubSocket";

// ===========================|| DASHBOARD ANALYTICS - MARKET SHARE AREA CHART CARD ||=========================== //

const CandleChartCard = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const cornsilk =
    theme.palette.mode === "dark"
      ? colors.cornsilkLightTakyon
      : colors.cornsilkDarkTakyon;
  const whiteTakyon =
    theme.palette.mode === "dark"
      ? colors.whiteLightTakyon
      : colors.whiteDarkTakyon;
  const green =
    theme.palette.mode === "dark"
      ? colors.successLightTakyon
      : colors.successDarkTakyon;
  const red =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;

  const { coin, ohlcInterval } = useSelector((state) => state.diveBarChart);

  const coin_style = map_coins.get(coin);
  // const coin_name = map_coin_to_name.get(coin) ? map_coin_to_name.get(coin) : coin;
  const coin_name = useSelector((state) => {
    const row =
      (state.coins.kraken_asset_pairs &&
        state.coins.kraken_asset_pairs.get(coin) &&
        state.coins.kraken_asset_pairs.get(coin)) ||
      undefined;
    if (row === undefined) {
      return coin;
    }
    const coin_name = row.marketcap
      ? row.marketcap.Name
      : row.base_currency
      ? row.base_currency
      : row.base
      ? row.base
      : row.wsname;
    return coin_name;
  });

  const [tabsValue, setTabsValue] = React.useState(0);
  const [prevCoin, setPrevCoin] = React.useState("BTC/USD");
  const handleChangeTab = (event, newValue) => {
    setTabsValue(newValue);
  };

  const { userMsg } = useDiveBarSocket();
  const { openedRoom } = useSelector((state) => state.diveBarClub);

  console.log( 'Prev: ' + prevCoin + ', coin: ' + coin + ' ------------------------------------');
  if ( prevCoin !== coin)
  {
    setPrevCoin(coin);

    if (openedRoom) {

      console.log( 'Sending transport_message for ' + coin + ', ohlcInterval: ' + ohlcInterval + ', roomId: ' + openedRoom.roomId);
      userMsg(
        JSON.stringify({
          type: "transport_message",
          interval: `${ohlcInterval}`,
          coin,
        }),
        openedRoom.roomId
      );
    }
  }
  const setInterval = (interval) => {
    dispatch(setDiveBarInterval(interval));

    if (openedRoom) {

        console.log( 'Sending transport_message for ' + coin);
      userMsg(
        JSON.stringify({
          type: "transport_message",
          interval: `${interval}`,
          coin,
        }),
        openedRoom.roomId
      );
    }
  };

  const intervals = [
    { interval: "1", title: "1m" },
    { interval: "5", title: "5m" },
    { interval: "15", title: "15m" },
    { interval: "60", title: "1h" },
    { interval: "360", title: "6h" },
    { interval: "1440", title: "1d" },
  ];

  // colorMap = colorMap.set(ohlcInterval, 'blue');

  const ltp = useSelector((state) => {
    return (
      (state.coins.kraken_asset_pairs_loaded &&
        state.coins.kraken_asset_pairs.get(coin) &&
        state.coins.kraken_asset_pairs.get(coin).ltp) ||
      undefined
    );
  });

  const daily = useSelector((state) => {
    return (
      (state.coins.kraken_asset_pairs_loaded &&
        state.coins.kraken_asset_pairs.get(coin) &&
        state.coins.kraken_asset_pairs.get(coin).daily) ||
      undefined
    );
  });

  const daily_hi = useSelector((state) => {
    return (
      (state.coins.kraken_asset_pairs_loaded &&
        state.coins.kraken_asset_pairs.get(coin) &&
        state.coins.kraken_asset_pairs.get(coin).daily_hi) ||
      0.0
    );
  });
  const daily_lo = useSelector((state) => {
    return (
      (state.coins.kraken_asset_pairs_loaded &&
        state.coins.kraken_asset_pairs.get(coin) &&
        state.coins.kraken_asset_pairs.get(coin).daily_lo) ||
      0.0
    );
  });
  const daily_percent = useSelector((state) => {
    return (
      (state.coins.kraken_asset_pairs.get(coin) &&
        state.coins.kraken_asset_pairs.get(coin).hilo_percent) ||
      0.0
    );
  });

  const handleCoinClick = () => {
    dispatch(setModalSearchStatus(true));
  };

  return (
    <>
      <Grid container padding={0.0} />
      {tabsValue === 0 && (
        <>
          <SubCard>
            <Grid container spacing={1} padding={1} alignItems="space-between">
              <Grid item xs={3.5}>
                <Panel
                  ltp={ltp}
                  daily_hi={daily_hi}
                  daily_lo={daily_lo}
                  daily_percent={daily_percent}
                />
              </Grid>
              <Grid item xs={0.5} onClick={handleCoinClick}>
                <font
                  style={{ spacing: "10px", "font-size": "0.9rem" }}
                  class={coin_style}
                >
                  {coin_name}
                </font>
              </Grid>
              <Grid
                item
                xs={5.0}
                align="center"
                justifyContent="center"
                alignItems="center"
              >
                {intervals.map((intervalItem) => (
                  <IconButton
                    key={intervalItem.interval}
                    sx={{
                      color:
                        intervalItem.interval === ohlcInterval
                          ? cornsilk
                          : colors.grey600,
                    }}
                    size="small"
                    onClick={() => setInterval(intervalItem.interval)}
                  >
                    {intervalItem.title}
                  </IconButton>
                ))}
              </Grid>
              <Grid item xs={2.5}>
                <DailyHiLo dh={daily_hi} dl={daily_lo} ltp={ltp} avg_px={0} />
              </Grid>
              <Grid item xs={0.5}>
                <ToolTipHelper
                  hover={tooltips.interval.hover}
                  modal={tooltips.interval.modal}
                />
              </Grid>
            </Grid>
            <TakyonLightweightChart />
          </SubCard>
        </>
      )}
      {tabsValue === 1 && <TradesCoin />}
      {tabsValue === 2 && <OrdersCoin />}
    </>
  );
};
export default CandleChartCard;
