import Config from 'config';
import { useSelector } from "react-redux";

const API_URL = "wss://"+Config.takyon_domain+"/api/v1";

let socket;

function connect(onMessage, onCLose, onConnect) {
  // const userToken = window.localStorage.getItem("takyon_token") || "no_token";
  // const coinbaseToken = window.localStorage.getItem("coinbase_token") || "no_token";
   


  const userToken = window.localStorage.getItem("coinbase_token") || window.localStorage.getItem("takyon_token") || "no_token";


let url  =`${API_URL}/?token=${encodeURIComponent(userToken)}`;
  // if(coinbaseToken) {
  //   url = `${url}&c_token=${coinbaseToken}`
  // }

  socket = new WebSocket(url);
  socket.onerror = (e) => {

    socket.close();
    // onError(e);
  };
  socket.onclose = (e) => {

    setTimeout(function () {
      connect(onMessage, onCLose, onConnect);
    }, 50000);
    onCLose(e);
  };

  socket.onopen = onConnect;
  socket.onmessage = onMessage;
}

function emit(message) {
  checkIsConnected() && socket.send(message.toString());
}

const checkIsConnected = () => {

  if(socket) {

    
    if(socket.readyState === WebSocket.OPEN) {
      return true;
    }
    if(socket.readyState === WebSocket.CONNECTING) {
      return true;
    }
  } 
  return false;
};

export default () => ({
  checkIsConnected,
  connect,
  emit,
});
