import React, {useState} from 'react';
import { useSelector } from "react-redux";

// material-ui
import { Typography } from '@material-ui/core';

// project imports
import NavGroup from './NavGroup';
// import menuItem from 'menu-items';
import menuItems from '../menu-items';
import menuItemsMobile from '../menu-items/mobile';

// ===========================|| SIDEBAR MENU LIST ||=========================== //

const MenuList = () => {

const [width, setWidth] = useState(window.innerWidth);

const alerts =  useSelector(state => state.coins.alerts);


const isMobile = width <= 768;

const items = isMobile ? menuItemsMobile(alerts) : menuItems(alerts);

    // const navItems = menuItem.items.map((item) => {
    const navItems = items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return navItems;
};

export default MenuList;
