import React, { useMemo, useRef, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from 'react-perfect-scrollbar';
import SubCard from "ui-component/cards/SubCard";
import TotalLineChartCard from 'ui-component/cards/TotalLineChartCard';
import ApexCharts from 'apexcharts';
import chartData from 'views/widget/Chart/chart-data';
import Chart from 'react-apexcharts';
import useDiveBarSocket from "hooks/useDiveBarClubSocket";
import MUIFormControl from "@material-ui/core/FormControl";


import {
  Box,
  ButtonGroup,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { withStyles, useTheme } from "@material-ui/styles";
import {
  setDiveBarCoin,
  setLastTrade,
  currencySent,
  RESET_ALERT_TRENDING_BUY,
  RESET_ALERT_TRENDING_SELL,
  RESET_ALERT_SWL_POSITIVE,
  RESET_ALERT_SWL_NEGATIVE,
  RESET_ALERT_VEL_POSITIVE,
  RESET_ALERT_VEL_NEGATIVE,
  CLEAR_SWL_POSITIVE_ALERTS,
  CLEAR_SWL_NEGATIVE_ALERTS,
} from "store/actions";

// material-ui
import {
  Badge,
  Button,
  Checkbox,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,

  InputAdornment,
  OutlinedInput,
  InputLabel,
  IconButton,
  Chip,
  Fab,
} from "@material-ui/core";

import { useSelector, useDispatch } from "react-redux";
import clear from 'assets/images/clear.svg';

// material-ui
import { makeStyles } from "@material-ui/styles";

// third party
import { FixedSizeList } from "react-window";
import { List, ListItemIcon, Avatar } from "@material-ui/core";
import CoinContext from "contexts/CoinContext";
import "styles/coins.css";
import { map_coins, map_balance_to_coin, map_coin_to_name } from "store/constant";
import colors from 'assets/scss/_themes-vars.module.scss';
import ToolTipHelper from 'ui-component/ToolTipHelper';
import {tooltips} from 'constants/tooltips';

// assets
import KeyboardArrowUpOutlinedIcon from "@material-ui/icons/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";
import FavoriteButton from "../FavoriteButton";
import {currencyFormat} from 'utils/currencyFormat';

// import ListItemButton from '@material-ui/core/ListItemButton';
// style constant
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 670,
    backgroundColor: theme.palette.background.paper,
  },
  avatarSuccess: {
    width: "16px",
    height: "16px",
    borderRadius: "5px",
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
    marginRight: "5px",
  },
  avatarError: {
    width: "16px",
    height: "16px",
    borderRadius: "5px",
    backgroundColor: theme.palette.orange.light,
    color: theme.palette.orange.dark,
    marginRight: "5px",
  },
  ScrollHeight: {
        height: '400px',
        overflowX: 'hidden',
        minHeight: '400px',
        [theme.breakpoints.down('md')]: {
            height: 'calc(100vh - 190px)',
            minHeight: 0
        }
  },
  chart: {
    position: 'absolute inherit',
    width: '100%'
  }
}));

const CustomizedBadge = withStyles((theme) => ({
  badge: {
    right: -5,
    top: -5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

const AlertItemPercent = (alertCount, rung, percent) => {
  if (alertCount !== "1") {
    return (
      <Typography variant="h2" sx={{ color: rung }}>
        {percent.toFixed(2)}%
      </Typography>
    );
  } else {
    return (
      <IconButton aria-label="cart">
        <CustomizedBadge badgeContent={alertCount} color="error">
          <Typography variant="h2" sx={{ color: rung }}>
            {percent.toFixed(2)}%
          </Typography>
        </CustomizedBadge>
      </IconButton>
    );
  }
};

// table filter
function descendingComparator(a, b, orderBy) {
  if (orderBy !== "Pair") {
    if (Number(b[orderBy]) < Number(a[orderBy])) {
      return -1;
    }
    if (Number(b[orderBy]) > Number(a[orderBy])) {
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if ( array === undefined)
  {
    return [];
  }
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// table header
const headCells = [
  {
    id: "fx",
    numeric: true,
    disablePadding: false,
    label: "Fx",
    hover: "none.hover",
    modal: "none.modal",
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: true,
    label: "Amount",
    hover: "tooltips.none.hover",
    modal: "tooltips.none.modal",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    liquidateAmount,
    changeLiquidateAmount,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
              // <ToolTipHelper  hover={tooltips[headCell.id].hover} modal={tooltips[headCell.id].modal} >

  return (
    <TableHead>
      <TableRow sx={{ alignItems: 'middle', align: 'right', backgroundColor: '#191b1e' }}>
        {headCells.map((headCell) => {
        
            return (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              bgColor={'gray'}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
                { headCell.label }
            </TableSortLabel>
          </TableCell>
        )})}
      </TableRow>
    </TableHead>
  );
}

// ================================|| UI LIST - SCROLLABLE ||================================ //

const ChannelPortfolio = () => {
  const classes = useStyles();
  const { currencySend, currencyGetAll, currencyExchange } = useDiveBarSocket();

  //test 
  document.title = `Takyon`;

  const dispatch = useDispatch();
  const theme = useTheme();
  const green = (theme.palette.mode === 'dark') ? colors.successLightTakyon : colors.successDarkTakyon;
  const red = (theme.palette.mode === 'dark') ? colors.errorLightTakyon : colors.errorDarkTakyon;
  const cornsilk = (theme.palette.mode === 'dark') ? colors.cornsilkLightTakyon : colors.cornsilkDarkTakyon;
  const chartContainerRef = useRef(new Array());
  const bgcolor = (theme.palette.mode === 'dark') ? '#00070f' : 'white';


  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("percent");
  const [transfer, setTransfer] = useState(0);
  const { coin, setCoin } = useContext(CoinContext);

  const assets = useSelector((state) => {
    return state.coins.kraken_asset_pairs;
  });

  const coin_style = map_coins.get(coin);
  const coin_name = map_coin_to_name.get(assets.get(coin).wsname)


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


    const { isCurrencySent } = useSelector((state) => { return state.diveBarClub});
    const { userId } = useSelector((state) => { return state.diveBarClub});
    const portfolio = useSelector((state) =>   state.diveBarClub.channelPortfolios.get(state.diveBarClub.openedRoom.id));
console.log({portfolio});
    console.log( 'ChannelPortfolio: isCurrencySent: ' + isCurrencySent);

    const openedRoom = useSelector((state) => {
        return state.diveBarClub.openedRoom;
    });

    const map_channels = useSelector((state) => {
        return state.diveBarClub.map_channels;
    });

    const current_channel = map_channels.get(openedRoom.id);

    const handleExchange = ((event) => {
        let dst_amt = Number(transfer) / assets.get(coin).ltp;
        console.log( openedRoom);
        console.log( 'handleExchange: ' + transfer + ', channel: ' + openedRoom.id);
        currencyExchange(assets.get(coin).wsname, dst_amt, "USD", Number(transfer), openedRoom.id);
        // currencyExchange("USD", transfer, "USD", transfer, openedRoom.id);
        setTimeout(()=>currencyGetAll(), 1000);
    });

  return (
    <> 
            <SubCard 
            title={
            <>
            <font style={{ fontSize: "1.1rem", textAlign: "left", color: 'white'}} >
                Channel Portfolio
            </font>
                <Grid container item xs={12} direction="rows">
                    <Grid item xs={12} lg={8}>
                        <font variant="h4" style={{color: 'orange'}}>{openedRoom?.name}</font>
                        <font variant="h4" style={{color: 'lightblue'}}>{'(' + openedRoom?.id + ')'}</font>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <a class={coin_style}> {coin_name} </a>
                    </Grid>
                      <Grid item xs={12}>
                      <MUIFormControl fullWidth>
                        <InputLabel></InputLabel>
                        <OutlinedInput
                          fullWidth
                          label="Amount"
                          name={"Amount"}
                          onChange={(e) => {
                            setTransfer(e.target.value);
                          }}
                          endAdornment={
                            <>
                              <Button
                                variant="contained"
                                align="center"
                                size="small"
                                label={"CREATE"}
                                color="success"
                                onClick={handleExchange}
                              >
                                Exchange
                              </Button>
                            </>
                          }
                        />
                      </MUIFormControl>
                    </Grid>
                </Grid>
            </>
            }
        >
                {/* <TableContainer >
                    <Table sx={{width: '100%'}} size="small" aria-label="LiqPortfolio">
                        <EnhancedTableHead
                          classes={classes}
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          backgroundColor={'#ccc'}
                    />
                        <TableBody>
                              {current_channel !== undefined && current_channel.ledger !== undefined && current_channel.ledger.map_fx.map((row, index) => {

                              const row_coin = assets.get(index);
                              let coin_style = "USDUSD";
                              let coin_name = "USD";
                              if ( row_coin !== undefined && row_coin.coin !== undefined)
                              {
                                  coin_style = map_coins.get(row_coin.coin);
                                  coin_name = map_coin_to_name.get(row_coin.wsname)
                                    ? map_coin_to_name.get(row_coin.wsname)
                                    : row_coin.wsname;
                              }

                              return (
                                <TableRow 
                                    hover 
                                    role="checkbox" 
                                    key={row.display_name}
                                >

                                    <TableCell>
                                        <font style={{ fontSize: "1.1rem", textAlign: "left", color: 'cornsilk'}} >
                                            <a class={coin_style}> {coin_name} </a>
                                        </font>
                                    </TableCell>
                                    <TableCell>
                                        <font style={{ fontSize: "1.1rem", textAlign: "left", color: 'cornsilk'}} >
                                        {Number(row).toFixed(2)}
                                        </font>
                                    </TableCell>
                                </TableRow>
                              );
                            })
                          }
                        </TableBody>
                    </Table>
                </TableContainer> */}

                <TableContainer >
                    <Table sx={{width: '100%'}} size="small" aria-label="LiqPortfolio">
                        <EnhancedTableHead
                          classes={classes}
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          backgroundColor={'#ccc'}
                    />
                        <TableBody>
                          {
                          portfolio &&   Object.keys(portfolio).map((key) =>(
                              <TableRow 
                                    hover 
                                    role="checkbox" 
                                    key={key}
                                >

                                    <TableCell>
                                        <font style={{ fontSize: "1.1rem", textAlign: "left", color: 'cornsilk'}} >
                                            <a className={map_coins.get(key)}> {key} </a>
                                        </font>
                                    </TableCell>
                                    <TableCell>
                                        <font style={{ fontSize: "1.1rem", textAlign: "left", color: 'cornsilk'}} >
                                        {Number(portfolio[key]).toFixed(2)}
                                        </font>
                                    </TableCell>
                                </TableRow>
                            ))
                          }
      
                        </TableBody>
                    </Table>
                </TableContainer>
        </SubCard>

    </>
  );
};
export default React.memo(ChannelPortfolio);
