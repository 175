import { Grid } from "@material-ui/core";
import React from "react";
import CenterLayout from "../CenterLayout";
// import OrderTicket from "ui-component/cards/OrderTicket";
import Basket from "../Basket";

const BasketLayout = () => {
  return (
        <Basket />
  );
};

export default BasketLayout;
