import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModalDiveBarClubStatus, setDiveBarClubRoomId } from "store/actions";
import MainCard from "ui-component/cards/MainCard";
import { appDrawerWidth as drawerWidth, gridSpacing } from "store/constant";
import {
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import useKraken from "hooks/useKraken";
import { makeStyles, useTheme } from "@material-ui/styles";
import UserAvatar from "./UserAvatar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
// assets
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";
import PerfectScrollbar from "react-perfect-scrollbar";
import SubCard from "ui-component/cards/SubCard";
import useAuth from "hooks/useAuth";
import useDiveBarSocket from "hooks/useDiveBarClubSocket";

import UserList from "./UserList";
import GroupsList from "./GroupsList";
import ChatMainPage from "views/application/chat";
import Chat from "./Chat";
// ===============================|| UI DIALOG - FORMS ||=============================== //

const useStyles = makeStyles((theme) => ({
  ScrollHeight: {
    // height: 'calc(100vh - 445px)',
    height: "200px",
    overflowX: "hidden",
    minHeight: "200px",
    [theme.breakpoints.down("md")]: {
      height: "calc(100vh - 190px)",
      minHeight: 0,
    },
  },
  contentClass: {
    padding: "0px",
  },
}));

export default function DiveBarClubCard() {
  const theme = useTheme();
  const classes = useStyles();

  const dispatch = useDispatch();

  const { isOpen, openedRoom } = useSelector((state) => state.diveBarClub);

  // const { isConnected, userCreate, isUserAuth } = useDiveBarSocket();

  // useEffect(() => {
  //   console.log("Litetakyonlayout: " + isConnected);
  //   if (isConnected && !isUserAuth) {
  //     const userNumber = Math.floor(Math.random() * (1 + 99999999 - 1)) + 1;
  //     const login = `${user.name}`;
  //     const password = `${user.id}`;
  //     const display_name = `${user.name}`;
  //     const email = `${user.email}`;
  //     userCreate(login, password, display_name, email);
  //   }
  // }, []);

  const { usersList, currencyGetEntity } = useDiveBarSocket();

  const { user } = useAuth();

  const handleCloseOpen = () => {
    dispatch(setModalDiveBarClubStatus(!isOpen));
  };

  const handleSelectRoom = (openedRoom) => {
    //TODO:user.roomId, or group.roomId.. etc
    dispatch(setDiveBarClubRoomId(openedRoom));

    //open room, fetch users
    // usersList(openedRoom.id);

    currencyGetEntity(openedRoom.id);
  };

  // useEffect(()=>{
  //   if(openedRoom.id === "") {
  //     handleSelectRoom()
  //   }
  // },[openedRoom])
  const w = openedRoom ? "260px" : "260px";
  console.log(user);
  return (
    <div
      style={{
        minHeight: "100px",
        width: "100%",
        // position: "fixed",
        // bottom: "1px",
        // left: "1px",
        // zIndex: 99999,
      }}
    >
      <SubCard
        alignItems="center"
        sx={{
          bgcolor: "dark.main",
          padding: 0,
        }}
        contentClass={classes.contentClass}
        // title={user.name}
        title={
          <Grid
            container
            // spacing={1}
            alignItems="center"
            sx={{ flexWrap: "nowrap" }}
          >
            <Grid item>
              <UserAvatar
                user={{
                  online_status: "available",
                  avatar: "avatar-1.png",
                  name: "User 1",
                }}
                w="30px"
                h="30px"
              />
            </Grid>

            <Grid item xs zeroMinWidth>
              <Typography align="center">{user.name}</Typography>
            </Grid>
          </Grid>
        }
      >
        {!isOpen && (
          <Grid item xs={12}>
            <Grid item xs zeroMinWidth>
              <Typography align="center" variant="h4" color={"#b9f6c9"}>
                DiveBarClub
              </Typography>
            </Grid>
          </Grid>
        )}

        {isOpen && (
          <Grid container xs={12}>
            <Grid item xs={openedRoom ? "12" : "12"}>
              <Grid container xs={12}>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                  <PerfectScrollbar className={classes.ScrollHeight}>
                    <GroupsList setUser={handleSelectRoom} />
                  </PerfectScrollbar>
                </Grid>
                <Grid item xs={12}>
                  <PerfectScrollbar className={classes.ScrollHeight}>
                    {/* <UserList setUser={handleSelectRoom} /> */}
                    <UserList setUser={() => {}} openedRoom={openedRoom} />
                  </PerfectScrollbar>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </SubCard>
    </div>
  );
}
