import { Grid } from "@material-ui/core";
import React from "react";
import TrendingSummarized from "../Summarized/TrendingSummarized";
import CandleChart from "../CandleChart";
import LiteOrderTicket from "ui-component/cards/LiteOrderTicket";
import SummaryCard from "ui-component/cards/SummaryCard";
import TrendingBuySummary from "../Summarized/components/TrendingBuySummary";
import TrendingSellSummary from "../Summarized/components/TrendingSellSummary";

const TrendingBuyLayout = () => {
  return (
    <Grid container spacing={2.0}>

        <Grid item xs={9.5} alignItems="center">
          <Grid container padding={1.0} />
          <CandleChart />
          <Grid container padding={1.0} />
          <TrendingSummarized />
        </Grid>

        <Grid item xs={2.5} alignItems="center">
          <Grid container padding={1.0} />
          <SummaryCard />
          <Grid container padding={1.0} />
          <LiteOrderTicket />
          <Grid container padding={1.0} />
          <TrendingBuySummary />
          <Grid container padding={1.0} />
          <TrendingSellSummary />
        </Grid>

    </Grid>
  );
};

export default TrendingBuyLayout;
