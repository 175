import React, {useSate} from 'react';

// third-party
import { FormattedMessage } from 'react-intl';
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
  Chip
} from "@material-ui/core";
import { withStyles, useTheme } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/styles';
import colors from 'assets/scss/_themes-vars.module.scss';
import ExchangeSection from "layout/LiteTakyonLayout/Header/ExchangeSection";
import LogoSection from "../../LogoSection";


// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconDeviceAnalytics };

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const CustomizedBadge = withStyles((theme) => ({
  badge: {
    right: -5,
    top: 0,
    border: `0px solid ${theme.palette.background.paper}`,
    padding: "0 0px",
  },
}))(Badge);


const Cart = () => {

    return (
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-shopping-cart-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
              <circle cx="6" cy="19" r="2" />
              <circle cx="17" cy="19" r="2" />
              <path d="M17 17h-11v-14h-2" />
              <path d="M6 5l6.005 .429m7.138 6.573l-.143 .998h-13" />
              <path d="M15 6h6m-3 -3v6" />
            </svg>
    );
};

const TrendingLastBuyTab = () => {
    const theme = useTheme();
    const rung = (theme.palette.mode === 'dark') ? colors.lightMenuTakyon : colors.darkMenuTakyon;

  // Let's get the count here.
  const alertCount = useSelector((state) => {
    if (
      state.coins.alerts === undefined ||
      state.coins.alerts.alertsTB === undefined
    )
      return 0;
    else return state.coins.alerts.alertsTB.size;
  });
  // console.log('TB: ' + alertCount);

  return (
    <Grid item>
      <IconButton sx={{fontSize:'1.1rem'}} aria-label="cart">
        <CustomizedBadge badgeContent={alertCount} color="error">
          <Typography variant="h3" fontWeight="normal" color={rung}>Trending Buy</Typography>
        </CustomizedBadge>
      </IconButton>
    </Grid>
  );
};
const TrendingLastSellTab = () => {
    const theme = useTheme();
    const rung = (theme.palette.mode === 'dark') ? colors.lightMenuTakyon : colors.darkMenuTakyon;

  // Let's get the count here.
  const alertCount = useSelector((state) => {
    if (
      state.coins.alerts === undefined ||
      state.coins.alerts.alertsTS === undefined
    )
      return 0;
    else return state.coins.alerts.alertsTS.size;
  });

  return (
    <Grid item>
      <IconButton  sx={{fontSize:'1.1rem'}} aria-label="cart">
        <CustomizedBadge badgeContent={alertCount} color="error">
          <Typography variant="h3" fontWeight="normal" color={rung}>Trending Sell</Typography>
        </CustomizedBadge>
      </IconButton>
    </Grid>
  );
};

const SummarizedTab = () => {

    const theme = useTheme();
    const rung = (theme.palette.mode === 'dark') ? colors.errorLightTakyon : colors.errorDarkTakyon;
    const mrung = (theme.palette.mode === 'dark') ? colors.lightMenuTakyon : colors.darkMenuTakyon;

  // Let's get the count here.
  const alertCountBuy = useSelector((state) => {
    if (
      state.coins.alerts === undefined ||
      state.coins.alerts.alertsTB === undefined
    )
      return 0;
    else return state.coins.alerts.alertsTB.size;
  });

    // Let's get the count here.
  const alertCountSell = useSelector((state) => {
    if (
      state.coins.alerts === undefined ||
      state.coins.alerts.alertsTS === undefined
    )
      return 0;
    else return state.coins.alerts.alertsTS.size;
  });

  const alertCount = alertCountBuy + alertCountSell;

  return (
    <Grid item>
      <IconButton  sx={{fontSize:'0.8rem'}} aria-label="cart">
        <CustomizedBadge badgeContent={alertCount} color="error">
          <Typography variant="h3" fontWeight="normal" color={mrung}>Trending Trades</Typography>
        </CustomizedBadge>
      </IconButton>
    </Grid>
  );
};


const ExploreTab = () => {
    const theme = useTheme();
    const rung = (theme.palette.mode === 'dark') ? colors.lightMenuTakyon : colors.darkMenuTakyon;

  // Let's get the count here.
  const alertCountBuy = useSelector((state) => {
    if (
      state.coins.alerts === undefined ||
      state.coins.alerts.alertsTB === undefined
    )
      return 0;
    else return state.coins.alerts.alertsTB.size;
  });

    // Let's get the count here.
  const alertCountSell = useSelector((state) => {
    if (
      state.coins.alerts === undefined ||
      state.coins.alerts.alertsTS === undefined
    )
      return 0;
    else return state.coins.alerts.alertsTS.size;
  });

  const alertCount = alertCountBuy + alertCountSell;

  return (
    <Grid item>
      <IconButton  sx={{fontSize:'1.1rem'}} aria-label="cart">
        <CustomizedBadge badgeContent={alertCount} color="error">
          <Typography variant="h4" fontWeight="normal" color={rung}>Explore</Typography>
        </CustomizedBadge>
      </IconButton>
    </Grid>
  );
};

const SmartWatchlistTab = () => {
    const theme = useTheme();
    const rung = (theme.palette.mode === 'dark') ? colors.lightMenuTakyon : colors.darkMenuTakyon;

  // Let's get the count here.
  const alertCountPositive = useSelector((state) => {
    if (
      state.coins.alerts === undefined ||
      state.coins.alerts.alertsSP === undefined
    )
      return 0;
    else return state.coins.alerts.alertsSP.size;
  });

  const alertCountNegative = useSelector((state) => {
    if (
      state.coins.alerts === undefined ||
      state.coins.alerts.alertsSN === undefined
    )
      return 0;
    else return state.coins.alerts.alertsSN.size;
  });

    const alertCount = alertCountPositive + alertCountNegative;

  return (
    <Grid item>
      <IconButton  sx={{fontSize:'0.8rem'}} aria-label="cart">
        <CustomizedBadge badgeContent={alertCount} color="error">
          <Typography variant="h4" fontWeight="normal" color={rung}>{'Breakout'}</Typography>
        </CustomizedBadge>
      </IconButton>
    </Grid>
  );
};

const VelocityTab = () => {
    const theme = useTheme();
    const rung = (theme.palette.mode === 'dark') ? colors.darkMenuTakyon : colors.lightMenuTakyon;

  // Let's get the count here.
  const alertCountVelNegative = useSelector((state) => {
    if (
      state.coins.alerts === undefined ||
      state.coins.alerts.alertsVN === undefined
    )
      return 0;
    else return state.coins.alerts.alertsVN.size;
  });

  const alertCountVelPositive = useSelector((state) => {
    if (
      state.coins.alerts === undefined ||
      state.coins.alerts.alertsVP === undefined
    )
      return 0;
    else return state.coins.alerts.alertsVP.size;
  });

  const alertCount = alertCountVelPositive + alertCountVelNegative;

  return (
    <Grid item>
      <IconButton  sx={{fontSize:'0.8rem'}} aria-label="cart">
        <CustomizedBadge badgeContent={alertCount} color="error">
          Velocity
        </CustomizedBadge>
      </IconButton>
    </Grid>
  );
};

const BasketBuyTab = () => {

    const theme = useTheme();
    const rung = (theme.palette.mode === 'dark') ? colors.successLightTakyon : colors.successDarkTakyon;

    console.log( 'Theme: ' + theme.palette.mode + ', ' + rung);

  return (
    <Grid item>
      <IconButton  sx={{fontSize:'0.8rem'}} aria-label="cart">
        <CustomizedBadge badgeContent={0} color="error">
          <Typography variant="h3" fontWeight="normal" color={rung}>Basket Trade</Typography>
        </CustomizedBadge>
      </IconButton>
    </Grid>
  );
};

const LiquidateTab = () => {

    const theme = useTheme();
    const rung = (theme.palette.mode === 'dark') ? colors.errorLightTakyon : colors.errorDarkTakyon;

  return (
    <Grid item>
      <IconButton  sx={{fontSize:'0.8rem'}} aria-label="cart">
        <CustomizedBadge badgeContent={0} color="error">
          <Typography variant="h3" fontWeight="normal" color={rung}>Liquidate</Typography>
        </CustomizedBadge>
      </IconButton>
    </Grid>
  );
};

const SettingsTab = () => {

    const theme = useTheme();
    const rung = (theme.palette.mode === 'dark') ? colors.errorLightTakyon : colors.errorDarkTakyon;
    const mrung = (theme.palette.mode === 'dark') ? colors.lightMenuTakyon : colors.darkMenuTakyon;

  return (
    <Grid item>
      <IconButton  sx={{fontSize:'0.8rem'}} aria-label="cart">
        <CustomizedBadge badgeContent={0} color="error">
          <Typography variant="h3" fontWeight="normal" color={mrung}>Settings</Typography>
        </CustomizedBadge>
      </IconButton>
    </Grid>
  );
};

const SupportTab = () => {

    const theme = useTheme();
    const rung = (theme.palette.mode === 'dark') ? colors.errorLightTakyon : colors.errorDarkTakyon;
    const mrung = (theme.palette.mode === 'dark') ? colors.lightMenuTakyon : colors.darkMenuTakyon;

  return (
    <Grid item>
      <IconButton  sx={{fontSize:'0.8rem'}} aria-label="cart">
        <CustomizedBadge badgeContent={0} color="error">
          <Typography variant="h3" fontWeight="normal" color={mrung}>Support</Typography>
        </CustomizedBadge>
      </IconButton>
    </Grid>
  );
};

const FAQTab = () => {

    const theme = useTheme();
    const rung = (theme.palette.mode === 'dark') ? colors.errorLightTakyon : colors.errorDarkTakyon;
    const mrung = (theme.palette.mode === 'dark') ? colors.lightMenuTakyon : colors.darkMenuTakyon;

  return (
    <Grid item>
      <IconButton  sx={{fontSize:'0.8rem'}} aria-label="cart">
        <CustomizedBadge badgeContent={0} color="error">
          <Typography variant="h3" fontWeight="normal" color={mrung}>FAQ</Typography>
        </CustomizedBadge>
      </IconButton>
    </Grid>
  );
};

const UploadTab = () => {

    const theme = useTheme();
    const rung = (theme.palette.mode === 'dark') ? colors.errorLightTakyon : colors.errorDarkTakyon;
    const mrung = (theme.palette.mode === 'dark') ? colors.lightMenuTakyon : colors.darkMenuTakyon;

  return (
    <Grid item>
      <IconButton  sx={{fontSize:'0.8rem'}} aria-label="cart">
        <CustomizedBadge badgeContent={0} color="error">
          <Typography variant="h3" fontWeight="normal" color={mrung}>Upload</Typography>
        </CustomizedBadge>
      </IconButton>
    </Grid>
  );
};

const TradesTab = () => {
    const theme = useTheme();
    const rung = (theme.palette.mode === 'dark') ? colors.darkMenuTakyon : colors.lightMenuTakyon;

  return (
    <Grid item>
      <IconButton  sx={{fontSize:'0.8rem'}} aria-label="cart">
        <CustomizedBadge badgeContent={0} color="error">
          Trades
        </CustomizedBadge>
      </IconButton>
    </Grid>
  );
};

const OrdersTab = () => {
    const theme = useTheme();
    const rung = (theme.palette.mode === 'dark') ? colors.darkMenuTakyon : colors.lightMenuTakyon;

  return (
    <Grid item>
      <IconButton  sx={{fontSize:'0.8rem'}} aria-label="cart">
        <CustomizedBadge badgeContent={0} color="error">
          Orders
        </CustomizedBadge>
      </IconButton>
    </Grid>
  );
};

const DetailedTab = () => {
    const theme = useTheme();
    const rung = (theme.palette.mode === 'dark') ? colors.lightMenuTakyon : colors.darkMenuTakyon;

  return (
    <Grid item>
      <IconButton  sx={{fontSize:'0.8rem'}} aria-label="cart">
        <CustomizedBadge badgeContent={0} color="error">
          <Typography variant="h3" fontWeight="normal" color={rung}>Favorites</Typography>
        </CustomizedBadge>
      </IconButton>
    </Grid>
  );
};

// const alertCountVelPositive = useSelector((state) => {
//   if (
//     state.coins.alerts === undefined ||
//     state.coins.alerts.alertsVP === undefined
//   )
//     return 0;
//   else return state.coins.alerts.alertsVP.size;
// });

// const alertCount = alertCountVelPositive + alertCountVelNegative;

const CountChip = () => {
  const alertCountVelPositive = useSelector((state) => {
    return state.coins.alerts.alertsVP.size + state.coins.alerts.alertsVN.size 
});

  return <span>{alertCountVelPositive}</span>
}

const lite = {
    id: 'lite',
    type: 'group',
    children: [
    //   {
    //     id: 'default',
    //     title: <FormattedMessage id="default" />,
    //     type: 'item',
    //     url: '/dashboard/default',
    //     icon: icons.IconDeviceAnalytics,
    //     breadcrumbs: false
    // },


    {
      id: 'oval-chip-menu',
      title: <FormattedMessage id="Explore" />,
      type: 'item',
      url: '/lite',
      icon: icons.IconCircle,
      // chip: {
      //     label: '9',
      //     color: 'warning'
      // },

      chip: {
        label:<CountChip />,
        color: 'warning',
        // avatar: <FormattedMessage id="c" />,
        // size: 'small'
    }
  },

        {
            id: 'explore',
            title: <ExploreTab />,
            type: 'item',
            url: '/lite/explore',
            breadcrumbs: false
        },
        /*
        {
            id: 'summarized',
            title: <SummarizedTab />,
            type: 'item',
            url: '/lite/summarized',
            breadcrumbs: false
        },
        */
        {
            id: 'swl',
            title: <SmartWatchlistTab />,
            type: 'item',
            url: '/lite/swl',
            breadcrumbs: false
        },
        /*
        {
            id: 'velocity',
            title: <VelocityTab />,
            type: 'item',
            url: '/lite/velocity',
            breadcrumbs: false
        },
        {
            id: 'trendingbuy',
            title: <TrendingLastBuyTab />,
            type: 'item',
            url: '/lite/trendingbuy',
            breadcrumbs: false
        },
        {
            id: 'trendingsell',
            title: <TrendingLastSellTab />,
            type: 'item',
            url: '/lite/trendingsell',
            breadcrumbs: false
        },
        */
        {
            id: 'oneclickbuy',
            title: <BasketBuyTab />,
            type: 'item',
            url: '/lite/oneclickbuy',
            breadcrumbs: false
        },
        {
            id: 'liquidate',
            title: <LiquidateTab />,
            type: 'item',
            url: '/lite/liquidate',
            breadcrumbs: false
        },
        {
            id: 'settings',
            title: <SettingsTab />,
            type: 'item',
            url: '/lite/settings',
            breadcrumbs: false
        },
        {
            id: 'support',
            title: <SupportTab />,
            type: 'item',
            url: '/lite/support',
            breadcrumbs: false
        },
        {
            id: 'faq',
            title: <FAQTab />,
            type: 'item',
            url: '/lite/faq',
            breadcrumbs: false
        },
        /*
        {
            id: 'upload',
            title: <UploadTab />,
            type: 'item',
            url: '/lite/upload',
            breadcrumbs: false
        },
        {
            id: 'trades',
            title: <TradesTab />,
            type: 'item',
            url: '/lite/trades',
            breadcrumbs: false
        },
        {
            id: 'orders',
            title: <OrdersTab />,
            type: 'item',
            url: '/lite/orders',
            breadcrumbs: false
        },
        */
    ]
};

export default lite;
