import React, { useContext } from "react";
import Panel from "./Panel";

import { useSelector, useDispatch } from "react-redux";
import { map_coins, map_coin_to_name } from "store/constant";
import { map_balance_to_coin } from "store/constant";
// import useKrakenOHLC from "hooks/useKrakenOHLC";
import { IconButton } from "@material-ui/core";
import { setKrakenInterval, KRAKEN_OHLC_INTERVAL, MOBILE_CHART_OPENED } from "store/actions";
import DailyHiLo from "../../../components/DailyHiLo";
import colors from "assets/scss/_themes-vars.module.scss";
import ToolTipHelper from 'ui-component/ToolTipHelper';
import {tooltips} from 'constants/tooltips';

// material-ui
import { useTheme } from "@material-ui/styles";
import { Grid, Box, Typography, Tab, Tabs } from "@material-ui/core";

// third party
import ApexCharts from "apexcharts";
import TakyonLightweightChart from "./Chart";

// project imports
import SubCard from "ui-component/cards/SubCard";

import CoinContext from "contexts/CoinContext";

// ===========================|| DASHBOARD ANALYTICS - MARKET SHARE AREA CHART CARD ||=========================== //

const MobileChartCard = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const cornsilk =
    theme.palette.mode === "dark"
      ? colors.cornsilkLightTakyon
      : colors.cornsilkDarkTakyon;
  const whiteTakyon =
    theme.palette.mode === "dark"
      ? colors.whiteLightTakyon
      : colors.whiteDarkTakyon;
  const green =
    theme.palette.mode === "dark"
      ? colors.successLightTakyon
      : colors.successDarkTakyon;
  const red =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;

  const { coin } = useContext(CoinContext);

  // const portfolio = useSelector((state) => {
  //   const ltp =
  //     (state.takyon.mapTrendingBuy.get(coin) &&
  //       state.takyon.mapTrendingBuy.get(coin).trade_price) ||
  //     0.0;

  //   if (state.takyon.portfolio) {
  //     let position = state.takyon.portfolio.mapPosition.get(coin);
  //     if (position !== undefined) {
  //       return [
  //         position.balance.toFixed(2),
  //         (position.balance * ltp).toFixed(2),
  //         position.real,
  //         position.unreal,
  //         position.avg,
  //       ];
  //     } else {
  //       return [0, 0, 0, 0, 0];
  //     }
  //   } else {
  //     return [0, 0, 0, 0, 0];
  //   }
  // });

  // const customization = useSelector((state) => state.customization);
  // const { navType } = customization;

  const coin_style = map_coins.get(coin);
  // const coin_name = map_coin_to_name.get(coin) ? map_coin_to_name.get(coin) : coin;
  const coin_name = useSelector((state) => {
    const row = state.coins.kraken_asset_pairs && state.coins.kraken_asset_pairs.get(coin) && state.coins.kraken_asset_pairs.get(coin) || undefined;
    if ( row === undefined)
    {
        return coin;
    }
    const coin_name = row.marketcap ?  row.marketcap.Name : (row.base_currency ? row.base_currency : (row.base ? row.base : row.wsname));
    return coin_name;
  });

  const ohlcInterval = useSelector((state) => state.chart.ohlcInterval);

  const setInterval = (interval) => dispatch(setKrakenInterval(interval));

  const intervals = [
    { interval: "1", title: "1m" },
    { interval: "5", title: "5m" },
    { interval: "15", title: "15m" },
    { interval: "60", title: "1h" },
    { interval: "360", title: "6h" },
    { interval: "1440", title: "1d" },
  ];

  // colorMap = colorMap.set(ohlcInterval, 'blue');

  const ltp = useSelector((state) => {
     return (
                (state.coins.kraken_asset_pairs_loaded                  &&
                 state.coins.kraken_asset_pairs.get(coin)               &&
                 state.coins.kraken_asset_pairs.get(coin).ltp
                 )
                 || undefined);
  });

  const daily = useSelector((state) => {
     return (
                (state.coins.kraken_asset_pairs_loaded                  &&
                 state.coins.kraken_asset_pairs.get(coin)               &&
                 state.coins.kraken_asset_pairs.get(coin).daily
                 )
                 || undefined);
  });

  const daily_hi = useSelector((state) => {
     return (
                (state.coins.kraken_asset_pairs_loaded                  &&
                 state.coins.kraken_asset_pairs.get(coin)               &&
                 state.coins.kraken_asset_pairs.get(coin).daily_hi
                 )
                 || 0.0);
  });
  const daily_lo = useSelector((state) => {
     return (
                (state.coins.kraken_asset_pairs_loaded                  &&
                 state.coins.kraken_asset_pairs.get(coin)               &&
                 state.coins.kraken_asset_pairs.get(coin).daily_lo
                 )
                 || 0.0);
  });
  const daily_percent = useSelector((state) => {
     return (
                (
                 state.coins.kraken_asset_pairs.get(coin)               &&
                 state.coins.kraken_asset_pairs.get(coin).hilo_percent
                 )
                 || 0.0);
  });

  return (
        <SubCard >
          <Grid container spacing={1} padding={1} alignItems="space-between">


            <Grid item xs={8}  >
              <Panel ltp={ltp} daily_hi={daily_hi} daily_lo={daily_lo} daily_percent={daily_percent} />
            </Grid>
            <Grid item xs={3}>
              <font style={{ spacing: "10px", "font-size": "0.9rem" }} class={coin_style} >
                  {coin_name}{" "}
                </font>
            </Grid>
            <Grid item xs={1} onClick={() => {return dispatch({type: MOBILE_CHART_OPENED, payload: {is_mobile_chart_opened: false}});}}>
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-back-up" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M9 13l-4 -4l4 -4m-4 4h11a4 4 0 0 1 0 8h-1" />
                </svg>
            </Grid>
            <Grid item xs={9}  align="center" justifyContent="center" alignItems="center" >
                {intervals.map((intervalItem) => (
                  <IconButton
                    key={intervalItem.interval}
                    sx={{
                      color:
                        intervalItem.interval === ohlcInterval
                          ? cornsilk
                          : colors.grey600,
                    }}
                    size="small"
                    onClick={() => setInterval(intervalItem.interval)}
                  >
                    {intervalItem.title}
                  </IconButton>
                ))}
            </Grid>
            <Grid item xs={3}  >
                <DailyHiLo dh={daily_hi} dl={daily_lo} ltp={ltp} avg_px={0} />
            </Grid>
          </Grid>
          <TakyonLightweightChart />
        </SubCard>
  );
};
export default MobileChartCard;
