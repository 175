import { Grid } from "@material-ui/core";
import React from "react";
import VirtualCardsMain from "../VirtualCards/VirtualCardsMain";

const VirtualCardsLayout = () => {
  return (
    <Grid container spacing={2.0}>

        <Grid item xs={12} alignItems="center">
          <Grid container padding={1.0} />
          <VirtualCardsMain />
        </Grid>

        <Grid item xs={2.5} alignItems="center">
        </Grid>

    </Grid>
  );
};

export default VirtualCardsLayout;
