import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import colors from "assets/scss/_themes-vars.module.scss";
import { SET_MENU, addVelocityNotify } from "store/actions";

import TakyonLogoLight from "assets/images/MishtiTakyonLightMini.png";
import TakyonLogoDark from "assets/images/MishtiTakyonDarkMiniBeta.png";
import BuySellBtn from "assets/images/explore/buysellbtn.png";

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import { IconMenu2 } from '@tabler/icons';
import {
  Button,
  ButtonBase,
  IconButton,
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import ColorSubCard from "../ColorSubCard";
import SubCard from "../SubCard";
import ToolTipHelper from "ui-component/ToolTipHelper";
import { tooltips } from "constants/tooltips";
import CoinBaseLoginButton from "ui-component/CoinBaseLoginButton";
// style constant
const useStyles = makeStyles((theme) => ({
  revenueCard: {
    position: "relative",
    color: "#fff",
  },
  revenueIcon: {
    position: "absolute",
    right: "13px",
    top: "14px",
    color: "#fff",
    "&> svg": {
      width: "100px",
      height: "100px",
      opacity: "0.5",
    },
    [theme.breakpoints.down("xs")]: {
      top: "13px",
      "&> svg": {
        width: "40px",
        height: "40px",
      },
    },
  },
  card: {},
}));

// =============================|| REVENUE CARD ||============================= //

const MobileSummaryCard = () => {
  const classes = useStyles();
  // const [timer, setTimer] = useState(Date.now() / 1000);
  const [revenue, setRevenue] = useState(0.0);
  //   const [cash, setCash] = useState(0.0);
    const dispatch = useDispatch();

  const theme = useTheme();
  const matchDownXs = useMediaQuery(theme.breakpoints.down("xs"));
  const cornsilk =
    theme.palette.mode === "dark"
      ? colors.cornsilkLightTakyon
      : colors.cornsilkDarkTakyon;
  const whiteTakyon =
    theme.palette.mode === "dark"
      ? colors.whiteLightTakyon
      : colors.whiteDarkTakyon;
  const green =
    theme.palette.mode === "dark"
      ? colors.successLightTakyon
      : colors.successDarkTakyon;
  const red =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;

  const [selectedExchange, exchanges] = useSelector((state) => [
    state.exchange.selectedExchange,
    state.exchange.exchanges,
  ]);

  let textc = "lightyellow";
  if (theme.palette.background.paper === "#ffffff") {
    textc = "darkred";
  }

  let usd = useSelector((state) => {
    return state.coins.newportfolio?.total_cash || 0;
  });

  let total_balance = useSelector((state) => {
    return state.coins.newportfolio?.total_balance || 0;
  });

  //   //TODO! CoinReducer: 392
  //   let total_balance = useSelector((state) => {
  //     let balance = 0;
  //     if (state.coins.kraken_asset_pairs !== undefined) {
  //       state.coins.kraken_asset_pairs.forEach((item) => {
  //         if (item.position !== undefined) {
  //           console.log("SummaryCard:" + item.position.balance);
  //           balance += item.position.balance * item.position.market_price;
  //         }
  //       });
  //       return Number(balance);
  //     } else {
  //       console.log("SC: asset_pairs === undefined");
  //       return Number(0);
  //     }
  //   });

  useEffect(() => {
    //i will update unly on 15 sec!
    if (total_balance !== 0) {
      let rev = total_balance + usd;
      setRevenue(rev);
      //   setCash(usd);
      console.log(
        "SC: rev: " + rev + ", bal: " + total_balance + ", usd: " + usd
      );
    }
  }, [total_balance, usd]);


    // Handle left drawer
  const leftDrawerOpened = useSelector((state) => {
    let item = state.customization.opened;
    return state.customization.opened;
  });

  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  return (
    <>
      <table style={{ align: "right" }}>
        <tr>
          <td>
          {theme.palette.mode === 'dark' && <img src={TakyonLogoDark} style={{width:95, height:35, }}/>}
          {theme.palette.mode !== 'dark' && <img src={TakyonLogoLight} style={{width:95, height:35, }}/>}
          </td>
          <td>
              <Button sx={{ background: 'inherit', borderRadius: '12px', overflow: 'hidden' }}>
                  <Avatar sx={{background: 'inherit', color: 'white'}} variant="rounded" className={classes.headerAvatar} onClick={handleLeftDrawerToggle} color="white">
                      <IconMenu2 stroke={1.5} size="1.3rem" />
                  </Avatar>
              </Button>
          </td>
          <td>
            <font
              style={{
                textAlign: "right",
                fontWeight: "normal",
                "font-size": "1.5rem",
              }}
            >
              {" "}
              ${revenue.toFixed(0)}{" "}
            </font>
          </td>
          <td>
              <font
                style={{
                  color: textc,
                  textAlign: "right",
                  fontWeight: "normal",
                  "font-size": "0.8rem",
                }}
              >
                {"bal"}
              </font>
          </td>
          <td style={{ textAlign: "right" }}>
            <font
              style={{
                textAlign: "right",
                fontWeight: "normal",
                "font-size": "1.5rem",
              }}
            >
              ${usd.toFixed(0)}{" "}
            </font>
          </td>
          <td style={{ textAlign: "right" }}>
            <font
              style={{
                color: "gray",
                textAlign: "right",
                fontWeight: "normal",
                "font-size": "0.8rem",
              }}
            >
              {" "}
              {((usd * 100) / revenue).toFixed(0)}
              {"% "}
            </font>
          </td>
          <td>
            <font
              style={{
                color: textc,
                textAlign: "right",
                fontWeight: "normal",
                "font-size": "0.5rem",
              }}
            >
              {"USD"}
            </font>
          </td>
        </tr>
      </table>
      {selectedExchange === "noExchange" && <CoinBaseLoginButton />}
    </>
    /*
            <>
                <font style={{color: 'gray', textAlign: 'middle', fontWeight: 'normal', spacing: "10px", "font-size": "1.0rem" }} className={classes.secondary}> 
                    {'Portfolio'}
                </font>
                <font style={{textAlign: 'middle', fontWeight: 'normal', spacing: "10px", "font-size": "1.0rem" }} className={classes.secondary}> 
                    {revenue} 
                </font>
           </>
            */
  );
};

MobileSummaryCard.propTypes = {
  content: PropTypes.string,
  color: PropTypes.string,
};

export default MobileSummaryCard;
