import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Grid  } from "@material-ui/core";
import DailyHiLo from "../../components/DailyHiLo";

import {
  createChart,
  createPriceLine,
  CrosshairMode,
} from "lightweight-charts";
import CoinContext from "contexts/CoinContext";
import { useSelector, useDispatch } from "react-redux";
import I from "immutable";
import axios from "axios";
// 1. No duplicates in the CandleStick Time Series
// 2. All time values must be Ascending order (increasing)
// 3. No NULL values in OHLC for that timestamp

const MarketShareAreaChartCard = () => {
  const chartContainerRef = useRef();
  const chart = useRef();
  const series = useRef();
  const avgLine = useRef();
  const priceLine = useRef();

  const resizeObserver = useRef();
  const { coin } = useContext(CoinContext);

  const ltp = useSelector((state) => {
    return ((state.coins.kraken_asset_pairs_loaded && state.coins.kraken_asset_pairs.get(coin) && state.coins.kraken_asset_pairs.get(coin).ltp) || 0.0);
  });

  const portfolio = useSelector((state) => {
    if (state.coins.kraken_asset_pairs) {
      let item = state.coins.kraken_asset_pairs.get(coin);
      if ((item !== undefined) && (item.position !== undefined)) {
        return [
          item.position.remaining_qty.toFixed(2),
          (item.position.remaining_qty * ltp).toFixed(2),
          item.position.realized,
          item.position.unrealized,
          item.position.remaining_avg_price,
        ];
      } else {
        return [0, 0, 0, 0, 0];
      }
    } else {
      return [0, 0, 0, 0, 0];
    }
  });

  const avg_px = portfolio[4];
  let avg_ts = 0;
  let avg_df = 1000000.0;

  const lastTrade = useSelector((state) => {
    if (state.chart.mapLastTrade) {
      return state.chart.mapLastTrade.get(coin);
    } else {
      return undefined;
    }
  });

  const mapOrders = useSelector((state) => {
    if (state.chart.mapOrders !== undefined) {
      return state.chart.mapOrders.get(coin);
    } else {
      return [];
    }
  });

  const lwMarkers = useSelector((state) => {
    return state.chart.lwMarkers;
  });

  const lineData = useSelector((state) => {
     return (state.chart.lineData[coin] || state.chart.lineData["aud/usd"]);
  });

  const lwData = useSelector((state) => {
    state.chart.lwData[0].data =
      state.chart.lwChartData[coin] || state.chart.lwChartData["aud/usd"];
    // return state.chart.lwChartData["aud/usd"];

    if (state.chart.chartData[coin] !== undefined) {
      // Let's format this
      let newdata = I.Map();
      state.chart.chartData[coin].forEach((item, index) => {
        const d = new Date(item[0]).toUTCString();
        newdata = newdata.set(Number(item[0]), {
          time: Number(item[0]),
          open: Number(item[1]).toString(),
          high: Number(item[2]).toString(),
          low: Number(item[3]).toString(),
          close: Number(item[4]).toString(),
        });
      });
      // Let's get an array and sort.
      let fdata = [];
      newdata.forEach((item, index) => {
        fdata = [...fdata, item];
      });
      fdata.sort(function (a, b) {
        // console.log( a.time + ' and ' + b.time);
        return a.time - b.time;
      });

      return fdata;
      // return state.chart.lwChartData["algo/usd"];
    } else {
      return state.chart.lwChartData["btc/usd"];
    }
  });

  const interval = useSelector((state) => {
    return state.chart.ohlcInterval;
  });

  let is_dirty =
    coin +
    " " +
    lwData.length +
    " " +
    interval +
    " " +
    lwData[lwData.length - 1].close;

  let ordersX = lwData[lwData.length - 1].time;

  useEffect(() => {
    // init chart, do not modify here
    chart.current = createChart(chartContainerRef.current, {
      height: 300,
      layout: {
        backgroundColor: "#00070f",
        textColor: "rgba(255, 255, 255, 0.9)",
      },
      grid: {
        vertLines: {
            visible: false,
            color: "#111",
        },
        horzLines: {
            visible: false,
            color: "#111",
        },
      },
      crosshair: {
        vertLine: {
          color: "#333",
          width: 0.1,
          style: 0,
          visible: true,
          labelVisible: true,
        },
        horzLine: {
          color: "#333",
          width: 0.1,
          style: 0,
          visible: true,
          labelVisible: true,
        },
        mode: CrosshairMode.Normal,
      },
      priceScale: {
        borderColor: "#888",
        borderVisible: false,
      },
      alignLabels: true,
      timeScale: {
        rightOffset: 10,
        barSpacing: 8,
        fixLeftEdge: false,
        lockVisibleTimeRangeOnResize: true,
        rightBarStaysOnScroll: true,
        borderVisible: false,
        borderColor: "#888",
        visible: true,
        timeVisible: true,
        secondsVisible: true,
      },
    });

    // upColor: "#4bffb5",
    // downColor: "#ff4976",
    /*
    const candleSeries = chart.current.addCandlestickSeries({
      upColor: "#000000",
      downColor: "#ff4976",
      borderUpColor: "#4bffb5",
      borderDownColor: "#ff4976",
      wickUpColor: "#4bffb5",
      wickDownColor: "#ff4976",
    });
    candleSeries.setData([]);
    */

    const lineSeries = chart.current.addLineSeries({
      borderUpColor: "#4bffb5",
      borderDownColor: "#ff4976",
      color: 'white',
      lineWidth:1,
      lineType: 0,
      lineStyle: 0,
      animation: true,
      transition: 'smooth',
    });
    lineSeries.setData([]);

    avgLine.current = lineSeries.createPriceLine({
      price: "0.00",
      color: "orange",
      lineWidth: -1,
      lineStyle: 0,
      axisLabelVisible: true,
      title: "avg",
    });

    /*
    priceLine.current = candleSeries.createPriceLine({
      price: "0.00",
      color: "#f24876",
      lineWidth: -1,
      lineStyle: 0,
      axisLabelVisible: true,
      title: "",
    });
    */

    series.current = lineSeries;

    /// REMOVE THIS, only for auth docker
    axios.interceptors.request.use((config) => {
      config.params = {
        ...config.params,
        token:
          "xxNwCb8HxdCMqPSNKnnYthAF912BijMof+RP7S3XecOuoOon1qrodoXd5/CdW45mX4C4U6M2gTq7oiYlY+mhsQ==",
      };
      return config;
    });
    /// REMOVE THIS, only for auth docker
  }, []);

  useEffect(() => {
    //update chart data here
    if (series.current) {
      series.current.setData(lineData);

      avgLine.current.applyOptions({
        price: avg_px,
      });

      /*
      priceLine.current.applyOptions({
        price: ltp,
      });
      */

      let markers = [];
      if (lastTrade !== undefined) {
        for (let i = 0; i < lastTrade.length; ++i) {
          if (lastTrade[i] !== undefined) {
            if (lastTrade[i].is_buy === true) {
              // markers.push( {time: lastTrade[i].time_ms/1000, size: 3, shape: "circle", position: "inBar", color: "#84ed66", text: "$" + lastTrade[i].trade_cost.toFixed(2)});
              markers.push({
                time: lastTrade[i].time_ms / 1000,
                size: 2.4,
                shape: "circle",
                position: "inBar",
                color: "white",
                text: "",
              });
              markers.push({
                time: lastTrade[i].time_ms / 1000,
                size: 2.2,
                shape: "circle",
                position: "inBar",
                color: "black",
                text: "",
              });
              markers.push({
                time: lastTrade[i].time_ms / 1000,
                size: 1.4,
                shape: "circle",
                position: "inBar",
                color: "#84dc44",
                text: "",
              });
            } else {
              // markers.push( {time: lastTrade[i].time_ms/1000, size: 2, shape: "circle", position: "inBar", color: "rgb(255, 70, 70)", text: "$" + lastTrade[i].trade_cost.toFixed(2)});
              markers.push({
                time: lastTrade[i].time_ms / 1000,
                size: 2.4,
                shape: "circle",
                position: "inBar",
                color: "white",
                text: "",
              });
              markers.push({
                time: lastTrade[i].time_ms / 1000,
                size: 2.2,
                shape: "circle",
                position: "inBar",
                color: "black",
                text: "",
              });
              markers.push({
                time: lastTrade[i].time_ms / 1000,
                size: 1.4,
                shape: "circle",
                position: "inBar",
                color: "rgb(255, 30, 30)",
                text: "",
              });
            }
          }
        }
      }

      series.current.setMarkers(markers);
    }

    // if ( (lwData !== undefined) && (lwData.length > 1))
    // {
    //     let ldata = [];

    //     // Get the last timestamp of the candlestick.
    //     // const last_ts = [lwData[lwData.length-1].time, lwData[lwData.length-2].time];

    //     /*
    //     for( let i = 0; i < mapOrders.length; ++i)
    //     {
    //         const cost = (mapOrders[i].qty * mapOrders[i].px).toFixed(2);
    //         const rung = mapOrders[i].side === "SELL" ? "Sell" : "Buy";
    //         series.current.createPriceLine({
    //             price: mapOrders[i].px,
    //             color: '#00070f',
    //             lineWidth: -1,
    //             lineStyle: 0,
    //             lineVisible: false,
    //             axisLabelVisible: true,
    //             title: rung + ' $' + cost,
    //         });

    //         // ldata.push( {time: last_ts[1], open: mapOrders[i].px, high: mapOrders[i].px, low: mapOrders[i].px, close: mapOrders[i].px});
    //     };
    //     */
    // }
  }, [lwData, series]);

  return (
    <Grid container alignItems="center" flexDirection="row">
      <Grid item  style={{width:'100%'}}>
        <div >
          <div ref={chartContainerRef} className="chart-container" />
        </div>
      </Grid>
      {/*
      <Grid item style={{width:'10%'}}>
        <DailyHiLo />
      </Grid>
      */}
    </Grid>
  );
};
export default React.memo(MarketShareAreaChartCard);
