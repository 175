// import axios from "utils/axios";
import Config from 'config';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addPortfolio } from "store/actions";

const getData = async (u, start, end, id) => {

    let startDate = start.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).replace(/\//g, '-').split('-').reverse().join('-');

    let endDate = end.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).replace(/\//g, '-').split('-').reverse().join('-');

    // Let's get Portfolio here
    if ( id === undefined)
    {
        id = Number(Date.now()).toString();

    }
    let url = 'http://'+Config.takyon_domain+'/api/v1/pnl?from=' + startDate + '&to=' + endDate + "&id=" + id;


  const response = await axios.post(
    url,
    u,
    {
      headers: {
        Host: Config.takyon_domain+"/api/v1",
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return response.data;
};

export default function usePortfolio() {
  const dispatch = useDispatch();

  const startDate = useSelector((state) => {
     return state.takyon.start_date;
   });

   const endDate = useSelector((state) => {
     return state.takyon.end_date;
   });

  const fetchPortfolio = (start, end) => {

    if ( start === undefined)
    {
        start = new Date('May 31, 2021 23:59:59');
    }

    if ( end === undefined)
    {
        end = new Date('December 31, 2021 23:59:59');
    }



    getData({}, start, end).then((data) => {

      dispatch(addPortfolio(data));
    });
  };

  return {
    fetchPortfolio,
  };
}
