import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { IconSettings } from "@tabler/icons";
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
} from "@material-ui/core";
import { withStyles, useTheme } from "@material-ui/styles";
import {
  setLastTrade,
  RESET_ALERT_TRENDING_BUY,
  RESET_ALERT_TRENDING_SELL,
  RESET_ALERT_SWL_POSITIVE,
  RESET_ALERT_SWL_NEGATIVE,
  RESET_ALERT_VEL_POSITIVE,
  RESET_ALERT_VEL_NEGATIVE,
} from "store/actions";

import FavoriteButton from "../../components/FavoriteButton";

import { useSelector, useDispatch } from "react-redux";

// material-ui
import { makeStyles } from "@material-ui/styles";
import ListItemButton from "@material-ui/core/ListItemButton";
// import useKrakenOHLC from "hooks/useKrakenOHLC";
import "styles/coins.css";
import { map_coins, map_coin_to_name } from "store/constant";

import { List } from "@material-ui/core";
import CoinContext from "contexts/CoinContext";

// import ListItemButton from '@material-ui/core/ListItemButton';
// style constant
const useStyles = makeStyles((theme) => ({
  root: {
    height: 400,
    width: "100%",
    overflowX: 'hidden',
    minHeight: 400,
    backgroundColor: theme.palette.background.paper,
  },
  avatarSuccess: {
    width: "16px",
    height: "16px",
    borderRadius: "5px",
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
    marginRight: "5px",
  },
  avatarError: {
    width: "16px",
    height: "16px",
    borderRadius: "5px",
    backgroundColor: theme.palette.orange.light,
    color: theme.palette.orange.dark,
    marginRight: "5px",
  },
  ScrollHeight: {
        height: '400px',
        overflowX: 'hidden',
        minHeight: '400px',
        [theme.breakpoints.down('md')]: {
            height: 'calc(100vh - 190px)',
            minHeight: 0
        }
  },
}));

const CustomizedBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

const AlertItemPercent = (alertCount, rung, percent) => {
  // if (alertCount !== "1") {
  return (
    <Typography variant="h2" sx={{ color: rung }}>
      {percent}%
    </Typography>
  );
  // } else {
  //   return (
  //     <IconButton aria-label="cart">
  //       <CustomizedBadge badgeContent={alertCount} color="error">
  //         <Typography variant="h2" sx={{ color: rung }}>
  //           {percent}%
  //         </Typography>
  //       </CustomizedBadge>
  //     </IconButton>
  //   );
  // }
};

const Item = ({ item, selected, handleListItemClick }) => {
  const customization = useSelector((state) => state.customization);

  const classes = useStyles();
  const move = item.move === "↓↓↓" ? "down" : "up";
  const rung = item.move === "↓↓↓" ? "#FAA" : "rgb(132, 237, 102)";
  // const pnl = ((item.price - item.position) * item.qty).toFixed(3);
  const cost = (item.position * item.qty).toFixed(3);
  const coin_style = map_coins.get(item.coin);
  const coin_name = (map_coin_to_name.get(item.coin)) ? map_coin_to_name.get(item.coin) : item.coin;

  const alertCount = item.alerted === true ? "1" : " ";
  return (
    <ListItemButton
      selected={selected}
      onClick={(event) => handleListItemClick(event, item.coin)}
      sx={{ borderRadius: `${customization.borderRadius}px` }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="subtitle1" color="inherit">
                <a class={coin_style}>
                {coin_name} 
                </a> 
                {/* <FavoriteButton pair={item.coin} />  */}
            
                {/* <a class={coin_style} style={{height: '22px', verticalAlign: 'top', textAlign: 'center', fontSize: '0.8rem', width: '80px'}}>
                    {item.coin} 
                </a>  */}
              </Typography>
            </Grid>

            <Grid item>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item>
                  <Typography variant="subtitle1" color={{ rung }}>
                    <font
                      style={{
                        color: rung,
                        "text-align": "right",
                        "font-size": "0.8rem",
                      }}
                    >
                      {item.move}
                      {"   "}
                      {item.price}
                    </font>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Grid item>{AlertItemPercent(alertCount, rung, item.percent)}</Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography
                variant="subtitle1"
                sx={{ align: "right", color: rung }}
              >
                {cost}{" "}
                <font
                  style={{
                    color: "white",
                    "text-align": "right",
                    "font-size": "0.8rem",
                  }}
                >
                  cost
                </font>
                <FavoriteButton pair={item.coin} /> 
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle1"
                sx={{ align: "right", color: rung }}
              >
                {item.avg} <font color="white">avg</font>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ position: "absolute", top: "1px", right: "3px" }}>
        {alertCount !== ' ' && (
          <IconButton aria-label="cart">
            <CustomizedBadge badgeContent={alertCount} color="error">
              {/* <Typography variant="h2" sx={{ color: rung }}>
                {percent}%
              </Typography> */}
            </CustomizedBadge>
          </IconButton>
        )}
      </div>
    </ListItemButton>
  );
};

// ================================|| UI LIST - SCROLLABLE ||================================ //

export default function VirtualizedList({ type, items }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedIndex, setSelectedIndex] = React.useState("0");
  const { coin, setCoin } = useContext(CoinContext);

  // const { fetchOHLC } = useKrakenOHLC();
  // const { fetchBalance } = useBalance();

  // const ohlcInterval = useSelector((state) => {
  //   return state.chart.ohlcInterval;
  // });

  const listTrades = useSelector((state) => {
    let items = [];
    if (state.takyon.mapTrades !== undefined) {
      let count = 0;
      state.takyon.mapTrades.forEach((item, i) => {
        if (item.pair === coin) {
          items.push(item);
        }
      });
    }
    // return items;
    items.sort(function (a, b) {
      return b.time_ms - a.time_ms;
    });

    return items;
  });

  useEffect(() => {
    dispatch(
      setLastTrade({
        coin: coin,
        trade: [listTrades[0], listTrades[1], listTrades[2], listTrades[3]],
      })
    );
  }, [coin, listTrades[0]]);

  const handleListItemClick = (event, coin) => {
    console.log( 'TREND: coin: ' + coin);
    setSelectedIndex(coin);
    setCoin(coin);
    // mapSWLPositive.ohlc.size < 500 && fetchOHLC(coin);
    // fetchOHLC(coin, ohlcInterval); // default interval
    // fetchBalance();

    // Let's reset the alert for this coin.
    if (type === "TrendingBuy") {
      dispatch({ type: RESET_ALERT_TRENDING_BUY, payload: { coin: coin } });
    } else if (type === "TrendingSell") {
      dispatch({ type: RESET_ALERT_TRENDING_SELL, payload: { coin: coin } });
    }
  };

  return (
      <List
        component="nav"
        aria-label="main mailbox folders"
        style={{ maxHeight: "100%", overflowX: "hidden" }}
      >
    <PerfectScrollbar className={classes.ScrollHeight}>
        {items.map((item) => (
          <Item
            key={item.coin}
            item={item}
            selected={selectedIndex === item.coin}
            handleListItemClick={handleListItemClick}
          />
        ))}
    </PerfectScrollbar>
      </List>
  );
}
