import React, { useEffect, useContext } from "react";
import TradesCard from "./components/TradesCard.js";
import { useDispatch, useSelector } from "react-redux";
import CoinContext from "contexts/CoinContext";
import {setLastTrade} from "store/actions";


const TradesCoin = () => {
    const { coin } = useContext(CoinContext);

    const dispatch = useDispatch();

    const listTrades = useSelector((state) => {
        let items = [];
        if (state.takyon.mapTrades !== undefined) {
          let count = 0;
          state.takyon.mapTrades.forEach((item, i) => {
            if (item.pair === coin) {
              items.push(item);
            }
          });
        }
        return items;
    });

    listTrades.sort(function(a, b) {
        // console.log( a.time_ms + ' and ' + b.time_ms);
        return (b.time_ms - a.time_ms);
    });

    useEffect(() => {
        console.log( 'coin: dispatching LastTrade: ' + coin);
        let len = (listTrades.length < 20) ? listTrades.length : 20;

        let trades = [];
        for( let i = 0; i < len; ++i)
        {
            trades = [...trades, listTrades[i]];
        }
        console.log(trades);
        dispatch(setLastTrade({coin: coin, trade: trades}));

    }, listTrades[0]);

    
  return (
    <TradesCard
      title="Trades"
      rows={listTrades}
    />
  );
};

export default TradesCoin;
