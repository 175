import React, { useEffect, useState } from "react";
import { gridSpacing } from "store/constant";
import { makeStyles } from "@material-ui/styles";



  
import MUIFormControl from "@material-ui/core/FormControl";

// material-ui
import {
  Autocomplete,
  Grid,
  Paper,
  InputAdornment,
  Box,
} from "@material-ui/core";

import {
  Divider,
  OutlinedInput,
  InputLabel,
  IconButton,
  Chip,
  Fab,
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({

  root: {
    width: '100%',
    // maxWidth: 360,
    minHeight: 200,
    minWidth: 500,
    backgroundColor: theme.palette.background.paper
},
buttonRoot: {
  minWidth: 500
}
}));

const SearchInput = ({handleSearch}) => {


    const handleChange = (e) => {
      handleSearch(e.target.value);
  };



  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
            <MUIFormControl fullWidth>
              <InputLabel>Search</InputLabel>
              <OutlinedInput
                fullWidth
                label="Search"
                name={"search"}
                onChange={handleChange}
              />
            </MUIFormControl>


</Grid>
 
         
      </Grid>
  );
};

export default SearchInput;
