import React, { lazy } from 'react';

// project imports
import TakyonLayout from 'layout/TakyonLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// takyon routing
const MainLayout = Loadable(lazy(() => import('views/dashboard/DashboardTakyon/MainLayout')));
const ProfileSettings = Loadable(lazy(() => import('views/Profile')));

// ===========================|| MAIN ROUTING ||=========================== //

const TakyonRoutes = {
    path: '/takyon',
    element: (
        <AuthGuard>
            <TakyonLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dash',
            element: <MainLayout />,
        },
        
        {
            path: '/dash/swl',
            element: <MainLayout />,
        },
        
        {
            path: '/settings',
            element: <ProfileSettings />
        }
    ]
};

export default TakyonRoutes;
