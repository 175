import lite from "./lite";
import { IconDashboard, IconDeviceAnalytics } from "@tabler/icons";
import { FormattedMessage } from "react-intl";
import { withStyles, useTheme } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import colors from "assets/scss/_themes-vars.module.scss";
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
  Chip,
} from "@material-ui/core";

const CustomizedBadge = withStyles((theme) => ({
  badge: {
    right: -5,
    top: 0,
    border: `0px solid ${theme.palette.background.paper}`,
    padding: "0 0px",
  },
}))(Badge);

const Cart = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-shopping-cart-plus"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="#2c3e50"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <circle cx="6" cy="19" r="2" />
      <circle cx="17" cy="19" r="2" />
      <path d="M17 17h-11v-14h-2" />
      <path d="M6 5l6.005 .429m7.138 6.573l-.143 .998h-13" />
      <path d="M15 6h6m-3 -3v6" />
    </svg>
  );
};

const ExploreTab = () => {
  const theme = useTheme();
  const rung =
    theme.palette.mode === "dark"
      ? colors.lightMenuTakyon
      : colors.darkMenuTakyon;

  // Let's get the count here.
  /**
  const alertCountBuy = useSelector((state) => {
    if (
      state.coins.alerts === undefined ||
      state.coins.alerts.alertsTB === undefined
    )
      return 0;
    else return state.coins.alerts.alertsTB.size;
  });

    // Let's get the count here.
  const alertCountSell = useSelector((state) => {
    if (
      state.coins.alerts === undefined ||
      state.coins.alerts.alertsTS === undefined
    )
      return 0;
    else return state.coins.alerts.alertsTS.size;
  });

  const alertCount = alertCountBuy + alertCountSell;
  */

  return (
    <CustomizedBadge spacing={0} padding={0} badgeContent={0} color="error">
      <Typography fontWeight="normal" color={rung}>
        Explore
      </Typography>
    </CustomizedBadge>
  );
};

const SmartWatchlistTab = () => {
  const theme = useTheme();
  const rung =
    theme.palette.mode === "dark"
      ? colors.lightMenuTakyon
      : colors.darkMenuTakyon;

  // Let's get the count here.
  const alertCountPositive = useSelector((state) => {
    if (
      state.coins.alerts === undefined ||
      state.coins.alerts.alertsSP === undefined
    )
      return 0;
    else return state.coins.alerts.alertsSP.size;
  });

  const alertCountNegative = useSelector((state) => {
    if (
      state.coins.alerts === undefined ||
      state.coins.alerts.alertsSN === undefined
    )
      return 0;
    else return state.coins.alerts.alertsSN.size;
  });

  const alertCount = alertCountPositive + alertCountNegative;

  return (
    <CustomizedBadge badgeContent={alertCount} color="error">
      <Typography fontWeight="normal" color={"yellow"}>
        {"Breakout"}
      </Typography>
    </CustomizedBadge>
  );
};

const SummarizedTab = () => {
  const theme = useTheme();
  const rung =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;
  const mrung =
    theme.palette.mode === "dark"
      ? colors.lightMenuTakyon
      : colors.darkMenuTakyon;

  // Let's get the count here.
  const alertCountBuy = useSelector((state) => {
    if (
      state.coins.alerts === undefined ||
      state.coins.alerts.alertsTB === undefined
    )
      return 0;
    else return state.coins.alerts.alertsTB.size;
  });

  // Let's get the count here.
  const alertCountSell = useSelector((state) => {
    if (
      state.coins.alerts === undefined ||
      state.coins.alerts.alertsTS === undefined
    )
      return 0;
    else return state.coins.alerts.alertsTS.size;
  });

  const alertCount = alertCountBuy + alertCountSell;

  return (
    <CustomizedBadge badgeContent={alertCount} color="error">
      <Typography fontWeight="normal" color={mrung}>
        My Trends
      </Typography>
    </CustomizedBadge>
  );
};

const DiveBarPortfolioTab = () => {
  // const alertCount = alertCountBuy + alertCountSell;

  return (
    <CustomizedBadge color="error">
      <Typography fontWeight="normal">Portfolio (DiveBarClub)</Typography>
    </CustomizedBadge>
  );
};

const VirtualCardsTab = () => {
  // const alertCount = alertCountBuy + alertCountSell;

  return (
    <CustomizedBadge color="error">
      <Typography fontWeight="normal">Virtual Prepaid Cards</Typography>
    </CustomizedBadge>
  );
};

const BasketBuyTab = () => {
  const theme = useTheme();
  const rung =
    theme.palette.mode === "dark"
      ? colors.successLightTakyon
      : colors.successDarkTakyon;

  console.log("Theme: " + theme.palette.mode + ", " + rung);

  return (
    <CustomizedBadge badgeContent={0} color="error">
      <Typography fontWeight="normal" color={rung}>
        Basket Trade
      </Typography>
    </CustomizedBadge>
  );
};

const LiquidateTab = () => {
  const theme = useTheme();
  const rung =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;

  return (
    <CustomizedBadge badgeContent={0} color="error">
      <Typography fontWeight="normal" color={rung}>
        Liquidate
      </Typography>
    </CustomizedBadge>
  );
};

const SettingsTab = () => {
  const theme = useTheme();
  const rung =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;
  const mrung =
    theme.palette.mode === "dark"
      ? colors.lightMenuTakyon
      : colors.darkMenuTakyon;

  return (
    <CustomizedBadge badgeContent={0} color="error">
      <Typography fontWeight="normal" color={mrung}>
        Settings
      </Typography>
    </CustomizedBadge>
  );
};

const SupportTab = () => {
  const theme = useTheme();
  const rung =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;
  const mrung =
    theme.palette.mode === "dark"
      ? colors.lightMenuTakyon
      : colors.darkMenuTakyon;

  return (
    <CustomizedBadge badgeContent={0} color="error">
      <Typography fontWeight="normal" color={mrung}>
        Support
      </Typography>
    </CustomizedBadge>
  );
};

const FAQTab = () => {
  const theme = useTheme();
  const rung =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;
  const mrung =
    theme.palette.mode === "dark"
      ? colors.lightMenuTakyon
      : colors.darkMenuTakyon;

  return (
    <CustomizedBadge badgeContent={0} color="error">
      <Typography fontWeight="normal" color={mrung}>
        FAQ
      </Typography>
    </CustomizedBadge>
  );
};
const LogoutTab = () => {
  const theme = useTheme();
  const rung =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;
  const mrung =
    theme.palette.mode === "dark"
      ? colors.lightMenuTakyon
      : colors.darkMenuTakyon;

  return (
    <CustomizedBadge badgeContent={0} color="error">
      <Typography fontWeight="normal" color={mrung}>
        Logout
      </Typography>
    </CustomizedBadge>
  );
};

const CollaborativeTab = () => {

    const theme = useTheme();
    const rung = (theme.palette.mode === 'dark') ? colors.errorLightTakyon : colors.errorDarkTakyon;
    const mrung = (theme.palette.mode === 'dark') ? colors.lightMenuTakyon : colors.darkMenuTakyon;

  return (
        <CustomizedBadge badgeContent={0} color="error">
          <Typography fontWeight="normal" color={mrung}>Dive-Bar-Club</Typography>
        </CustomizedBadge>
  );
};

// ===========================|| MENU ITEMS ||=========================== //
const icons = { IconDashboard, IconDeviceAnalytics };

const menuItems = (alerts) => [
  {
    id: "lite",
    type: "group",
    children: [
      {
        id: "oval-chip-menu",
        title: <ExploreTab />,
        type: "item",
        url: "/lite",
        icon: icons.IconCircle,
        breadcrumbs: false,
      },
      {
        id: "swl",
        title: <SmartWatchlistTab />,
        type: "item",
        url: "/lite/swl",
        breadcrumbs: false,
      },
      {
        id: "summarized",
        title: <SummarizedTab />,
        type: "item",
        url: "/lite/summarized",
        breadcrumbs: false,
      },

      {
        id: "oneclickbuy",
        title: <BasketBuyTab />,
        type: "item",
        url: "/lite/oneclickbuy",
        breadcrumbs: false,
      },
      {
        id: "liquidate",
        title: <LiquidateTab />,
        type: "item",
        url: "/lite/liquidate",
        breadcrumbs: false,
      },
      {
        id: "trades",
        title: "Trades",
        type: "item",
        url: "/lite/trades",
        breadcrumbs: false,
      },
      {
        id: "settings",
        title: <SettingsTab />,
        type: "item",
        url: "/lite/settings",
        breadcrumbs: false,
      },
      {
        id: "support",
        title: <SupportTab />,
        type: "item",
        url: "/lite/support",
        breadcrumbs: false,
      },
      {
        id: "faq",
        title: <FAQTab />,
        type: "item",
        url: "/lite/faq",
        breadcrumbs: false,
      },
      {
        id: "divebarportfolio",
        title: <DiveBarPortfolioTab />,
        type: "item",
        url: "/lite/divebarportfolio",
        breadcrumbs: false,

      },
      {
        id: "virtualcards",
        title: <VirtualCardsTab />,
        type: "item",
        url: "/lite/virtualcards",
        breadcrumbs: false,

      },
      // {          id: 'collaborative',
      // title: <CollaborativeTab />,
      // type: 'item',
      // url: '/lite/collaborative',
      // breadcrumbs: false},
      {
        id: "logout",
        title: <LogoutTab />,
        type: "item",
        url: "/lite/logout",
        breadcrumbs: false,
      },
      //   chip: {
      //     label:<CountChip />,
      //     color: 'warning',
      //     // avatar: <FormattedMessage id="c" />,
      //     // size: 'small'
      // }
      //    }
    ],
  },
];

export default menuItems;
