import React, { useContext, useState } from "react";
import { setModalCoinbaseConfirmStatus, coinbaseCommit } from "store/actions";
// material-ui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Search from "./components/Search";
// ===============================|| UI DIALOG - SCROLLABLE ||=============================== //

export default function ModalCoinBaseConfirmOrder() {
  //
  const dispatch = useDispatch();

  const isOpen = useSelector((state) => {
    return state.coinbaseConfirmOrder.isOpen;
  });

  const response = useSelector((state) => {
    return state.coinbaseConfirmOrder.response;
  });

  const scroll = "paper";

  const [selectedExchange, exchanges] = useSelector((state) => [
    state.exchange.selectedExchange,
    state.exchange.exchanges,
  ]);

  const handleClose = () => {
    dispatch(setModalCoinbaseConfirmStatus(false));
  };

  const handleConfirm = () => {
    const accessToken = exchanges.coinbase.credentials.accessToken;
    dispatch(
      coinbaseCommit({
        buyId: response?.id,
        accessToken,
        accountId: response?.accountId,
        resource: response.resource
      })
    );
    handleClose();
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (isOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [isOpen]);

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Confirm order</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          {response.resource && (
            <Grid container spacing={3} justifyContent="center">
              <Grid item><font color={(response.resource === "sell" ? "error" : 'success')}> {response.resource.toUpperCase()}</font></Grid>
            </Grid>
          )}
          {response.fee && (
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item>Coinbase Fee</Grid>
              <Grid item>
                {response.fee.amount} ({response.fee.currency}){" "}
              </Grid>
            </Grid>
          )}

          {response.amount && (
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item>Amount</Grid>
              <Grid item>
                {response.amount.amount} ({response.amount.currency}){" "}
              </Grid>
            </Grid>
          )}
          {response.unit_price && (
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item>Unit price</Grid>
              <Grid item>
                {response.unit_price.amount} ({response.unit_price.currency}){" "}
              </Grid>
            </Grid>
          )}

          {response.total && (
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item>Total</Grid>
              <Grid item>
                {response.total.amount} ({response.total.currency}){" "}
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions sx={{ pr: 2.5, pt: 2.5 }}>
          <Button onClick={handleConfirm} color="success">
            Confirm
          </Button>
          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
