import * as actionTypes from "./actions";

const initialState = {
  accounts: [],
};

const coinbaseAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_COINBASE_ACCOUNTS: {
      const newAccounts = state.accounts.concat(action.payload);
      return {
        ...state,
        accounts: newAccounts,
      };
    }
    default:
      return state;
  }
};

export default coinbaseAccountsReducer;
