import * as actionTypes from "./actions";

//todo: move to middleware
const savedFavoritesConfig = window.localStorage.getItem("takyon_favorites");
const savedFavorites = savedFavoritesConfig
  ? JSON.parse(savedFavoritesConfig)
  : [];


const initialState = {
  favorites: savedFavorites,
};

const favoritesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FAVORITES: {
      console.log("SET_FAVORITES");
      console.log(action.payload);
      window.localStorage.setItem("takyon_favorites", JSON.stringify(action.payload));
      return {
        ...state,
        favorites: action.payload,
      };
    }

    case actionTypes.ADD_FAVORITES: {
      console.log("ADD_FAVORITES");
      console.log(action.payload);
      let items = state.favorites;
      items.push({ id: action.payload, label: action.payload });
      window.localStorage.setItem("takyon_favorites", JSON.stringify(items));

      return {
        ...state,
        favorites: items,
      };
    }

    case actionTypes.REMOVE_FAVORITES: {
      let items = state.favorites.filter(
        (item) => item.label !== action.payload
      );
      window.localStorage.setItem("takyon_favorites", JSON.stringify(items));

      return {
        ...state,
        favorites: items,
      };
    }

    default:
      return state;
  }
};

export default favoritesReducer;
