import { Grid } from "@material-ui/core";
import React from "react";
import TrendingSell from "../TrendingBuySell/TrendingSell";
import TrendingSellSummary from "../TrendingBuySell/TrendingSellSummary";
import CenterLayout from "../CenterLayout";
import CandleChart from "../CandleChart";
import LiteOrderTicket from "ui-component/cards/LiteOrderTicket";
import SummaryCard from "ui-component/cards/SummaryCard";

const TrendingSellLayout = () => {
  return (
    <Grid container spacing={2.0}>

        <Grid item xs={9.5} alignItems="center">
          <CandleChart />
          <Grid container padding={1.0} />
          <TrendingSell />
        </Grid>

        <Grid item xs={2.5} alignItems="center">
            <SummaryCard />
            <Grid container padding={1.0} />
            <LiteOrderTicket />
            <Grid container padding={1.0} />
            <TrendingSellSummary />
        </Grid>

    </Grid>
  );
};

export default TrendingSellLayout;
