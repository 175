import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";
import reducer from "./reducer";
import mySaga from "./sagas";

// ===========================|| REDUX - MAIN STORE ||=========================== //
const sagaMiddleware = createSagaMiddleware();

const store = createStore(reducer, applyMiddleware(sagaMiddleware));
const persister = persistStore(store);

sagaMiddleware.run(mySaga);


export { store, persister };
