import * as actionTypes from "./actions";
import * as I from "immutable";
import { Map } from "immutable";
import CoinContext from "contexts/CoinContext";
import { map_coins, map_balance } from "./constant";
import Portfolio from "./models/Portfolio";
import { OrderTicketTypes } from "./actions";
import { de } from "date-fns/locale";

const initialState = {
  mapSWLPositive: new I.Map(), // (I.Map(): I.Map<string, TrendingPostion>),

  mapTrades: new I.Map(),
  mapLastTrade: new I.Map(),
  ohlcInterval: "60",
  currentChartData: [], //TODO!
  mobile_chart_opened: false,
  mobile_lite_order_ticket: false,

  lwMarkers: [
    {
      time: "2021-10-21",
      position: "belowBar",
      color: "green",
      text: "example",
      shape: "circle",
      id: "id1",
    },
    {
      time: "2021-10-22",
      position: "belowBar",
      color: "red",
      text: "example",
      shape: "arrowUp",
      id: "id2",
    },
    {
      time: "2021-10-23",
      position: "belowBar",
      color: "yellow",
      shape: "arrowUp",
      id: "id3",
      text: "example",
    },
    {
      time: "2021-10-24",
      position: "belowBar",
      color: "orange",
      shape: "arrowUp",
      id: "id3",
      text: "example",
    },
    {
      time: "2021-10-24",
      position: "belowBar",
      color: "orange",
      shape: "arrowUp",
      id: "id4",
      text: "avg",
    },
  ],
  lwChartData: {
    "aud/usd": [
      {
        time: "2021/09/21 10:00:00 AM",
        open: 1.77754,
        high: 1.80022,
        low: 1.77281,
        close: 1.79351,
      },
      {
        time: "2021/09/21 11:00:00 AM",
        open: 1.79609,
        high: 1.81186,
        low: 1.78404,
        close: 1.78599,
      },
      {
        time: "2021/09/21 12:00:00 AM",
        open: 1.78839,
        high: 1.79685,
        low: 1.75831,
        close: 1.75831,
      },
      {
        time: "2021/09/22 1:00:00 AM",
        open: 1.75827,
        high: 1.7835,
        low: 1.75655,
        close: 1.78201,
      },
      {
        time: "2021/09/22 2:00:00 AM",
        open: 1.78269,
        high: 1.78269,
        low: 1.7254,
        close: 1.7254,
      },
      {
        time: "2021/09/22 3:00:00 AM",
        open: 1.7319,
        high: 1.757,
        low: 1.6936,
        close: 1.75073,
      },
      {
        time: "2021/09/23 4:00:00 AM",
        open: 1.75113,
        high: 1.77095,
        low: 1.74179,
        close: 1.77095,
      },
      {
        time: "2021/09/23 5:00:00 AM",
        open: 1.76949,
        high: 1.7822,
        low: 1.75827,
        close: 1.75913,
      },
      {
        time: "2021/09/23 6:00:00 AM",
        open: 1.76156,
        high: 1.76302,
        low: 1.73477,
        close: 1.75904,
      },
      {
        time: "2021/09/24 7:00:00 AM",
        open: 1.75703,
        high: 1.76756,
        low: 1.74474,
        close: 1.74974,
      },
    ],
    "btc/usd": [
      {
        time: 11,
        open: "261.37",
        high: "266.34",
        low: "259.61",
        close: "265.61",
      },
      {
        time: 12,
        open: "275.32",
        high: "275.32",
        low: "274.26",
        close: "274.26",
      },
      {
        time: 13,
        open: "261.46",
        high: "263.67",
        low: "260.27",
        close: "263.67",
      },
      {
        time: 14,
        open: "274.34",
        high: "275.35",
        low: "271.42",
        close: "271.42",
      },
      { time: 15, open: "272.5", high: "275.5", low: "272.5", close: "275.5" },
      {
        time: 16,
        open: "274.63",
        high: "275.81",
        low: "274.63",
        close: "274.85",
      },
      {
        time: 17,
        open: "301.88",
        high: "302.58",
        low: "298.68",
        close: "299.86",
      },
      { time: 18, open: "288", high: "291.4", low: "288", close: "288.22" },
      {
        time: 19,
        open: "308.4",
        high: "309.69",
        low: "307.85",
        close: "309.15",
      },
      {
        time: 20,
        open: "287.56",
        high: "287.56",
        low: "286.01",
        close: "286.78",
      },
      {
        time: 21,
        open: "285.43",
        high: "289.96",
        low: "283.93",
        close: "287.07",
      },
    ],
  },
  lineData: {
    "aud/usd": [
      { time: 1605664235, value: 11301.5 },
      { time: 1605664335, value: 11376.6 },
    ],
  },
  chartData: {
    "aud/usd": [
      [1605664235, 11060.1, 11499.0, 11060.1, 11301.5],
      [1605664335, 11299.4, 11443.9, 11262.0, 11376.6],
    ],
  },
  // mapOrders: I.Map(),
  lwOptions: {
    id: "lightweight",
    upColor: "#0F0",
    downColor: "#F00",
    candlestick: {
      upColor: "yellow",
      downColor: "blue",
    },
    candleSeries: {
      upColor: "yellow",
      downColor: "blue",
    },
    candlestickSeries: {
      upColor: "yellow",
      downColor: "blue",
    },
    layout: {
      backgroundColor: "#00070f",
      textColor: "white",
    },
    watermark: {
      color: "darkgray",
      visible: true,
      text: "takyon",
      fontSize: 14,
      horzAlign: "right",
      vertAlign: "top",
    },
    grid: {
      vertLines: {
        visible: true,
        color: "#111",
      },
      horzLines: {
        visible: true,
        color: "#111",
      },
    },
    crosshair: {
      vertLine: {
        color: "#333",
        width: 0.1,
        style: 0,
        visible: true,
        labelVisible: true,
      },
      horzLine: {
        color: "#333",
        width: 0.1,
        style: 0,
        visible: true,
        labelVisible: true,
      },
      mode: 1,
    },
    alignLabels: true,
    timeScale: {
      rightOffset: 12,
      barSpacing: 8,
      fixLeftEdge: true,
      lockVisibleTimeRangeOnResize: true,
      rightBarStaysOnScroll: true,
      borderVisible: false,
      borderColor: "#fff000",
      visible: true,
      timeVisible: true,
      secondsVisible: false,
    },
    marker: [
      {
        time: 1634940716,
        position: "inBar",
        color: "yellow",
        shape: "circle",
        visible: true,
      },
      {
        time: 1634940716,
        position: "inBar",
        color: "yellow",
        shape: "circle",
        visible: true,
      },
      {
        time: 1634940716,
        position: "inBar",
        color: "yellow",
        shape: "circle",
        visible: true,
      },
      {
        time: 1634940716,
        position: "inBar",
        color: "yellow",
        shape: "circle",
        visible: true,
      },
    ],
    markers: [
      {
        time: 1634940716,
        position: "inBar",
        color: "yellow",
        shape: "circle",
        visible: true,
      },
      {
        time: 1634940716,
        position: "inBar",
        color: "yellow",
        shape: "circle",
        visible: true,
      },
      {
        time: 1634940716,
        position: "inBar",
        color: "yellow",
        shape: "circle",
        visible: true,
      },
      {
        time: 1634940716,
        position: "inBar",
        color: "yellow",
        shape: "circle",
        visible: true,
      },
    ],
  },
  lwData: [
    {
      data: [],
    },
  ],
  chartOptions: {
    id: "takyon-apex-chart",
    options: {
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            borderColor: "yellow",
            label: {
              borderColor: "yellow",
              position: "right",
              offsetX: 60,
              style: {
                color: "#000",
                background: "yellow",
                padding: {
                  left: 3,
                  top: 2,
                  right: 10,
                  bottom: 2,
                },
              },
              text: "average price",
            },
          },
          {
            y: 0,
            borderColor: "white",
            label: {
              borderColor: "white",
              position: "right",
              offsetX: 60,
              style: {
                color: "#000",
                background: "white",
                padding: {
                  left: 3,
                  top: 2,
                  right: 10,
                  bottom: 2,
                },
              },
              text: "ltp",
            },
          },
        ],
        points: [
          {
            x: 1633136806000,
            y: 12.081,
            marker: {
              size: 8,
              fillColor: "#888",
              borderColor: "white",
              strokeColor: "white",
            },
            label: {
              offsetX: -50,
              offsetY: 25,
              textAnchor: "left",
              style: {
                background: "#000",
                color: "white",
              },
            },
          },
          {
            x: 1633136806000,
            y: 12.081,
            marker: {
              size: 8,
              fillColor: "#888",
              borderColor: "white",
              strokeColor: "white",
            },
            label: {
              offsetX: -50,
              offsetY: 25,
              textAnchor: "left",
              style: {
                background: "#000",
                color: "white",
              },
            },
          },
          {
            x: 1633136806000,
            y: 12.081,
            marker: {
              size: 8,
              fillColor: "#888",
              borderColor: "white",
              strokeColor: "white",
            },
            label: {
              offsetX: -50,
              offsetY: 25,
              textAnchor: "left",
              style: {
                background: "#000",
                color: "white",
              },
            },
          },
          {
            x: 1633136806000,
            y: 12.081,
            marker: {
              size: 8,
              fillColor: "#888",
              borderColor: "white",
              strokeColor: "white",
            },
            label: {
              offsetX: -50,
              offsetY: 25,
              textAnchor: "left",
              style: {
                background: "#000",
                color: "white",
              },
            },
          },
          {
            x: 1633136806000,
            y: 12.081,
            marker: {
              size: 8,
              fillColor: "#888",
              borderColor: "white",
              strokeColor: "white",
            },
            label: {
              offsetX: -50,
              offsetY: 25,
              textAnchor: "left",
              style: {
                color: "#ffffff",
                background: "#000",
              },
            },
          },
          {
            x: 1633136806000,
            y: 12.081,
            marker: {
              size: 8,
              fillColor: "#888",
              borderColor: "white",
              strokeColor: "white",
            },
            label: {
              offsetX: -50,
              offsetY: 25,
              textAnchor: "left",
              style: {
                color: "#ffffff",
                background: "#000",
              },
            },
          },
          {
            x: 1633136806000,
            y: 12.081,
            marker: {
              size: 8,
              fillColor: "#888",
              borderColor: "white",
              strokeColor: "white",
            },
            label: {
              offsetX: -50,
              offsetY: 25,
              textAnchor: "left",
              style: {
                color: "#ffffff",
                background: "#000",
              },
            },
          },
          {
            x: 1633136806000,
            y: 12.081,
            marker: {
              size: 8,
              fillColor: "#888",
              borderColor: "white",
              strokeColor: "white",
            },
            label: {
              offsetX: -50,
              offsetY: 25,
              textAnchor: "left",
              style: {
                color: "#ffffff",
                background: "#000",
              },
            },
          },
        ],
      },
      dataLabels: {
        enabled: false,
      },
      chart: {
        animations: {
          enabled: false,
        },
        height: 500,
        id: "takyon-apex-chart",
        toolbar: {
          autoSelected: "pan",
          show: false,
        },
        zoom: {
          enabled: true,
        },
      },
      stroke: {
        curve: "smooth",
      },
      fill: {
        type: "solid",
        opacity: 0,
      },
      plotOptions: {
        candlestick: {
          colors: {
            upward: "#84ed66",
            downward: "rgb(255, 70, 70)",
          },
          wick: {
            useFillColor: true,
          },
        },
      },
      xaxis: {
        color: "white",
        labels: {
          show: false,
          style: {
            colors: [
              "white",
              "white",
              "white",
              "white",
              "white",
              "white",
              "white",
            ],
          },
        },
        type: "datetime",
      },
      yaxis: {
        color: "white",
        opposite: true,
        position: "right",
        labels: {
          show: true,
          align: "right",
          position: "right",
          style: {
            colors: ["white"],
          },
        },
        crosshairs: {
          show: true,
          position: "back",
          stroke: {
            color: "#b6b6b6",
            width: 1,
            dashArray: 0,
          },
        },
        tooltip: {
          enabled: true,
          offsetX: 0,
        },
      },
    },
    series: [
      {
        data: [],
      },
    ],

    seriesBar: [
      {
        name: "volume",
        data: [
          {
            x: new Date(2016, 1, 1),
            y: 3.85,
          },
          {
            x: new Date(2016, 2, 1),
            y: 2.95,
          },
          {
            x: new Date(2016, 3, 1),
            y: -12.48,
          },
          {
            x: new Date(2016, 4, 1),
            y: 19.24,
          },
          {
            x: new Date(2016, 5, 1),
            y: 12.78,
          },
          {
            x: new Date(2016, 6, 1),
            y: 22.29,
          },
          {
            x: new Date(2016, 7, 1),
            y: -12.58,
          },
          {
            x: new Date(2016, 8, 1),
            y: -17.03,
          },
          {
            x: new Date(2016, 9, 1),
            y: -19.19,
          },
          {
            x: new Date(2016, 10, 1),
            y: -28.79,
          },
          {
            x: new Date(2016, 11, 1),
            y: -39.05,
          },
          {
            x: new Date(2017, 1, 1),
            y: 20.34,
          },
          {
            x: new Date(2017, 1, 1),
            y: 36.93,
          },
          {
            x: new Date(2017, 2, 1),
            y: 36.82,
          },
          {
            x: new Date(2017, 3, 1),
            y: 29.3,
          },
          {
            x: new Date(2017, 4, 1),
            y: 39.85,
          },
          {
            x: new Date(2017, 5, 1),
            y: 28.42,
          },
          {
            x: new Date(2017, 6, 1),
            y: 37.42,
          },
          {
            x: new Date(2017, 7, 1),
            y: 24.71,
          },
          {
            x: new Date(2017, 8, 1),
            y: 37.35,
          },
          {
            x: new Date(2017, 9, 1),
            y: 32.99,
          },
          {
            x: new Date(2017, 10, 1),
            y: 31.76,
          },
          {
            x: new Date(2017, 11, 1),
            y: 43.04,
          },
        ],
      },
    ],
    optionsBar: {
      chart: {
        height: 160,
        type: "bar",
        brush: {
          enabled: true,
          target: "candles",
        },
        selection: {
          enabled: true,
          xaxis: {
            min: new Date("20 Jan 2017").getTime(),
            max: new Date("10 Dec 2017").getTime(),
          },
          fill: {
            type: "solid",
            opacity: 0.4,
          },
          stroke: {
            color: "#0D47A1",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          columnWidth: "80%",
          colors: {
            ranges: [
              {
                from: -1000,
                to: 0,
                color: "#F15B46",
              },
              {
                from: 1,
                to: 10000,
                color: "#FEB019",
              },
            ],
          },
        },
      },
      stroke: {
        width: 0,
      },
      xaxis: {
        type: "datetime",
        axisBorder: {
          offsetX: 13,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
    },
  },
  // tradesTable: [],
  // orderTicket: {
  //   status: OrderTicketTypes.CLEAR,
  //   blocked: false,
  //   reason: "",
  //   userRef: "",
  // },
};

export class Trade {
  constructor(
    pair,
    trade_id,
    order_id,
    time_ms,
    trade_price,
    trade_qty,
    remain_qty,
    trade_cost,
    fee,
    is_buy
  ) {
    this.pair = pair;
    this.trade_id = trade_id;
    this.order_id = order_id;
    this.time_ms = time_ms;
    this.trade_price = trade_price;
    this.trade_qty = trade_qty;
    this.remain_qty = remain_qty;
    this.trade_cost = trade_cost;
    this.fee = fee;
    this.is_buy = is_buy;
  }
}

const chartReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MOBILE_CHART_OPENED: {
      return {
        ...state,
        mobile_chart_opened: action.payload.is_mobile_chart_opened,
      };
    }

    case actionTypes.MOBILE_LITE_ORDER_TICKET: {
      return {
        ...state,
        mobile_lite_order_ticket: action.payload.is_mobile_lite_order_ticket,
      };
    }

    case actionTypes.KRAKEN_OHLC_INTERVAL: {
      return {
        ...state,
        ohlcInterval: action.payload.ohlcInterval,
      };
    }
    /*
    case actionTypes.SWL_NOTIFY: {
      // !!! TEMPORARRY SKIP LIVE CHART DATA !!!
      // return state;


      const payload = action.payload;
      const adjust_epoch = 6 * 3600; // 60 min in millisec

      // if (payload.data.percent === 0) {}
      let currentChartData = { ...state.chartData };

      const ohlc = [
        payload.data.ohlc.epoch/1000 - adjust_epoch,
        payload.data.ohlc.open,
        payload.data.ohlc.high,
        payload.data.ohlc.low,
        payload.data.ohlc.close,
      ];

      if (currentChartData[payload.data.symbol]) {
        //CHECK UNIQUE TIMESTAMP
        const index = currentChartData[payload.data.symbol].findIndex(
          (arrayOHLC) => arrayOHLC[0] === ohlc[0]
        );


        if (index > -1) {
            // console.log( payload.data.symbol + ': ' + index + ', ' + ohlc[0] + ' --> ' + ohlc[4]);
            //data is the same
          if (
            currentChartData[payload.data.symbol][index].join() === ohlc.join
          ) {
            console.log("cahrt data is the same, no need to update");
            return state;
          }

          let ohlcArr = currentChartData[payload.data.symbol];
          ohlcArr[index] = ohlc;

          currentChartData[payload.data.symbol] = ohlcArr;
        } else {
          currentChartData[payload.data.symbol] = [
            ...currentChartData[payload.data.symbol],
            ohlc,
          ];
        }

      } else {
        currentChartData[payload.data.symbol] = [ohlc];
      }

      return {
        ...state,
        chartData: currentChartData,
      };
    }
    */

    case actionTypes.SET_LAST_TRADE: {
      let iMap = state.mapLastTrade;
      iMap = iMap.set(action.payload.coin, action.payload.trade);

      return {
        ...state,
        mapLastTrade: iMap,
      };
    }

    case actionTypes.FETCH_TRADES: {
      //HERE we set all  orders in charts.
      return state;

      let iMap = state.mapTrades;
      action.payload.data.trades.forEach((t, index) => {
        let trade = new Trade(
          t.pair,
          t.trade_id,
          t.order_id,
          t.time_ms,
          t.trade_price,
          t.trade_qty,
          t.remain_qty,
          t.trade_cost,
          t.fee,
          t.is_buy
        );
        iMap = iMap.set(trade.trade_id, trade);
      });

      return {
        ...state,
        mapTrades: iMap,
      };
    }

    case actionTypes.KRAKEN_OHLC: {
      const payload = action.payload;

      const ohlcData = payload.data[payload.rcoin].reverse().map((result) => {
        let ts = result[0] - 5 * 3600;

        return {
          time: ts,
          open: result[1],
          high: result[2],
          low: result[3],
          close: result[4],
        };
      });

      return {
        ...state,
        currentChartData: ohlcData,
      };
    }

    case actionTypes.TRADES_TABLE: {
      let mapOrders = state.mapOrders;
      if (mapOrders === undefined) {
        mapOrders = I.Map();
      }

      map_coins.forEach((value, key) => {
        const mapListOrders = action.payload
          .filter(
            (orderList) =>
              orderList.coin === key &&
              !orderList.data.some((order) => order.orderStatus === 6)
          )
          .map((item) => item.data[item.data.length - 1]);
        if (key !== undefined && mapListOrders !== undefined) {
          mapOrders = mapOrders.set(key, mapListOrders);
        }
      });
      // console.log("reducer: TRADES_TABLE mapOrders");
      // console.log(mapOrders);

      return {
        ...state,
        tradesTable: action.payload,
        mapOrders: mapOrders,
      };
    }

    default:
      return state;
  }
};

export default chartReducer;
