import React, { useContext } from "react";
import Panel from "./Panel";

import { useSelector, useDispatch } from "react-redux";
import { map_coins, map_coin_to_name } from "store/constant";
import { map_balance_to_coin } from "store/constant";
// import useKrakenOHLC from "hooks/useKrakenOHLC";
import { IconButton } from "@material-ui/core";
import { KRAKEN_OHLC_INTERVAL } from "store/actions";
import DailyHiLo from "../../components/DailyHiLo";

// material-ui
import { useTheme } from "@material-ui/styles";
import { Grid, Box, Typography, Tab, Tabs } from "@material-ui/core";

// third party
import ApexCharts from "apexcharts";
import TakyonLightweight from "./Chart";

// project imports
import SubCard from "ui-component/cards/SubCard";

import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import CoinContext from "contexts/CoinContext";
import TradesCoin from "../../Trades/TradesCoin";
import OrdersCoin from "./OrdersCoin.js";

// ===========================|| DASHBOARD ANALYTICS - MARKET SHARE AREA CHART CARD ||=========================== //

const ChartLayoutCard = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { coin } = useContext(CoinContext);

  // const portfolio = useSelector((state) => {
  //   const ltp =
  //     (state.takyon.mapTrendingBuy.get(coin) &&
  //       state.takyon.mapTrendingBuy.get(coin).trade_price) ||
  //     0.0;

  //   if (state.takyon.portfolio) {
  //     let position = state.takyon.portfolio.mapPosition.get(coin);
  //     if (position !== undefined) {
  //       return [
  //         position.balance.toFixed(2),
  //         (position.balance * ltp).toFixed(2),
  //         position.real,
  //         position.unreal,
  //         position.avg,
  //       ];
  //     } else {
  //       return [0, 0, 0, 0, 0];
  //     }
  //   } else {
  //     return [0, 0, 0, 0, 0];
  //   }
  // });

  // const customization = useSelector((state) => state.customization);
  // const { navType } = customization;

  const coin_style = map_coins.get(coin);
  const coin_name = (map_coin_to_name.get(coin)) ? map_coin_to_name.get(coin) : coin;

  const [tabsValue, setTabsValue] = React.useState(0);
  const [ohlcInt, setOhlcInt] = React.useState("240");
  const handleChangeTab = (event, newValue) => {
    setTabsValue(newValue);
  };

  // const { fetchOHLC } = useKrakenOHLC();

  const handleOHLC = (interval) => {
    console.log("handleOHLC: " + coin + " interval: " + interval);
    // setOhlcInt(interval);
    // fetchOHLC(coin, interval);
    dispatch({
      type: KRAKEN_OHLC_INTERVAL,
      payload: { ohlcInterval: interval },
    });
  };

    let colorMap = new Map([
        ['1', '#606d88'],
        ['5', '#606d88'],
        ['15', '#606d88'],
        ['30', '#606d88'],
        ['60', '#606d88'],
        ['240', '#606d88'],
        ['1440', '#606d88'],
        ['10080', '#606d88'],
    ]);

    colorMap = colorMap.set(ohlcInt, 'white');

  return (
    <SubCard sx={{ "&>div": { p: 0, pb: "0px !important" } }}>
      <Box
        sx={{
          p: 3,
        }}
      >
        <Grid container lg={10} md={7} direction="column" spacing={3} alignItems="center">
          <Grid item container spacing={5} alignItems="center">

            <Panel />

            <Grid item>
              <Tabs
                value={tabsValue}
                onChange={handleChangeTab}
                aria-label="ant example"
              >
                <Tab label="Chart" />
                <Tab label="Trades" />
                <Tab label="Orders" />
              </Tabs>
            </Grid>

            <Grid item>
                <font
                    style={{ spacing: "10px", "font-size": "0.9rem" }}
                    class={coin_style}
                >
                    {coin_name}{" "}
                </font>
            </Grid>

            <Grid item>
              <IconButton
                sx={{color:colorMap.get('1')}}
                size="small"
                onClick={() => handleOHLC("1")}
              >
                1
              </IconButton>
              {/*
              <IconButton
                sx={{color:colorMap.get('5')}}
                size="small"
                onClick={() => handleOHLC("5")}
              >
                5
              </IconButton>
              */}
              <IconButton
                sx={{color:colorMap.get('15')}}
                size="small"
                onClick={() => handleOHLC("15")}
              >
                15
              </IconButton>
              {/*
              <IconButton
                sx={{color:colorMap.get('30')}}
                size="small"
                onClick={() => handleOHLC("30")}
              >
                30
              </IconButton>
              */}
              <IconButton
                sx={{color:colorMap.get('60')}}
                size="small"
                onClick={() => handleOHLC("60")}
              >
                60
              </IconButton>
              <IconButton
                sx={{color:colorMap.get('240')}}
                size="small"
                onClick={() => handleOHLC("240")}
              >
                4h
              </IconButton>
              <IconButton
                sx={{color:colorMap.get('1440')}}
                size="small"
                onClick={() => handleOHLC("1440")}
              >
                1d
              </IconButton>
              <IconButton
                sx={{color:colorMap.get('10080')}}
                size="small"
                onClick={() => handleOHLC("10080")}
              >
                1w
              </IconButton>
            </Grid>
            <Grid item xs zeroMinWidth />
          </Grid>

          
          {/* <Grid item justifyContent="center">
              <DailyHiLo />
            </Grid> */}

        </Grid>
      </Box>
      {tabsValue === 0 && <TakyonLightweight />}
      {tabsValue === 1 && <TradesCoin />}
      {tabsValue === 2 && <OrdersCoin />}
    </SubCard>
  );
};
export default ChartLayoutCard;
