import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useKraken from "hooks/useKraken";
import takyonRest from "hooks/useTakyonRest";
import { useNavigate } from "react-router-dom";

import {
  SWL_NOTIFY,
  TRENDING_NOTIFY_ALL,
  DHL_NOTIFY,
  addTradeNotify,
  addBboNotify,
  addDailyHiloWl,
  addNotifyPnl,
  addSwlNotify,
  addVelocityNotify,
  addTrendingNotify,
  addTrendingBootstrap,
} from "store/actions";
import testSocket from "./testSocket";
import { openOrders, addOrderStatus } from "../handlers/index";
import useAuth from "hooks/useAuth";

function useSocket() {
  const dispatch = useDispatch();
  const { takyonEmailPasswordSignIn, initUser } = useAuth();
  const { initTakyonUser } = takyonRest();

  const [isConnected, setIsconnected] = useState(false);
  const [lastTimestamp, setLastTimestamp] = useState(0);

  const [startTime1, setStartTime1] = useState(0);
  const [startTime2, setStartTime2] = useState(0);
  const [startTime3, setStartTime3] = useState(0);
  const [startTime4, setStartTime4] = useState(0);

  const [endTime1, setEndTime1] = useState(0);
  const [endTime2, setEndTime2] = useState(0);
  const [endTime3, setEndTime3] = useState(0);
  const [endTime4, setEndTime4] = useState(0);

  let [roundtrip, setRoundtrip] = useState(0);
  let [avg, setAvg] = useState(0);
  let [counter, setCounter] = useState(0);

  const onMessage = (event) => {
    try {
      let trending = JSON.parse(event.data);

      if (trending.response_type === "trade_notify") {
        dispatch(addTradeNotify(trending));
      } else if (trending.response_type === "swl_notify") {
        /*
      else if (trending.response_type === "velocity_notify") 
      {
        dispatch(addVelocityNotify(trending));
      } 
      */
        dispatch({ type: SWL_NOTIFY, payload: trending });
      } else if (trending.response_type === "dhl") {
        dispatch({ type: DHL_NOTIFY, payload: trending });
      } else if (trending.response_type === "trending_wl") {
        dispatch({ type: TRENDING_NOTIFY_ALL, payload: trending });
      } else if (trending.response_type === "daily_hilo_wl") {
        /*
      else if (trending.response_type === "bbo_notify") 
      {
        dispatch(addBboNotify(trending));
      }
      */
        dispatch(addDailyHiloWl(trending));
      } else if (trending.response_type === "last_trade") {
        /*
      else if (trending.response_type === "notify_pnl") 
      {
        dispatch(addNotifyPnl(trending));
      } 
      */
        dispatch(addTrendingBootstrap(trending));
      } else if (trending.response_type === "openOrders") {
        openOrders(trending);
      } else if (trending.response_type === "addOrderStatus") {
        addOrderStatus(trending);
      }
    } catch (e) {}
  };

  const onClose = () => {
    setIsconnected(false);
    // window.location.href = '/pages/login/takyon';

    //try to login and connect again

    /// init
    //  don't infinite loop
    //
    // const coinbaseToken = window.localStorage.getItem("coinbase_token") || "no_token";
    // const { initTakyonUser } = takyonRest();
    // initTakyonUser(coinbaseToken,'coinbase_light');
    // const { connect } = testSocket();
    // connect(onMessage, onClose, onConnect);
  };

  const onConnect = () => {
    setIsconnected(true);
    console.log("connected!");
    // Let's send our first packet, so we get openOrders
    // emit(" ");
  };

  const { emit } = testSocket();
  // const emit = () => {}

  useEffect(() => {
    const { checkIsConnected, connect } = testSocket();
    if (!checkIsConnected()) {
      console.log("connect");
      connect(onMessage, onClose, onConnect);
    }
  }, []);

  //RECONNECT every 20 sec
  useEffect(() => {
    let reconnectTimer = setInterval(() => {
      const { checkIsConnected, connect } = testSocket();
      // console.log("timer check  is connected");
      // console.log(checkIsConnected());

      if (!checkIsConnected()) {
        //ONLY FOR SUBSCRIPTION, no coinbase
        takyonEmailPasswordSignIn("seed", "seed123")
          .then((result) => {
            // console.log({ result });
            if (result.data.data.result === "good") {
              initUser(result.data.data.token);
              console.log("connect");
              connect(onMessage, onClose, onConnect);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }, 20000);

    return () => {
      clearTimeout(reconnectTimer);
    };
  }, [initUser, onMessage, takyonEmailPasswordSignIn]);

  return { isConnected, emit, setIsconnected };
}

export default useSocket;
