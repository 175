import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@material-ui/core';
import { makeStyles, useTheme } from "@material-ui/styles";

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';
import TakyonLogo from "assets/images/takyon_logo.png";
import TakyonLogoLight from "assets/images/MishtiTakyonLightMini.png";
import TakyonLogoDark from "assets/images/MishtiTakyonDarkMiniBeta.png";

// ===========================|| MAIN LOGO ||=========================== //

const LogoSection = () => {

    const theme = useTheme();

    return (
        <ButtonBase disableRipple component={Link} to={config.defaultPath}>
            {theme.palette.mode === 'dark' && <img src={TakyonLogoDark} height={45}  style={{borderRadius: 8}}/>}
            {theme.palette.mode !== 'dark' && <img src={TakyonLogoLight} height={45}  style={{borderRadius: 8}}/>}
        </ButtonBase>
    );
};

export default LogoSection;
