// import axios from "utils/axios";
import Config from 'config';
import axios from "axios";

const API = "http://"+Config.takyon_domain+":3106";

export default function useTakyonRest() {

  const getKrakenToken = (key, secret) =>
    axios.get(
      `${API}/ktoken?user_event=k_token&k_key=${key}&k_sec=${secret}`
    );

  return {
    getKrakenToken
  };
}
