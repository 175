import { Grid } from "@material-ui/core";
import React from "react";
import VelocityNegative from "../VelocityPositiveNegative/VelocityNegative";
import CenterLayout from "../CenterLayout";
import LiteOrderTicket from "ui-component/cards/LiteOrderTicket";
import SummaryCard from "ui-component/cards/SummaryCard";

const VelocityLayout = () => {
  return (
    <Grid container spacing={2.5}>
      <Grid item xs={9.5}>
          <CenterLayout />
          <Grid container padding={1.5} />
          <VelocityNegative />
      </Grid>

      <Grid item xs={2.5}>
        <SummaryCard />
        <Grid container padding={1.5} />
        <LiteOrderTicket />
      </Grid>

    </Grid>
  );
};

export default VelocityLayout;
