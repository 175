import * as actionTypes from "./actions";
import * as I from "immutable";


const getCardNumber = () => `414718${Math.random().toString().slice(2, 12)}`

export class EventCard {
    
    constructor(amount, created, result, type, token) {
        this.amount = amount;
        this.created = created;
        this.result = result;
        this.type = type;
        this.token = token;
    }
};

export class VirtualCard {

    constructor(name, rotation, pan, cvv, expiry, lifetime, current, isFreezed = false) {
        this.name = name;
        this.rotation = rotation;
        this.pan = pan;
        this.cvv = cvv;
        this.expiry = expiry;
        this.lifetime = lifetime;
        this.current = current;
        this.isFreezed = isFreezed;
    }
};

const initialState = {
    map_virtual_cards: new I.Map({
        "4147187465525911":   new VirtualCard('First card','rotated0',"4147187465525911", '112',
         '12/25',
         '10000.00', 
         '10000.00',
         false 
        ),
        "4147185717982971": new VirtualCard('Second card','rotated0',"4147185717982971", '112',
        '12/25',
        '10000.00', 
        '10000.00',
        true 
       ),
    }),
    isOpen: false,
    last_virtual_card: '',
    list_event_cards: new I.List(),
};

const cardsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_VIRTUAL_CARD: {
            const payload = action.payload;
            console.log('SET_VIRTUAL_CARD for: ' + payload.pan);

            let map_virtual_cards = state.map_virtual_cards;
            let vc = new VirtualCard(payload.name, payload.rotation, payload.pan, payload.cvv, payload.expiry, payload.lifetime, payload.current);
            map_virtual_cards = map_virtual_cards.set(vc.pan, vc);

            console.log({ map_virtual_cards });
            const currentDate = new Date().toISOString().split('T')[1];
            let ec = new EventCard( payload.lifetime, currentDate, "SUCCESS", "SINGLE", vc.pan);
            let list_event_cards = state.list_event_cards;
            list_event_cards = list_event_cards.push(ec);
            console.log( 'LIST');
            console.log( list_event_cards);
            return {
                ...state,
                map_virtual_cards: map_virtual_cards,
                list_event_cards: list_event_cards,
            };
        }

        case actionTypes.DEL_VIRTUAL_CARD: {
            const payload = action.payload;
            console.log('DEL_VIRTUAL_CARD for: ' + payload.pan);

            let map_virtual_cards = state.map_virtual_cards;
            let vc = map_virtual_cards.get(payload.pan);
            console.log(vc);
            if (vc !== undefined) {
                map_virtual_cards = map_virtual_cards.delete(payload.pan);
            }
            console.log({ map_virtual_cards });
            const currentDate = new Date().toISOString().split('T')[1];
            let ec = new EventCard( vc.lifetime, currentDate, "DELETE", "SINGLE", vc.pan);
            let list_event_cards = state.list_event_cards;
            list_event_cards = list_event_cards.push(ec);
            console.log( 'LIST');
            console.log( list_event_cards);
            return {
                ...state,
                map_virtual_cards: map_virtual_cards,
                list_event_cards: list_event_cards,
            };
        }

        case actionTypes.SET_VIRTUAL_CARD_LIMITS: {
            const payload = action.payload;
            console.log('SET_VIRTUAL_CARD_LIMITS for: ' + payload.pan);
            console.log(payload);

            let map_virtual_cards = state.map_virtual_cards;
            let vc = map_virtual_cards.get(payload.pan);
            console.log(vc);
            if (vc !== undefined) {
                let current: number = Number(vc.current);
                let lifetime: number = Number(vc.lifetime);
                let p_life: number = Number(payload.lifetime);
                if ( lifetime < p_life)
                {
                    // increase the lifetime AND current.
                    current = (p_life - lifetime + current);
                    vc.current = Number(current).toString();
                }
                else if (p_life < current)
                {
                    vc.current = Number(p_life).toString();
                }
                vc.lifetime = payload.lifetime;
                map_virtual_cards = map_virtual_cards.set(vc.pan, vc);
            }
            console.log({ map_virtual_cards });
            const currentDate = new Date().toISOString().split('T')[1];
            let ec = new EventCard( vc.current, currentDate, "UPDATE", "SINGLE", vc.pan);
            let list_event_cards = state.list_event_cards;
            list_event_cards = list_event_cards.push(ec);
            console.log( 'LIST');
            console.log( {list_event_cards});
            return {
                ...state,
                map_virtual_cards: map_virtual_cards,
                list_event_cards: list_event_cards,
            };
        }

        case actionTypes.UPDATE_VIRTUAL_CARD: {
            const payload = action.payload;
            console.log('UPDATE_VIRTUAL_CARD for: ' + payload.pan + ', curent: ' + payload.current);

            let map_virtual_cards = state.map_virtual_cards;
            let vc = map_virtual_cards.get(payload.pan);
            console.log(vc);
            if (vc !== undefined) {
                let value: number = Number(vc.current);
                value -= Number(payload.current);
                console.log(value);
                vc.current = Number(value).toString();
                console.log(vc.current);
                map_virtual_cards = map_virtual_cards.set(vc.pan, vc);
            }
            console.log({ map_virtual_cards });
            return {
                ...state,
                map_virtual_cards: map_virtual_cards
            };
        }

        case actionTypes.SET_FREEZE_VIRTUAL_CARD: {
            const payload = action.payload;
            let map_virtual_cards = state.map_virtual_cards;
            let vc = map_virtual_cards.get(payload.pan);
            if (vc !== undefined) {
                vc.isFreezed = action.payload.isFreezed
                map_virtual_cards = map_virtual_cards.set(vc.pan, vc);
            }
            const currentDate = new Date().toISOString().split('T')[1];
            let ec = new EventCard( vc.current, currentDate, "FROZEN", "SINGLE", vc.pan);
            if ( !action.payload.isFreezed)
            {
                ec.result = "ACTIVATED";
            }
            let list_event_cards = state.list_event_cards;
            list_event_cards = list_event_cards.push(ec);
            console.log( 'LIST');
            console.log( {list_event_cards});
            return {
                ...state,
                map_virtual_cards: map_virtual_cards,
                list_event_cards: list_event_cards,
            };
        }

        case actionTypes.SET_VIRTUAL_CARD_DIALOG_STATUS: {
            return {
                ...state,
                isOpen: action.isOpen,
                last_virtual_card: action.last_virtual_card,
            };
        }

        default:
            return state;
    }
};

export default cardsReducer;
