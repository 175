import * as actionTypes from "./actions";
 
const initialState = {
  quotes:{}
};

const coinbaseQuotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_COINBASE_QUOTES: {
      return {
        ...state,
        quotes: action.payload,
      };
    }
    default:
      return state;
  }
};

export default coinbaseQuotesReducer;
