import * as actionTypes from "./actions";
import { OrderTicketTypes } from "./actions";

const initialState = {
    status: OrderTicketTypes.CLEAR,
    blocked: false,
    reason: "",
    userRef: "",
    liteOrderTicket: {
      tabValue: 0,
      total: 0,
    }
};

// ===========================|| TAKYON REDUCER ||=========================== //
        
const orderTicketReducer = (state = initialState, action) => {
  switch (action.type) {
      
    case actionTypes.ORDER_TICKET: {
      return {
        ...state,
        ...action.payload,
        // ...action.payload
        // orderTicket: action.payload,
      };
    }

    case actionTypes.ORDER_TICKET_LITE: {
      return {
        ...state,
        // ...action.payload,
        // ...action.payload
        liteOrderTicket: action.payload,
      };
    }
     

    default:
      return state;
  }
};

export default orderTicketReducer;
