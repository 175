import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import ExplorePortfolioCard from "./ExplorePortfolioCard";
import ExplorePortfolioReadOnlyCard from "./ExplorePortfolioReadOnlyCard";

// material-ui
import CoinContext from "contexts/CoinContext";
import { balance_history, portfolio_weekly_total } from "store/coinReducer";
import { useTheme } from "@material-ui/core/styles";
import { Box, Card, Grid, Typography } from "@material-ui/core";
import ApexCharts from "apexcharts";
import {
  map_weeks,
  map_coins,
  map_balance_to_coin,
  map_coin_to_name,
} from "store/constant";
import { weekly_portfolio } from "store/weekly";
import { useDispatch, useSelector } from "react-redux";
import colors from "assets/scss/_themes-vars.module.scss";

// third party
import Chart from "react-apexcharts";

const portData = {
  type: "area",
  height: 150,
  options: {
    chart: {
      sparkline: {
        enabled: true,
      },
      background: "#7ce0d6",
    },
    colors: ["#fff"],
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
      opacity: 0.4,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    xaxis: {
      categories: [],
      title: {
        text: "Weekly",
      },
    },
    yaxis: {
      title: {
        text: "Portfolio-Profit",
      },
    },
    tooltip: {
      theme: "dark",
      fixed: {
        enabled: true,
      },
      x: {
        show: false,
      },
      y: {
        title: "Total Sales",
      },
      marker: {
        show: false,
      },
    },
  },

  series: [
    {
      name: "Portfolio",
      data: [20, 10, 18, 12, 25, 10, 20],
    },
  ],
};

const ExplorePortfolio = ({ title, rows }) => {
  const theme = useTheme();
  const { coin } = useContext(CoinContext);

  const [selectedExchange, exchanges] = useSelector((state) => [
    state.exchange.selectedExchange,
    state.exchange.exchanges,
  ]);

  const cornsilk =
    theme.palette.mode === "dark"
      ? colors.cornsilkLightTakyon
      : colors.cornsilkDarkTakyon;
  const whiteTakyon =
    theme.palette.mode === "dark"
      ? colors.whiteLightTakyon
      : colors.whiteDarkTakyon;
  const green =
    theme.palette.mode === "dark"
      ? colors.successLightTakyon
      : colors.successDarkTakyon;
  const red =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;
  const coin_style = map_coins.get(coin);
  const coin_name = map_coin_to_name.get(coin)
    ? map_coin_to_name.get(coin)
    : coin;

  const cdata = useSelector((state) => {
    return state.coins.balance_history;
  });
  const weeks = useSelector((state) => {
    return state.coins.weeks;
  });

  /*
    let cdata = [];
    let weeks = [];
    portfolio_weekly_total.forEach((item, index) => {
        const total = (item.data.total_realized + item.data.total_unrealized - item.data.total_fee);
        // console.log(item.id + ', ' + item.data.total_realized + ', ' + item.data.total_unrealized + ', ' + item.data.total_fee);
        cdata.push(total.toFixed(0));
    });

    Object.keys(balance_history.data).forEach((key, index) => {
        // key = '202101'...'202145'
        const week = map_weeks.get(key);
        weeks.push(week);
        cdata.push(balance_history.data[key]);
    });
    */

  let bgColor = theme.palette.background.paper;
  let color = "white";

  portData.series[0].data = cdata;
  portData.options.xaxis.categories = weeks;

  if (theme.palette.mode === "dark") {
    portData.options.chart.background = theme.palette.background.paper;
    portData.options.colors = ["#6dc2ba"];

    portData.options.lineColor = ["#2B2B43"];

    // var areaSeries = chart.addAreaSeries({
    //     topColor: 'rgba(38, 198, 218, 0.56)',
    //     bottomColor: 'rgba(38, 198, 218, 0.04)',
    //     lineColor: 'rgba(38, 198, 218, 1)',
    //     lineWidth: 2,
    //     crossHairMarkerVisible: false,
    //   });
  } else {
    bgColor = "white";
    color = "black";

    portData.options.chart.background = bgColor;
    portData.options.colors = ["#888"];
  }

  const heading =
    selectedExchange === "noExchange"
      ? "Login to Coinbase to view 'My Portfolio'"
      : "My Portfolio";
  return (
    <>
      {selectedExchange === "noExchange" ? (
        <ExplorePortfolioReadOnlyCard
          chartData={portData}
          bgColor={bgColor}
          color={color}
          value={" "}
          title={heading}
          percentage=""
        />
      ) : (
        <ExplorePortfolioCard
          chartData={portData}
          bgColor={bgColor}
          color={color}
          value={" "}
          title={heading}
          percentage=""
        />
      )}
    </>
  );
};

export default ExplorePortfolio;
