import * as actionTypes from "./actions";
import * as I from "immutable";

export class Channel {
  constructor(id, channel, ledger) {
    this.id = id;
    this.channel = channel;
    this.ledger = ledger;
  }
}

export class Ledger {
  constructor(id, map_fx) {
    this.id = id;
    this.map_fx = map_fx;
  }
}

export class DBUser {
  constructor(id, user, ledger) {
    this.id = id;
    this.user = user;
    this.ledger = ledger;
  }
}

const initialState = {
  isOpen: true,
  isCurrencySent: false,
  users: [],
  groups: [],
  openedRoom: {
    id: "",
    lastMessage: "",
    messages: [],
    name: "",
    roomId: "",
    unReadChatCount: 0,
  },
  userId: "",
  map_users: new I.Map(),
  map_channels: new I.Map(),

  channelUsers: new I.Map(),
  mainChannel: undefined,
  channelPortfolios: new I.Map(),
};

const diveBarClubReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DIVEBARCLUB_MODAL_STATUS: {
      const isOpen = action.isOpen;
      return {
        ...state,
        isOpen,
      };
    }
    case actionTypes.SET_DIVEBARCLUB_USER: {
      const userId = action.userId;
      return {
        ...state,
        userId,
      };
    }
    case actionTypes.SET_DIVEBARCLUB_ROOM: {
      const roomId = action.roomId;
      return {
        ...state,
        openedRoom: roomId,
      };
    }
    case actionTypes.SET_DIVEBARCLUB_MAIN_CHANNEL: {
      const channel = action.channel;
      return {
        ...state,
        mainChannel: channel,
      };
    }

    case actionTypes.SET_DIVEBARCLUB_USERS: {
      const { users, channelId } = action.payload;
      let tmp_users = users.map((user) => ({
        id: user.id,
        display_name: user.display_name,
        online_status: user.online_status,
        avatar: "user-round.svg",
        name: user.display_name,
        unReadChatCount: 0,
        lasMessage: "",
        roomId: user.id,
        messages: [],
      }));

      let map_users = state.map_users;
      users.forEach((user) => {
        console.log("         USERS ---------------->");
        let tuser = map_users.get(user.id);
        if (tuser === undefined) {
          tuser = new DBUser(user.id, user, undefined);
          map_users = map_users.set(user.id, tuser);
        } else {
          tuser.user = user;
          map_users = map_users.set(user.id, tuser);
        }
      });

      console.log("------------------ MAP_USERS -----------------");
      console.log(map_users);
      return {
        ...state,
        users: tmp_users,
        map_users: map_users,
        channelUsers: state.channelUsers.set(channelId, users),
      };
    }

    case actionTypes.SET_DIVEBARCLUB_CHANNELS: {
      const channels = action.channels;

      const getRoomMessages = (roomId) => {
        const existRoom = state.groups.find((group) => group.roomId === roomId);
        return existRoom ? existRoom.messages : [];
      };

      let tmpgroups = channels.map((channel) => {
        const roomMessages = getRoomMessages(channel.id);
        return {
          id: channel.id,
          name: channel.display_name,
          unReadChatCount: 0,
          lasMessage:
            roomMessages.length > 0
              ? roomMessages[roomMessages.length - 1].message
              : "",
          roomId: channel.id,
          messages: roomMessages,
        };
      });

      let map_channels = state.map_channels;
      tmpgroups.forEach((channel) => {
        let tgrp = map_channels.get(channel.id);
        if (tgrp === undefined) {
          tgrp = new Channel(channel.id, channel, undefined);
          map_channels = map_channels.set(channel.id, tgrp);
        } else {
          tgrp.channel = channel;
          map_channels = map_channels.set(channel.id, tgrp);
        }
      });

      console.log("------------------ MAP_CHANNELS -----------------");
      console.log(map_channels);

      return {
        ...state,
        groups: tmpgroups,
        map_channels: map_channels,
      };
    }

    case actionTypes.SET_DIVEBARCLUB_MESSAGE: {
      const message = action.message;
      return {
        ...state,
        groups: state.groups.map((group) => {
          if (group.roomId === message.channel_id) {
            group.messages.push({
              from: message.from,
              message: message.msg,
            });
          }
          return group;
        }),
      };
    }

    case actionTypes.DIVEBARCLUB_CURRENCY_SENT: {
      console.log("CURRENCY_SENT: " + state.isCurrencySent);
      return {
        ...state,
        isCurrencySent: true,
      };
    }

    case actionTypes.DIVEBARCLUB_CURRENCY_GET_ALL: {
      const payload = action.payload;
      console.log("CURRENCY_GET_ALL: ");
      console.log({ payload });

      let map_channels = state.map_channels;
      let map_users = state.map_users;

      Object.keys(payload).forEach((key) => {
        console.log("key: " + key);
        console.log(payload[key]);

        let map_fx = new I.Map();
        Object.keys(payload[key]).forEach((k) => {
          console.log("FX: " + k + " = " + payload[key][k]);
          map_fx = map_fx.set(k, payload[key][k]);
        });
        let ledger = new Ledger(key, map_fx);
        let dbuser = map_users.get(key);
        if (dbuser !== undefined) {
          dbuser.ledger = ledger;
          map_users = map_users.set(key, dbuser);
        }
        let chn = map_channels.get(key);
        if (chn !== undefined) {
          chn.ledger = ledger;
          map_channels = map_channels.set(key, chn);
        }
      });

      console.log("------------------ MAP_CHANNELS ---------------");
      console.log({ map_channels });
      console.log("------------------ MAP_USERS -----------------");
      console.log({ map_users });
      console.log({ payload });
      
      let channelPortfolios =  state.channelPortfolios;
      Object.keys(payload).forEach((key)=>{
        channelPortfolios = channelPortfolios.set(key, payload[key])
      })
      
      return {
        ...state,
        map_channels: map_channels,
        map_users: map_users,
        channelPortfolios
      };
    }
    case actionTypes.DIVEBARCLUB_CURRENCY_CHANNEL: {
      const payload = action.payload;

      return {
        ...state,
        channelPortfolios: state.channelPortfolios.set(
          payload.id,
          payload.portfolio
        ),
      };
    }

    default:
      return state;
  }
};

export default diveBarClubReducer;
