import React, { useEffect } from "react";
import {
  IconButton,
  Tooltip as MuiTooltip,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from "@material-ui/core";
import HelpOutline from "@material-ui/icons/HelpOutline";
import { makeStyles } from "@material-ui/styles";
import MainCard from "ui-component/cards/MainCard";

// import { help } from "constants/tooltips";
// style constant
const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
  customWidth: {
    maxWidth: 500,
  },
  noMaxWidth: {
    maxWidth: "none",
  },
}));

const ToolTipHelper = ({
  label,
  hover,
  modal,
  labelStyle,
  children,
  isOpen = false,
  onClose,
}) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(isOpen );
  const [scroll, setScroll] = React.useState("paper");

  useEffect(() => {}, []);

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
        onClose();
    }
  };

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const style = labelStyle || {
    color: "white",
    "text-align": "right",
    "font-size": "1.3rem",
  };

  return (
    <>
      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <font style={style}> {label}</font>
        {children}
        <MuiTooltip
          title={hover}
          classes={{ tooltip: classes.customWidth }}
          arrow
        >
          <IconButton aria-label="delete" onClick={handleClickOpen("body")}>
            <HelpOutline />
          </IconButton>
        </MuiTooltip>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Help</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <Grid container spacing={3}>
            <Grid item>
              <Typography variant="body2"> {modal}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ pr: 2.5, pt: 2.5 }}>
          <Button variant="contained" size="small" onClick={handleClose}>
            Got it!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ToolTipHelper;
