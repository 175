import { Grid } from "@material-ui/core";
import React from "react";
import MobileSummaryCard from "ui-component/cards/mobile/MobileSummaryCard";
import MobileBreakout from "../mobile/MobileBreakout";

const SmartWatchListLayout = () => {
  return (
    <Grid container spacing={1.0}>

      <Grid item xs={12} alignItems="center"/>

      <Grid item spacing={0} xs={12}>
        <MobileSummaryCard sx={{ alignItems: "middle" }} />
      </Grid>

      <Grid item xs={12} alignItems="center">
        <MobileBreakout />
      </Grid>

    </Grid>
  );
};

export default SmartWatchListLayout;
