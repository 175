import * as actionTypes from "./actions";
import { TRADES_START_DATE, TRADES_END_DATE } from "constants/app";

const initialState = {
  startDate: TRADES_START_DATE,
  endDate: TRADES_END_DATE,
  tradesTable: [],
};

export class Trade {
  constructor(
    pair,
    trade_id,
    order_id,
    time_ms,
    trade_price,
    trade_qty,
    remain_qty,
    trade_cost,
    fee,
    is_buy
  ) {
    this.pair = pair;
    this.trade_id = trade_id;
    this.order_id = order_id;
    this.time_ms = time_ms;
    this.trade_price = Number(trade_price);
    this.trade_qty = Number(trade_qty);
    this.remain_qty = Number(remain_qty);
    this.trade_cost = Number(trade_cost);
    this.fee = Number(fee);
    this.is_buy = is_buy;
  }
}

const tradesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TRADES: {
      let trades = [];
      if (action.payload.trades) {
        trades = action.payload.trades.map((t) => {
          return new Trade(
            t.pair,
            t.trade_id,
            t.order_id,
            t.time_ms,
            t.trade_price,
            t.trade_qty,
            t.remain_qty,
            t.trade_cost,
            t.fee,
            t.is_buy
          );
        });
      } else {
        trades = action.payload.data.trades.map((t) => {
          return new Trade(
            t.pair,
            t.trade_id,
            t.order_id,
            t.time_ms,
            t.trade_price,
            t.trade_qty,
            t.remain_qty,
            t.trade_cost,
            t.fee,
            t.is_buy
          );
        });
      }

      return {
        ...state,
        tradesTable: trades,
      };
    }

    case actionTypes.ADD_TRADE: {
    
      const t = action.payload;
      const isBuy = t.resource === "buy";

      const trade = new Trade(
        `${t.amount.currency}/${t.unit_price.currency}`,
        t.id,
        t.id,
        t.created_at,
        t.unit_price.amount,
        t.amount.amount,
        t.amount.amount,
        t.unit_price.amount,
        t.fee.amount,
        isBuy
      );

      let trades = [...state.tradesTable,trade ];
      return {
        ...state,
        tradesTable: trades,
      };
    }

    default:
      return state;
  }
};

export default tradesReducer;
