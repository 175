import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import SubCard from "../../../../ui-component/cards/SubCard";
import ColorSubCard from "../../../../ui-component/cards/ColorSubCard";
import VirtualizedList from "./components/VirtualizedList";
import TrendingTable from "./components/TrendingTable";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { sort_by } from "./helpers";
import { sortLastSell, clearTrendingSellAlerts } from "store/actions";
import MUIFormControl from "@material-ui/core/FormControl";
import {
  InputAdornment,
  OutlinedInput,
  InputLabel,
  IconButton,
  Chip,
  Fab,
} from "@material-ui/core";
import {
  map_coins,
  map_balance_to_coin,
  map_coin_to_name,
} from "store/constant";

import colors from "assets/scss/_themes-vars.module.scss";
// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";

const TrendingSell = () => {
  const [query, setQuery] = useState("");

  const dispatch = useDispatch();
  const theme = useTheme();
  const cornsilk =
    theme.palette.mode === "dark"
      ? colors.cornsilkLightTakyon
      : colors.cornsilkDarkTakyon;
  const whiteTakyon =
    theme.palette.mode === "dark"
      ? colors.whiteLightTakyon
      : colors.whiteDarkTakyon;
  const green =
    theme.palette.mode === "dark"
      ? colors.successLightTakyon
      : colors.successDarkTakyon;
  const red =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;

  const mapTrendingSell = useSelector((state) => {
    let items = [];

    if (
      state.coins === undefined ||
      state.coins.kraken_asset_pairs === undefined
    ) {
      return items;
    }

    state.coins.kraken_asset_pairs.forEach((item) => {
      if (
        item.trending_sell !== undefined &&
        item.trending_sell.position > 0.0
      ) {
        item.trending_sell.avg = 0;
        item.trending_sell.balance = 0;
        item.trending_sell.coin_name = map_coin_to_name.get(item.coin)
          ? map_coin_to_name.get(item.coin)
          : item.coin;
        if (item.position !== undefined) {
          item.trending_sell.avg = item.position.remaining_avg_price.toFixed(3);
          item.trending_sell.balance = item.position.balance;
          item.trending_sell.last_trade = Number(
            (item.trending_sell.position * item.trending_sell.qty).toFixed(0)
          );
          item.trending_sell.invested = Number(
            (item.trending_sell.balance * item.trending_sell.price).toFixed(0)
          );
        }
        items.push(item.trending_sell);
      }
    });

    return items.sort(
      sort_by(
        state.takyon.filters.mapTrendingSell.item,
        state.takyon.filters.mapTrendingSell.value,
        (a) => {
          if (typeof a === "string") {
            return a.toUpperCase();
          }
          return a;
        }
      )
    );
  });

  const handleSortSellCoin = () => dispatch(sortLastSell("coin"));
  const handleSortSellPercent = () => dispatch(sortLastSell("percent"));
  const handleClearAllSellAlerts = () => dispatch(clearTrendingSellAlerts());

  let filtered = [];
  mapTrendingSell
    .filter((post) => {
      if (query === "") {
        return post;
      } else if (post === undefined) {
        return post;
      } else if (post.coin_name.toLowerCase().includes(query.toLowerCase())) {
        return post;
      }
    })
    .map((post, index) => {
      filtered.push(post);
    });

  return (
    <Grid>
      <ColorSubCard
        border={red}
        title={
          <Grid item container padding={0} alignItems="center" spacing={0.5}>
            <Grid item xs={12} lg={5}>
              <MUIFormControl>
                <InputLabel>Search</InputLabel>
                <OutlinedInput
                  fullWidth
                  label="Search"
                  name={"search"}
                  onChange={(event) => setQuery(event.target.value)}
                />
              </MUIFormControl>
              {/* <input
                placeholder="Search"
                onChange={(event) => setQuery(event.target.value)}
              /> */}
            </Grid>
            <Grid item xs={12} lg={3}>
              <font
                style={{
                  color: cornsilk,
                  "text-align": "middle",
                  "font-size": "1.4rem",
                }}
              >
                {"Trending"}{" "}
              </font>
              <font
                style={{
                  color: red,
                  "text-align": "middle",
                  "font-size": "1.4rem",
                }}
              >
                {"SELL"}{" "}
              </font>
            </Grid>
            <Grid item xs={12} lg={3} />
            <Grid item xs={12} lg={1}>
              <div onClick={handleClearAllSellAlerts}>
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-bell-off" width="32" height="32" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <line x1="3" y1="3" x2="21" y2="21" />
  <path d="M17 17h-13a4 4 0 0 0 2 -3v-3a7 7 0 0 1 1.279 -3.716m2.072 -1.934c.209 -.127 .425 -.244 .649 -.35a2 2 0 1 1 4 0a7 7 0 0 1 4 6v3" />
  <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
</svg>
                {/*
                <font
                  style={{
                    color: cornsilk,
                    "text-align": "right",
                    "font-size": "0.9rem",
                  }}
                >
                  Clear-Alerts
                </font>
                */}
              </div>
            </Grid>
          </Grid>
        }
      >
        <TrendingTable type="TrendingSell" items={filtered} />
      </ColorSubCard>
    </Grid>
  );
};

export default React.memo(TrendingSell);
