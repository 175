import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { map_coins, map_balance_to_coin, map_coin_to_name } from "store/constant";
import { weekly_portfolio } from "store/weekly";
import I from "immutable";
import useSocket from "hooks/useSocket";
import Transitions from 'ui-component/extended/Transitions';
import LiteOrderTicket from 'ui-component/cards/LiteOrderTicket';
import CoinContext from "contexts/CoinContext";
import { useDispatch, useSelector } from "react-redux";
import { TAB_ADD_PINNED_COIN } from "store/actions";
import ToolTipHelper from 'ui-component/ToolTipHelper';
import {tooltips} from 'constants/tooltips';

import {
  InputAdornment,
  OutlinedInput,
  InputLabel,
  IconButton,
  Chip,
  Fab,
} from "@material-ui/core";
import MUIFormControl from "@material-ui/core/FormControl";
import FavoriteButton from "../../components/FavoriteButton";

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import colors from "assets/scss/_themes-vars.module.scss";

// material-ui
import {
  Button,
  Checkbox,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { visuallyHidden } from "@material-ui/utils";

// third party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import ColorSubCard from "ui-component/cards/ColorSubCard";
import SubCard from "ui-component/cards/SubCard";

// assets
import Flag1 from "assets/images/widget/AUSTRALIA.jpg";
import Flag2 from "assets/images/widget/BRAZIL.jpg";
import Flag3 from "assets/images/widget/GERMANY.jpg";
import Flag4 from "assets/images/widget/UK.jpg";
import Flag5 from "assets/images/widget/USA.jpg";

// table filter
function descendingComparator(a, b, orderBy) {
  if (orderBy !== "Pair") {
    if (Number(b[orderBy]) < Number(a[orderBy])) {
      return -1;
    }
    if (Number(b[orderBy]) > Number(a[orderBy])) {
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// ===========================|| TABLE - HEADER ||=========================== //

// table header
const headCells = [
  {
    id: "Pair",
    numeric: false,
    disablePadding: true,
    label: "Pair",
  },
  {
    id: "invested",
    numeric: true,
    disablePadding: false,
    label: "Invested",
  },
  {
    id: "sticky",
    numeric: false,
    disablePadding: false,
    label: "Sticky",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    purchaseAmount,
    changePurchaseAmount,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{backgroundColor: '#191b1e'}}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.sortSpan}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};


// style constant
const useStyles = makeStyles({
  root: {
    width: "100%",
    overflow: "hidden",
  },
  sortSpan: visuallyHidden,
});

// =========================|| DASHBOARD ANALYTICS - LATEST CUSTOMERS TABLE CARD ||========================= //

const ExploreRecent = () => {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("invested");
  const classes = useStyles();
  const { emit } = useSocket();
  const [selectedIndex, setSelectedIndex] = React.useState("0");
  const { coin, setCoin } = useContext(CoinContext);
  const dispatch = useDispatch();

  const theme = useTheme();
  // const orange = theme.palette.mode === "dark" ? colors.lightTakyonOrange : colors.darkTakyonOrange;
  const orange = theme.palette.mode === "dark" ? 'orange': colors.darkTakyonOrange;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleListItemClick = (event, row) => {
    setSelectedIndex(row.coin);
    setCoin(row.coin);
    dispatch({type: TAB_ADD_PINNED_COIN, payload: {coin: row.coin}});
  };

  /*
  const rows = useSelector((state) => {
        let items = [];
        state.coins.map_explore_recent.map((value, key) => {

            let item = state.coins.kraken_asset_pairs.get(key);
            if ( item !== undefined)
            {
                items.push(item);
            }
        });
        return items;
  });
  */

    const rows = useSelector((state) => {
    let items = [];

    if (state.coins.kraken_asset_pairs !== undefined) {
      state.favorites.favorites.forEach((item, index) => {
        let coin = state.coins.kraken_asset_pairs.get(item.label);
        if (coin !== undefined)
        {
          items.push(coin);
        }
      });
    }
    return items;
  });


  return (
        <SubCard
          border={orange}
          title={
              <Grid container alignItems="center" flexDirection="column">
                <Grid item>
                  <table>
                    <tr>
                      <td>
                      <ToolTipHelper  label="Pinned..." hover={tooltips.pinned.hover} modal={tooltips.pinned.modal} />
                      </td>
                    </tr>
                  </table>
                </Grid>
              </Grid>
          }
          content={false}
          sx={{ align: "center" }}
          md={5}
          aria-label="main mailbox folders"
        >
            <TableContainer className={classes.container}>
              <Table stickyHeader size="small" aria-label="explore">
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy)).map(
                    (row, index) => {
                      const coin_style = map_coins.get(row.coin);
                      // const coin_name = (map_coin_to_name.get(row.coin)) ? map_coin_to_name.get(row.coin) : row.coin;
                      const coin_name = row.marketcap ?  row.marketcap.Name : (row.base_currency ? row.base_currency : (row.base ? row.base : row.wsname));
                      const rung = row.ltp < row.avg ? "#ff4976" : "#4bffb5";
                      const labelId = `${row.coin}`;

                      let invested = (row.position) ? (row.position.balance * row.position.market_price) : 0;
                      let dollar = '$';
                      if ( (invested === 0) || isNaN(invested))
                      {
                        invested = ' ';
                        dollar = ' ';
                      }
                      else
                      {
                        invested = Number(invested.toFixed(0));
                      }

                      return (
                        <TableRow 
                            hover 
                            role="checkbox" 
                            key={row.coin}
                            onClick={(event) => handleListItemClick(event, row)}
                        >
                          <TableCell>
                                    <table
                                      style={{
                                        fixed: { "table-layout": "fixed" },
                                        td: { overflow: "hidden" },
                                      }}
                                    >
                                      <tr>
                                        <td style={{ align: "left" }}>
                                      
                                            <a class={coin_style}>
                                              
                                            {/* <FavoriteButton pair={row.coin} /> */}
                                             {coin_name} </a>
                                        </td>
                                        <td>
                                        <FavoriteButton pair={row.coin} />
                                        </td>
                                      </tr>
                                    </table>
                          </TableCell>

                          <TableCell
                            sx={{ "font-weight": "bold", color: rung }}
                          >
                            <table
                              style={{
                                fixed: { "table-layout": "fixed" },
                                td: { overflow: "hidden" },
                              }}
                            >
                              <tr>
                                <td style={{ textAlign: "left" }}>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      fontSize: "1.2rem",
                                      textAlign: "left",
                                      color: rung,
                                    }}
                                  >
                                    {dollar}
                                    {invested}
                                  </Typography>
                                </td>
                              </tr>
                            </table>
                          </TableCell>
                          <TableCell sx={{ pl: 3 }} padding="checkbox">
                            <table>
                                <tr><td>
                                    {`%`}
                                </td></tr>
                            </table>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
        </SubCard>
  );
};

ExploreRecent.propTypes = {
  title: PropTypes.string,
};

export default ExploreRecent;
