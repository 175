import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import useAuth from "hooks/useAuth";

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import { AppBar, CssBaseline, Toolbar, useMediaQuery } from "@material-ui/core";
import { openOrders } from "../../handlers/index";

// third-party
import clsx from "clsx";

// project imports
import Breadcrumbs from "ui-component/extended/Breadcrumbs";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Customization from "../Customization";
import navigation from "menu-items";
import { drawerWidth } from "store/constant";
import { SET_MENU, addVelocityNotify } from "store/actions";
import useKraken from "hooks/useKraken";
import KrakenTokenModal from "./KrakenTokenModal";
import CoinbaseTokenModal from "./CoinbaseTokenModal";

// assets
import { IconChevronRight } from "@tabler/icons";
// eslint-disable-next-line import/no-unresolved
import useSocket from "hooks/useSocket";
import useOrders from "hooks/useOrders";
import { addOrder } from "emitters";

// import useTrades from "hooks/useTrades";
import usePortfolio from "hooks/usePortfolio";
import useBalance from "hooks/useBalance";
import useKrakenAssetPairs from "hooks/useKrakenAssetPairs";
import useCoinbaseAssetPairs from "hooks/useCoinbaseAssetPairs";

// style constant
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
  },
  appBarWidth: {
    transition: theme.transitions.create("width"),
    backgroundColor: theme.palette.background.default,
  },
  content: {
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("md")]: {
      marginLeft: -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
      marginRight: "10px",
    },
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
    },
  },
}));

// ===========================|| MAIN LAYOUT ||=========================== //

// // typedef enum {
//   ORDER_STATUS_UNKNOWN                            = 0,
//   ORDER_STATUS_NEW                                = 1,
//   ORDER_STATUS_PENDING                            = 2,
//   ORDER_STATUS_ACK                                = 3,
//   ORDER_STATUS_PENDING_CANCEL                     = 4,
//   ORDER_STATUS_CANCELED                           = 5,
//   ORDER_STATUS_FILLED                             = 6,
//   ORDER_STATUS_PARTIAL                            = 7,
//   ORDER_STATUS_REJECTED                           = 8

// // } OrderStatusType;
// //orders

// ORDER_STATUS_PENDING
// ORDER_STATUS_FILLED
// ORDER_STATUS_REJECTED
// ORDER_STATUS_CANCELED
// ORDER_STATUS_ACK
// ORDER_STATUS_PARTIAL;

const TakyonLayout = () => {
  //   const { orders } = useOrders();
  //TEST data start

  const dispatch = useDispatch();

  window.order = (uferref) =>
    openOrders({
      id: "",
      timestamp: 1633018548545,
      response_type: "openOrders",
      data: [
        {
          avg_px: 0.0,
          cancelReason: "",
          clOrd: "",
          closePrice: 0.0,
          closePrice2: 0.0,
          connection: 0,
          curr: 1633018548402,
          exchId: "O45UD6-JTYSG-JDUYKM",
          exchangeType: 0,
          fc: "EOS/USD",
          filled: 0.0,
          id: 0,
          orderStatus: 2,
          orderType: 2,
          origId: "",
          portfolio: "empty",
          prev: 1633018548402,
          price2: 0.0,
          px: 2.04,
          qty: 2.0,
          rejectType: 0,
          rem: 2.0,
          reqid: 0,
          sequence: 3,
          side: "BUY",
          strategyId: 0,
          tif: 0,
          trig: 0,
          tx_px: 0.0,
          type: 16,
          user: 4,
          userref: uferref,
        },
      ],
    });
  openOrders({
    id: "",
    timestamp: 1633018548545,
    response_type: "openOrders",
    data: [
      {
        avg_px: 0.0,
        cancelReason: "",
        clOrd: "",
        closePrice: 0.0,
        closePrice2: 0.0,
        connection: 0,
        curr: 1633018548402,
        exchId: "O45UD6-JTYSG-JDUYKM",
        exchangeType: 0,
        fc: "EOS/USD",
        filled: 0.0,
        id: 0,
        orderStatus: 2,
        orderType: 2,
        origId: "",
        portfolio: "empty",
        prev: 1633018548402,
        price2: 0.0,
        px: 2.04,
        qty: 2.0,
        rejectType: 0,
        rem: 2.0,
        reqid: 0,
        sequence: 3,
        side: "BUY",
        strategyId: 0,
        tif: 0,
        trig: 0,
        tx_px: 0.0,
        type: 16,
        user: 4,
        userref: "11001",
      },
    ],
  });

  openOrders({
    id: "",
    timestamp: 1633018548546,
    response_type: "openOrders",
    data: [
      {
        avg_px: 0.0,
        cancelReason: "",
        clOrd: "",
        closePrice: 0.0,
        closePrice2: 0.0,
        connection: 0,
        curr: 1633018548402,
        exchId: "O45UD6-JTYSG-JDUYKM",
        exchangeType: 0,
        fc: "EOS/USD",
        filled: 0.0,
        id: 0,
        orderStatus: 3,
        orderType: 2,
        origId: "",
        portfolio: "empty",
        prev: 1633018548402,
        price2: 0.0,
        px: 2.04,
        qty: 2.0,
        rejectType: 0,
        rem: 2.0,
        reqid: 0,
        sequence: 4,
        side: "BUY",
        strategyId: 0,
        tif: 0,
        trig: 0,
        tx_px: 0.0,
        type: 16,
        user: 4,
        userref: "11001",
      },
    ],
  });

  openOrders({
    id: "",
    timestamp: 1633018575666,
    response_type: "openOrders",
    data: [
      {
        avg_px: 0.0,
        cancelReason: "",
        clOrd: "",
        closePrice: 0.0,
        closePrice2: 0.0,
        connection: 0,
        curr: 1633018575666,
        exchId: "OSWXEK-C2RTB-TE5NVM",
        exchangeType: 0,
        fc: "EOS/USD",
        filled: 0.0,
        id: 0,
        orderStatus: 2,
        orderType: 2,
        origId: "",
        portfolio: "empty",
        prev: 1633018575666,
        price2: 0.0,
        px: 12.04,
        qty: 2.0,
        rejectType: 0,
        rem: 2.0,
        reqid: 0,
        sequence: 1,
        side: "BUY",
        strategyId: 0,
        tif: 0,
        trig: 0,
        tx_px: 0.0,
        type: 16,
        user: 4,
        userref: "11002",
      },
    ],
  });
  openOrders({
    id: "",
    timestamp: 1633018575666,
    response_type: "openOrders",
    data: [
      {
        avg_px: 0.0,
        cancelReason: "",
        clOrd: "",
        closePrice: 0.0,
        closePrice2: 0.0,
        connection: 0,
        curr: 1633018575666,
        exchId: "OSWXEK-C2RTB-TE5NVM",
        exchangeType: 0,
        fc: "EOS/USD",
        filled: 0.0,
        id: 0,
        orderStatus: 3,
        orderType: 2,
        origId: "",
        portfolio: "empty",
        prev: 1633018575666,
        price2: 0.0,
        px: 12.04,
        qty: 2.0,
        rejectType: 0,
        rem: 2.0,
        reqid: 0,
        sequence: 2,
        side: "BUY",
        strategyId: 0,
        tif: 0,
        trig: 0,
        tx_px: 0.0,
        type: 16,
        user: 4,
        userref: "11002",
      },
    ],
  });
  openOrders({
    id: "",
    timestamp: 1633018575667,
    response_type: "openOrders",
    data: [
      {
        avg_px: 3.8656,
        cancelReason: "",
        clOrd: "",
        closePrice: 0.0,
        closePrice2: 0.0,
        connection: 0,
        curr: 1633018575666,
        exchId: "OSWXEK-C2RTB-TE5NVM",
        exchangeType: 0,
        fc: "EOS/USD",
        filled: 2.0,
        id: 0,
        orderStatus: 3,
        orderType: 2,
        origId: "",
        portfolio: "empty",
        prev: 1633018575666,
        price2: 0.0,
        px: 12.04,
        qty: 2.0,
        rejectType: 0,
        rem: 0.0,
        reqid: 0,
        sequence: 4,
        side: "BUY",
        strategyId: 0,
        tif: 0,
        trig: 0,
        tx_px: 0.0,
        type: 16,
        user: 4,
        userref: "11002",
      },
    ],
  });
  openOrders({
    id: "",
    timestamp: 1633018575668,
    response_type: "openOrders",
    data: [
      {
        avg_px: 3.8656,
        cancelReason: "",
        clOrd: "",
        closePrice: 0.0,
        closePrice2: 0.0,
        connection: 0,
        curr: 1633018575666,
        exchId: "OSWXEK-C2RTB-TE5NVM",
        exchangeType: 0,
        fc: "EOS/USD",
        filled: 2.0,
        id: 0,
        orderStatus: 6,
        orderType: 2,
        origId: "",
        portfolio: "empty",
        prev: 1633018575666,
        price2: 0.0,
        px: 12.04,
        qty: 2.0,
        rejectType: 0,
        rem: 0.0,
        reqid: 0,
        sequence: 6,
        side: "BUY",
        strategyId: 0,
        tif: 0,
        trig: 0,
        tx_px: 0.0,
        type: 16,
        user: 4,
        userref: "11002",
      },
    ],
  });

  //TEST data end

  //{"id":"","timestamp":1633018548545,"response_type":"openOrders","data":[{"avg_px":0.0,"cancelReason":"","clOrd":"","closePrice":0.0,"closePrice2":0.0,"connection":0,"curr":1633018548402,"exchId":"O45UD6-JTYSG-JDUYKM","exchangeType":0,"fc":"EOS/USD","filled":0.0,"id":0,"orderStatus":2,"orderType":2,"origId":"","portfolio":"empty","prev":1633018548402,"price2":0.0,"px":2.04,"qty":2.0,"rejectType":0,"rem":2.0,"reqid":0,"sequence":3,"side":"BUY","strategyId":0,"tif":0,"trig":0,"tx_px":0.0,"type":16,"user":4,"userref":"11001"}]}
  const { user } = useAuth();

  const { isConnected } = useSocket();

  const { renewKrakenToken } = useKraken();

  useEffect(() => {
    if (isConnected) {
      renewKrakenToken();
    }
  }, [isConnected]);
  // const {isConnected} =  false;

  //!!! use SAGA!
  // const { fetchTrades } = useTrades();
  const { fetchPortfolio } = usePortfolio();
  const { fetchBalance } = useBalance();
  const { fetchKrakenAssetPairs } = useKrakenAssetPairs();
  const { fetchCoinbaseAssetPairs } = useCoinbaseAssetPairs();

  // !!! use saga!
  // useEffect(() => {
  //   fetchKrakenAssetPairs();
  //   fetchCoinbaseAssetPairs();
  //   console.log(
  //     "FETCHED COINBASE ASSET PAIRS ----------------------------------------"
  //   );
  //   fetchTrades();
  //   fetchPortfolio();
  // }, [fetchKrakenAssetPairs, fetchTrades, fetchPortfolio]);

  // Fetch balance every 30 sec.
  // Do not check if no_exchange
  useEffect(() => {
    fetchBalance();
    let balanceTimer = setTimeout(() => {
      console.log("SAMEER: fetch balance");
      fetchBalance();
    }, 30000);
    return () => {
      clearTimeout(balanceTimer);
    };
  }, [fetchBalance]);

  const classes = useStyles();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => {
    let item = state.customization.opened;
    console.log(item);
    return state.customization.opened;
  });
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  // React.useEffect(() => {
  //   dispatch({ type: SET_MENU, opened: !matchDownMd });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [matchDownMd]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        className={leftDrawerOpened ? classes.appBarWidth : classes.appBar}
      >
        <Toolbar>
          <Header
            handleLeftDrawerToggle={handleLeftDrawerToggle}
            isConnected={isConnected}
          />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar
        drawerOpen={leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
      />

      {/* main content */}
      <main
        className={clsx([
          classes.content,
          {
            [classes.contentShift]: leftDrawerOpened,
          },
        ])}
      >
        {/* breadcrumb */}
        <Breadcrumbs
          separator={IconChevronRight}
          navigation={navigation}
          icon
          title
          rightAlign
        />
        <Outlet />
      </main>
      <Customization />
      <KrakenTokenModal />
      <CoinbaseTokenModal />
    </div>
  );
};

export default TakyonLayout;
