import React from 'react';
import CandleChartCard from './components/CandleChartCard';
import { Box, Grid, Tab, Tabs, Typography} from "@material-ui/core";
import PropTypes from 'prop-types';
import DiveBarExploreCard from "layout/LiteTakyonLayout/DiveBarClub/DiveBarExploreCard";
import DiveBarLendingCard from "layout/LiteTakyonLayout/DiveBarClub/DiveBarLendingCard";
import DiveBarStakingCard from "layout/LiteTakyonLayout/DiveBarClub/DiveBarStakingCard";
import DiveBarNFTCard from "layout/LiteTakyonLayout/DiveBarClub/DiveBarNFTCard";
import { useDispatch, useSelector } from "react-redux";

// tab content
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid item xs={12} md={12}>
          <Typography variant="h4" sx={{color: 'white'}}>{children}</Typography>
        </Grid>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CandleChartLayout  = () => {

    const explore_list = useSelector((state) => {
        return state.coins.kraken_asset_pairs;
    });

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }; 

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="simple tabs example"
            variant="scrollable"
          >

            <Tab value={0} onChange={handleChange} label={<font variant="h4" style={{'fontSize': '1.1rem', 'color': 'white'}} >Chart</font>} />
            <Tab value={1} onChange={handleChange} label={ <Typography variant="h4" sx={{color: 'white'}} >Explore</Typography>} />
            <Tab value={2} onChange={handleChange} label={ <Typography variant="h4" sx={{color: 'white'}} >Staking</Typography>} />
            <Tab value={3} onChange={handleChange} label={ <Typography variant="h4" sx={{color: 'white'}} >Lending</Typography>} />
            <Tab value={4} onChange={handleChange} label={ <Typography variant="h4" sx={{color: 'white'}} >OpenSea NFT</Typography>} />

          </Tabs>

          <TabPanel value={value} index={0}>
            <CandleChartCard />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <DiveBarExploreCard rows={explore_list}/>
          </TabPanel>

          <TabPanel value={value} index={2}>
            <DiveBarStakingCard  rows={explore_list}/>
          </TabPanel>

          <TabPanel value={value} index={3}>
            <DiveBarLendingCard rows={explore_list}/>
          </TabPanel>

          <TabPanel value={value} index={4}>
            <DiveBarNFTCard  rows={explore_list}/>
          </TabPanel>

        </Grid>
      </Grid>
    );
}

export default React.memo(CandleChartLayout);
