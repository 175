import React from "react";
import { Button } from "@material-ui/core";
const Purchase = ({ items, handlePurchase }) => {

  return (
    <div>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        label="One-Click-BUY"
        color="success"
        onClick={handlePurchase}
        disabled={!(items.length > 0)}
      >
        Basket (BUY)
      </Button>
    </div>
  );
};

export default Purchase;
