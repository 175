import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// project imports
import useAuth from "hooks/useAuth";
import { useEffect } from "react";
import axios from "axios";

// ===========================|| AUTH GUARD ||=========================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
  const { isLoggedIn, user, coinbaseRefreshToken } = useAuth();
  // const   isLoggedIn  = true;
  const navigate = useNavigate();


  const [selectedExchange, exchanges] = useSelector((state) => [
    state.exchange.selectedExchange,
    state.exchange.exchanges,
  ]);

  const refreshToken  = useSelector((state) =>  state.exchange.exchanges.coinbase.credentials.refreshToken);
  //Add axios/ TODO: move

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     axios.interceptors.request.use((config) => {
  //       // config.params = { ...config.params, token: encodeURIComponent(user.token) };
  //       config.params = { ...config.params, token: user.token };
  //       return config;
  //     });
  //   }
  // }, [isLoggedIn, user.token]);

  

  
  useEffect(()=>{
    if (selectedExchange === 'coinbase') {

      axios.interceptors.response.use(
        (response) => ({...response, data: response.data.data || response.data}),
        async (error) => {
          const response = error ? error.response : undefined;
      
          if (response.status === 403) {
            coinbaseRefreshToken(refreshToken);
            // refreshToken(refreshToken)
          }
        }
      );

    }
  },[selectedExchange, refreshToken])

  useEffect(() => {

 
    if (isLoggedIn && selectedExchange === 'coinbase') {

      axios.interceptors.request.use((config) => {
        // config.params = { ...config.params, token: encodeURIComponent(user.token) };
        config.params = { ...config.params, c_token: exchanges[selectedExchange].credentials.accessToken };
        return config;
      });


    }
  }, [isLoggedIn, selectedExchange, exchanges]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/pages/login/takyon", { replace: true });
    }
  }, [isLoggedIn, navigate]);

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
