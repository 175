import React, { useContext } from "react";
import { useSelector } from "react-redux";
import CoinContext from "contexts/CoinContext";
import { Grid, Typography } from "@material-ui/core";
import { map_coins } from "store/constant";
import colors from 'assets/scss/_themes-vars.module.scss';
import { makeStyles, useTheme } from "@material-ui/styles";
import {currencyFormat}  from 'utils/currencyFormat';

const Panel = ({ltp, daily_hi, daily_lo, daily_percent}) => {
  const { coin } = useContext(CoinContext);
  const coin_style = map_coins.get(coin);
  const theme = useTheme();
  const cornsilk = (theme.palette.mode === 'dark') ? colors.cornsilkLightTakyon : colors.cornsilkDarkTakyon;
  const whiteTakyon = (theme.palette.mode === 'dark') ? colors.whiteLightTakyon : colors.whiteDarkTakyon;
  const green = (theme.palette.mode === 'dark') ? colors.successLightTakyon : colors.successDarkTakyon;
  const red = (theme.palette.mode === 'dark') ? colors.errorLightTakyon : colors.errorDarkTakyon;



  const prefix = daily_percent >= 0 ? '+' : '-';

  let rung = (daily_percent < 0.0) ? red : green;

  return (
      <Typography variant="h3">
        <font style={{ fontWeight: 'normal', textAlign: 'top', spacing: "10px", "font-size": "1.8rem" }}> {currencyFormat(ltp)} </font>
        <font style={{ color: rung, textAlign: 'top', fontWeight: 'normal', spacing: "10px", "font-size": "1.4rem" }}> {prefix}{daily_percent.toFixed(2)} % </font>
      </Typography>
  );
};

export default React.memo(Panel);
