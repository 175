function compare(a, b) {
  if (a.sequence < b.sequence) {
    return -1;
  }
  if (a.sequence > b.sequence) {
    return 1;
  }
  return 0;
}

class Orders {
  constructor() {
    this.orders = {};
  }

  addItem(order) {
    if (this.orders.hasOwnProperty(order.exchId)) {
      //skip existing sequence
      if (
        this.orders[order.exchId].some(
          (item) => item.sequence === order.sequence
        )
      ) {
        console.log("skip  order data with same sequence");
      } else {
        this.orders[order.exchId].push(order);
        console.log("add new order data");
      }

      console.log({ order });
    } else {
      this.orders[order.exchId] = [order];
      //todo if message exists
      console.log("adding informtion to order");
      console.log(this.orders[order.exchId]);
    }
  }

  getOrders() {
    //TODO make own model
    return Object.keys(this.orders).map((key) => ({
      name: this.orders[key][0].exchId,
      coin: this.orders[key][0].fc,
      data: this.orders[key].sort(compare),
    }));
    // return this.orders;
  }

  getOrderData(id) {
    return this.orders[id].sort(compare);
  }
}

export default Orders;
