import * as PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import {
  Chip,
  Divider,
  Grid,
  List,
  ListItemAvatar,
  ListItemText,
  Typography,
  ListSubheader,
} from "@material-ui/core";
import ListItemButton from "@material-ui/core/ListItemButton";

// project imports
import UserAvatar from "./UserAvatar";
import axios from "utils/axios";

// ===========================|| CHAT USER LIST ||=========================== //

const UserList = ({ setUser, openedRoom }) => {
  // const { users, channelUsers } = useSelector((state) => state.diveBarClub);
  const users = useSelector((state) => state.diveBarClub.channelUsers.has(openedRoom.id) ? state.diveBarClub.channelUsers.get(openedRoom.id) : []);
  const {channelUsers} = useSelector((state) => state.diveBarClub);
  console.log({users, openedRoom,channelUsers})
  // ..channelId:


  return (
    <List
      component="nav"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Users
        </ListSubheader>
      }
    >
      {users.map((user) => (
        <React.Fragment key={user.id}>
          <ListItemButton
            onClick={() => {
              setUser(user);
            }}
          >
            <ListItemAvatar>
              <UserAvatar user={user} w='20px' h='20px'/>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Grid
                  container
                  alignItems="center"
                  spacing={1}
                  component="span"
                >
                  <Grid item xs zeroMinWidth component="span">
                    <Typography
                      variant="h5"
                      color="inherit"
                      component="span"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        display: "block",
                      }}
                    >
                      {user.display_name}
                    </Typography>
                  </Grid>
                </Grid>
              }
              secondary={
                <Grid
                  container
                  alignItems="center"
                  spacing={1}
                  component="span"
                >
                  <Grid item xs zeroMinWidth component="span">
                    <Typography
                      variant="caption"
                      component="span"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        display: "block",
                      }}
                    >
                      {user.lasMessage}
                    </Typography>
                  </Grid>
                  <Grid item component="span">
                    {user.unReadChatCount !== 0 && (
                      <Chip
                        label={user.unReadChatCount}
                        component="span"
                        color="secondary"
                        sx={{
                          width: "20px",
                          height: "20px",
                          "& .MuiChip-label": {
                            px: "4px",
                          },
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              }
            />
          </ListItemButton>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
};

UserList.propTypes = {
  setUser: PropTypes.func,
};

export default UserList;
