import React, { useEffect, useState } from "react";
import { Grid, Chip, IconButton } from "@material-ui/core";
import Button from "../../../../ui-component/cards/SubCard";
import ColorSubCard from "../../../../ui-component/cards/ColorSubCard";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setModalVirtualCardDialogStatus,
  setVirtualCard,
  delVirtualCard,
  updateVirtualCard,
} from "store/actions";
import { sort_by } from "./helpers";
import MUIFormControl from "@material-ui/core/FormControl";
import { tooltips } from "constants/tooltips";
import ToolTipHelper from "ui-component/ToolTipHelper";
import CoinBaseLoginButton from "ui-component/CoinBaseLoginButton";
import "styles/cards.css";
import ModalAddVirtualCard from "ui-component/ModalAddVirtualCard";
import { gridSpacing } from 'store/constant';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import {
  map_coins,
  map_balance_to_coin,
  map_coin_to_name,
} from "store/constant";
import {
  Fab,
  Typography,
} from "@material-ui/core";
import colors from "assets/scss/_themes-vars.module.scss";
// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";

const VirtualCardsMain = () => {

  const [selectedExchange, exchanges] = useSelector((state) => [
    state.exchange.selectedExchange,
    state.exchange.exchanges,
  ]);


  const map_virtual_cards = useSelector((state) => state.cards.map_virtual_cards);
  const isOpen = useSelector((state) => state.cards.isOpen);

  const [virtualCards, setVirtualCards] = useState([]);

  useEffect(() => {
    const newVirtualCards = [];
    map_virtual_cards.forEach(element => {

      newVirtualCards.push(element)
    });

    setVirtualCards(newVirtualCards)
  }, [map_virtual_cards])

  // TMD console.log( 'MAP_VIRTUAL_CARDS:');
  // TMD console.log( map_virtual_cards);

  const dispatch = useDispatch();
  const theme = useTheme();
  const cornsilk =
    theme.palette.mode === "dark"
      ? colors.cornsilkLightTakyon
      : colors.cornsilkDarkTakyon;
  const whiteTakyon =
    theme.palette.mode === "dark"
      ? colors.whiteLightTakyon
      : colors.whiteDarkTakyon;
  const green =
    theme.palette.mode === "dark"
      ? colors.successLightTakyon
      : colors.successDarkTakyon;
  const red =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;

  const handleAddVirtualCard = () => {
    dispatch(setModalVirtualCardDialogStatus(true, undefined));
  };

  const handleOpenVirtualCard = (event, vc) => {
    // TMD console.log('HANDLE_OPEN: ' + vc.pan);
    dispatch(setModalVirtualCardDialogStatus(true, vc.pan));
  };

  const handleUpdateVirtualCard = () => {
    let pan = undefined;
    map_virtual_cards.map((vc, index) => {
      pan = vc.pan;
    });
    dispatch(updateVirtualCard({ pan: pan, current: 98.5 }));
  };


  const handleDeleteVirtualCard = (event, pan) => dispatch(delVirtualCard({ pan: pan }));

  // TMD console.log( 'LIST_VC_LENGTH: ' + map_virtual_cards.length);

  const list_virtual_cards = Array.from(map_virtual_cards).map(([pan, vc]) => ({ vc }));

  // TMD console.log('LIST');
  // TMD console.log(list_virtual_cards);

  return (
    <Grid
      item
      container
      padding={0}
      direction="rows"
      alignItems="top"
      spacing={0.5}
    >
      <Grid item xs={12} lg={12}>
        <ColorSubCard
          border={'white'}
          title={
            <Grid item container padding={0} alignItems="center" spacing={0.5}>
              <Grid item xs={12} lg={9}>Virtual Prepaid Cards</Grid>
              <Grid item  >
                
    
                <div style={{
                    cursor: 'pointer', 
                    background: 'green', 
                    color: 'white',
                    minWidth:"100px", 
                    borderRadius:"5px",
                    textAlign:"center", 
                    minWidth:"100px",
                    lineHeight:"35px"
              }} onClick={handleAddVirtualCard}>Add</div>

                {/* <Button
                  variant="contained"
                  align="center"
                  size="small"
                  label="Add"
                  color="success"
                  onClick={handleAddVirtualCard}
                >
                Add
                </Button> */}
              </Grid>
              {/* <Grid item xs={12} lg={3}><div style={{
                cursor: 'pointer'
              }} onClick={handleUpdateVirtualCard}>Update</div></Grid> */}
            </Grid>
          }
        >
          <>

            <Grid container alignItems="center" justifyContent={"center"}>

              {(virtualCards.length !== 0) &&
                virtualCards.map((vc) => {

                  return (
                    <Grid item xs={4} id={vc.pan} >
                      
                      <div style={{ position: 'relative' }} >
                          <span className="rotated0 visa" id="card" item onClick={(event) => handleOpenVirtualCard(event, vc)}>
                            <a id="name">{vc.name}</a>
                            <a id="pan">{vc.pan}</a>
                            <a id="cvv">{vc.cvv}</a>
                            <a id="expiry">{vc.expiry}</a>
                            <a style={{ textAlign: 'right' }} id="current">{'$'}{vc.current}</a>
                          </span>
                        <div style={{ position: "absolute", right: "1px", top: "4px" }}>
                          <IconButton color="inherit" onClick={(event) => handleDeleteVirtualCard(event, vc.pan)}>
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                        </div>
                      </div>

                    </Grid>
                  );
                })
              }

            </Grid>
          </>
        </ColorSubCard>
      </Grid>

      {
        isOpen && <ModalAddVirtualCard />
      }
      {/* <ModalAddVirtualCard /> */}
      {
        selectedExchange === "noExchange" && (
          <>
            <Grid item xs={12} />
            <Grid item xs={12} />

            <Grid item xs={12}>
              <ColorSubCard border={"orange"}>
                <Grid container xs={12} alignItems={"center"} flexDirection={"column"}>
                  <Grid item>
                    <Typography variant="h2" sx={{ color: whiteTakyon }}>
                      Login to Coinbase Exchange to see Trends in your
                    </Typography>
                  </Grid>
                  <Grid item>
                    <CoinBaseLoginButton />
                  </Grid>
                </Grid>
              </ColorSubCard>
            </Grid>
          </>
        )
      }
    </Grid >
  );
};

export default React.memo(VirtualCardsMain);
