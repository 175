import * as PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef } from "react";
import * as I from "immutable";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { Card, CardContent, Grid, Typography } from "@material-ui/core";

// project imports
import { gridSpacing } from "store/constant";

// ===========================|| CHAT MESSAGE HISTORY ||=========================== //

const ChartHistory = ({ theme, data, userId }) => {
  // scroll to bottom when new message is sent or received
  const wrapper = useRef(document.createElement("div"));
  const el = wrapper.current;
  const scrollToBottom = useCallback(() => {
    el.scrollIntoView(false);
  }, [el]);

  useEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  const map_uid = useSelector((state) => {
    let umap = I.Map();
    if ( state.diveBarClub.users !== undefined)
    {
        state.diveBarClub.users.forEach((user, index) => {
            umap = umap.set( user.id, user);
        });
    }
    return umap;
  });

  return (
    <Grid item xs={12}>
      <Grid container spacing={0.5} ref={wrapper}>
        {data && data.messages && data.messages.map((history, index) => {
        
            const userObj = map_uid.get(history.from);
            return (
          <React.Fragment key={index}>
            {history.from === userId ? (
              <Grid item xs={12}>
                <Grid container >
                  <Grid item xs={2} />
                  <Grid item xs={10}>
                    <Card
                      sx={{
                        display: "inline-block",
                        float: "right",
                        bgcolor:
                          theme.palette.mode === "dark"
                            ? theme.palette.grey[600]
                            : theme.palette.primary.light,
                      }}
                    >
                      <CardContent
                        sx={{
                          p: "5px",
                          pb: "5px !important",
                          width: "fit-content",
                          ml: "auto",
                        }}
                      >
                            {/*<Typography variant="body3" color="darkgray" >
                              {userObj.display_name}
                            </Typography>
                            */}
                            <Typography
                              variant="body4"
                              color={
                                theme.palette.mode === "dark" ? "dark.900" : ""
                              }
                            >
                              {history.message}
                            </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Grid container >
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        display: "inline-block",
                        float: "left",
                        background:
                          theme.palette.mode === "dark"
                            ? theme.palette.dark[900]
                            : theme.palette.secondary.light,
                      }}
                    >
                      <CardContent sx={{ p: "16px", pb: "16px !important" }}>
                          <Grid item xs={12}>
                            <Typography color="yellow" variant="body3">
                              {userObj.display_name}
                            </Typography>
                            <Typography variant="body2">
                              {history.message}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography align="right" variant="subtitle2">
                              {/* 00:00 */}
                            </Typography>
                          </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </React.Fragment>
        );
        }
        )}
      </Grid>
    </Grid>
  );
};

ChartHistory.propTypes = {
  theme: PropTypes.object,
  data: PropTypes.array,
  user: PropTypes.object,
};

export default ChartHistory;
