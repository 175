import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  map_coins,
  map_balance_to_coin,
  map_coin_to_name,
} from "store/constant";
import { weekly_portfolio } from "store/weekly";
import I from "immutable";
import useSocket from "hooks/useSocket";
import Transitions from "ui-component/extended/Transitions";
import { useDispatch, useSelector } from "react-redux";
import CoinContext from "contexts/CoinContext";
// import useKrakenOHLC from "hooks/useKrakenOHLC";
import colors from "assets/scss/_themes-vars.module.scss";

import {
  InputAdornment,
  OutlinedInput,
  InputLabel,
  IconButton,
  Chip,
  Fab,
} from "@material-ui/core";
import MUIFormControl from "@material-ui/core/FormControl";
import FavoriteButton from "../../components/FavoriteButton";

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";

// material-ui
import {
  Button,
  Checkbox,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { visuallyHidden } from "@material-ui/utils";

// third party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import SubCard from "ui-component/cards/SubCard";

// assets
import Flag1 from "assets/images/widget/AUSTRALIA.jpg";
import Flag2 from "assets/images/widget/BRAZIL.jpg";
import Flag3 from "assets/images/widget/GERMANY.jpg";
import Flag4 from "assets/images/widget/UK.jpg";
import Flag5 from "assets/images/widget/USA.jpg";

// table filter
function descendingComparator(a, b, orderBy) {
  if (orderBy !== "Pair") {
    if (Number(b[orderBy]) < Number(a[orderBy])) {
      return -1;
    }
    if (Number(b[orderBy]) > Number(a[orderBy])) {
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// ===========================|| TABLE - HEADER ||=========================== //

// table header
const headCells = [
  {
    id: "Pair",
    numeric: false,
    disablePadding: true,
    label: "Pair",
  },
  {
    id: "invested",
    numeric: true,
    disablePadding: false,
    label: "Inv.",
  },
  {
    id: "watch",
    numeric: false,
    disablePadding: false,
    label: "*",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    purchaseAmount,
    changePurchaseAmount,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ bgColor: "white" }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            backgroundColor={"white"}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              bgColor={"white"}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.sortSpan}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

// style constant
const useStyles = makeStyles({
  root: {
    width: "100%",
    overflow: "hidden",
  },
  sortSpan: visuallyHidden,
});

// =========================|| DASHBOARD ANALYTICS - LATEST CUSTOMERS TABLE CARD ||========================= //

const FavoritesRecent = () => {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("invested");
  const classes = useStyles();
  const { emit } = useSocket();
  const [selectedIndex, setSelectedIndex] = React.useState("0");
  const { coin, setCoin } = useContext(CoinContext);
  const [query, setQuery] = useState("");

  const theme = useTheme();
  const cornsilk =
    theme.palette.mode === "dark"
      ? colors.cornsilkLightTakyon
      : colors.cornsilkDarkTakyon;
  const whiteTakyon =
    theme.palette.mode === "dark"
      ? colors.whiteLightTakyon
      : colors.whiteDarkTakyon;
  const green =
    theme.palette.mode === "dark"
      ? colors.successLightTakyon
      : colors.successDarkTakyon;
  const red =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;

  // const { fetchOHLC } = useKrakenOHLC();

  // const ohlcInterval = useSelector((state) => {
  //    return state.chart.ohlcInterval;
  // });

  const dispatch = useDispatch();

  const rows = useSelector((state) => {
    let items = [];

    if (state.coins.kraken_asset_pairs !== undefined) {
      state.favorites.favorites.forEach((item, index) => {
        let coin = state.coins.kraken_asset_pairs.get(item.label);
        if (coin !== undefined) 
        {
          items.push(coin);
        }
      });
    }
    return items;
  });

  /*
  const rows = useSelector((state) => {
        let items = [];
        state.coins.map_explore_recent.map((value, key) => {

            let item = state.coins.kraken_asset_pairs.get(key);
            if ( item !== undefined)
            {
                items.push(item);
            }
        });
        return items;
  });
*/

  // console.log( 'RECENT: size: ' + rows.length);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleListItemClick = (event, row) => {
    console.log("FavoritesRecent: coin: " + row.coin + ", " + row.wsname);
    console.log(row);
    setSelectedIndex(row.wsname);
    setCoin(row.wsname);
    // fetchOHLC(coin, ohlcInterval); // default interval
  };

  let filtered = [];
  rows
    .filter((post) => {
      if (query === "") {
        return post;
      } else if (post === undefined) {
        return post;
      } else if (post.coin_name.toLowerCase().includes(query.toLowerCase())) {
        return post;
      }
    })
    .map((post, index) => {
      filtered.push(post);
    });

  return (
    <SubCard
      title={
        <Grid container padding={0} alignItems="center" flexDirection="column" spacing={0.5}>
          <Grid item xs={12} lg={12}>
            <font
              style={{
                color: cornsilk,
                "text-align": "middle",
                "font-size": "1.4rem",
              }}
            >
              {"Favorites"}
            </font>
          </Grid>
          <Grid item xs={12} lg={12}>
            <MUIFormControl>
              <InputLabel>Search</InputLabel>
              <OutlinedInput
                fullWidth
                label="Search"
                name={"search"}
                onChange={(event) => setQuery(event.target.value)}
              />
            </MUIFormControl>
            {/* <input
              placeholder="Search"
              onChange={(event) => setQuery(event.target.value)}
            /> */}
          </Grid>
        </Grid>
      }
      content={false}
      alignItems="middle"
      md={5}
      aria-label="main mailbox folders"
    >
      <TableContainer className={classes.container}>
        <Table stickyHeader size="small" aria-label="explore">
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(filtered, getComparator(order, orderBy)).map(
              (row, index) => {
                const coin_style = map_coins.get(row.coin);
                // const coin_name = map_coin_to_name.get(row.wsname) ? map_coin_to_name.get(row.wsname) : row.wsname;
                const coin_name = row.marketcap ?  row.marketcap.Name : (row.base_currency ? row.base_currency : (row.base ? row.base : row.wsname));
                const rung = row.ltp < row.avg ? "#ff4976" : "#4bffb5";
                // const rung = row.ltp < row.remaining_avg_price ? "#ff4976" : "#4bffb5";
                const labelId = `${row.coin}`;

                let invested = row.position
                  ? row.position.balance * row.position.market_price
                  : 0;
                let dollar = "$";
                if (invested === 0 || isNaN(invested)) {
                  invested = " ";
                  dollar = " ";
                } else {
                  invested = Number(invested.toFixed(0));
                }

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    key={row.coin}
                    onClick={(event) => handleListItemClick(event, row)}
                  >
                    <TableCell>
                      <table
                        style={{
                          fixed: { "table-layout": "fixed" },
                          td: { overflow: "hidden" },
                        }}
                      >
                        <tr>
                          <td style={{ align: "left" }}>
                            <a class={coin_style}> {coin_name} </a>
                            {/*
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontSize: "0.9rem",
                                textAlign: "left",
                                color: "gray",
                              }}
                            >
                              {map_balance_to_coin.get(row.coin)
                                ? map_balance_to_coin.get(row.coin)
                                : row.coin}
                            </Typography>
                            */}
                          </td>
                        </tr>
                      </table>
                    </TableCell>

                    <TableCell sx={{ "font-weight": "bold", color: rung }}>
                      <table
                        style={{
                          fixed: { "table-layout": "fixed" },
                          td: { overflow: "hidden" },
                        }}
                      >
                        <tr>
                          <td style={{ textAlign: "left" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontSize: "1.2rem",
                                textAlign: "left",
                                color: rung,
                              }}
                            >
                              {dollar}
                              {invested}
                            </Typography>
                          </td>
                        </tr>
                      </table>
                    </TableCell>
                    <TableCell sx={{ pl: 3 }} padding="checkbox">
                      <table>
                        <tr>
                          <td>
                            <FavoriteButton pair={row.coin} />
                          </td>
                        </tr>
                      </table>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </SubCard>
  );
};

FavoritesRecent.propTypes = {
  title: PropTypes.string,
};

export default FavoritesRecent;
