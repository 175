import PropTypes from "prop-types";
import React, { useState, useContext, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import CoinContext from "contexts/CoinContext";
import { useDispatch } from "react-redux";
import {
  map_coins,
  map_balance_to_coin,
  map_coin_to_name,
} from "store/constant";
import { Tab, Tabs } from "@material-ui/core";
import SelectedExchange from "ui-component/SelectedExchange";
import LiqPortfolio from "./LiqPortfolio.js";
import Liquidate from "./Liquidate";
import SummaryCard from "ui-component/cards/SummaryCard";
import colors from "assets/scss/_themes-vars.module.scss";
import CoinBaseLoginButton from "ui-component/CoinBaseLoginButton";
// material-ui
import {
  Button,
  Checkbox,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  IconButton,
  Chip,
  Fab,
  Box,
} from "@material-ui/core";

import {
  placeOrderTicket,
  orderTicketTimeout,
  OrderTicketTypes,
} from "store/actions";

import { coinbaseSell } from "store/actions";

import {
  setModalSearchStatus,
  setModalCoinbasePaymentmethodStatus,
} from "store/actions";

import MUIFormControl from "@material-ui/core/FormControl";
import Transitions from "ui-component/extended/Transitions";

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import SubCard from "ui-component/cards/SubCard";
import ColorSubCard from "ui-component/cards/ColorSubCard";
import MainCard from "ui-component/cards/MainCard";
import useSocket from "hooks/useSocket";
import useOrderTicket from "hooks/useOrderTicket";
import { addOrder } from "emitters";
import { IconSettings } from "@tabler/icons";

import { IconLoader } from "@tabler/icons";
import AnimateButton from "ui-component/extended/AnimateButton";
import FavoriteButton from "../../components/FavoriteButton";
import ToolTipHelper from "ui-component/ToolTipHelper";
import { tooltips } from "constants/tooltips";

// style constant
const useStyles = makeStyles((theme) => ({
  revenueCard: {
    position: "relative",
    color: "#fff",
  },
  revenueIcon: {
    position: "absolute",
    right: "13px",
    top: "14px",
    color: "#fff",
    "&> svg": {
      width: "100px",
      height: "100px",
      opacity: "0.5",
    },
    [theme.breakpoints.down("xs")]: {
      top: "13px",
      "&> svg": {
        width: "80px",
        height: "80px",
      },
    },
  },
}));

// table filter
function descendingComparator(a, b, orderBy) {
  if (orderBy !== "Pair") {
    if (Number(b["position"][orderBy]) < Number(a["position"][orderBy])) {
      return -1;
    }
    if (Number(b["position"][orderBy]) > Number(a["position"][orderBy])) {
      return 1;
    }
    return 0;
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// =============================|| REVENUE CARD ||============================= //

// table header
const headCells = [
  {
    id: "Pair",
    numeric: false,
    disablePadding: true,
    label: "Pair",
  },
  {
    id: "invested",
    numeric: true,
    disablePadding: false,
    label: "Invested",
  },
  {
    id: "profit",
    numeric: true,
    disablePadding: false,
    label: "Profit",
  },
  {
    id: "percent",
    numeric: true,
    disablePadding: false,
    label: "Profit %",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    liquidateAmount,
    changeLiquidateAmount,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow
        sx={{
          alignItems: "middle",
          align: "right",
          backgroundColor: "#191b1e",
        }}
      >
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              backgroundColor: "#191b1e",
            }}
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              bgColor={"gray"}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}

        <TableCell
          align="left"
          padding="normal"
          // sortDirection={orderBy === headCell.id ? order : false}
        >
          <MUIFormControl sx={{ width: "200px", height: "50px" }}>
            <InputLabel>{"% of each asset"}</InputLabel>
            <OutlinedInput
              fullWidth
              label="Amount"
              name={"amount"}
              value={liquidateAmount}
              onChange={changeLiquidateAmount}
              background={"gray"}
              disabled={false}
              endAdornment={
                <>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <InputAdornment sx={{ fontWeight: "bold" }} position="end">
                    %
                  </InputAdornment>
                </>
              }
            />
          </MUIFormControl>
        </TableCell>
        <TableCell
          align="left"
          padding="normal"
          sx={{ width: "50px", height: "50px" }}
          // sortDirection={orderBy === headCell.id ? order : false}
        >
          {"Add"}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
const LiquidateNewCard = ({ title, rows }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [queryLiquidate, setQueryLiquidate] = useState("");

  const { accounts } = useSelector((state) => {
    return state.coinbaseAccounts;
  });
  const theme = useTheme();
  const cornsilk =
    theme.palette.mode === "dark"
      ? colors.cornsilkLightTakyon
      : colors.cornsilkDarkTakyon;
  const whiteTakyon =
    theme.palette.mode === "dark"
      ? colors.whiteLightTakyon
      : colors.whiteDarkTakyon;
  const green =
    theme.palette.mode === "dark"
      ? colors.successLightTakyon
      : colors.successDarkTakyon;
  const red =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("percent");
  const { emit } = useSocket();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [liquidateAmount, setLiquidateAmount] = useState(0);

  const [liquidate, setLiquidate] = useState(
    rows.map((row) => {
      // const coin_name = map_coin_to_name.get(row.coin) ? map_coin_to_name.get(row.coin) : row.coin;
      // const coin_name = row.marketcap ?  row.marketcap.Name : row.coin;
      const coin_name = row.marketcap
        ? row.marketcap.Name
        : row.base_currency
        ? row.base_currency
        : row.base
        ? row.base
        : row.wsname;
      return {
        ...row,
        coin: row.coin,
        coin_name: coin_name,
        amount: 0,
        cost: row.position.invested,
        selected: false,
      };
    })
  );

  const [selectedExchange, exchanges] = useSelector((state) => [
    state.exchange.selectedExchange,
    state.exchange.exchanges,
  ]);
  const coinbaseSelectedPayment = useSelector((state) => {
    return state.coinbasePaymentMethods.selected;
  });
  const { coin } = useContext(CoinContext);

  const coin_style = map_coins.get(coin);
  // const coin_name = map_coin_to_name.get(coin) ? map_coin_to_name.get(coin) : coin;
  const coin_name = useSelector((state) => {
    return state.coins.kraken_asset_pairs.get(coin) &&
      state.coins.kraken_asset_pairs.get(coin).marketcap
      ? state.coins.kraken_asset_pairs.get(coin).marketcap.Name
      : coin;
  });

  const handleSellMarket = (coin, qty) => {
    const UUID = Date.now() % 32768;
    const krakenToken =
      window.localStorage.getItem("kraken_token") || "no_token";

    const add_order_msg = JSON.stringify({
      event: "addOrder",
      k_token: krakenToken,
      order: {
        fc: coin,
        side: "SELL",
        id: Number(UUID),
        reqid: Number(UUID),
        userref: UUID.toString(),
        qty,
        orderType: Number(1),
      },
    });

    // TMD  console.log("ORDER: ");
    // TMD console.log(add_order_msg);

    emit(add_order_msg);

    return;
  };

  const handleLiquidate = () => {
    if (!coinbaseSelectedPayment) {
      dispatch(setModalCoinbasePaymentmethodStatus(true));
      return;
    }

    liquidate.forEach((item) => {
      if (item.selected === true) {
        console.log("Liquidate " + item.coin);

        
        const amount = item.amount / item.ltp;

        const account = accounts.find(
          (accountItem) =>
            accountItem.coin.toLowerCase() ===
            item.coin.split("/")[0].toLocaleLowerCase()
        );

        const buyObj = {
          coin: item.coin.split("/")[0],
          paymentMethod: coinbaseSelectedPayment?.id,
          // amount: Math.ceil(amount),
          amount,//: Math.ceil(amount),
          accessToken: exchanges.coinbase.credentials.accessToken,
          accountId: account.id,
        };

        console.log(buyObj);
        dispatch(coinbaseSell(buyObj));

        console.log(item.coin, item.amount / item.ltp);
        // handleSellMarket( item.coin, (item.amount / item.ltp),  ) /// add here params
      }
    });

    let items = [...liquidate];
    items.forEach((item) => {
      item.selected = false;
    });

    setLiquidate(items);
  };

  const changeLiquidateAmount = (e) => {
    if (Number(e.target.value) > 100 || Number(e.target.value) < 0) {
      return;
    }
    setLiquidateAmount(e.target.value);

    let items = [...liquidate];
    items.forEach((item) => {
      let amount = (Number(e.target.value) * Number(item.invested)) / 100;
      item.amount = amount;
    });

    setLiquidate(items);
  };

  const changeLiquidateAmountRow = (e, row) => {
    let amount =
      row.position.invested - e.target.value > 0.0
        ? e.target.value
        : row.position.price;
    const coin = row.coin;

    changeLiquidatePrice(coin, amount);
  };

  const changeLiquidatePrice = (pair, amount) => {
    let items = [...liquidate];
    const index = items.findIndex((item) => item.coin === pair);
    const item = { ...items[index], amount: amount };
    items[index] = item;
    setLiquidate(items);
  };

  const handleCheckboxSelect = (pair) => {
    let items = [...liquidate];
    const index = items.findIndex((item) => item.coin === pair);
    const item = { ...items[index], selected: !items[index].selected };
    items[index] = item;
    setLiquidate(items);
  };

  const checkSelected = (pair) =>
    liquidate.find((item) => item.coin === pair).selected;

  const coinLiquidateAmount = (pair) => {
    let amount = liquidate.find((item) => item.coin === pair).amount;
    amount = Math.floor(amount * 100) / 100.0;
    return amount;
  };

  const handleSetPercent = (percent) => {
    //duplicate logic in  changeamount

    setLiquidateAmount(percent);

    let items = [...liquidate];
    items.forEach((item) => {
      let amount = (Number(percent) * Number(item.invested)) / 100;
      item.amount = amount;
    });

    setLiquidate(items);
  };

  let filteredLiquidate = [];
  liquidate
    .filter((post) => {
      if (queryLiquidate === "") {
        return post;
      } else if (post === undefined) {
        return post;
      } else if (
        post.coin_name.toLowerCase().includes(queryLiquidate.toLowerCase())
      ) {
        return post;
      }
    })
    .map((post, index) => {
      filteredLiquidate.push(post);
    });

  return (
    <Grid container spacing={1.0} padding={0} alignItems="top">
      <Grid item xs={9.5} padding={2} alignItems="center">
        <Grid container padding={2.0} />
        <ColorSubCard
          border={red}
          title={
            <Grid container direction="rows" alignItems="center">
              <Grid item xs={3}>
                {selectedExchange === "noExchange" && <CoinBaseLoginButton />}
                {selectedExchange !== "noExchange" && (
                  <MUIFormControl>
                    <InputLabel>Search</InputLabel>
                    <OutlinedInput
                      fullWidth
                      label="Search"
                      name={"search"}
                      onChange={(event) =>
                        setQueryLiquidate(event.target.value)
                      }
                    />
                  </MUIFormControl>
                )}
              </Grid>
              <Grid item xs={3}>
                <ToolTipHelper
                  hover={tooltips.liquidate.hover}
                  modal={tooltips.liquidate.modal}
                >
                  <font
                    style={{
                      color: red,
                      "text-align": "center",
                      "font-size": "1.3rem",
                    }}
                  >
                    Liquidate...
                  </font>
                </ToolTipHelper>
              </Grid>
              <Grid item xs={6}>
                <LiqPortfolio setPercent={handleSetPercent} />
              </Grid>
            </Grid>
          }
          sx={{ align: "center" }}
          md={5}
          content={false}
          aria-label="main mailbox folders"
        >
          <TableContainer className={classes.container}>
            <Table size="small" aria-label="LiqPortfolio">
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                liquidateAmount={liquidateAmount}
                changeLiquidateAmount={changeLiquidateAmount}
                backgroundColor={"#ccc"}
              />
              <TableBody>
                {stableSort(
                  filteredLiquidate,
                  getComparator(order, orderBy)
                ).map((row, index) => {
                  const coin_style = map_coins.get(row.coin);
                  // const coin_name = map_coin_to_name.get(row.coin) ? map_coin_to_name.get(row.coin) : row.coin;
                  const coin_name = row.marketcap
                    ? row.marketcap.Name
                    : row.base_currency
                    ? row.base_currency
                    : row.base
                    ? row.base
                    : row.coin;
                  const rung = row.position.percent < 0 ? red : green;
                  const labelId = `${row.coin}`;

                  return (
                    <TableRow hover role="checkbox" key={row.coin}>
                      <TableCell>
                        <table
                          style={{
                            fixed: { "table-layout": "fixed" },
                            td: { overflow: "hidden" },
                          }}
                        >
                          <tr>
                            <td style={{ align: "left" }}>
                              <a class={coin_style}> {coin_name} </a>
                              <Typography>
                                <font
                                  variant="subtitle1"
                                  style={{
                                    fontSize: "0.8rem",
                                    textAlign: "left",
                                    color: "gray",
                                  }}
                                >
                                  {/*map_balance_to_coin.get(row.coin) ? map_balance_to_coin.get(row.coin) : row.coin*/}
                                  {row.base_currency}
                                </font>
                                <font
                                  variant="subtitle1"
                                  style={{
                                    fontSize: "0.8rem",
                                    textAlign: "left",
                                    color: "white",
                                  }}
                                >
                                  {" "}
                                  {row.position.balance < 1 &&
                                    row.position.balance.toFixed(5)}
                                  {row.position.balance >= 1 &&
                                    row.position.balance.toFixed(2)}
                                </font>
                              </Typography>
                            </td>
                          </tr>
                        </table>
                      </TableCell>
                      <TableCell sx={{ "font-weight": "bold" }}>
                        <table
                          style={{
                            fixed: { "table-layout": "fixed" },
                            td: { overflow: "hidden" },
                          }}
                        >
                          <tr>
                            <td style={{ textAlign: "left" }}>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: "1.2rem",
                                  textAlign: "left",
                                  color: rung,
                                }}
                              >
                                {"$"}
                                {row.position.invested}
                              </Typography>
                            </td>
                          </tr>
                        </table>
                      </TableCell>
                      <TableCell sx={{ "font-weight": "bold" }}>
                        <table
                          style={{
                            fixed: { "table-layout": "fixed" },
                            td: { overflow: "hidden" },
                          }}
                        >
                          <tr>
                            <td style={{ textAlign: "left" }}>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: "1.2rem",
                                  textAlign: "left",
                                  color: rung,
                                }}
                              >
                                {"$"}
                                {row.position.profit}
                              </Typography>
                            </td>
                          </tr>
                        </table>
                      </TableCell>
                      <TableCell sx={{ "font-weight": "bold" }}>
                        <table
                          style={{
                            fixed: { "table-layout": "fixed" },
                            td: { overflow: "hidden" },
                          }}
                        >
                          <tr>
                            <td style={{ textAlign: "left" }}>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: "1.0rem",
                                  textAlign: "left",
                                  color: rung,
                                }}
                              >
                                {row.position.percent}
                                {"%"}
                              </Typography>
                            </td>
                          </tr>
                        </table>
                      </TableCell>
                      <TableCell>
                        <MUIFormControl sx={{ width: "200px", height: "50px" }}>
                          <InputLabel>{"$"}</InputLabel>
                          <OutlinedInput
                            fullWidth
                            coin={row.coin}
                            // value={
                            //   mapLiquidateRows.get(row.coin)
                            //     ? mapLiquidateRows.get(row.coin).amount
                            //     : 0
                            // }
                            value={coinLiquidateAmount(row.coin)}
                            onChange={(event) =>
                              changeLiquidateAmountRow(event, row)
                            }
                            disabled={false}
                            endAdornment={
                              <>
                                <Divider
                                  sx={{ height: 28, m: 0.5 }}
                                  orientation="vertical"
                                />
                                <InputAdornment position="end">
                                  USD
                                </InputAdornment>
                              </>
                            }
                          />
                        </MUIFormControl>
                      </TableCell>
                      <TableCell sx={{ pl: 3 }} padding="checkbox">
                        <table>
                          <tr>
                            <td>
                              <Checkbox
                                color="primary"
                                // selected={checkSelected(row.coin)}
                                // checked
                                checked={row.selected === true}
                                onChange={() => handleCheckboxSelect(row.coin)}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </td>
                            <td>
                              <FavoriteButton pair={row.coin} />
                            </td>
                          </tr>
                        </table>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </ColorSubCard>
      </Grid>

      <Grid item xs={2.5} padding={2} alignItems="center">
        <Grid container padding={2.0} />
        <SummaryCard />
        <Grid container padding={1.0} />
        <ColorSubCard
          border={red}
          title={
            <Liquidate
              items={liquidate.filter((item) => item.selected === true)}
              handleLiquidate={handleLiquidate}
            />
          }
          content={false}
          spacing={1}
        >
          <TableContainer className={classes.container}>
            <Table stickyHeader size="small" aria-label="LiqPortfolio">
              <TableBody>
                {liquidate
                  .filter((item) => item.selected === true)
                  .map((item) => {
                    const coin_style = map_coins.get(item.coin);
                    const coin_name = map_coin_to_name.get(item.coin)
                      ? map_coin_to_name.get(item.coin)
                      : item.coin;
                    const amount = Math.floor(item.amount * 100) / 100.0;
                    return (
                      <Transitions type={"slide"} in={true} direction={"left"}>
                        <TableRow hover key={item.coin}>
                          <TableCell sx={{ color: red }}>
                            <table
                              style={{
                                fixed: { "table-layout": "fixed" },
                                td: { overflow: "hidden" },
                              }}
                            >
                              <tr>
                                <td>
                                  <a class={coin_style}>{coin_name}</a>
                                </td>
                              </tr>
                            </table>
                          </TableCell>

                          <TableCell sx={{ "font-weight": "bold" }}>
                            {"$"}
                            {amount}
                          </TableCell>
                        </TableRow>
                      </Transitions>
                    );
                  })}

                <TableRow hover>
                  <TableCell sx={{ "font-weight": "bold" }}>
                    {"$"}
                    {liquidate
                      .filter((item) => item.selected === true)
                      .reduce(
                        (acc, item) =>
                          (Number(acc) + Number(item.amount)).toFixed(2),
                        0
                      )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </ColorSubCard>
      </Grid>
    </Grid>
  );
};

LiquidateNewCard.propTypes = {};

export default React.memo(LiquidateNewCard);
