import React, { createContext, useEffect, useReducer } from "react";
import { Auth0Client } from "@auth0/auth0-spa-js";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { coinbaseSettingsExchange, selectExchange } from "store/actions";

// third-party
// import firebase from "firebase/app";
// import "firebase/auth";

// action - state management
import {
  ACCOUNT_INITIALIZE,
  fetchCoinbaseAccounts,
  fetchCoinbasePaymentMethods,
} from "store/actions";

// project imports
import accountReducer from "store/accountReducer";
import Loader from "ui-component/Loader";
import takyonRest from "hooks/useTakyonRest";
import config from "config";

// const
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: {
    token: "",
  },
};

// ===========================|| FIREBASE CONTEXT & PROVIDER ||=========================== //

const TakyonContext = createContext({
  ...initialState,
  takyonEmailPasswordNewUser: () => Promise.resolve(),
  takyonEmailPasswordSignIn: () => Promise.resolve(),
  coinbaseHandler: () => {},
  coinbaseLogin: () => {},
  coinbaseRefreshToken: () => {},
  logout: () => Promise.resolve(),
  initUser: () => {},
});

export const TakyonProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const { loginPasswordSignIn, emailPasswordNewUser, initTakyonUser } =
    takyonRest();

  const reduxDispatch = useDispatch();

  // const scopes =['wallet:user:email','wallet:accounts:read','wallet:payment-methods:read','wallet:buys:read','wallet:buys:create','wallet:sells:read','wallet:sells:create'];
  // const scopes =["address","email","offline","access","openid","phone","profile","wallet:accounts:create","wallet:accounts:delete","wallet:accounts:read","wallet:accounts:update","wallet:addresses:create","wallet:addresses:read","wallet:buys:create","wallet:buys:read","wallet:checkouts:create","wallet:checkouts:read","wallet:contacts:read","wallet:deposits:create","wallet:deposits:read","wallet:notifications:read","wallet:orders:create","wallet:orders:read","wallet:orders:refund","wallet:payment-methods:delete","wallet:payment-methods:limits","wallet:payment-methods:read","wallet:sells:create","wallet:sells:read","wallet:supported-assets:read","wallet:trades:create","wallet:trades:read","wallet:transactions:read","wallet:transactions:request","wallet:transactions:send","wallet:transactions:transfer","wallet:user:email","wallet:user:read","wallet:user:update","wallet:withdrawals:create","wallet:withdrawals:read"];
  // const scopes =[ "email", "access","openid","phone","profile","wallet:accounts:create" ,"wallet:accounts:read" ,"wallet:addresses:read","wallet:buys:create","wallet:buys:read","wallet:checkouts:create","wallet:checkouts:read","wallet:contacts:read" ,"wallet:deposits:read","wallet:notifications:read","wallet:orders:create","wallet:orders:read","wallet:orders:refund",  "wallet:payment-methods:limits","wallet:payment-methods:read","wallet:sells:create","wallet:sells:read","wallet:supported-assets:read","wallet:trades:create","wallet:trades:read","wallet:transactions:read","wallet:transactions:request","wallet:transactions:send","wallet:transactions:transfer","wallet:user:email","wallet:user:read","wallet:user:update","wallet:withdrawals:create","wallet:withdrawals:read"];
  // const scopes =[ "profile", "wallet:accounts:create" ,"wallet:accounts:read" ,"wallet:addresses:read","wallet:buys:create","wallet:buys:read","wallet:checkouts:create","wallet:checkouts:read","wallet:contacts:read" ,"wallet:deposits:read","wallet:notifications:read","wallet:orders:create","wallet:orders:read","wallet:orders:refund",  "wallet:payment-methods:limits","wallet:payment-methods:read","wallet:sells:create","wallet:sells:read","wallet:supported-assets:read","wallet:trades:create","wallet:trades:read","wallet:transactions:read","wallet:transactions:request",  "wallet:transactions:transfer","wallet:user:email","wallet:user:read","wallet:user:update","wallet:withdrawals:create","wallet:withdrawals:read"];
  // const scopes =["wallet:accounts:read","wallet:transactions:read"]; //works!
  // const scopes =["email", 'wallet:user:email', "wallet:accounts:read","wallet:transactions:read"]; //works!

  // const scopes =[
  //     'email',
  //     'wallet:user:email',
  //     'wallet:accounts:read',
  //     'wallet:transactions:read',
  //     'wallet:withdrawals:read',
  //     'wallet:buys:read', 'wallet:buys:create',
  //     'wallet:sells:read','wallet:sells:create',
  // ];

  const scopes = [
    "wallet:user:email",
    "wallet:accounts:read",
    "wallet:buys:create",
    "wallet:sells:create",
    "wallet:payment-methods:read",
    "wallet:buys:read",
    "wallet:sells:read",
  ];

  const coinbaseHandler = () =>
    (window.location.href = `https://www.coinbase.com/oauth/authorize?${new URLSearchParams(
      {
        ...config.coinbase,
        response_type: "code",
        client_secret: "",
        scope: scopes.concat(","),
      }
    ).toString()}&account=all`);

  const coinbaseLogin = async (code) => {
    const response = await axios.post(`https://api.coinbase.com/oauth/token`, {
      ...config.coinbase,
      code: code,
    });

    //save to localstore
    const accessToken = response.data.access_token;
    const refreshToken = response.data.refresh_token;

    return coinbaseUser(accessToken, refreshToken);
  };

  const coinbaseRefreshToken = async (refresh_token) => {
    const response = await axios.post(`https://api.coinbase.com/oauth/token`, {
      ...config.coinbase,
      grant_type: "refresh_token",
      refresh_token,
    });

    //save to localstore
    const accessToken = response.data.access_token;
    const refreshToken = response.data.refresh_token;

    return coinbaseUser(accessToken, refreshToken);
  };

  const coinbaseUser = async (accessToken, refreshToken) => {
    const config = {
      method: "get",
      url: "https://api.coinbase.com/v2/user",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const user = await axios(config);
    console.log(user);

    initTakyonUser(accessToken, "coinbase_light");

    window.localStorage.setItem("coinbase_token", accessToken);

    reduxDispatch(selectExchange("coinbase"));
    reduxDispatch(
      coinbaseSettingsExchange({
        accessToken,
        refreshToken,
      })
    );

    //fetch coinbase accounts, but after few seconds after init..
    setTimeout(() => {
      reduxDispatch(fetchCoinbaseAccounts(accessToken));
    }, 2500);

    //fetchPaymentMethods
    reduxDispatch(fetchCoinbasePaymentMethods(accessToken));

    console.log( 'TAKYON_CONTEXT');
    console.log( user);
    console.log( user.data);
    console.log( user.data.data);
    dispatch({
      type: ACCOUNT_INITIALIZE,
      payload: {
        isLoggedIn: true,
        user: {
          url: user.data.data.avatar_url,
          id: user.data.data.id,
          email: user.data.data.email,
          token: "", //remove.
          name: user.data.data.name,
        },
      },
    });
  };

  const takyonEmailPasswordSignIn = (login, password) =>
    loginPasswordSignIn(login, password);
  const takyonEmailPasswordNewUser = (login, password, email) =>
    emailPasswordNewUser(login, password, email);

  const logout = () => {
    window.localStorage.removeItem("takyon_token");
    // window.localStorage.removeItem('kraken_key');
    // window.localStorage.removeItem('kraken_sec');
    // window.localStorage.clear();

    dispatch({
      type: ACCOUNT_INITIALIZE,
      payload: {
        isLoggedIn: false,
        user: {
          token: "",
        },
      },
    });
  };

  const initUser = (token) => {
    window.localStorage.setItem("takyon_token", token);
    reduxDispatch(selectExchange("noExchange")); //TEMPORARY set to 'noExchange' for seed login
    // reduxDispatch(selectExchange('kraken'));

    dispatch({
      type: ACCOUNT_INITIALIZE,
      payload: {
        isLoggedIn: true,
        user: {
          id: "123",
          email: "user.email",
          token: token,
        },
      },
    });
  };

  useEffect(() => {
    if (window.localStorage.getItem("coinbase_token")) {
      coinbaseUser(
        window.localStorage.getItem("coinbase_token"),
        "refreshToken-init"
      ).catch((e) => {
        console.log(e);
        dispatch({
          type: ACCOUNT_INITIALIZE,
          payload: {
            isLoggedIn: false,
            user: {
              token: "",
            },
          },
        });
      });
    } else if (window.localStorage.getItem("takyon_token")) {
      dispatch({
        type: ACCOUNT_INITIALIZE,
        payload: {
          isLoggedIn: true,
          user: {
            id: "123",
            email: "user.email",
            token: window.localStorage.getItem("takyon_token"),
          },
        },
      });
    } else {
      dispatch({
        type: ACCOUNT_INITIALIZE,
        payload: {
          isLoggedIn: false,
          user: {
            token: "",
          },
        },
      });
    }

    //check saved login...
  }, [dispatch]);

  if (!state.isInitialized) {
    return <Loader />;
  }

  return (
    <TakyonContext.Provider
      value={{
        ...state,
        takyonEmailPasswordNewUser,
        takyonEmailPasswordSignIn,
        coinbaseHandler,
        coinbaseLogin,
        logout,
        initUser,
        coinbaseRefreshToken,
      }}
    >
      {children}
    </TakyonContext.Provider>
  );
};

export default TakyonContext;
