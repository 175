// import axios from "utils/axios";
import Config from 'config';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addKrakenAssetPairs } from "store/actions";

const getData = async (u) => {

    // let url = 'https://api.kraken.com/0/public/AssetPairs';
    let url = 'http://'+Config.takyon_domain+'/api/v1/AssetPairs';


  const response = await axios.get(
    url,
    {},
    {
      headers: {
        "Accept":	"*",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return response.data;
};

export default function useKrakenAssetPairs() {
  const dispatch = useDispatch();

  const fetchKrakenAssetPairs = () => {

    getData({}).then((data) => {
      dispatch(addKrakenAssetPairs(data));
    });
  };

  return {
    fetchKrakenAssetPairs,
  };
}
