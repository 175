import React, { useEffect } from "react";
// import usePortfolio from "hooks/usePortfolio";
import useBalance from "hooks/useBalance";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useKrakenAssetPairs from "hooks/useKrakenAssetPairs";
import colors from 'assets/scss/_themes-vars.module.scss';
import { makeStyles, useTheme } from "@material-ui/styles";
import ToolTipHelper from 'ui-component/ToolTipHelper';
import {tooltips} from 'constants/tooltips';
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@material-ui/core";

const LiqPortfolio = ({ setPercent }) => {

  // const { fetchPortfolio } = usePortfolio();
  // const { fetchBalance } = useBalance();
  const { fetchKrakenAssetPairs } = useKrakenAssetPairs();
  const dispatch = useDispatch();
  const theme = useTheme();
  const cornsilk = (theme.palette.mode === 'dark') ? colors.cornsilkLightTakyon : colors.cornsilkDarkTakyon;
  const whiteTakyon = (theme.palette.mode === 'dark') ? colors.whiteLightTakyon : colors.whiteDarkTakyon;
  const green = (theme.palette.mode === 'dark') ? colors.successLightTakyon : colors.successDarkTakyon;
  const red = (theme.palette.mode === 'dark') ? colors.errorLightTakyon : colors.errorDarkTakyon;

  const start_date = useSelector((state) => {
    return state.takyon.start_date;
  });

  const end_date = useSelector((state) => {
    return state.takyon.end_date;
  });

  const [startDate, setStartDate] = React.useState(start_date);
  const [endDate, setEndDate] = React.useState(end_date);

  const refreshPortfolioAndBalance = (startDate, endDate) => {
    dispatch({
      type: "FETCH_PORTFOLIO",
      payload: {
        start: new Date("May 31, 2021 23:59:59"),
        end: new Date("December 31, 2021 23:59:59"),
      },
    });
  };

  const percents = [5, 10, 15, 25, 50, 75, 100];

  return (
    <ToolTipHelper  hover={tooltips.liquidatePercent.hover} modal={tooltips.liquidatePercent.modal} >


        <ButtonGroup variant="outlined" aria-label="outlined button group">
          {percents.map((percent) => (
            <Button
              disableElevation
              variant={"outlined"}
              onClick={() => setPercent(percent)}
            >
              {percent}%
            </Button>
          ))}
        </ButtonGroup>
        </ToolTipHelper>

  );
};

export default LiqPortfolio;
