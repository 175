// import axios from "utils/axios";
import Config from 'config';
import axios from "axios";

const API = "https://"+Config.takyon_domain+"/api/v1";

export default function useTakyonRest() {
  const emailPasswordNewUser = (login, password, email) =>
    axios.post(
      `${API}/user?user_event=register&login=${login}&password=${password}&email=${email}`
    );

  const loginPasswordSignIn = (login, password) =>
    axios.post(
      `${API}/user?user_event=login&login=${login}&password=${password}`
    );

  const getKrakenToken = (key, secret) =>
    axios.get(
      `${API}/ktoken?user_event=k_token&k_key=${key}&k_sec=${secret}`
    );

  const initTakyonUser = (token, exchange) =>
  {
    const initConfig = {
      method: "post",
      url: `${API}/init`,
      params:{
        token,
        exchange
      } 
    };
   
   axios(initConfig);
  //  axios.post(`${API}/init`,{
  //   key: secret,
  //   type:provider
  //  });
  }

  return {
    emailPasswordNewUser,
    loginPasswordSignIn,
    getKrakenToken,
    initTakyonUser
  };
}
