import PropTypes from "prop-types";
import React, { createContext, useEffect, useReducer, useState } from "react";

// third-party
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";

// action - state management
import { ACCOUNT_INITIALIZE } from "store/actions";

// project imports
import accountReducer from "store/accountReducer";
import Loader from "ui-component/Loader";
import config from "config";
import axios from "axios";

const firebaseApp = firebase.initializeApp(config.firebase);
// // firebase initialize
// if (!firebase.apps.length) {
//     firebase.initializeApp(config.firebase);
// }

const db = firebaseApp.firestore();

// var messagesRef = firebase.database().ref('messages')

// const
const initialState = {
  loading: false,
  isSent: false,
  issueSent: false,
};

// ===========================|| FIREBASE CONTEXT & PROVIDER ||=========================== //

const TakyonFirebaseContext = createContext({
  ...initialState,
  sendMessage: (messageObj) => {},
  sendIssue: (messageObj) => {},
  saveUserInfo: (userInfo) => {},
  getUsers: () => {},
});

export const TakyonFirebaseProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  const sendMessage = (messageObj) => {
    setState({ ...state, loading: true });
    return new Promise((resolve, reject) => {
      db.collection("messages")
        .add({ ...messageObj })
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          setState({ ...state, loading: false });
        });
    });
  };
  const sendIssue = (messageObj) => {
    setState({ ...state, loading: true });
    return new Promise((resolve, reject) => {
      db.collection("issues")
        .add({ ...messageObj })
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          setState({ ...state, loading: false });
        });
    });
  };

  const saveUserInfo = (userInfo) => {
    db.collection("users")
      .add({ ...userInfo })
      .then((data) => {


        //   axios.get("https://api.ipify.org/").then((response) => {
        axios.get("https://api.ipregistry.co/?key=tryout").then((response) => {
          data.update({ ipInfo: response.data });
        });
      })
      .catch((e) => {

      });
  };

  const getUsers = () => {
    db.collection("users")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
        });
      });
  };

  return (
    <TakyonFirebaseContext.Provider
      value={{
        ...state,
        sendMessage,
        saveUserInfo,
        sendIssue,
        getUsers,
      }}
    >
      {children}
    </TakyonFirebaseContext.Provider>
  );
};

TakyonFirebaseProvider.propTypes = {
  children: PropTypes.node,
};

export default TakyonFirebaseContext;
