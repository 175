import React from "react";
import PropTypes from "prop-types";
import colors from "assets/scss/_themes-vars.module.scss";

// material-ui
import { useTheme } from "@material-ui/styles";

// material-ui
import {
  Button,
  CardActions,
  CardMedia,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

// third party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";

// assets
import Flag1 from "assets/images/widget/AUSTRALIA.jpg";
import Flag2 from "assets/images/widget/BRAZIL.jpg";
import Flag3 from "assets/images/widget/GERMANY.jpg";
import Flag4 from "assets/images/widget/UK.jpg";
import Flag5 from "assets/images/widget/USA.jpg";
import { useSelector } from "react-redux";

// =========================|| DASHBOARD ANALYTICS - LATEST CUSTOMERS TABLE CARD ||========================= //

// style constant
const useStyles = makeStyles({
  root: {
    width: "100%",
    overflow: "hidden",
  },
  container: {
    maxHeight: 645,
  },
});

const TableTrades = () => {
  const theme = useTheme();
  const green =
    theme.palette.mode === "dark"
      ? colors.successLightTakyon
      : colors.successDarkTakyon;
  const red =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;
  const cornsilk =
    theme.palette.mode === "dark"
      ? colors.cornsilkLightTakyon
      : colors.cornsilkDarkTakyon;
  const classes = useStyles();

    const rows = useSelector((state) => state.trades.tradesTable);
  return (
    <MainCard
      title={'Trades'}
      content={false}
      sx={{
        padding: 0,
      }}
    >
      <TableContainer className={classes.container}>
        <Table stickyHeader>
          {/* <TableHead> */}
          {/* <TableRow
            sx={{
              alignItems: "middle",
              align: "right",
              backgroundColor: "#191b1e",
            }}
          >
                <TableCell>pair</TableCell>
                <TableCell>order_id</TableCell>
                <TableCell>date</TableCell>
                <TableCell>trade_cost</TableCell>
                <TableCell>trade_price</TableCell>
                <TableCell>trade_qty</TableCell>
                <TableCell>fee</TableCell>
              </TableRow> */}
          {/* </TableHead> */}
          <TableBody>
            <TableRow
              sx={{
                alignItems: "middle",
                align: "right",
                backgroundColor: "#191b1e",
              }}
            >
              <TableCell>Pair</TableCell>
              <TableCell>order_id</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Trade cost</TableCell>
              <TableCell>Trade price</TableCell>
              <TableCell>Trade qty</TableCell>
              <TableCell>Fee</TableCell>
            </TableRow>

            {rows.map((row, index) => {
              const rung = row.is_buy === false ? red : green;
              const ldate = new Date(row.time_ms).toLocaleString("en-US");

              return (
                <TableRow key={index}>
                  <TableCell sx={{ color: rung }}>{row.pair}</TableCell>
                  <TableCell sx={{ color: rung }}>{row.order_id}</TableCell>
                  <TableCell sx={{ color: rung }}>{ldate}</TableCell>
                  <TableCell sx={{ color: rung }}>
                    {row.trade_cost.toFixed(2)}
                  </TableCell>
                  <TableCell sx={{ color: rung }}>
                    {row.trade_price.toFixed(5)}
                  </TableCell>
                  <TableCell sx={{ color: rung }}>
                    {row.trade_qty.toFixed(5)}
                  </TableCell>
                  <TableCell sx={{ color: rung }}>
                    {row.fee.toFixed(2)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Divider />
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button variant="text" size="small">
          View all Latest Customers
        </Button>
      </CardActions>
    </MainCard>
  );
};

TableTrades.propTypes = {
  title: PropTypes.string,
};

export default TableTrades;
