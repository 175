import PropTypes from "prop-types";
import React, { useState, useContext, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import CoinContext from "contexts/CoinContext";
import { useDispatch } from "react-redux";
import { map_coins, map_balance_to_coin } from "store/constant";
import { Tab, Tabs } from "@material-ui/core";
import SelectedExchange from "ui-component/SelectedExchange";


import {
  placeOrderTicket,
  orderTicketTimeout,
  OrderTicketTypes,
} from "store/actions";

import {
  Divider,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  IconButton,
  Chip,
  Fab,
  Box,
} from "@material-ui/core";
import MUIFormControl from "@material-ui/core/FormControl";
import Transitions from "ui-component/extended/Transitions";

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import SubCard from "ui-component/cards/SubCard";
import useSocket from "hooks/useSocket";
import useOrderTicket from "hooks/useOrderTicket";
import { addOrder } from "emitters";
import { IconSettings } from "@tabler/icons";

import { IconLoader } from "@tabler/icons";
import AnimateButton from "ui-component/extended/AnimateButton";

// style constant
const useStyles = makeStyles((theme) => ({
  revenueCard: {
    position: "relative",
    color: "#fff",
    background: "#00070f",
  },
  revenueIcon: {
    position: "absolute",
    right: "13px",
    top: "14px",
    color: "#fff",
    background: "#00070f",
    "&> svg": {
      width: "100px",
      height: "100px",
      opacity: "0.5",
    },
    [theme.breakpoints.down("xs")]: {
      top: "13px",
      "&> svg": {
        width: "80px",
        height: "80px",
      },
    },
  },
}));

// =============================|| REVENUE CARD ||============================= //

const ExploreSummary = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const theme = useTheme();
  let textc = "lightyellow";
  if (theme.palette.background.paper === "#ffffff") {
    textc = "darkred";
  }

  
  const { coin } = useContext(CoinContext);

  const cash = useSelector((state) => {
        return ((state.coins.newportfolio) ? state.coins.newportfolio.total_cash.toFixed(2): 0.0);
  });

    const trending_buy = useSelector((state) => {
        return ((state.coins.kraken_asset_pairs_loaded && state.coins.kraken_asset_pairs.get(coin) && state.coins.kraken_asset_pairs.get(coin).trending_buy)?state.coins.kraken_asset_pairs.get(coin).trending_buy : {});
    });

    const trending_sell = useSelector((state) => {
        return ((state.coins.kraken_asset_pairs_loaded && state.coins.kraken_asset_pairs.get(coin) && state.coins.kraken_asset_pairs.get(coin).trending_sell)?state.coins.kraken_asset_pairs.get(coin).trending_sell : {});
    });

    const pos = useSelector((state) => {
        return ((state.coins.kraken_asset_pairs_loaded && state.coins.kraken_asset_pairs.get(coin) && state.coins.kraken_asset_pairs.get(coin).position)?state.coins.kraken_asset_pairs.get(coin).position : {});
    });

  const spot = map_balance_to_coin.get(coin);
  const coin_style = map_coins.get(coin);
  const rung_buy = (trending_buy.position >  trending_buy.price)? "#ff4976" : "#4bffb5";
  const rung_sell = (trending_sell.position >  trending_sell.price)? "#ff4976" : "#4bffb5";

  let total_cost = 0.0;
  let total_pnl = 0.0;
  if ( pos)
  {
    total_cost = pos.remaining_cost;
    total_pnl = (pos.market_price - pos.remaining_avg_price) * pos.balance;

    if ( (total_pnl === undefined) || Number.isNaN(total_pnl))
    {
        total_pnl = 0.0;
    }
    if ( (total_cost === undefined) || Number.isNaN(total_cost))
    {
        total_cost = 0.0;
    }
  }

    // { lt.pair, move_up ? up : down, lt.is_buy ? buy : sell, lt.trade_price, lt.market_price, _market_price, lt.levels[i], lt.lb_pc, lt.trade_qty }
  return (
    <SubCard>
        <Card sx={{ fontWeight: 'normal', bgcolor: '#00070f', color: '#fff' }}>
            <CardHeader
                title={
                        <>
                        <Typography sx={{ color: "white", textAlign: 'center', fontWeight: 'normal', fontSize: '1.4rem'}} color="inherit">
                            Explore 
                        </Typography>
                        </>
                }
            />
            <Divider />
            <CardContent>
                <Grid container padding={2} alignItems="right" direction="column" xs={5} spacing={1}>
                    <Grid item>
                        <Typography sx={{ color: "white", textAlign: 'middle', fontWeight: 'normal', fontSize: '1.4rem'}} color="inherit">
                            <a class={coin_style}>{coin}</a>
                        </Typography>
                        <table style={{width: '100%'}}>
                            <tr spacing={70}> 
                                <td spacing={70}> 
                                    <font style={{ textAlign: 'right', fontSize: '0.8rem', fontWeight: 'normal', bgcolor: '#00070f', color: '#fff' }}>
                                        {'Trending_Profit'}
                                    </font>
                                 </td> 
                                 <td>
                                    {' '}
                                 </td>
                                 <td>
                                    {' '}
                                 </td>
                                 <td spacing={70}> 
                                    <font style={{ textAlign: 'right', fontSize: '1.3rem', fontWeight: 'normal', bgcolor: '#00070f', color: rung_buy }}>
                                        {(trending_buy.qty * (trending_buy.price - trending_buy.position)).toFixed(2)}
                                    </font>
                                 </td> 
                                 <td spacing={70}> 
                                    <font style={{ textAlign: 'right', fontSize: '1.3rem', fontWeight: 'normal', bgcolor: '#00070f', color: rung_sell }}>
                                        {(trending_sell.qty * (trending_sell.price - trending_sell.position)).toFixed(2)}
                                    </font>
                                 </td> 
                            </tr>
                        </table>
                    </Grid>
                    <Grid item>
                        <table style={{width: '100%'}}>
                            <tr spacing={70}> 
                                <td spacing={70}> 
                                    <font style={{ textAlign: 'right', fontSize: '0.8rem', fontWeight: 'normal', bgcolor: '#00070f', color: '#fff' }}>
                                        {'Last_Trade'}
                                    </font>
                                 </td> 
                                 <td>
                                    {' '}
                                 </td>
                                 <td>
                                    {' '}
                                 </td>
                                 <td spacing={70}> 
                                    <font style={{ textAlign: 'right', fontSize: '1.3rem', fontWeight: 'normal', bgcolor: '#00070f', color: rung_buy }}>
                                        {(trending_buy.qty * trending_buy.position).toFixed(2)}
                                    </font>
                                 </td> 
                                 <td spacing={70}> 
                                    <font style={{ textAlign: 'right', fontSize: '1.3rem', fontWeight: 'normal', bgcolor: '#00070f', color: rung_sell }}>
                                        {(trending_sell.qty * trending_sell.position).toFixed(2)}
                                    </font>
                                 </td> 
                            </tr>
                        </table>
                    </Grid>
                    <Grid item>
                        <table style={{width: '100%'}}>
                            <tr spacing={70}> 
                                <td spacing={70}> 
                                    <font style={{ textAlign: 'right', fontSize: '0.8rem', fontWeight: 'normal', bgcolor: '#00070f', color: '#fff' }}>
                                        {'P&L'}
                                    </font>
                                 </td> 
                                 <td>
                                    {' '}
                                 </td>
                                 <td>
                                    {' '}
                                 </td>
                                 <td spacing={70}> 
                                    <font style={{ textAlign: 'right', fontSize: '0.8rem', fontWeight: 'normal', bgcolor: '#00070f', color: rung_buy }}>
                                        {total_pnl.toFixed(2)}
                                    </font>
                                 </td> 
                            </tr>
                        </table>
                    </Grid>
                    <Grid item>
                        <table style={{width: '100%'}}>
                            <tr spacing={70}> 
                                <td spacing={70}> 
                                    <font style={{ textAlign: 'right', fontSize: '0.8rem', fontWeight: 'normal', bgcolor: '#00070f', color: '#fff' }}>
                                        {'LTP'}
                                    </font>
                                 </td> 
                                 <td>
                                    {' '}
                                 </td>
                                 <td>
                                    {' '}
                                 </td>
                                 <td spacing={70}> 
                                    <font style={{ textAlign: 'right', fontSize: '0.8rem', fontWeight: 'normal', bgcolor: '#00070f', color: rung_buy }}>
                                        {trending_buy.price}
                                    </font>
                                 </td> 
                                 <td spacing={70}> 
                                    <font style={{ textAlign: 'right', fontSize: '0.8rem', fontWeight: 'normal', bgcolor: '#00070f', color: rung_sell }}>
                                        {trending_sell.price}
                                    </font>
                                 </td> 
                            </tr>
                        </table>
                    </Grid>
                    <Grid item>
                        <table style={{width: '100%'}}>
                            <tr spacing={70}> 
                                <td spacing={70}> 
                                    <font style={{ textAlign: 'right', fontSize: '0.8rem', fontWeight: 'normal', bgcolor: '#00070f', color: '#fff' }}>
                                        {'Avg.Px.'}
                                    </font>
                                 </td> 
                                 <td>
                                    {' '}
                                 </td>
                                 <td>
                                    {' '}
                                 </td>
                                 <td spacing={70}> 
                                    <font style={{ textAlign: 'right', fontSize: '0.8rem', fontWeight: 'normal', bgcolor: '#00070f', color: rung_buy }}>
                                        {trending_buy.avg}
                                    </font>
                                 </td> 
                                 <td spacing={70}> 
                                    <font style={{ textAlign: 'right', fontSize: '0.8rem', fontWeight: 'normal', bgcolor: '#00070f', color: rung_sell }}>
                                        {trending_sell.avg}
                                    </font>
                                 </td> 
                            </tr>
                        </table>
                    </Grid>
                    <Grid item>
                        <table style={{width: '100%'}}>
                            <tr spacing={70}> 
                                <td spacing={70}> 
                                    <font style={{ textAlign: 'right', fontSize: '0.8rem', fontWeight: 'normal', bgcolor: '#00070f', color: '#fff' }}>
                                        {'Total_Inv.'}
                                    </font>
                                 </td> 
                                 <td>
                                    {' '}
                                 </td>
                                 <td>
                                    {' '}
                                 </td>
                                 <td spacing={70}> 
                                    <font style={{ textAlign: 'right', fontSize: '0.8rem', fontWeight: 'normal', bgcolor: '#00070f', color: rung_buy }}>
                                        {total_cost.toFixed(2)}
                                    </font>
                                 </td> 
                            </tr>
                        </table>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </SubCard>
  );
};

ExploreSummary.propTypes = {};

export default React.memo(ExploreSummary);
