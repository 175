import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";
import MUIFormControl from "@material-ui/core/FormControl";
import BuySellBtn from "assets/images/explore/buysellbtn.png";
import { tooltips } from "constants/tooltips";

import {
  map_coins,
  map_balance_to_coin,
  map_coin_to_name,
  map_category,
} from "store/constant";
import { weekly_portfolio } from "store/weekly";
import I from "immutable";
import useSocket from "hooks/useSocket";
import Transitions from "ui-component/extended/Transitions";
import { useDispatch, useSelector } from "react-redux";
import CoinContext from "contexts/CoinContext";
import colors from "assets/scss/_themes-vars.module.scss";
import DiveBarModal from "./DiveBarModal";
import ToolTipHelper from "ui-component/ToolTipHelper";

import {
  setLastTrade,
  setDiveBarCoin,
  TAB_ADD_PINNED_COIN,
  RESET_ALERT_TRENDING_BUY,
  RESET_ALERT_TRENDING_SELL,
  RESET_ALERT_SWL_POSITIVE,
  RESET_ALERT_SWL_NEGATIVE,
  RESET_ALERT_VEL_POSITIVE,
  RESET_ALERT_VEL_NEGATIVE,
} from "store/actions";

import {
  InputAdornment,
  OutlinedInput,
  InputLabel,
  IconButton,
  Chip,
  Fab,
} from "@material-ui/core";
import FavoriteButton from "views/dashboard/DashboardTakyon/components/FavoriteButton";

// material-ui
import { withStyles, makeStyles, useTheme } from "@material-ui/styles";

// material-ui
import {
  Badge,
  Button,
  Box,
  Checkbox,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { visuallyHidden } from "@material-ui/utils";

// third party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import SubCard from "ui-component/cards/SubCard";
import ColorSubCard from "ui-component/cards/ColorSubCard";

// assets
import Flag1 from "assets/images/widget/AUSTRALIA.jpg";
import Flag2 from "assets/images/widget/BRAZIL.jpg";
import Flag3 from "assets/images/widget/GERMANY.jpg";
import Flag4 from "assets/images/widget/UK.jpg";
import Flag5 from "assets/images/widget/USA.jpg";

// table filter
function descendingComparator(a, b, orderBy) {
  if (orderBy !== "Pair") {
    if (Number(b[orderBy]) < Number(a[orderBy])) {
      return -1;
    }
    if (Number(b[orderBy]) > Number(a[orderBy])) {
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const CustomizedBadge = withStyles((theme) => ({
  badge: {
    right: 0,
    top: -15,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

// ===========================|| TABLE - HEADER ||=========================== //

// table header
const headCells = [
  {
    id: "Pair",
    numeric: false,
    disablePadding: true,
    label: "Asset",
  },
  {
    id: "ltp",
    numeric: true,
    disablePadding: false,
    label: "price",
  },
  {
    id: "hilo_percent",
    numeric: true,
    disablePadding: false,
    label: "24h %",
  },
  {
    id: "invested",
    numeric: true,
    disablePadding: false,
    label: "Balance",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    purchaseAmount,
    changePurchaseAmount,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const theme = useTheme();
  const cornsilk =
    theme.palette.mode === "dark"
      ? colors.cornsilkLightTakyon
      : colors.cornsilkDarkTakyon;
  const whiteTakyon =
    theme.palette.mode === "dark"
      ? colors.whiteLightTakyon
      : colors.whiteDarkTakyon;

  return (
    <TableHead>
      <TableRow
        sx={{
          alignItems: "middle",
          align: "right",
          backgroundColor: "#191b1e",
        }}
      >
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              backgroundColor: "#191b1e",
            }}
            key={headCell.id}
            align={headCell.numeric ? "left" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              // bgColor={"gray"}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

// style constant
const useStyles = makeStyles({
  root: {
    width: "100%",
    overflow: "hidden",
  },
  sortSpan: visuallyHidden,
  cell: {
    cursor: "pointer",
  },
});

// =========================|| DASHBOARD ANALYTICS - LATEST CUSTOMERS TABLE CARD ||========================= //

const DiveBarLendingCard = ({ title }) => {
  const [checkbox, setCheckbox] = React.useState([
    true,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [category, setCategory] = React.useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("invested");
  const [query, setQuery] = useState("");

  const classes = useStyles();
  const { emit } = useSocket();
  const { coin, setCoin } = useContext(CoinContext);
  const dispatch = useDispatch();
  const theme = useTheme();
  const green =
    theme.palette.mode === "dark"
      ? colors.successLightTakyon
      : colors.successDarkTakyon;
  const red =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;
  const cornsilk =
    theme.palette.mode === "dark"
      ? colors.cornsilkLightTakyon
      : colors.cornsilkDarkTakyon;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  let items = [];
  let categoryItems = [];
  const start = Date.now();

  let changed = items.length;

  const [firstInit, setFirstInit] = useState(false);

  const onCloseModal = () => {
    setFirstInit(false);
    window.localStorage.setItem("explore_card_help", "true");
  };

  useEffect(() => {
    const item = window.localStorage.getItem("explore_card_help");
    if (!item) {
      setFirstInit(true);
    }
  }, []);

  const eth_style = map_coins.get("ETH/USD"); // 3.67
  const dai_style = map_coins.get("DAI/USD"); // 0.15
  const usdc_style = map_coins.get("USDC/ETH"); // 0.15
  const tezos_style = map_coins.get("XTZ/USD"); // 4.63
  const algorand_style = map_coins.get("ALGO/USD"); // 5.75
  const cardano_style = map_coins.get("ADA/USD"); // 2.67
  //
  return (
    <SubCard sx={{ width: "100%", height: "400px", align: "center" }}>
      <Grid container padding={0} spacing={0} alignItems="center">
        LendingCard
      </Grid>
      <Grid
        container
        padding={2}
        spacing={2}
        direction="rows"
        alignItems="center"
      >
        <ColorSubCard
          sx={{
            width: "150px",
            height: "150px",
            align: "center",
            alignItems: "center",
          }}
          border={"orange"}
        >
          <table>
            <tr>
              <td>
                <a className={eth_style}>{"ethereum"} </a>
              </td>
            </tr>
            <tr>
              <td>
                <font style={{ fontSize: "2.1rem", color: "cornsilk" }}>
                  {"3.67%"}
                </font>
              </td>
            </tr>
          </table>
        </ColorSubCard>
        <Grid item padding={2} spacing={1} />
        <ColorSubCard
          sx={{
            width: "150px",
            height: "150px",
            padding: "2",
            align: "center",
            alignItems: "center",
          }}
          border={"orange"}
        >
          <table>
            <tr>
              <td>
                <a className={dai_style}> {"DAI"} </a>
              </td>
            </tr>
            <tr>
              <td>
                <font style={{ fontSize: "2.1rem", color: "cornsilk" }}>
                  {"0.15%"}
                </font>
              </td>
            </tr>
          </table>
        </ColorSubCard>
        <Grid item padding={2} spacing={1} />
        <ColorSubCard
          sx={{
            width: "150px",
            height: "150px",
            padding: "2",
            align: "center",
            alignItems: "center",
          }}
          border={"orange"}
        >
          <table>
            <tr>
              <td>
                <a className={usdc_style}> {"USDC"} </a>
              </td>
            </tr>
            <tr>
              <td>
                <font style={{ fontSize: "2.1rem", color: "cornsilk" }}>
                  {"0.15%"}
                </font>
              </td>
            </tr>
          </table>
        </ColorSubCard>
        <Grid item padding={2} spacing={1} />
        <ColorSubCard
          sx={{
            width: "150px",
            height: "150px",
            padding: "2",
            align: "center",
            alignItems: "center",
          }}
          border={"orange"}
        >
          <table>
            <tr>
              <td>
                <a className={tezos_style}> {"Tezos"} </a>
              </td>
            </tr>
            <tr>
              <td>
                <font style={{ fontSize: "2.1rem", color: "cornsilk" }}>
                  {"4.63%"}
                </font>
              </td>
            </tr>
          </table>
        </ColorSubCard>
        <Grid item padding={2} spacing={1} />
        <ColorSubCard
          sx={{
            width: "150px",
            height: "150px",
            padding: "2",
            align: "center",
            alignItems: "center",
          }}
          border={"orange"}
        >
          <table>
            <tr>
              <td>
                <a className={algorand_style}> {"Algorand"} </a>
              </td>
            </tr>
            <tr>
              <td>
                <font style={{ fontSize: "2.1rem", color: "cornsilk" }}>
                  {"5.75%"}
                </font>
              </td>
            </tr>
          </table>
        </ColorSubCard>
        <Grid item padding={2} spacing={1} />
        <ColorSubCard
          sx={{
            width: "150px",
            height: "150px",
            padding: "2",
            align: "center",
            alignItems: "center",
          }}
          border={"orange"}
        >
          <table>
            <tr>
              <td>
                <a className={cardano_style}> {"Cardano"} </a>
              </td>
            </tr>
            <tr>
              <td>
                <font style={{ fontSize: "2.1rem", color: "cornsilk" }}>
                  {"2.67%"}
                </font>
              </td>
            </tr>
          </table>
        </ColorSubCard>
      </Grid>
    </SubCard>
  );
};

DiveBarLendingCard.propTypes = {
  title: PropTypes.string,
};

export default DiveBarLendingCard;
