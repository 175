import useSocket from "hooks/useSocket";
import signatureRest from "hooks/useSignatureRest";
import { initialMessage } from "emitters";

export default function useKraken() {
  const { getKrakenToken } = signatureRest();

  const { emit } = useSocket();

  const getToken = (key, secret) => {
    getKrakenToken(key, secret).then((response) => {


      //bug..
      try {
        const token = response.data.data.result.token;
        window.localStorage.setItem("kraken_token", token);
        emit(initialMessage(token));
      } catch (e) {

      }
    });
  };

  const renewKrakenToken = () => {
    const key = window.localStorage.getItem("kraken_key");
    const secret = window.localStorage.getItem("kraken_sec");
    if (!key || !secret) {
    }

    getKrakenToken(key, secret).then((response) => {

        const token = response.data.data.result.token;
        window.localStorage.setItem("kraken_token", token);
        emit(initialMessage(token));
    });
  };

  return {
    renewKrakenToken,
    getToken,
  };
}
