import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { MENU_TYPE } from "store/actions"; // THEME_RTL
// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import { Box, Drawer, useMediaQuery, Grid } from "@material-ui/core";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import { BrowserView, MobileView } from "react-device-detect";
import LogoSection from "../LogoSection";
// import ExchangeSection from "layout/LiteTakyonLayout/Header/ExchangeSection";
import ExchangeLogoSection from "layout/LiteTakyonLayout/Header/ExchangeLogoSection";

// project imports
import MenuList from "./MenuList";
import MenuCard from "./MenuCard";
import { drawerWidth } from "store/constant";

import { FormControlLabel, Switch } from "@material-ui/core";

// style constant
const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    // background: theme.palette.background.default,
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderRight: "none",
    [theme.breakpoints.up("md")]: {
      top: "0px",
    },
  },
  ScrollHeight: {
    // height: 'calc(100vh - 88px)',
    // height: "50vh",
    paddingLeft: "16px",
    paddingRight: "16px",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 56px)",
    },
  },
  boxContainer: {
    display: "flex",
    padding: "16px",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

// ===========================|| SIDEBAR DRAWER ||=========================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  const customization = useSelector((state) => state.customization);
  const [themeColor, setThemecolor] = React.useState(customization.navType);

  const handleThemeChange = (event) => {
    if (themeColor === "dark") {
      setThemecolor("light");
    } else {
      setThemecolor("dark");
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: MENU_TYPE, navType: themeColor });
  }, [dispatch, themeColor]);

  const drawer = (
    <>
      <LogoSection />
      <BrowserView>
        <PerfectScrollbar component="div" className={classes.ScrollHeight}>
          <MenuList />
          {/* <MenuCard /> */}
        </PerfectScrollbar>
        <Grid container justifyContent="space-around">
          <Grid item>
            {/* <ExchangeSection /> */}
            <ExchangeLogoSection />
          </Grid>
          <Grid item></Grid>
          <FormControlLabel
            control={
              <Switch
                checked={themeColor === "dark"}
                onChange={handleThemeChange}
                color="primary"
              />
            }
            label=""
          />
        </Grid>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList />
          {/* <MenuCard /> */}
        </Box>
      </MobileView>
    </>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Drawer
        container={container}
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </nav>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;
