import { Grid } from "@material-ui/core";
import React from "react";
import TrendingSummarized from "../Summarized/TrendingSummarized";
import CandleChart from "../CandleChart";
import LiteOrderTicket from "ui-component/cards/LiteOrderTicket";
import SummaryCard from "ui-component/cards/SummaryCard";
import TrendingBuySummary from "../Summarized/components/TrendingBuySummary";
import TrendingSellSummary from "../Summarized/components/TrendingSellSummary";
import UploadCSV from '../UploadCSV'; 


const UploadLayout = () => {

  return (
    <Grid container spacing={2.0}>
        <Grid item xs={12} alignItems="center">
            <UploadCSV />
        </Grid>
    </Grid>
  );
};

export default UploadLayout;
