// theme constant
export const gridSpacing = 5;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

export const map_weeks = new Map([
    ["202101", "2021-01-08"],
    ["202102", "2021-01-15"],
    ["202103", "2021-01-22"],
    ["202104", "2021-01-29"],
    ["202105", "2021-02-05"],
    ["202106", "2021-02-12"],
    ["202107", "2021-02-19"],
    ["202108", "2021-02-26"],
    ["202109", "2021-03-05"],
    ["202110", "2021-03-12"],
    ["202111", "2021-03-19"],
    ["202112", "2021-03-26"],
    ["202113", "2021-04-02"],
    ["202114", "2021-04-09"],
    ["202115", "2021-04-16"],
    ["202116", "2021-04-23"],
    ["202117", "2021-04-30"],
    ["202118", "2021-05-07"],
    ["202119", "2021-05-14"],
    ["202120", "2021-05-21"],
    ["202121", "2021-05-28"],
    ["202122", "2021-06-04"],
    ["202123", "2021-06-11"],
    ["202124", "2021-06-18"],
    ["202125", "2021-06-25"],
    ["202126", "2021-07-02"],
    ["202127", "2021-07-09"],
    ["202128", "2021-07-16"],
    ["202129", "2021-07-23"],
    ["202130", "2021-07-30"],
    ["202131", "2021-08-06"],
    ["202132", "2021-08-13"],
    ["202133", "2021-08-20"],
    ["202134", "2021-08-27"],
    ["202135", "2021-09-03"],
    ["202136", "2021-09-10"],
    ["202137", "2021-09-17"],
    ["202138", "2021-09-24"],
    ["202139", "2021-10-01"],
    ["202140", "2021-10-08"],
    ["202141", "2021-10-15"],
    ["202142", "2021-10-22"],
    ["202143", "2021-10-29"],
    ["202144", "2021-11-05"],
    ["202145", "2021-11-12"],
    ["202146", "2021-11-19"],
    ["202147", "2021-11-26"],
    ["202148", "2021-12-03"],
    ["202149", "2021-12-10"],
    ["202150", "2021-12-17"],
    ["202151", "2021-12-24"],
    ["202152", "2021-12-31"],
    ["202152", "2021-12-31"],
    ["202201", "2022-01-01"],
    ["202202", "2022-01-08"],
]);


export const map_balance = new Map([
  ["ZUSD", "ZUSD"],
  ["XXBT", "XBT/USD"],
  ["XXRP", "XRP/USD"],
  ["XLTC", "LTC/USD"],
  ["XXDG", "XDG/USD"],
  ["XXLM", "XLM/USD"],
  ["XETH", "ETH/USD"],
  ["XETC", "ETC/USD"],
  ["XREP", "REP/USD"],
  ["XZEC", "ZEC/USD"],
  ["XXMR", "XMR/USD"],
  ["XMLN", "MLN/USD"],
  ["DASH", "DASH/USD"],
  ["GNO", "GNO/USD"],
  ["EOS", "EOS/USD"],
  ["BCH", "BCH/USD"],
  ["ADA", "ADA/USD"],
  ["QTUM", "QTUM/USD"],
  ["XTZ", "XTZ/USD"],
  ["BSV", "BSV/USD"],
  ["ATOM", "ATOM/USD"],
  ["NANO", "NANO/USD"],
  ["SC", "SC/USD"],
  ["LSK", "LSK/USD"],
  ["WAVES", "WAVES/USD"],
  ["ICX", "ICX/USD"],
  ["BAT", "BAT/USD"],
  ["OMG", "OMG/USD"],
  ["LINK", "LINK/USD"],
  ["ALGO", "ALGO/USD"],
  ["DOT", "DOT/USD"],
  ["KAVA", "KAVA/USD"],
  ["OXT", "OXT/USD"],
  ["STORJ", "STORJ/USD"],
  ["KNC", "KNC/USD"],
  ["COMP", "COMP/USD"],
  ["ENJ", "ENJ/USD"],
  ["SNX", "SNX/USD"],
  ["KEEP", "KEEP/USD"],
  ["FIL", "FIL/USD"],
  ["REPV2", "REPV2/USD"],
  ["BAL", "BAL/USD"],
  ["CRV", "CRV/USD"],
  ["KSM", "KSM/USD"],
  ["UNI", "UNI/USD"],
  ["AAVE", "AAVE/USD"],
  ["ANT", "ANT/USD"],
  ["MANA", "MANA/USD"],
  ["OCEAN", "OCEAN/USD"],
  ["MATIC", "MATIC/USD"],
  ["SOL", "SOL/USD"],
]);

export const map_balance_to_coin = new Map([
  ["XBT/USD", "XXBT"],
  ["XRP/USD", "XXRP"],
  ["LTC/USD", "XLTC"],
  ["XDG/USD", "XXDG"],
  ["XLM/USD", "XXLM"],
  ["ETH/USD", "XETH"],
  ["ETC/USD", "XETC"],
  ["REP/USD", "XREP"],
  ["ZEC/USD", "XZEC"],
  ["XMR/USD", "XXMR"],
  ["MLN/USD", "XMLN"],
  ["DASH/USD", "DASH"],
  ["GNO/USD", "GNO"],
  ["EOS/USD", "EOS"],
  ["BCH/USD", "BCH"],
  ["ADA/USD", "ADA"],
  ["QTUM/USD", "QTUM"],
  ["XTZ/USD", "XTZ"],
  ["BSV/USD", "BSV"],
  ["ATOM/USD", "ATOM"],
  ["NANO/USD", "NANO"],
  ["SC/USD", "SC"],
  ["LSK/USD", "LSK"],
  ["WAVES/USD", "WAVES"],
  ["ICX/USD", "ICX"],
  ["BAT/USD", "BAT"],
  ["OMG/USD", "OMG"],
  ["LINK/USD", "LINK"],
  ["ALGO/USD", "ALGO"],
  ["DOT/USD", "DOT"],
  ["KAVA/USD", "KAVA"],
  ["OXT/USD", "OXT"],
  ["STORJ/USD", "STORJ"],
  ["KNC/USD", "KNC"],
  ["COMP/USD", "COMP"],
  ["ENJ/USD", "ENJ"],
  ["SNX/USD", "SNX"],
  ["KEEP/USD", "KEEP"],
  ["FIL/USD", "FIL"],
  ["REPV2/USD", "REPV2"],
  ["BAL/USD", "BAL"],
  ["CRV/USD", "CRV"],
  ["KSM/USD", "KSM"],
  ["UNI/USD", "UNI"],
  ["AAVE/USD", "AAVE"],
  ["ANT/USD", "ANT"],
  ["MANA/USD", "MANA"],
  ["OCEAN/USD", "OCEAN"],
  ["MATIC/USD", "MATIC"],
  ["SOL/USD", "SOL"],
  ["CTSI/USD", "CTSI"],
  ["AVAX/USD", "AVAX"],
  ["CRO/USD", "CRO"],
]);

export const map_coins = new Map([
  ["EXPLORE", "EXPLORE"],
  ["AAVE/USD", "AAVEUSD"],
  ["ADA/USD", "ADAUSD"],
  ["ALGO/USD", "ALGOUSD"],
  ["ANT/USD", "ANTUSD"],
  ["ATOM/USD", "ATOMUSD"],
  ["BAL/USD", "BALUSD"],
  ["BAT/USD", "BATUSD"],
  ["COMP/USD", "COMPUSD"],
  ["CRV/USD", "CRVUSD"],
  ["DASH/USD", "DASHUSD"],
  ["DOT/USD", "DOTUSD"],
  ["ENJ/USD", "ENJUSD"],
  ["EOS/USD", "EOSUSD"],
  ["ETC/USD", "XETCZUSD"],
  ["ETH/USD", "XETHZUSD"],
  ["FIL/USD", "FILUSD"],
  ["GNO/USD", "GNOUSD"],
  ["ICX/USD", "ICXUSD"],
  ["KAVA/USD", "KAVAUSD"],
  ["KEEP/USD", "KEEPUSD"],
  ["KNC/USD", "KNCUSD"],
  ["KSM/USD", "KSMUSD"],
  ["LINK/USD", "LINKUSD"],
  ["LSK/USD", "LSKUSD"],
  ["LTC/USD", "XLTCZUSD"],
  ["MANA/USD", "MANAUSD"],
  ["MATIC/USD", "MATICUSD"],
  ["MLN/USD", "XMLNZUSD"],
  ["NANO/USD", "NANOUSD"],
  ["OCEAN/USD", "OCEANUSD"],
  ["OMG/USD", "OMGUSD"],
  ["OXT/USD", "OXTUSD"],
  ["QTUM/USD", "QTUMUSD"],
  ["REP/USD", "XREPZUSD"],
  ["REPV2/USD", "REPV2USD"],
  ["SC/USD", "SCUSD"],
  ["SNX/USD", "SNXUSD"],
  ["SOL/USD", "SOLUSD"],
  ["STORJ/USD", "STORJUSD"],
  ["UNI/USD", "UNIUSD"],
  ["WAVES/USD", "WAVESUSD"],
  ["XBT/USD", "XXBTZUSD"],
  ["BTC/USD", "XXBTZUSD"],
  ["XDG/USD", "XDGUSD"],
  ["DOGE/USD", "XDGUSD"],
  ["XLM/USD", "XXLMZUSD"],
  ["XMR/USD", "XXMRZUSD"],
  ["XTZ/USD", "XTZUSD"],
  ["ZEC/USD", "XZECZUSD"],
  ["MDT/USD", "MDTUSD"],
["SOL/USD", "SOLUSD"],
["DDX/USD", "DDXUSD"],
["AUCTION/USD", "AUCTIONUSD"],
["PERP/USD", "PERPUSD"],
["ATOM/USD", "ATOMUSD"],
["ANKR/USD", "ANKRUSD"],
["TRB/USD", "TRBUSD"],
["ACH/USD", "ACHUSD"],
["AXS/USD", "AXSUSD"],
["BAT/USD", "BATUSD"],
["ENJ/USD", "ENJUSD"],
["CLV/USD", "CLVUSD"],
["RARI/USD", "RARIUSD"],
["BAND/USD", "BANDUSD"],
["PLA/USD", "PLAUSD"],
["QUICK/USD", "QUICKUSD"],
["EOS/USD", "EOSUSD"],
["NU/USD", "NUUSD"],
["RLY/USD", "RLYUSD"],
["TRAC/USD", "TRACUSD"],
["LINK/USD", "LINKUSD"],
["GTC/USD", "GTCUSD"],
["COVAL/USD", "COVALUSD"],
["RAI/USD", "RAIUSD"],
["AAVE/USD", "AAVEUSD"],
["RGT/USD", "RGTUSD"],
["XLM/USD", "XLMUSD"],
["COTI/USD", "COTIUSD"],
["KRL/USD", "KRLUSD"],
["WLUNA/USD", "WLUNAUSD"],
["PAX/USD", "PAXUSD"],
["MASK/USD", "MASKUSD"],
["ETH/USD", "ETHUSD"],
["NMR/USD", "NMRUSD"],
["SNX/USD", "SNXUSD"],
["MKR/USD", "MKRUSD"],
["BAL/USD", "BALUSD"],
["MANA/USD", "MANAUSD"],
["RBN/USD", "RBNUSD"],
["LRC/USD", "LRCUSD"],
["MIR/USD", "MIRUSD"],
["BNT/USD", "BNTUSD"],
["REQ/USD", "REQUSD"],
["SKL/USD", "SKLUSD"],
["CTSI/USD", "CTSIUSD"],
["IDEX/USD", "IDEXUSD"],
["SPELL/USD", "SPELLUSD"],
["DOGE/USD", "DOGEUSD"],
["GODS/USD", "GODSUSD"],
["ALGO/USD", "ALGOUSD"],
["ARPA/USD", "ARPAUSD"],
["TRU/USD", "TRUUSD"],
["AVAX/USD", "AVAXUSD"],
["ZEN/USD", "ZENUSD"],
["FIL/USD", "FILUSD"],
["DAI/USD", "DAIUSD"],
["POLY/USD", "POLYUSD"],
["SUKU/USD", "SUKUUSD"],
["KEEP/USD", "KEEPUSD"],
["WCFG/USD", "WCFGUSD"],
["BTRST/USD", "BTRSTUSD"],
["COMP/USD", "COMPUSD"],
["BCH/USD", "BCHUSD"],
["FET/USD", "FETUSD"],
["FARM/USD", "FARMUSD"],
["MATIC/USD", "MATICUSD"],
["NKN/USD", "NKNUSD"],
["LPT/USD", "LPTUSD"],
["XYO/USD", "XYOUSD"],
["SUSHI/USD", "SUSHIUSD"],
["CGLD/USD", "CGLDUSD"],
["IMX/USD", "IMXUSD"],
["ASM/USD", "ASMUSD"],
["POWR/USD", "POWRUSD"],
["ALCX/USD", "ALCXUSD"],
["DOT/USD", "DOTUSD"],
["USDT/USD", "USDTUSD"],
["CRO/USD", "CROUSD"],
["MUSD/USD", "MUSDUSD"],
["YFI/USD", "YFIUSD"],
["VGX/USD", "VGXUSD"],
["CRV/USD", "CRVUSD"],
["LOOM/USD", "LOOMUSD"],
["IOTX/USD", "IOTXUSD"],
["LTC/USD", "LTCUSD"],
["BADGER/USD", "BADGERUSD"],
["TRIBE/USD", "TRIBEUSD"],
["UMA/USD", "UMAUSD"],
["RAD/USD", "RADUSD"],
["ORN/USD", "ORNUSD"],
["WBTC/USD", "WBTCUSD"],
["AGLD/USD", "AGLDUSD"],
["YFII/USD", "YFIIUSD"],
["UST/USD", "USTUSD"],
["QNT/USD", "QNTUSD"],
["GYEN/USD", "GYENUSD"],
["FORTH/USD", "FORTHUSD"],
["MCO2/USD", "MCO2USD"],
["CHZ/USD", "CHZUSD"],
["POLS/USD", "POLSUSD"],
["UNI/USD", "UNIUSD"],
["OGN/USD", "OGNUSD"],
["CVC/USD", "CVCUSD"],
["DESO/USD", "DESOUSD"],
["DNT/USD", "DNTUSD"],
["OXT/USD", "OXTUSD"],
["KNC/USD", "KNCUSD"],
["STORJ/USD", "STORJUSD"],
["OMG/USD", "OMGUSD"],
["SUPER/USD", "SUPERUSD"],
["GRT/USD", "GRTUSD"],
["1INCH/USD", "1INCHUSD"],
["GALA/USD", "GALAUSD"],
["JASMY/USD", "JASMYUSD"],
["ADA/USD", "ADAUSD"],
["RLC/USD", "RLCUSD"],
["ZEC/USD", "ZECUSD"],
["XTZ/USD", "XTZUSD"],
["FOX/USD", "FOXUSD"],
["ETC/USD", "ETCUSD"],
["SHIB/USD", "SHIBUSD"],
["LCX/USD", "LCXUSD"],
["ICP/USD", "ICPUSD"],
["MLN/USD", "MLNUSD"],
["BLZ/USD", "BLZUSD"],
["DASH/USD", "DASHUSD"],
["AMP/USD", "AMPUSD"],
["ZRX/USD", "ZRXUSD"],
["FX/USD", "FXUSD"],
["REN/USD", "RENUSD"],
["API3/USD", "API3USD"],
["ENS/USD", "ENSUSD"],
["REP/USD", "REPUSD"],
["BOND/USD", "BONDUSD"],
["BICO/USD", "BICOUSD"],
["ZRX/EUR", "ZRXEUR"],
["ETH/EUR", "ETHEUR"],
["CGLD/EUR", "CGLDEUR"],
["CRO/EUR", "CROEUR"],
["MIR/EUR", "MIREUR"],
["NU/EUR", "NUEUR"],
["KRL/EUR", "KRLEUR"],
["DOT/EUR", "DOTEUR"],
["WCFG/EUR", "WCFGEUR"],
["DOGE/EUR", "DOGEEUR"],
["SOL/EUR", "SOLEUR"],
["TRAC/EUR", "TRACEUR"],
["BTC/EUR", "BTCEUR"],
["GALA/EUR", "GALAEUR"],
["LTC/EUR", "LTCEUR"],
["ANKR/EUR", "ANKREUR"],
["AXS/EUR", "AXSEUR"],
["CLV/EUR", "CLVEUR"],
["XTZ/EUR", "XTZEUR"],
["MATIC/EUR", "MATICEUR"],
["OMG/EUR", "OMGEUR"],
["AAVE/EUR", "AAVEEUR"],
["GRT/EUR", "GRTEUR"],
["AUCTION/EUR", "AUCTIONEUR"],
["LINK/EUR", "LINKEUR"],
["NMR/EUR", "NMREUR"],
["BAT/EUR", "BATEUR"],
["ENS/EUR", "ENSEUR"],
["NKN/EUR", "NKNEUR"],
["ETC/EUR", "ETCEUR"],
["USDC/EUR", "USDCEUR"],
["ALCX/EUR", "ALCXEUR"],
["CHZ/EUR", "CHZEUR"],
["BCH/EUR", "BCHEUR"],
["MANA/EUR", "MANAEUR"],
["WLUNA/EUR", "WLUNAEUR"],
["TRU/EUR", "TRUEUR"],
["XYO/EUR", "XYOEUR"],
["BICO/EUR", "BICOEUR"],
["SHIB/EUR", "SHIBEUR"],
["USDT/EUR", "USDTEUR"],
["EOS/EUR", "EOSEUR"],
["ADA/EUR", "ADAEUR"],
["BAND/EUR", "BANDEUR"],
["BTRST/EUR", "BTRSTEUR"],
["DESO/EUR", "DESOEUR"],
["ARPA/EUR", "ARPAEUR"],
["ALGO/EUR", "ALGOEUR"],
["LCX/EUR", "LCXEUR"],
["SNX/EUR", "SNXEUR"],
["UNI/EUR", "UNIEUR"],
["VGX/EUR", "VGXEUR"],
["ATOM/EUR", "ATOMEUR"],
["UMA/EUR", "UMAEUR"],
["BNT/EUR", "BNTEUR"],
["RLY/EUR", "RLYEUR"],
["MASK/EUR", "MASKEUR"],
["FIL/EUR", "FILEUR"],
["CRV/EUR", "CRVEUR"],
["ICP/EUR", "ICPEUR"],
["DDX/EUR", "DDXEUR"],
["SKL/EUR", "SKLEUR"],
["SUSHI/EUR", "SUSHIEUR"],
["REQ/EUR", "REQEUR"],
["1INCH/EUR", "1INCHEUR"],
["AVAX/EUR", "AVAXEUR"],
["BADGER/EUR", "BADGEREUR"],
["SUKU/EUR", "SUKUEUR"],
["POWR/EUR", "POWREUR"],
["RAD/EUR", "RADEUR"],
["IOTX/EUR", "IOTXEUR"],
["XLM/EUR", "XLMEUR"],
["PERP/EUR", "PERPEUR"],
["UST/EUR", "USTEUR"],
["FORTH/EUR", "FORTHEUR"],
]);
export const map_rev_coins = new Map([
  ["AAVEUSD", "AAVE/USD"],
  ["ADAUSD", "ADA/USD"],
  ["ALGOUSD", "ALGO/USD"],
  ["ANTUSD", "ANT/USD"],
  ["ATOMUSD", "ATOM/USD"],
  ["BALUSD", "BAL/USD"],
  ["BATUSD", "BAT/USD"],
  ["COMPUSD", "COMP/USD"],
  ["CRVUSD", "CRV/USD"],
  ["DASHUSD", "DASH/USD"],
  ["DOTUSD", "DOT/USD"],
  ["ENJUSD", "ENJ/USD"],
  ["EOSUSD", "EOS/USD"],
  ["ETCUSD", "ETC/USD"],
  ["XETCZUSD", "ETC/USD"],
  ["ETHUSD", "ETH/USD"],
  ["XETHZUSD", "ETH/USD"],
  ["FILUSD", "FIL/USD"],
  ["GNOUSD", "GNO/USD"],
  ["ICXUSD", "ICX/USD"],
  ["KAVAUSD", "KAVA/USD"],
  ["KEEPUSD", "KEEP/USD"],
  ["KNCUSD", "KNC/USD"],
  ["KSMUSD", "KSM/USD"],
  ["LINKUSD", "LINK/USD"],
  ["LSKUSD", "LSK/USD"],
  ["LTCUSD", "LTC/USD"],
  ["MANAUSD", "MANA/USD"],
  ["MATICUSD", "MATIC/USD"],
  ["MLNUSD", "MLN/USD"],
  ["NANOUSD", "NANO/USD"],
  ["OCEANUSD", "OCEAN/USD"],
  ["OMGUSD", "OMG/USD"],
  ["OXTUSD", "OXT/USD"],
  ["QTUMUSD", "QTUM/USD"],
  ["REPUSD", "REP/USD"],
  ["REPV2USD", "REPV2/USD"],
  ["SCUSD", "SC/USD"],
  ["SNXUSD", "SNX/USD"],
  ["SOLUSD", "SOL/USD"],
  ["STORJUSD", "STORJ/USD"],
  ["UNIUSD", "UNI/USD"],
  ["WAVESUSD", "WAVES/USD"],
  ["XBTUSD", "XBT/USD"],
  ["XDGUSD", "XDG/USD"],
  ["XLMUSD", "XLM/USD"],
  ["XMRUSD", "XMR/USD"],
  ["XXMRZUSD", "XMR/USD"],
  ["XTZUSD", "XTZ/USD"],
  ["ZECUSD", "ZEC/USD"],
  ["XZECZUSD", "ZEC/USD"],
  ["MDTUSD", "MDT/USD"],
["SOLUSD", "SOL/USD"],
["DDXUSD", "DDX/USD"],
["AUCTIONUSD", "AUCTION/USD"],
["PERPUSD", "PERP/USD"],
["ATOMUSD", "ATOM/USD"],
["ANKRUSD", "ANKR/USD"],
["TRBUSD", "TRB/USD"],
["ACHUSD", "ACH/USD"],
["AXSUSD", "AXS/USD"],
["BATUSD", "BAT/USD"],
["ENJUSD", "ENJ/USD"],
["CLVUSD", "CLV/USD"],
["RARIUSD", "RARI/USD"],
["BANDUSD", "BAND/USD"],
["PLAUSD", "PLA/USD"],
["QUICKUSD", "QUICK/USD"],
["EOSUSD", "EOS/USD"],
["NUUSD", "NU/USD"],
["RLYUSD", "RLY/USD"],
["TRACUSD", "TRAC/USD"],
["LINKUSD", "LINK/USD"],
["GTCUSD", "GTC/USD"],
["COVALUSD", "COVAL/USD"],
["RAIUSD", "RAI/USD"],
["AAVEUSD", "AAVE/USD"],
["RGTUSD", "RGT/USD"],
["XLMUSD", "XLM/USD"],
["COTIUSD", "COTI/USD"],
["KRLUSD", "KRL/USD"],
["WLUNAUSD", "WLUNA/USD"],
["PAXUSD", "PAX/USD"],
["MASKUSD", "MASK/USD"],
["ETHUSD", "ETH/USD"],
["NMRUSD", "NMR/USD"],
["SNXUSD", "SNX/USD"],
["MKRUSD", "MKR/USD"],
["BALUSD", "BAL/USD"],
["MANAUSD", "MANA/USD"],
["RBNUSD", "RBN/USD"],
["LRCUSD", "LRC/USD"],
["MIRUSD", "MIR/USD"],
["BNTUSD", "BNT/USD"],
["REQUSD", "REQ/USD"],
["SKLUSD", "SKL/USD"],
["CTSIUSD", "CTSI/USD"],
["IDEXUSD", "IDEX/USD"],
["SPELLUSD", "SPELL/USD"],
["DOGEUSD", "DOGE/USD"],
["GODSUSD", "GODS/USD"],
["ALGOUSD", "ALGO/USD"],
["ARPAUSD", "ARPA/USD"],
["TRUUSD", "TRU/USD"],
["AVAXUSD", "AVAX/USD"],
["ZENUSD", "ZEN/USD"],
["FILUSD", "FIL/USD"],
["DAIUSD", "DAI/USD"],
["POLYUSD", "POLY/USD"],
["SUKUUSD", "SUKU/USD"],
["KEEPUSD", "KEEP/USD"],
["WCFGUSD", "WCFG/USD"],
["BTRSTUSD", "BTRST/USD"],
["COMPUSD", "COMP/USD"],
["BCHUSD", "BCH/USD"],
["FETUSD", "FET/USD"],
["FARMUSD", "FARM/USD"],
["MATICUSD", "MATIC/USD"],
["NKNUSD", "NKN/USD"],
["LPTUSD", "LPT/USD"],
["XYOUSD", "XYO/USD"],
["SUSHIUSD", "SUSHI/USD"],
["CGLDUSD", "CGLD/USD"],
["IMXUSD", "IMX/USD"],
["ASMUSD", "ASM/USD"],
["POWRUSD", "POWR/USD"],
["ALCXUSD", "ALCX/USD"],
["DOTUSD", "DOT/USD"],
["USDTUSD", "USDT/USD"],
["CROUSD", "CRO/USD"],
["MUSDUSD", "MUSD/USD"],
["YFIUSD", "YFI/USD"],
["VGXUSD", "VGX/USD"],
["CRVUSD", "CRV/USD"],
["LOOMUSD", "LOOM/USD"],
["IOTXUSD", "IOTX/USD"],
["LTCUSD", "LTC/USD"],
["BADGERUSD", "BADGER/USD"],
["TRIBEUSD", "TRIBE/USD"],
["UMAUSD", "UMA/USD"],
["RADUSD", "RAD/USD"],
["ORNUSD", "ORN/USD"],
["WBTCUSD", "WBTC/USD"],
["AGLDUSD", "AGLD/USD"],
["YFIIUSD", "YFII/USD"],
["USTUSD", "UST/USD"],
["QNTUSD", "QNT/USD"],
["GYENUSD", "GYEN/USD"],
["FORTHUSD", "FORTH/USD"],
["MCO2USD", "MCO2/USD"],
["CHZUSD", "CHZ/USD"],
["POLSUSD", "POLS/USD"],
["UNIUSD", "UNI/USD"],
["OGNUSD", "OGN/USD"],
["CVCUSD", "CVC/USD"],
["DESOUSD", "DESO/USD"],
["DNTUSD", "DNT/USD"],
["OXTUSD", "OXT/USD"],
["KNCUSD", "KNC/USD"],
["STORJUSD", "STORJ/USD"],
["OMGUSD", "OMG/USD"],
["SUPERUSD", "SUPER/USD"],
["GRTUSD", "GRT/USD"],
["1INCHUSD", "1INCH/USD"],
["GALAUSD", "GALA/USD"],
["JASMYUSD", "JASMY/USD"],
["ADAUSD", "ADA/USD"],
["RLCUSD", "RLC/USD"],
["ZECUSD", "ZEC/USD"],
["XTZUSD", "XTZ/USD"],
["FOXUSD", "FOX/USD"],
["ETCUSD", "ETC/USD"],
["SHIBUSD", "SHIB/USD"],
["LCXUSD", "LCX/USD"],
["ICPUSD", "ICP/USD"],
["MLNUSD", "MLN/USD"],
["BLZUSD", "BLZ/USD"],
["DASHUSD", "DASH/USD"],
["AMPUSD", "AMP/USD"],
["ZRXUSD", "ZRX/USD"],
["FXUSD", "FX/USD"],
["RENUSD", "REN/USD"],
["API3USD", "API3/USD"],
["ENSUSD", "ENS/USD"],
["REPUSD", "REP/USD"],
["BONDUSD", "BOND/USD"],
["BICOUSD", "BICO/USD"],
["ZRXEUR", "ZRX/EUR"],
["ETHEUR", "ETH/EUR"],
["CGLDEUR", "CGLD/EUR"],
["CROEUR", "CRO/EUR"],
["MIREUR", "MIR/EUR"],
["NUEUR", "NU/EUR"],
["KRLEUR", "KRL/EUR"],
["DOTEUR", "DOT/EUR"],
["WCFGEUR", "WCFG/EUR"],
["DOGEEUR", "DOGE/EUR"],
["SOLEUR", "SOL/EUR"],
["TRACEUR", "TRAC/EUR"],
["BTCEUR", "BTC/EUR"],
["GALAEUR", "GALA/EUR"],
["LTCEUR", "LTC/EUR"],
["ANKREUR", "ANKR/EUR"],
["AXSEUR", "AXS/EUR"],
["CLVEUR", "CLV/EUR"],
["XTZEUR", "XTZ/EUR"],
["MATICEUR", "MATIC/EUR"],
["OMGEUR", "OMG/EUR"],
["AAVEEUR", "AAVE/EUR"],
["GRTEUR", "GRT/EUR"],
["AUCTIONEUR", "AUCTION/EUR"],
["LINKEUR", "LINK/EUR"],
["NMREUR", "NMR/EUR"],
["BATEUR", "BAT/EUR"],
["ENSEUR", "ENS/EUR"],
["NKNEUR", "NKN/EUR"],
["ETCEUR", "ETC/EUR"],
["USDCEUR", "USDC/EUR"],
["ALCXEUR", "ALCX/EUR"],
["CHZEUR", "CHZ/EUR"],
["BCHEUR", "BCH/EUR"],
["MANAEUR", "MANA/EUR"],
["WLUNAEUR", "WLUNA/EUR"],
["TRUEUR", "TRU/EUR"],
["XYOEUR", "XYO/EUR"],
["BICOEUR", "BICO/EUR"],
["SHIBEUR", "SHIB/EUR"],
["USDTEUR", "USDT/EUR"],
["EOSEUR", "EOS/EUR"],
["ADAEUR", "ADA/EUR"],
["BANDEUR", "BAND/EUR"],
["BTRSTEUR", "BTRST/EUR"],
["DESOEUR", "DESO/EUR"],
["ARPAEUR", "ARPA/EUR"],
["ALGOEUR", "ALGO/EUR"],
["LCXEUR", "LCX/EUR"],
["SNXEUR", "SNX/EUR"],
["UNIEUR", "UNI/EUR"],
["VGXEUR", "VGX/EUR"],
["ATOMEUR", "ATOM/EUR"],
["UMAEUR", "UMA/EUR"],
["BNTEUR", "BNT/EUR"],
["RLYEUR", "RLY/EUR"],
["MASKEUR", "MASK/EUR"],
["FILEUR", "FIL/EUR"],
["CRVEUR", "CRV/EUR"],
["ICPEUR", "ICP/EUR"],
["DDXEUR", "DDX/EUR"],
["SKLEUR", "SKL/EUR"],
["SUSHIEUR", "SUSHI/EUR"],
["REQEUR", "REQ/EUR"],
["1INCHEUR", "1INCH/EUR"],
["AVAXEUR", "AVAX/EUR"],
["BADGEREUR", "BADGER/EUR"],
["SUKUEUR", "SUKU/EUR"],
["POWREUR", "POWR/EUR"],
["RADEUR", "RAD/EUR"],
["IOTXEUR", "IOTX/EUR"],
["XLMEUR", "XLM/EUR"],
["PERPEUR", "PERP/EUR"],
["USTEUR", "UST/EUR"],
["FORTHEUR", "FORTH/EUR"],
]);

export const searchCoins = [
  { label: "AAVE/USD", id: "AAVEUSD" },
  { label: "ADA/USD", id: "ADAUSD" },
  { label: "ALGO/USD", id: "ALGOUSD" },
  { label: "ANT/USD", id: "ANTUSD" },
  { label: "ATOM/USD", id: "ATOMUSD" },
  { label: "BAL/USD", id: "BALUSD" },
  { label: "BAT/USD", id: "BATUSD" },
  { label: "COMP/USD", id: "COMPUSD" },
  { label: "CRV/USD", id: "CRVUSD" },
  { label: "DASH/USD", id: "DASHUSD" },
  { label: "DOT/USD", id: "DOTUSD" },
  { label: "ENJ/USD", id: "ENJUSD" },
  { label: "EOS/USD", id: "EOSUSD" },
  { label: "ETC/USD", id: "XETCZUSD" },
  { label: "ETH/USD", id: "XETHZUSD" },
  { label: "FIL/USD", id: "FILUSD" },
  { label: "GNO/USD", id: "GNOUSD" },
  { label: "ICX/USD", id: "ICXUSD" },
  { label: "KAVA/USD", id: "KAVAUSD" },
  { label: "KEEP/USD", id: "KEEPUSD" },
  { label: "KNC/USD", id: "KNCUSD" },
  { label: "KSM/USD", id: "KSMUSD" },
  { label: "LINK/USD", id: "LINKUSD" },
  { label: "LSK/USD", id: "LSKUSD" },
  { label: "LTC/USD", id: "XLTCZUSD" },
  { label: "MANA/USD", id: "MANAUSD" },
  { label: "MATIC/USD", id: "MATICUSD" },
  { label: "MLN/USD", id: "XMLNZUSD" },
  { label: "NANO/USD", id: "NANOUSD" },
  { label: "OCEAN/USD", id: "OCEANUSD" },
  { label: "OMG/USD", id: "OMGUSD" },
  { label: "OXT/USD", id: "OXTUSD" },
  { label: "QTUM/USD", id: "QTUMUSD" },
  { label: "REP/USD", id: "XREPZUSD" },
  { label: "REPV2/USD", id: "REPV2USD" },
  { label: "SC/USD", id: "SCUSD" },
  { label: "SNX/USD", id: "SNXUSD" },
  { label: "SOL/USD", id: "SOLUSD" },
  { label: "STORJ/USD", id: "STORJUSD" },
  { label: "UNI/USD", id: "UNIUSD" },
  { label: "WAVES/USD", id: "WAVESUSD" },
  { label: "XBT/USD", id: "XXBTZUSD" },
  { label: "BTC/USD", id: "XXBTZUSD" },
  { label: "XDG/USD", id: "XDGUSD" },
  { label: "DOGE/USD", id: "XDGUSD" },
  { label: "XLM/USD", id: "XXLMZUSD" },
  { label: "XMR/USD", id: "XXMRZUSD" },
  { label: "XTZ/USD", id: "XTZUSD" },
  { label: "ZEC/USD", id: "XZECZUSD" },
  { label: "AAVEUSD", id: "AAVE/USD" },
  { label: "ADAUSD", id: "ADA/USD" },
  { label: "ALGOUSD", id: "ALGO/USD" },
  { label: "ANTUSD", id: "ANT/USD" },
  { label: "ATOMUSD", id: "ATOM/USD" },
  { label: "BALUSD", id: "BAL/USD" },
  { label: "BATUSD", id: "BAT/USD" },
  { label: "COMPUSD", id: "COMP/USD" },
  { label: "CRVUSD", id: "CRV/USD" },
  { label: "DASHUSD", id: "DASH/USD" },
  { label: "DOTUSD", id: "DOT/USD" },
  { label: "ENJUSD", id: "ENJ/USD" },
  { label: "EOSUSD", id: "EOS/USD" },
  { label: "ETCUSD", id: "ETC/USD" },
  { label: "XETCZUSD", id: "ETC/USD" },
  { label: "ETHUSD", id: "ETH/USD" },
  { label: "XETHZUSD", id: "ETH/USD" },
  { label: "FILUSD", id: "FIL/USD" },
  { label: "GNOUSD", id: "GNO/USD" },
  { label: "ICXUSD", id: "ICX/USD" },
  { label: "KAVAUSD", id: "KAVA/USD" },
  { label: "KEEPUSD", id: "KEEP/USD" },
  { label: "KNCUSD", id: "KNC/USD" },
  { label: "KSMUSD", id: "KSM/USD" },
  { label: "LINKUSD", id: "LINK/USD" },
  { label: "LSKUSD", id: "LSK/USD" },
  { label: "LTCUSD", id: "LTC/USD" },
  { label: "MANAUSD", id: "MANA/USD" },
  { label: "MATICUSD", id: "MATIC/USD" },
  { label: "MLNUSD", id: "MLN/USD" },
  { label: "NANOUSD", id: "NANO/USD" },
  { label: "OCEANUSD", id: "OCEAN/USD" },
  { label: "OMGUSD", id: "OMG/USD" },
  { label: "OXTUSD", id: "OXT/USD" },
  { label: "QTUMUSD", id: "QTUM/USD" },
  { label: "REPUSD", id: "REP/USD" },
  { label: "REPV2USD", id: "REPV2/USD" },
  { label: "SCUSD", id: "SC/USD" },
  { label: "SNXUSD", id: "SNX/USD" },
  { label: "SOLUSD", id: "SOL/USD" },
  { label: "STORJUSD", id: "STORJ/USD" },
  { label: "UNIUSD", id: "UNI/USD" },
  { label: "WAVESUSD", id: "WAVES/USD" },
  { label: "XBTUSD", id: "XBT/USD" },
  { label: "XDGUSD", id: "XDG/USD" },
  { label: "XLMUSD", id: "XLM/USD" },
  { label: "XMRUSD", id: "XMR/USD" },
  { label: "XXMRZUSD", id: "XMR/USD" },
  { label: "XTZUSD", id: "XTZ/USD" },
  { label: "ZECUSD", id: "ZEC/USD" },
  { label: "XZECZUSD", id: "ZEC/USD" },
];
export const assetPairsCoins = [
        "AAVEUSD",
        "ADAUSD",
        "ALGOUSD",
        "ANTUSD",
        "ATOMUSD",
        "BALUSD",
        "BATUSD",
        "COMPUSD",
        "CRVUSD",
        "DASHUSD",
        "DOTUSD",
        "ENJUSD",
        "EOSUSD",
        "ETCUSD",
        "XETCZUSD",
        "ETHUSD",
        "XETHZUSD",
        "FILUSD",
        "GNOUSD",
        "ICXUSD",
        "KAVAUSD",
        "KEEPUSD",
        "KNCUSD",
        "KSMUSD",
        "LINKUSD",
        "LSKUSD",
        "LTCUSD",
        "MANAUSD",
        "MATICUSD",
        "MLNUSD",
        "NANOUSD",
        "OCEANUSD",
        "OMGUSD",
        "OXTUSD",
        "QTUMUSD",
        "REPUSD",
        "REPV2USD",
        "SCUSD",
        "SNXUSD",
        "SOLUSD",
        "STORJUSD",
        "UNIUSD",
        "WAVESUSD",
        "XBTUSD",
        "XDGUSD",
        "XLMUSD",
        "XMRUSD",
        "XXMRZUSD",
        "XTZUSD",
        "ZECUSD",
        "XZECZUSD"
];

export const map_coin_to_name = new Map([
  ["XBT/USD", "BITCOIN"],
  ["BTC/USD", "BITCOIN"],
  ["LTC/USD", "LITECOIN"],
  ["XDG/USD", "DOGECOIN"],
  ["DOGE/USD", "DOGECOIN"],
  ["XLM/USD", "LUMEN"],
  ["ETH/USD", "ETHEREUM"],
  ["ETC/USD", "ETHCLASSIC"],
  ["REP/USD", "AUGUR"],
  ["ZEC/USD", "ZCASH"],
  ["XMR/USD", "MONERO"],
  ["MLN/USD", "ENZYME"],
  ["DASH/USD", "DASH"],
  ["GNO/USD", "GNOSIS"],
  ["EOS/USD", "EOS"],
  ["BCH/USD", "BCH"],
  ["ADA/USD", "CARDANO"],
  ["QTUM/USD", "QUANTUM"],
  ["XTZ/USD", "TEZOS"],
  ["BSV/USD", "BSV"],
  ["ATOM/USD", "ATOM"],
  ["NANO/USD", "NANO"],
  ["SC/USD", "SIACOIN"],
  ["LSK/USD", "LISK"],
  ["WAVES/USD", "WAVES"],
  ["ICX/USD", "ICON"],
  ["BAT/USD", "BAT"],
  ["OMG/USD", "OMG"],
  ["LINK/USD", "CHAINLINK"],
  ["ALGO/USD", "ALGORAND"],
  ["DOT/USD", "POLKADOT"],
  ["KAVA/USD", "KAVA"],
  ["OXT/USD", "ORCHID"],
  ["STORJ/USD", "STORJ"],
  ["KNC/USD", "KYBER"],
  ["COMP/USD", "COMPOUND"],
  ["ENJ/USD", "ENJIN"],
  ["SNX/USD", "SYNTHETIX"],
  ["KEEP/USD", "KEEP"],
  ["FIL/USD", "FILECOIN"],
  ["REPV2/USD", "AUGUR2"],
  ["BAL/USD", "BALANCE"],
  ["CRV/USD", "CURVEDAO"],
  ["KSM/USD", "KUSAMA"],
  ["UNI/USD", "UNISWAP"],
  ["AAVE/USD", "AAVE"],
  ["ANT/USD", "ARAGON"],
  ["MANA/USD", "MANA"],
  ["OCEAN/USD", "OCEAN"],
  ["MATIC/USD", "POLYGON"],
  ["SOL/USD", "SOLANA"],
]);

export const map_untradable_kraken_assets = new Map([
    ["AUD/USD", true],
    ["EUR/USD", true],
    ["USD", true],
]);




export const map_category = new Map([
["LUNA/USD", "defi"],
["AVAX/USD", "defi"],
["WBTC/USD", "defi"],
["UNI/USD", "defi"],
["LINK/USD", "defi"],
["DAI/USD", "defi"],
["XTZ/USD", "defi"],
["FTM/USD", "defi"],
["GRT/USD", "defi"],
["LRC/USD", "defi"],
["CAKE/USD", "defi"],
["AAVE/USD", "defi"],
["MKR/USD", "defi"],
["RUNE/USD", "defi"],
["BAT/USD", "defi"],
["CRV/USD", "defi"],
["COMP/USD", "defi"],
["1INCH/USD", "defi"],
["ANKR/USD", "defi"],
["ROSE/USD", "defi"],
["BNT/USD", "defi"],
["YFI/USD", "defi"],
["SUSHI/USD", "defi"],
["RENBTC/USD", "defi"],
["XZRX/USD", "defi"],
["PREP/USD", "defi"],
["SCRT/USD", "defi"],
["SNX/USD", "defi"],
["UMA/USD", "defi"],
["GNO/USD", "defi"],

["AXS/USD", "gamefi"],
["MANA/USD", "gamefi"],
["SAND/USD", "gamefi"],
["GALA/USD", "gamefi"],
["ENJ/USD", "gamefi"],
["WAXP/USD", "gamefi"],
["BORA/USD", "gamefi"],
["UOS/USD", "gamefi"],
["ALICE/USD", "gamefi"],
["CHR/USD", "gamefi"],
["DVI/USD", "gamefi"],
["BAKE/USD", "gamefi"],
["WEMIX/USD", "gamefi"],
["ILV/USD", "gamefi"],
["RACA/USD", "gamefi"],
["UFO/USD", "gamefi"],
["YOOSHI/USD", "gamefi"],
["MBOX/USD", "gamefi"],
["PYR/USD", "gamefi"],
["YGG/USD", "gamefi"],
["PLA/USD", "gamefi"],
["WILD/USD", "gamefi"],
["SFUND/USD", "gamefi"],
["BABY/USD", "gamefi"],
["DAR/USD", "gamefi"],
["ATLAS/USD", "gamefi"],
["SOUL/USD", "gamefi"],
["MC/USD", "gamefi"],
["TLM/USD", "gamefi"],
["EFI/USD", "gamefi"],
["VRA/USD", "gamefi"],
["DAWN/USD", "gamefi"],
["GHST/USD", "gamefi"],
["SPS/USD", "gamefi"],
["DERC/USD", "gamefi"],
["POLIS/USD", "gamefi"],
["GAFI/USD", "gamefi"],


["AAVE/USD", "lending"],
["MKR/USD", "lending"],
["COMP/USD", "lending"],
["CEL/USD", "lending"],
["KAVA/USD", "lending"],
["ALCX/USD", "lending"],
["XVS/USD", "lending"],
["TRU/USD", "lending"],
["BZRX/USD", "lending"],
["RAMP/USD", "lending"],
["ALPACA/USD", "lending"],
["HARD/USD", "lending"],
["FOR/USD", "lending"],
["Ersdl/USD", "lending"],
["UFT/USD", "lending"],
["CREAM/USD", "lending"],
["BNC/USD", "lending"],
["EQZ/USD", "lending"],
["EZ/USD", "lending"],
["FIN/USD", "lending"],
["RCN/USD", "lending"],
["KALM/USD", "lending"],
["SMARTCREDIT/USD", "lending"],
["TRAVA/USD", "lending"],
["TAD/USD", "lending"],
["ETNA/USD", "lending"],
["BAG/USD", "lending"],
["OPEN/USD", "lending"],
["YETH/USD", "lending"],
["RULER/USD", "lending"],
["SLND/USD", "lending"],
["INV/USD", "lending"],
["RABBIT/USD", "lending"],

["DOGE/USD", "memes"],
["SHIB/USD", "memes"],
["ELON/USD", "memes"],
["SAMO/USD", "memes"],
["HOGE/USD", "memes"],
["MONA/USD", "memes"],
["DOGEDASH/USD", "memes"],
["ERC20/USD", "memes"],
["CUMMIES/USD", "memes"],
["BAN/USD", "memes"],
["DOGGY/USD", "memes"],
["SMI/USD", "memes"],
["WOOF/USD", "memes"],
["DOE/USD", "memes"],
["PEPECASH/USD", "memes"],
["WOW/USD", "memes"],
["YUMMY/USD", "memes"],
["DINU/USD", "memes"],
["SHIBX/USD", "memes"],
["LOWB/USD", "memes"],
["GRLC/USD", "memes"],
["KANGAL/USD", "memes"],
["PPBLZ/USD", "memes"],
["GOGEX/USD", "memes"],
["SAMU/USD", "memes"],
["ROT/USD", "memes"],
["TEND/USD", "memes"],
["DANK/USD", "memes"],
["BGTT/USD", "memes"],
["GM/USD", "memes"],
["FLOKI/USD", "memes"],
["AKITA/USD", "memes"],
["LEASH/USD", "memes"],
["KISHU/USD", "memes"],
["BABYDOQE/USD", "memes"],
["PIT/USD", "memes"],
]);

