import PropTypes from "prop-types";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

// material-ui
import { Avatar, Box, ButtonBase } from "@material-ui/core";
import {
  Autocomplete,
  Button,
  Link,
  Grid,
  Paper,
  InputAdornment,
  TextField,
} from "@material-ui/core";

// project imports
import LogoSection from "../LogoSection";
import SearchSection from "./SearchSection";
import LocalizationSection from "./LocalizationSection";
import MobileSection from "./MobileSection";
import ProfileSection from "./ProfileSection";
import NotificationSection from "./NotificationSection";
import Search from "./Search";
import KrakenTokenModal from "views/dashboard/DashboardTakyon/KrakenTokenModal";
// assets
import { IconMenu2 } from "@tabler/icons";
import ExchangeSection from "./ExchangeSection";
import QuotesPanel from "views/dashboard/DashboardTakyon/Tabs/QuotesPanel";
import Quotes from "views/dashboard/DashboardTakyon/Tabs/components/Quotes";
import SummaryCard from "ui-component/cards/SummaryCard";
import colors from 'assets/scss/_themes-vars.module.scss';
import { makeStyles, useTheme } from "@material-ui/styles";
import useAuth from "hooks/useAuth";

// style constant
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  headerAvatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: "all .2s ease-in-out",
    background: theme.palette.background.paper,
    color: theme.palette.background.paper,
    "&:hover": {
      background: theme.palette.background.paper,
      color: theme.palette.background.paper,
    },
  },
  boxContainer: {
    width: "128px",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  },
}));

// ===========================|| MAIN NAVBAR / HEADER ||=========================== //

const Header = ({ handleLeftDrawerToggle, isConnected }) => {
  const classes = useStyles();
  const theme = useTheme();


  const { user } = useAuth();

  const cornsilk = (theme.palette.mode === 'dark') ? colors.cornsilkLightTakyon : colors.cornsilkDarkTakyon;
  const whiteTakyon = (theme.palette.mode === 'dark') ? colors.whiteLightTakyon : colors.whiteDarkTakyon;
  const green = (theme.palette.mode === 'dark') ? colors.successLightTakyon : colors.successDarkTakyon;
  const red = (theme.palette.mode === 'dark') ? colors.errorLightTakyon : colors.errorDarkTakyon;
  const back = (theme.palette.mode === 'dark') ? colors.darkTakyonPaper : colors.lightTakyonPaper;


  return (
    <>

        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>

      {/* logo & toggler button */}
      <div className={classes.boxContainer}>
        {/* NAVBAR */}
        <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
          {(theme.palette.mode === 'dark') &&
          <Avatar sx={{ background: theme.palette.background.paper, color: '#ccc', borderRadius: "12px", overflow: "hidden" }} variant="rounded" onClick={handleLeftDrawerToggle} >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
          }
          {(theme.palette.mode !== 'dark') &&
          <Avatar sx={{ background: theme.palette.background.paper, color: '#888', borderRadius: "12px", overflow: "hidden" }} variant="rounded" onClick={handleLeftDrawerToggle} >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
          }
        </ButtonBase>
      </div>
      {/* header search 
      <div className={classes.grow} style={{ marginLeft: "40px" }} />

      <div style={{ height: 20, width: 100 }}> </div>


      <div className={classes.grow} />
      <div className={classes.grow} />
*/}
      <div style={{ width: 80 }}></div>
      <Quotes />
      {user.name}
      {/*
      <table spacing={13} padding={3}>
        <tr spacing={13} padding={3}>
          <td spacing={13} padding={3}>
            <Button
              variant="outlined"
              size="medium"
              label="Basket"
              color="success"
              component={RouterLink}
              to="/lite/oneclickbuy"
              linkButton={true}
            >
              Basket
            </Button>
          </td>
          <td spacing={13} padding={3}>

          <Button
              variant="outlined"
              size="medium"
              label="Liquidate"
              color="error"
              component={RouterLink}
              to="/lite/liquidate"
              linkButton={true}
            >
              Liquidate
            </Button>
          </td>
        </tr>
      </table>
      */}
      <ProfileSection isConnected={isConnected} />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
