import React from "react";
import { Button } from "@material-ui/core";
const Liquidate = ({ items, handleLiquidate }) => {

  return (
    <div>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        label="Liquidate"
        color="error"
        onClick={handleLiquidate}
        disabled={!(items.length > 0)}
      >
        Liquidate (SELL)
      </Button>
    </div>
  );
};

export default Liquidate;
