import { useContext } from 'react';

// auth provider
// import FirebaseContext from 'contexts/FirebaseContext';
import TakyonContext from 'contexts/TakyonContext';
// import Auth0Context from 'contexts/Auth0Context';
// import JWTContext from 'contexts/JWTContext';

// ===========================|| AUTH HOOKS ||=========================== //

const useAuth = () => useContext(TakyonContext);

export default useAuth;
