// import { useDispatch, useSelector } from "react-redux";

import {
  orderTicketTimeout,
  OrderTicketTypes,
  orderTicketError,
  orderTicketPlaced,
  SNACKBAR_OPEN,
} from "store/actions";

import { store } from "store";

export default function useOrderTicket() {
  const displayErrorMessage = (error) =>
    store.dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      message: error,
      variant: "alert",
      alertSeverity: "error",
      close: true,
      transition: "SlideLeft",
      anchorOrigin: { vertical: "top", horizontal: "right" },
    });

  const displaySuccessMessage = (order) => {

    store.dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      message: `Order ${order.exchId} placed successfully. ${order.fc} ${order.qty}`,
      variant: "alert",
      alertSeverity: order.side === "BUY" ? "success" : "error",
      close: true,
      transition: "SlideLeft",
      anchorOrigin: { vertical: "top", horizontal: "right" },
    });
  };

  const checkOrderTimeout = () => {
    const { orderTicket } = store.getState();
    if (orderTicket.status === OrderTicketTypes.PLACING) {
      store.dispatch(orderTicketTimeout(orderTicket.userRef));
      displayErrorMessage("Order ticket Timeout");
    }
  };

  const handleOrderError = (error) => {
    store.dispatch(orderTicketError(error));
    displayErrorMessage(error);
  };

  const handleOrderOpen = (orders) => {
    const { orderTicket } = store.getState();


    if (orderTicket.userRef !== "") {
      if (
        orders.some((order) => Number(order.userref) === orderTicket.userRef)
      ) {
        store.dispatch(orderTicketPlaced());

        const order = orders.find(
          (order) => Number(order.userref) === orderTicket.userRef
        );


        displaySuccessMessage(order);
      }
    }
  };

  return {
    checkOrderTimeout,
    handleOrderError,
    handleOrderOpen,
  };
}
