import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { makeStyles, styled, useTheme } from "@material-ui/styles";
import {
  Box,
  CardContent,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Popper,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";

// project imports
// import UserDetails from './UserDetails';
// import ChatDrawer from './ChatDrawer';
import ColorSubCard from "ui-component/cards/ColorSubCard";
import ChartHistory from "./ChartHistory";
import MainCard from "ui-component/cards/MainCard";
import axios from "utils/axios";
import { SET_MENU } from "store/actions";
import { appDrawerWidth as drawerWidth, gridSpacing } from "store/constant";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
// assets
import AttachmentTwoToneIcon from "@material-ui/icons/AttachmentTwoTone";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import MoreHorizTwoToneIcon from "@material-ui/icons/MoreHorizTwoTone";
import ErrorTwoToneIcon from "@material-ui/icons/ErrorTwoTone";
import VideoCallTwoToneIcon from "@material-ui/icons/VideoCallTwoTone";
import CallTwoToneIcon from "@material-ui/icons/CallTwoTone";
import SendTwoToneIcon from "@material-ui/icons/SendTwoTone";
import MoodTwoToneIcon from "@material-ui/icons/MoodTwoTone";
import HighlightOffTwoToneIcon from "@material-ui/icons/HighlightOffTwoTone";
import { setDiveBarClubRoomId } from "store/actions";
import useDiveBarSocket from "hooks/useDiveBarClubSocket";

const avatarImage = require.context("assets/images/users", true);

// style constant
const useStyles = makeStyles((theme) => ({
  ScrollHeight: {
    // width: "100%",
    // height: "calc(100vh - 440px)",
    height: "100px",
    overflowX: "hidden",
    minHeight: "270px",
  },
  smallDrawer: {
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
    },
  },
}));

// drawer content element
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    paddingLeft: open ? theme.spacing(0.5) : 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      marginLeft: 0,
    },
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

// ===========================|| APPLICATION CHAT ||=========================== //

const Chat = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));

  // handle right sidebar dropdown menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClickSort = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSort = () => {
    setAnchorEl(null);
  };

  // set chat details page open when user is selected from sidebar
  const [emailDetails, setEmailDetails] = React.useState(false);
  const handleUserChange = () => {
    setEmailDetails((prev) => !prev);
  };

  const { openedRoom, userId } = useSelector((state) => state.diveBarClub);
  const { userMsg } = useDiveBarSocket();

  const [message, setMessage] = useState("");
  const handleOnSend = () => {
    userMsg(message, openedRoom.roomId);
    setMessage("");
  };

  const handleEnter = (event) => {
    if (event.key !== "Enter") {
      return;
    }
    handleOnSend();
  };

  // handle emoji
  const onEmojiClick = (event, emojiObject) => {
    setMessage(message + emojiObject.emoji);
  };

  const [anchorElEmoji, setAnchorElEmoji] = React.useState(null);
  const handleOnEmojiButtonClick = (event) => {
    setAnchorElEmoji(anchorElEmoji ? null : event.currentTarget);
  };

  const emojiOpen = Boolean(anchorElEmoji);
  const emojiId = emojiOpen ? "simple-popper" : undefined;
  const handleCloseEmoji = () => {
    setAnchorElEmoji(null);
  };

  const handleClose = () => dispatch(setDiveBarClubRoomId(undefined));

  return (
    <ColorSubCard
      border="lightyellow"
      alignItems="middle"
      md={8}
      aria-label="main mailbox folders"
      style={{ position: "relative" }}
    >
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <Grid container alignItems="center" spacing={0.5}>
                                    <Grid item>
                                        <Grid
                                            container
                                            spacing={0.5}
                                            alignItems="center"
                                            sx={{ flexWrap: "nowrap" }}
                                        >
                                            <Grid item>
                                                <Typography align="center" variant="h4">
                                                    Chat: {(openedRoom !== undefined) ? openedRoom.name : 'NO_NAME'}
                                                </Typography>
                                            </Grid>
                                            <Grid item sm zeroMinWidth></Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm zeroMinWidth />
                                    {/*
                                    <Grid item>
                                        <IconButton onClick={handleClose}>
                                            <ChevronLeft />
                                        </IconButton>
                                    </Grid>
                                    */}
                                </Grid>
                                <Divider sx={{ mt: theme.spacing(0.5) }} />
                            </Grid>
                            <Grid item xs={12}>
                                <PerfectScrollbar className={classes.ScrollHeight}>
                                    <CardContent>
                                        <ChartHistory
                                            theme={theme}
                                            handleUserDetails={handleUserChange}
                                            // handleDrawerOpen={handleDrawerOpen}
                                            // user={user}
                                            userId={userId}
                                            data={openedRoom}
                                        />
                                    </CardContent>
                                </PerfectScrollbar>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={0.5} alignItems="center">
                                    <Grid item>
                                        <IconButton
                                            ref={anchorElEmoji}
                                            aria-describedby={emojiId}
                                            onClick={handleOnEmojiButtonClick}
                                        >
                                            <MoodTwoToneIcon />
                                        </IconButton>
                                        <Popper
                                            id={emojiId}
                                            open={emojiOpen}
                                            anchorEl={anchorElEmoji}
                                            disablePortal
                                            popperOptions={{
                                                modifiers: [
                                                    {
                                                        name: "offset",
                                                        options: {
                                                            offset: [-20, 20],
                                                        },
                                                    },
                                                ],
                                            }}
                                        >
                                            <ClickAwayListener onClickAway={handleCloseEmoji}>
                                                <MainCard elevation={8} content={false}>
                                                    <Picker
                                                        onEmojiClick={onEmojiClick}
                                                        skinTone={SKIN_TONE_MEDIUM_DARK}
                                                        disableAutoFocus
                                                    />
                                                </MainCard>
                                            </ClickAwayListener>
                                        </Popper>
                                    </Grid>
                                    <Grid item xs zeroMinWidth>
                                        <TextField
                                            fullWidth
                                            label="Type a Message"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            onKeyPress={handleEnter}
                                        />
                                    </Grid>
                                    {/*
                                    <Grid item>
                                        <IconButton>
                                            <AttachmentTwoToneIcon />
                                        </IconButton>
                                    </Grid>
                                    */}
                                    <Grid item>
                                        <IconButton color="primary" onClick={handleOnSend}>
                                            <SendTwoToneIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
    </ColorSubCard>
  );
};

export default Chat;
