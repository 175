import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from "@material-ui/styles";
import VirtualCardSettings from "views/dashboard/DashboardTakyon/VirtualCards/components/VirtualCardSettings";
import VirtualCardDetails from "views/dashboard/DashboardTakyon/VirtualCards/components/VirtualCardDetails";
import VirtualCardTransactions from "views/dashboard/DashboardTakyon/VirtualCards/components/VirtualCardTransactions";
import VirtualCardLimits from "views/dashboard/DashboardTakyon/VirtualCards/components/VirtualCardLimits";
// material-ui
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import colors from "assets/scss/_themes-vars.module.scss";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  List,
  ListItems,
  ListItemButton,
  ListItemText,
  Select,
  MenuItem,
  InputLabel,
  OutlinedInput,
  Tab,
  Tabs,
  TextField,
} from "@material-ui/core";
import AnimateButton from "ui-component/extended/AnimateButton";
import {
  setVirtualCard,
  setModalVirtualCardDialogStatus,
  delVirtualCard,
  updateVirtualCard,
  setFreezeVirtualCard
} from "store/actions";
import { gridSpacing } from 'store/constant';


// third party
import * as Yup from "yup";
import { useFormik } from "formik";

// tab content
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid item xs={12} md={12}>
          <Typography variant="h4" sx={{ color: 'white' }}>{children}</Typography>
        </Grid>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function randomIntFromInterval(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}
// ===============================|| UI DIALOG - SCROLLABLE ||=============================== //

export default function ModalAddVirtualCard() {
  //
  const dispatch = useDispatch();

  // const isOpen = useSelector((state) =>  state.cards.isOpen  );

  const map_virtual_cards = useSelector((state) => { return state.cards.map_virtual_cards });
  const last_virtual_card = useSelector((state) => { return state.cards.last_virtual_card });
  const paymentMethods = useSelector((state) => { return state.coinbasePaymentMethods.paymentMethods; });

  const [value, setValue] = React.useState(0);


  const theme = useTheme();
  const cornsilk =
    theme.palette.mode === "dark"
      ? '#fff'
      : colors.cornsilkDarkTakyon;
  const whiteTakyon =
    theme.palette.mode === "dark"
      ? colors.whiteLightTakyon
      : colors.whiteDarkTakyon;
  const green =
    theme.palette.mode === "dark"
      ? colors.successLightTakyon
      : colors.successDarkTakyon;
  const red =
    theme.palette.mode === "dark"
      ? colors.errorLightTakyon
      : colors.errorDarkTakyon;

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const explore_list = useSelector((state) => {
    return state.coins.kraken_asset_pairs;
  });


  const getCardNumber = () => `414718${Math.random().toString().slice(2, 12)}`

  // TMD console.log(paymentMethods);

  const vc = map_virtual_cards.get(last_virtual_card);
  // TMD console.log(vc);

  const scroll = 'paper';

  let initialValues = {
    name: '',
    // pan: Date.now().toString(),
    cvv: '112',
    expiry: '12/25',
    rotation: 'rotated0',
    lifetime: '10000.00',
    current: '10000.00',
  };

  const validationSchema = (values) => {
    let errors = {};
    return errors;
  };

  // const handleAdd = (values) => {
  //     console.log( values);
  //     dispatch(setVirtualCard(values));
  //     dispatch(setModalVirtualCardDialogStatus(false));
  //     formik.resetForm();
  // };

  const handleClose = () => {
    dispatch(setModalVirtualCardDialogStatus(false));
  };


  const handleUpdateVirtualCard = (pan) => {
    dispatch(updateVirtualCard({ pan: pan, current: 98.5 }));
  };


  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: values => {
      const newCard = { pan: getCardNumber(), ...values };
      // TMD console.log({ newCard });
      dispatch(setVirtualCard(newCard));
      dispatch(setModalVirtualCardDialogStatus(false));
    },
    validate: values => {
      let errors = {};
      return errors;
    },
  });


  const handleFreeze = (isFreezed, cardNumber) => dispatch(setFreezeVirtualCard(isFreezed, cardNumber));


  return (
    <div>
      {(vc === undefined) && (
        <Dialog
          open={true}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >

<DialogTitle id="form-dialog-title">Create card</DialogTitle>

 

          <DialogContent dividers={scroll === 'paper'}>
          <form onSubmit={formik.handleSubmit} >
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12}>
                <TextField
                  {...formik.getFieldProps('name')}
                  fullWidth
                  label="Name of the card"
                  name='name'
                  variant="outlined"
                  background="white"
                  helperText={formik.errors.name && formik.touched.name && `${formik.errors.name}`}
                  error={formik.errors.name && formik.touched.name}
                />
              </Grid>
              {/* <TextField 
                                {...formik.getFieldProps('pan')}
                                fullWidth 
                                label="Pan" 
                                name='pan' 
                                variant="outlined"
                                helperText={formik.errors.pan && formik.touched.pan && `${formik.errors.pan}`}
                                error={formik.errors.pan && formik.touched.pan}
                            /> */}

              {/* <TextField 
                                {...formik.getFieldProps('cvv')} 
                                fullWidth 
                                label="CVV" 
                                name='cvv' 
                                variant="outlined"
                                helperText={formik.errors.cvv && formik.touched.cvv && `${formik.errors.cvv}`}
                                error={formik.errors.cvv && formik.touched.cvv}
                            /> */}

              <Grid item xs={12}>
                <TextField
                  {...formik.getFieldProps('expiry')}
                  fullWidth
                  label="Expiry"
                  name='expiry'
                  variant="outlined"
                  background="white"
                  helperText={formik.errors.expiry && formik.touched.expiry && `${formik.errors.expiry}`}
                  error={formik.errors.expiry && formik.touched.expiry}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...formik.getFieldProps('rotation')}
                  fullWidth
                  label="Rotation"
                  name='rotation'
                  variant="outlined"
                  background="white"
                  helperText={formik.errors.rotation && formik.touched.rotation && `${formik.errors.rotation}`}
                  error={formik.errors.rotation && formik.touched.rotation}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...formik.getFieldProps('current')}
                  fullWidth
                  label="Current"
                  name='current'
                  variant="outlined"
                  background="white"
                  helperText={formik.errors.current && formik.touched.current && `${formik.errors.current}`}
                  error={formik.errors.current && formik.touched.current}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    mt: 2,
                  }}
                >
                  <AnimateButton>
                    <Button
                      disableElevation
                      disabled={false}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      background="lightgreen"
                      color="secondary"
                    >
                      Add Virtual Card
                    </Button>
                  </AnimateButton>
                </Box>
              </Grid>
            </Grid>
          </form> 
          </DialogContent>
          <DialogActions sx={{ pr: 2.5, pt: 2.5 }}>
            <Button onClick={handleClose} color="error">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {vc && (
        <Dialog
          maxWidth="md"
          open={true}
          onClose={handleClose}
        >


          <Grid container padding={2} spacing={2}>
            <Grid item xs={12} lg={4} alignItems="center">
              <Grid container spacing={3} padding={3} direction="rows">
                <Grid id={vc.pan} item xs={12} lg={12} onClick={(event) => {if(!vc.isFreezed) handleUpdateVirtualCard(vc.pan);}}>
                  <span class={'rotated0'} id="card" >
                    <a id="name">{vc.name}</a>
                    <a id="pan">{vc.pan}</a>
                    <a id="cvv">{vc.cvv}</a>
                    <a id="expiry">{vc.expiry}</a>
                    <a style={{ textAlign: 'right' }} id="current">{'$'}{vc.current}</a>
                  </span>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Typography variant="h4" sx={{ color: '#aaa' }}>
                    Nickname
                  </Typography>
                  <Typography variant="h2" sx={{ color: cornsilk }}>
                    {vc.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Typography variant="h4" sx={{ color: '#aaa' }}>
                    Limit
                  </Typography>
                  <Typography variant="h2" sx={{ color: cornsilk }}>
                    {'$'}{vc.current}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Typography variant="h4" sx={{ color: '#aaa' }}>
                    Payment Methods
                  </Typography>
                  <List component="nav" aria-label="main mailbox folders">
                    {
                      paymentMethods.map(listItem => {

                        return (
                          <>
                            {(listItem.type === "ach_bank_account") &&
                              <ListItemText id={listItem.id} primary={(
                                <font style={{ color: cornsilk, fontWeight: 'normal', horizontalAlign: "middle", verticalAlign: "middle", fontSize: "1.1em", }} >
                                  {listItem.name}
                                </font>
                              )} />
                            }
                          </>
                        )
                      })
                    }

                  </List>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Button
                    disableElevation
                    disabled={false}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    style={{ background: `${vc.isFreezed ?  'green' :  'red'}`, align: 'center', width: "150px" }}
                    onClick={() => handleFreeze(!vc.isFreezed, vc.pan)}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-snowflake" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke='#fff' fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M10 4l2 1l2 -1" />
                      <path d="M12 2v6.5l3 1.72" />
                      <path d="M17.928 6.268l.134 2.232l1.866 1.232" />
                      <path d="M20.66 7l-5.629 3.25l.01 3.458" />
                      <path d="M19.928 14.268l-1.866 1.232l-.134 2.232" />
                      <path d="M20.66 17l-5.629 -3.25l-2.99 1.738" />
                      <path d="M14 20l-2 -1l-2 1" />
                      <path d="M12 22v-6.5l-3 -1.72" />
                      <path d="M6.072 17.732l-.134 -2.232l-1.866 -1.232" />
                      <path d="M3.34 17l5.629 -3.25l-.01 -3.458" />
                      <path d="M4.072 9.732l1.866 -1.232l.134 -2.232" />
                      <path d="M3.34 7l5.629 3.25l2.99 -1.738" />
                    </svg>&nbsp;&nbsp;&nbsp;&nbsp;<font style={{ color: 'white' }}> {(vc.isFreezed ? 'Activate' : 'Freeze')} </font>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item spacing={3} xs={12} lg={8} alignItems="center">
              <Grid item xs={12} />
              <Grid item xs={12}>
                <Tabs
                  value={value}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                  variant="scrollable"
                >

                  <Tab value={0} onChange={handleTabChange} label={
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke={cornsilk} fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                      <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                    </svg>
                  } />
                  <Tab value={1} onChange={handleTabChange} label={
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-premium-rights" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke={cornsilk} fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                      <path d="M13.867 9.75c-.246 -.48 -.708 -.769 -1.2 -.75h-1.334c-.736 0 -1.333 .67 -1.333 1.5c0 .827 .597 1.499 1.333 1.499h1.334c.736 0 1.333 .671 1.333 1.5c0 .828 -.597 1.499 -1.333 1.499h-1.334c-.492 .019 -.954 -.27 -1.2 -.75" />
                      <path d="M12 7v2" />
                      <path d="M12 15v2" />
                    </svg>
                  } />
                  <Tab value={2} onChange={handleTabChange} label={
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-settings" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke={cornsilk} fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" />
                      <path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                    </svg>
                  } />
                  <Tab value={3} onChange={handleTabChange} label={
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrows-right-left" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke={cornsilk} fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M21 7l-18 0" />
                      <path d="M18 10l3 -3l-3 -3" />
                      <path d="M6 20l-3 -3l3 -3" />
                      <path d="M3 17l18 0" />
                    </svg>
                  } />

                </Tabs>

                <TabPanel value={value} index={0}>
                  <VirtualCardDetails virtual_card={vc} />
                </TabPanel>


                <TabPanel value={value} index={1}>
                  <VirtualCardLimits virtual_card={vc} />
                </TabPanel>

                <TabPanel value={value} index={2}>
                  <VirtualCardSettings virtual_card={vc} />
                </TabPanel>

                <TabPanel value={value} index={3}>
                  <VirtualCardTransactions virtual_card={vc} />
                </TabPanel>

              </Grid>
            </Grid>
          </Grid>

          <DialogActions sx={{ pr: 2.5, pt: 2.5 }}>
            <Button onClick={handleClose} color="error">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
