import { Grid } from "@material-ui/core";
import React from "react";
import SwlNegative from "../SwlPositiveNegative/SwlNegative";
import CenterLayout from "../CenterLayout";
import CandleChart from "../CandleChart";
import ExploreRecent from "../Explore/components/ExploreRecent";
import LiteOrderTicket from "ui-component/cards/LiteOrderTicket";
import SummaryCard from "ui-component/cards/SummaryCard";
import SmartWatchListSummary from "../SwlPositiveNegative/components/SmartWatchListSummary";
import Chat from "layout/LiteTakyonLayout/DiveBarClub/Chat";
import { gridSpacing } from "store/constant";

const CollaborativeLayout = () => {
  return (
    <Grid container spacing={2.0} >
        <Grid item xs={9.5} alignItems="center">
            <Grid container padding={1.0} />
            <CandleChart />
            <Grid container padding={1.0} />
            <SwlNegative />
        </Grid>

        <Grid item xs={2.5} alignItems="center">
            <Grid container padding={1.0} />
            <SummaryCard />
            <Grid container padding={1.0} />
            <LiteOrderTicket />
            <Grid container padding={1.0} />
            <Chat />
            <Grid container padding={1.0} />
        </Grid>

    </Grid>
  );
};

export default CollaborativeLayout;
