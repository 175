import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import exchangeReducer from './exchangeReducer';


// reducer import
import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';
import cartReducer from './cartReducer';
import accountReducer from './accountReducer';
import takyonReducer from './takyonReducer';
import orderTicketReducer from './orderTicketReducer';
import chartReducer from './chartReducer';
import favoritesReducer from './favoritesReducer';
import coinReducer from './coinReducer';
import coinbaseReducer from './coinbaseReducer';
import modalSearchReducer from './modalSearchReducer';
import coinbaseQuotesReducer from './coinbaseQuotesReducer';
import coinbaseAccountsReducer from './coinbaseAccountsReducer';
import coinbasePaymentMethodsReducer from './coinbasePaymentMethodsReducer';
import coinbaseConfirmOrderReducer from './coinbaseConfirmOrderReducer';
import diveBarClubReducer from './diveBarClubReducer';
import diveBarChartReducer from './diveBarChartReducer';
import tradesReducer from './tradesReducer';
import cardsReducer from './cardsReducer';

// ===========================|| COMBINE REDUCER ||=========================== //

const reducer = combineReducers({
    customization: customizationReducer,
    snackbar: snackbarReducer,
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'berry-'
        },
        cartReducer
    ),
    takyon: takyonReducer,
    orderTicket: orderTicketReducer,
    chart: chartReducer,
    favorites: favoritesReducer,
    coins: coinReducer,
    coinb: coinbaseReducer,
    exchange: exchangeReducer,
    modalSearch: modalSearchReducer,
    coinbaseQuotes: coinbaseQuotesReducer,
    coinbaseAccounts: coinbaseAccountsReducer,
    coinbasePaymentMethods: coinbasePaymentMethodsReducer,
    coinbaseConfirmOrder: coinbaseConfirmOrderReducer,
    diveBarClub: diveBarClubReducer,
    trades: tradesReducer,
    diveBarChart:diveBarChartReducer,
    cards: cardsReducer,
    account: accountReducer
});

export default reducer;
